import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { fetchAssistants, fetchAssistant } from '../actions/assistants'
import { Filter, Sorter } from '../types/filter'
import { CalendlyUser } from '../types/assistants'

type AssistantsSlice = {
    assistants: CalendlyUser[]
    selectedAssistant?: CalendlyUser
    pending?: boolean
    filters: Filter[]
    sorters: Sorter[]
}

export const assistantsReducer = createSlice({
    name: 'assistants',
    initialState: {
        assistants: [],
        filters: [],
        sorters: []
    } as AssistantsSlice,
    reducers: {
        clearSelected: state => {
            state.selectedAssistant = undefined
        },
        setSelectedAssistant: (state, action: PayloadAction<{ assistant: CalendlyUser }>) => {
            state.selectedAssistant = action.payload.assistant
        }
    },
    extraReducers: builder => {
        builder
            .addCase(fetchAssistants.fulfilled, (state, action) => {
                state.assistants = action.payload.assistants
            })
            .addCase(fetchAssistant.fulfilled, (state, action) => {
                state.selectedAssistant = action.payload.assistant
            })
    }
})

export const { clearSelected, setSelectedAssistant } = assistantsReducer.actions
