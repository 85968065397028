import Table, { ColumnsType } from 'antd/lib/table'
import React, { useCallback, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { deleteDevelopers } from '../../actions/developers'
import { Button } from '../../components/ui/Button/Button'
import { useDispatch, useSelector } from '../../reducers/store'
import { Developer } from '../../types/developer'
import styles from './DevelopersAdminPage.module.css'
import { ReactComponent as PlusIcon } from '../../assets/plus.svg'
import { useDevelopers } from '../../hooks/useDevelopers'
import useGtmPageLoad from '../../hooks/useGtmPageLoad'

export const DevelopersAdminPage = () => {
    useGtmPageLoad('DevelopersAdminPage')
    const dispatch = useDispatch()
    const [selectedItems, setSelectedItems] = useState<string[]>([])
    useDevelopers()
    const data = useSelector(store => store.developers.developers)

    const renderLink = useCallback((value: any, item: Developer) => {
        return <Link to={`/developers-admin/${item.id}`}>{value}</Link>
    }, [])

    const columns: ColumnsType<Developer> = useMemo(() => {
        return [
            {
                dataIndex: 'name',
                title: 'Name',
                render: renderLink
            }
        ]
    }, [renderLink])

    const handleDelete = () => {
        dispatch(deleteDevelopers(selectedItems))
        setSelectedItems([])
    }

    const handleSelect = (selectedKeys: (string | number)[]) => {
        setSelectedItems(selectedKeys.map(key => (typeof key !== 'string' ? key.toString() : key)))
    }

    return (
        <div className={styles.container}>
            <div className={styles.controls}>
                <Link to="/developers-admin/new">
                    <Button icon={<PlusIcon />} style={{ width: 'auto' }} className={styles.button}>
                        Add
                    </Button>
                </Link>
                <Button style={{ width: 'auto' }} type="ghost" className={styles.button} onClick={handleDelete}>
                    Delete
                </Button>
            </div>
            <div style={{ overflow: 'auto' }}>
                <Table columns={columns} dataSource={data} rowSelection={{ onChange: handleSelect }} rowKey="id" />
            </div>
        </div>
    )
}
