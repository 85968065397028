import React from 'react'
import { cn } from '../../../utils/cn'
import { BottomSheet } from '../BottomSheet/BottomSheet'
import styles from './StatsBlock.module.css'

export interface StatsBlockProps {
    title?: string
    titleInfo?: string
    minorValues?: string[]
    majorValues?: string[]
    className?: string
}

export const StatsBlockText = ({ title = '', titleInfo, majorValues = [], minorValues = [], className }: StatsBlockProps) => {
    return (
        <div className={cn(className, styles.container)}>
            {title && (
                <h2 className={cn(styles.title, !minorValues ? 'mb-5' : undefined)}>
                    {title}
                    {titleInfo && <BottomSheet content={titleInfo} className={styles.icon} title={title} />}
                </h2>
            )}
            {majorValues.map((item, index) => {
                return (
                    <React.Fragment key={index}>
                        {minorValues[index] && <div className={styles.textMinorValue}>{minorValues[index]}</div>}
                        <div key={index} className={styles.textMajorValue}>
                            {item}
                        </div>
                    </React.Fragment>
                )
            })}
        </div>
    )
}
