import React, { useState } from 'react'
import styles from './LoginPage.module.css'
import { Redirect, useLocation, useRouteMatch } from 'react-router-dom'
import { cn } from '../../utils/cn'
import { PRIVACY_PAGE, TERMS_PAGE } from '../../api/endpoints'
import { LoginInit } from './LoginInit'
import { useMe } from '../../hooks/useMe'
import { LoginWithEmail } from './LoginWithEmail'
import { LoginWithSms } from './LoginWithSms'
import { LegalFooter } from '../../components/ui/LegalFooter/LegalFooter'
import useGtmPageLoad from '../../hooks/useGtmPageLoad'

export interface LoginPageProps {
    embedded?: boolean
}

export const LoginPage = ({ embedded }: LoginPageProps) => {
    useGtmPageLoad('LoginPage')
    const [step, setStep] = useState('init')
    const [user] = useMe()
    const location = useLocation()

    const match = useRouteMatch()
    if (user && match.path === '/login') {
        const params = new URLSearchParams(location.search)
        return <Redirect to={(params.get('redirect_uri') as string) ?? '/'} />
    }

    const showTerms = step !== 'email' && step !== 'phone'

    return (
        <>
            <div className={cn(styles.container, { [styles.embedded]: !!embedded })}>
                <div className={styles.block} style={{ display: step !== 'init' ? 'none' : 'block' }}>
                    <LoginInit embedded={embedded} onStep={setStep} />
                </div>
                {step === 'email' && <LoginWithEmail popup />}
                {step === 'phone' && <LoginWithSms popup />}
            </div>
            {showTerms && (
                <p className={styles.p}>
                    <span>By logging In, you agree to our </span>
                    <a className={styles.link} href={TERMS_PAGE!}>
                        Terms & Conditions
                    </a>
                    <span> and </span>
                    <a className={styles.link} href={PRIVACY_PAGE!}>
                        Privacy Policy
                    </a>
                </p>
            )}
            <LegalFooter className={styles.footer} />
        </>
    )
}
