import React, { useState, useCallback, useMemo } from 'react'
import { Form } from 'antd'
import { FloatButton } from '../../components/ui/Button/FloatButton'
import InputMask from 'react-input-mask'
import { Input } from '../../components/ui/Input/Input'
import styles from './LoginWithSms.module.css'
import { useForm } from 'antd/lib/form/Form'
import { useRecaptcha } from '../../hooks/useRecaptcha'
import { useDispatch, useSelector } from '../../reducers/store'
import { setRequestedEmail } from '../../reducers/session'
import { sendPost } from '../../utils/api'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import { Dropdown, Option } from '../../components/ui/Dropdown/Dropdown'
import { Button } from '../../components/ui/Button/Button'
import { cn } from '../../utils/cn'
import { SuccessPage } from '../SuccessPage/SuccessPage'
import phoneCodes from '../../mock/phonecodes.mock.json'
import { RECAPTCHA_KEY } from '../../constants/app'

export interface LoginWithSmsProps {
    popup?: boolean
}

export interface IPhoneCodes {
    [key: string]: IPhoneCode
}

export interface IPhoneCode {
    [key: string]: string
}

export function LoginWithSms({ popup }: LoginWithSmsProps) {
    const [form] = useForm()
    const { t } = useTranslation()
    const lang = useSelector(store => store.session.lang) ?? 'en'
    const [completed, setCompleted] = useState(false)
    const { recaptchaRef, recaptchaV2Token, recaptchaV3Failed, setRecaptchaV3Failed } = useRecaptcha(form.submit)
    const dispatch = useDispatch()
    const history = useHistory()
    const [selectedCode, setSelectedCode] = useState<string>()

    const [phoneValue, setPhoneValue] = useState<string>()
    const [buttonEnable, setButtonEnable] = useState<boolean>(false)

    const phoneCodesLang = useMemo(() => (phoneCodes as IPhoneCodes)[lang] || (phoneCodes as IPhoneCodes)['en'], [lang])

    const regionsOptions = useMemo(
        () =>
            phoneCodesLang &&
            Object.keys(phoneCodesLang)
                ?.map((item, index) => ({
                    value: phoneCodesLang[item],
                    label: item,
                    key: index
                }))
                ?.sort((a, b) => (a.label > b.label ? 1 : -1)),
        [phoneCodesLang]
    )

    const handleSubmitSms = (values: Record<string, string>) => {
        const numberValue = `+${selectedCode}${values.phone}`
        if (recaptchaV2Token) {
            smsSignIn(numberValue, recaptchaV2Token, 'v2', lang)
        } else {
            const grecaptcha = (window as any).grecaptcha
            grecaptcha.ready(function () {
                grecaptcha.execute(RECAPTCHA_KEY, { action: 'submit' }).then((token: string) => {
                    smsSignIn(numberValue, token, 'v3', lang)
                })
            })
        }
    }

    const smsSignIn = (phone: string, token: string, type: 'v2' | 'v3', lang?: string) => {
        dispatch(setRequestedEmail({ requestedEmail: phone }))
        sendPost(`/auth/phone`, {}, { phone, token, type, lang })
            .then(() => {
                if (!popup) {
                    history.push('/success/sms')
                } else {
                    setCompleted(true)
                }
            })
            .catch(() => {
                setRecaptchaV3Failed(true)
            })
    }

    const handleChange = useCallback(
        (country: string) => {
            setSelectedCode(country)
            if ((phoneValue || '')?.length > 12 && country?.length) {
                setButtonEnable(true)
            } else {
                setButtonEnable(false)
            }
        },
        [phoneValue]
    )

    const handleChangePhone = useCallback(
        (phone: any) => {
            setPhoneValue(phone?.target?.value)
            if (phone?.target?.value?.length > 12 && selectedCode?.length) {
                setButtonEnable(true)
            } else {
                setButtonEnable(false)
            }
        },
        [selectedCode]
    )

    const handleSearch = useCallback((input: string, option: Option) => {
        return option.label.toLowerCase().includes(input.toLowerCase())
    }, [])

    if (completed) {
        return (
            <div className={styles.successContainer}>
                <SuccessPage forceType="sms" className={styles.success} page={!popup} />
            </div>
        )
    }
    return (
        <div className={styles.container}>
            <Form form={form} onFinish={handleSubmitSms} requiredMark={false}>
                <h1 className={styles.title}>{t('Log In With Cellphone')}</h1>
                <div className={styles.label}>{t('We send you private link SMS')}</div>
                <Form.Item name="phone" className={styles.wesend}>
                    <div className={styles.phoneContainer}>
                        <div>
                            <Dropdown
                                options={regionsOptions}
                                placeholder={t('Select your country')}
                                onChange={handleChange}
                                filterOption={handleSearch as any}
                            />
                        </div>
                        <div className={styles.phoneControls}>
                            <Input value={selectedCode} className={styles.phonePrefix} readOnly placeholder="—" />
                            <InputMask
                                mask="999 999 99 99"
                                maskChar={null}
                                className={styles.phone}
                                placeholder={t('Number')}
                                onChange={handleChangePhone}
                            />
                        </div>
                    </div>
                </Form.Item>
                {!popup && <FloatButton disabled={!buttonEnable} className={styles.mobile} onClick={form.submit} title={t('Send Link')} />}
            </Form>
            {popup && (
                <Button disabled={!buttonEnable} onClick={() => form.submit()} className={styles.button}>
                    {t('Send Link')}
                </Button>
            )}
            <span className={styles.captcha}>
                {t('This site is protected by reCAPTCHA and the Google')}
                <a href="https://policies.google.com/privacy">{t('Privacy Policy')}</a>
                <a href="https://policies.google.com/terms">{t('Terms of Service')}</a>
            </span>
            <div className={cn(styles.recaptcha, { [styles.recaptchaHidden]: !recaptchaV3Failed })} ref={recaptchaRef} />
        </div>
    )
}
