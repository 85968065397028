import { useEffect } from 'react'
import { clearSelectedCategory } from '../reducers/categoryIndustry'
import { useDispatch } from '../reducers/store'
import { fetchCategoryIndustry, fetchCategoriesIndustry } from '../actions/categoryIndustry'

export function useCategoriesIndustry() {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchCategoriesIndustry())
    }, [dispatch])
}

export function useCategoryIndustry(catId: string) {
    const dispatch = useDispatch()
    useEffect(() => {
        if (catId !== 'new') {
            dispatch(fetchCategoryIndustry(catId))
        }
        return () => {
            dispatch(clearSelectedCategory())
        }
    }, [catId, dispatch])
}
