import { BlogArticle } from './blog'
import { Video } from './file'
import { LawFirm } from './lawFirm'
import { Person } from './person'
import { ILanguageItem } from './languages'
import { Review } from './Review'

export interface Expert extends Person {
    languages: ILanguageItem[]
    location: string
    state: string
    timezone: number
    photos: IPhoto[]
    firstConsultationFee: number
    firstConsultationDuration: number
    specialOffer: number
    /**
     * TODO: Rename to totalCost
     */
    totalConst: number
    visaPracticeSet: any
    experience: any
    experienceDescription: string
    lawFirm?: LawFirm
    authorizedIn: string[]
    licensedIn: string[]
    reviews: Review[]
    practiceAreasSet?: any
    video: Video[]
    published: boolean
    jobTitle?: JobTitle
    proposalManualStatus?: string
    proposalManualStatusLocalizations?: any
    proposalStatus?: string
    articles?: BlogArticle[]
    localizations?: Record<
        string,
        { locale: string } & Pick<
            Expert,
            'firstName' | 'lastName' | 'location' | 'biography' | 'experience' | 'experienceDescription' | 'video'
        >
    >
}

export const practiceLevels = [
    { value: 'expert', label: 'Expert' },
    { value: 'super-expert', label: 'Super Expert' },
    { value: 'additional', label: 'Additional' }
]

export interface JobTitle {
    id: number
    name: string
    localizations: Record<string, Omit<JobTitle, 'localizations'> & { locale: string }>
}

export interface IPhoto {
    photo: string
    ord: number
}

export interface ExpertShort {
    id: string
}
