import { createAsyncThunk } from '@reduxjs/toolkit'
import { assistantsGetAll, assistantsGet, assistantsUpdate } from '../api/assistants'
import { RootState } from '../reducers/store'
import { CalendlyUser } from '../types/assistants'

export const fetchAssistants = createAsyncThunk<{ assistants: CalendlyUser[] }, void, { state: RootState }>(
    'fetchAssistants',
    async (_, thunkAPI) => {
        const response = await assistantsGetAll(thunkAPI)
        return { assistants: response }
    }
)

export const fetchAssistant = createAsyncThunk('fetchAssistant', async (assistantSlug: string, thunkAPI) => {
    const response = await assistantsGet(assistantSlug, thunkAPI)
    return { assistant: response }
})

export const updateAssistant = createAsyncThunk<{ assistant: CalendlyUser }, CalendlyUser, { state: RootState }>(
    'updateAssistant',
    async (assistant: CalendlyUser, thunkAPI) => {
        const response = await assistantsUpdate(assistant, thunkAPI)
        return { assistant: response }
    }
)
