import { Page } from './types/page'
import { Person } from './types/person'
import { Project } from './types/project'
import { RegionalCenter } from './types/regionalCenter'
import Avatar from './stories/components/Person/person.png'

const roles = ['ADMIN']

export const pages: Page[] = [
    { url: '/', navigation: 'Home' },
    { url: '/experts', title: 'Back to Home', navigation: 'Experts' },
    { url: '/projects', title: 'Back to Home', navigation: 'EB-5 Projects' },
    { url: '/wiki', title: 'Back to Home', navigation: 'Knowledge Center' },
    { url: '/experts-admin', title: 'Back to Home', navigation: 'Experts Administration', roles },
    { url: '/projects/:id', title: 'Projects for EB-5', group: '/projects' },
    { url: '/projects-admin', title: 'Back to Home', navigation: 'EB-5 Projects Administration', roles },
    { url: '/regional-centers/:id', title: 'Back to Home', showLogo: true, group: '/projects' },
    { url: '/regional-centers-admin', title: 'Back to Home', navigation: 'Regional Centers Administration', roles },
    { url: '/review-admin', title: 'Back to Home', navigation: 'Review Administration', roles },
    { url: '/proposals-direct', title: 'Back to Home', navigation: 'Direct Call Administration', roles },
    { url: '/law-firms-admin', title: 'Back to Home', navigation: 'Law Firms Administration', roles },
    { url: '/assistants-admin', title: 'Back to Home', navigation: 'Assistants Administration', roles },
    { url: '/languages-admin', title: 'Back to Home', navigation: 'Languages Administration', roles },
    { url: '/category-industry-admin', title: 'Back to Home', navigation: 'Category Industry Administration', roles },
    { url: '/required-investment-admin', title: 'Back to Home', navigation: 'Required Investment Administration', roles },
    { url: '/practice-areas-admin', title: 'Back to Home', navigation: 'Practice Areas Administration', roles },
    { url: '/rc-managers-admin', title: 'Back to Home', navigation: 'RC Managers Administration', roles },
    { url: '/developers-admin', title: 'Back to Home', navigation: 'Partners Administration', roles },
    { url: '/jobs-admin', title: 'Back to Home', navigation: 'Jobs Administration', roles },
    { url: '/requests-admin', title: 'Back to Home', navigation: 'Requests Administration', roles },
    { url: '/job-titles-admin', title: 'Back to Home', navigation: 'Expert Titles Administration', roles },
    { url: '/languages-expertise-admin', title: 'Back to Home', navigation: 'Language Expertise Administration', roles },
    { url: '/visa-admin', title: 'Back to Home', navigation: 'Visa Administration', roles },
    { url: '/blog-admin', title: 'Back to Home', navigation: 'Blog Administration', roles },
    { url: '/wiki-admin', title: 'Back to Home', navigation: 'Knowledge Center Administration', roles }
]

const mockDetails = `Mr. Patrick F. Hogan is the founder and CEO of CMB Regional Centers. Mr. Hogan's considerable experience in the EB-5 investment field dating back to 1994 establishes him as one of the most experienced practitioners in the EB-5 industry. In fact, Mr. Hogan has been invo

Mr. Patrick F. Hogan is the founder and CEO of CMB Regional Centers. Mr. Hogan's considerable experience in the EB-5 investment field dating back to 1994 `

export const persons: Person[] = [
    { id: '0', firstName: 'Angelique', lastName: 'Brunner', title: 'Founder & CEO', photo: Avatar, biography: mockDetails, ord: 1 },
    { id: '1', firstName: 'Brian', lastName: 'Ostar', title: 'President', biography: mockDetails, ord: 2 },
    { id: '2', firstName: 'Jonathan', lastName: 'Mullen', title: 'Senior Vice President, Investments', biography: mockDetails, ord: 3 },
    { id: '3', firstName: 'Lulu', lastName: 'Gordon', title: 'Senior Vice President, General counsel', biography: mockDetails, ord: 4 },
    { id: '4', firstName: 'Matthew', lastName: 'Hogan', title: 'Vice President of Project Development', biography: mockDetails, ord: 5 },
    { id: '5', firstName: 'Mariana', lastName: 'Gomez', title: 'Vice President, Communications', biography: mockDetails, ord: 6 },
    { id: '6', firstName: 'Angelique', lastName: 'Brunner', title: 'Director, Business development', biography: mockDetails, ord: 7 }
]

export const rcDescription = `EB5 Capital is $800 million investment firm focused on real estate and headquartered in Washington, DC. Our company provides investment opportunities to foreign nationals seeking permanent residency (green cards). EB5 Capital owns and operates USCIS-authorized Regional Centers that serve 14 states, most notably in California, New York, Oregon, and the District of Columbia. We raise capital from foreign investors to finance job-creating projects across the United States under the EB-5 Immigrant Investor Program.

Our clients come from 70 different countries, and all are eligible, along with their families, to become permanent residents of the United States by investing in qualifying U.S. businesses through our Regional Centers.

With over 28 completed and current projects, a 100% I-526 and I-829 project approval rate, and repayments to investors on multiple past projects, we are one of the few EB-5 operators with such an accomplished track record. Our first I-526 approval came in 2008, when there were fewer than 15 Regional Centers in the country.

EB5 Capital’s success comes from working with experienced development partners, using rigorous standards to select projects, and investing in strong real estate markets. Since EB5 Capital was founded in 2008, our mission has been the same: to connect qualified investors with superior job-creating projects that satisfy all of the requirements of the EB-5 Visa Program.
`

export const regionalCenter: RegionalCenter = {
    id: 'eb5capital',
    name: 'EB5 Capital',
    description: rcDescription,
    city: 'Bethesda',
    state: 'Maryland',
    founded: '2008',
    states: ['AZ', 'AL'],
    eb5CompletedProjects: 12,
    eb5ProgressProjects: 16,
    eb5TotalProjects: 28,
    i526: 26,
    i829: 6,
    raised: '$800+ MLN',
    returned: '$400+ MLN',
    updated: '27 JAN 2021',
    managers: persons,
    timezone: -3,
    languages: {},
    localizations: {},
    files: []
}

export const project: Project = {
    id: 'JF28',
    name: 'Rivergate (JF28)',
    regionalCenter,
    state: 'Virginia',
    address: 'Woodbridge',
    categories: [{ id: '1', name: 'Warehouse and distribution facility', localizations: {} }],
    requiredInvestment: { id: 1, name: '1', value: 1, localizations: {} },
    administrativeFee: 70000,
    specialOffer: 30000,
    total: 940000,
    constructionStartPlanned: 'Q2 2021',
    constructionEndPlanned: 'Q3 2023',
    constructionStartActual: 'Q2 2021',
    constructionEndActual: 'Q3 2023',
    capitalStack: {
        'EB-5 Capital': 18900000,
        'Senior Construction Load': 54900000,
        'Developer Equity': 24300000
    },
    interestRate: 'up to 1%',
    investmentType: 'Preferred equity',
    investmentTypeDescription: `
    Proident reprehenderit proident ullamco velit sit dolor excepteur nisi eu fugiat occaecat. Id nulla nostrud incididunt
    nisi ad sit esse proident cupidatat reprehenderit sint culpa nulla. Labore quis cillum magna ut enim amet sit qui.
`,
    investmentTerm: '5+1+1 years',
    eb5Units: 21,
    jobsRequired: 210,
    jobsEstimated: 386,
    description: `
EB-5 Capital - Jobs Fund 28, LLC, Rivergate is the second phase of the development of a riverfront apartment community along the
Occoquan River in Woodbridge, VA, which is one of the fastest growing and most mock mock mock
Rivergate is the second phase of the development of a riverfront apartment community along the
Occoquan River in Woodbridge, VA, which is one of the fastest growing and most mock mock mock
Rivergate is the second phase of the development of a riverfront apartment community along the
Occoquan River in Woodbridge, VA, which is one of the fastest growing and most mock mock mock
    `,
    files: [],
    photos: [],
    video: [],
    status: 'open',
    tea: false,
    published: true,
    disclaimer: 'Any discounts and adjustments to offering specifics are subject to offering documents.',
    localizations: {}
}
