import { Select, SelectProps } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocalizedStates } from '../../hooks/useLocalizedStates'
import { Project } from '../../types/project'
import { Dropdown } from '../ui/Dropdown/Dropdown'
import styles from './StatesSelect.module.css'

interface StateSelectProps extends SelectProps<any> {
    allStates?: boolean | React.ReactNode
    projects?: Project[]
}

export const StatesSelect = ({
    allStates = false,
    value,
    defaultValue,
    projects,
    maxTagCount,
    tagRender,
    onChange,
    ...rest
}: StateSelectProps) => {
    const states = useLocalizedStates()
    const [selectedValue, setSelectedValue] = useState(defaultValue ?? value)
    const [selectedValues, setSelectedValues] = useState<string[]>(defaultValue ?? value ?? [])
    const { t } = useTranslation()
    useEffect(() => {
        if (rest.mode) {
            setSelectedValues(value ?? [])
        }
    }, [value, rest.mode])

    const handleSingleChane = (value: string, option: any) => {
        setSelectedValue(value)
        onChange?.(value, option)
    }

    const handleChange = (value: string[], option: any) => {
        let newValue = value
        if (selectedValues.includes('all') && value.length > 1) {
            newValue = value.filter(item => item !== 'all')
        } else if (!selectedValues.includes('all') && value.includes('all')) {
            newValue = ['all']
        } else if (value.length === 0 && allStates) {
            newValue = ['all']
        }
        setSelectedValues(newValue)
        onChange?.(newValue, option)
    }

    const Tag = ({ value, label, ...rest }: any) => {
        const isLast = selectedValues[selectedValues.length - 1] === value
        return <span className={styles.tag}>{`${states[value] ?? label}${isLast ? '' : ','}`}</span>
    }

    const availableOptions = useMemo(() => {
        let availableStates: string[] = []
        projects
            ?.map(item => item.state)
            .forEach(item => {
                if (!availableStates.includes(item)) {
                    availableStates.push(item)
                }
            })
        let result: Record<string, number | null> = {}
        if (!projects) {
            Object.keys(states).forEach(item => (result[item] = null))
        } else {
            availableStates.forEach(item => {
                result[item] = projects.filter(project => project.state === item && project.published)?.length
            })
        }
        return Object.entries(result).map(([key, amount]) => {
            const defaultRender = !projects && states[key]
            const openProjects = projects && !!amount && (
                <>
                    {states[key]}, <span className={styles.open}>{t('N open projects', { count: amount })}</span>{' '}
                </>
            )
            const noOpenProjects = projects && amount === 0 && (
                <span className={styles.open}>
                    {states[key]}, {t('no open projects')}
                </span>
            )
            return noOpenProjects ? null : (
                <Select.Option key={key} value={key} disabled={!!noOpenProjects}>
                    {defaultRender}
                    {openProjects}
                    {noOpenProjects}
                    <span> {key}</span>
                </Select.Option>
            )
        })
    }, [projects, states, t])

    return (
        <Dropdown
            maxTagCount={maxTagCount === undefined ? undefined : 'responsive'}
            onChange={rest.mode ? handleChange : handleSingleChane}
            inlineTags
            value={rest.mode ? selectedValues : selectedValue}
            tagRender={Tag as any}
            {...rest}
        >
            {allStates === true && (
                <Select.Option key="all" value="all">
                    {t('All States')}
                </Select.Option>
            )}
            {allStates !== true && allStates}
            {availableOptions}
        </Dropdown>
    )
}
