import React, { ReactNodeArray, useState } from 'react'
import { Carousel as AntdCarousel, CarouselProps as AntdCarouselProps, Modal } from 'antd'
import { cn } from '../../../utils/cn'
import styles from './Carousel.module.css'
import { CarouselRef } from 'antd/lib/carousel'
import { ReactComponent as LeftIcon } from '../../../assets/chevron-large-left.svg'
import { ReactComponent as RightIcon } from '../../../assets/chevron-large-right.svg'
import { ReactComponent as CloseIcon } from '../../../assets/×-modal-desktop.svg'
import { createPortal } from 'react-dom'

export type CarouselProps = AntdCarouselProps & {
    /**
     * If true slider dots will be padded to allow some content between images and dots (e.g. image description)
     */
    paddedDots?: boolean
    /**
     * If false only first picture will be shown as preview
     */
    showGallery?: boolean
    /**
     * If false previews will not open modal on click
     */
    showModal?: boolean
}

/**
 * Image carousel
 *
 * On mobile swipes through all pictures
 *
 * On desktop shows only three previews and opens slidable modal with rest
 */
export const Carousel = React.forwardRef<CarouselRef | null, CarouselProps>(
    ({ className, children, dots, paddedDots, showGallery = true, showModal = true, ...rest }: CarouselProps, ref) => {
        const [imgIndex, setImgIndex] = useState<number | undefined>(undefined)

        let mainPreview: React.ReactNode = null
        let secondPreview: React.ReactNode = null
        let thirdPreview: React.ReactNode = null
        const isDesktop = window.screen.width > 960

        if (Array.isArray(children)) {
            mainPreview = children[0]
            secondPreview = children[1]
            thirdPreview = children[2]
        }

        const handleClick = (imgIndex: number) => {
            if (showModal) {
                setImgIndex(imgIndex)
            }
        }

        const handleLeft = () => {
            const newImgIndex = imgIndex! - 1
            setImgIndex(newImgIndex >= 0 ? newImgIndex : (children as ReactNodeArray).length - 1)
        }

        const handleRight = () => {
            const newImgIndex = imgIndex! + 1
            setImgIndex(newImgIndex < (children as ReactNodeArray).length ? newImgIndex : 0)
        }

        return (
            <>
                {!isDesktop && (
                    <AntdCarousel
                        {...rest}
                        ref={ref}
                        className={cn(className, styles.mobile, styles.container, { [styles.paddedDots]: !!paddedDots })}
                        dots={dots === false ? false : { className: styles.dots }}
                    >
                        {children}
                    </AntdCarousel>
                )}
                {isDesktop && (
                    <div className={cn(styles.desktop, styles.previews, className)}>
                        {mainPreview && (
                            <button className={cn(styles.preview, styles.mainPreview)} onClick={() => handleClick(0)}>
                                {mainPreview}
                            </button>
                        )}
                        {showGallery && (
                            <div className={styles.secondaryPreviews}>
                                {secondPreview && (
                                    <button
                                        className={cn(styles.preview, {
                                            [styles.secondaryPreview]: !!thirdPreview,
                                            [styles.secondaryPreviewOnly]: !thirdPreview
                                        })}
                                        onClick={() => handleClick(1)}
                                    >
                                        {secondPreview}
                                    </button>
                                )}
                                {thirdPreview && (
                                    <button className={cn(styles.preview, styles.tertiaryPreview)} onClick={() => handleClick(2)}>
                                        <div className={styles.caption}>Show more</div>
                                        <div className={styles.tertiaryPreviewContainer}>{thirdPreview}</div>
                                    </button>
                                )}
                            </div>
                        )}
                    </div>
                )}
                {Array.isArray(children) && (
                    <Modal
                        className={styles.modal}
                        visible={imgIndex !== undefined}
                        centered
                        width="78%"
                        footer={null}
                        onCancel={() => setImgIndex(undefined)}
                        closeIcon={<CloseIcon />}
                        maskClosable={false}
                        destroyOnClose
                    >
                        {createPortal(
                            <button className={cn(styles.arrow, styles.left)} onClick={handleLeft}>
                                <LeftIcon style={{ marginLeft: '26px' }} />
                            </button>,
                            document.querySelector('body')!
                        )}
                        <div className={styles.modalContent}>{children?.[imgIndex!]}</div>
                        <ul className={styles.miniContainer}>
                            {(children as ReactNodeArray)?.map((item, index) => {
                                return (
                                    <li
                                        key={index}
                                        className={cn(styles.miniItem, { [styles.selected]: index === imgIndex })}
                                        onClick={() => setImgIndex(index)}
                                    >
                                        {item}
                                    </li>
                                )
                            })}
                        </ul>
                        {createPortal(
                            <button className={cn(styles.arrow, styles.right)} onClick={handleRight}>
                                <RightIcon style={{ marginLeft: '36px' }} />
                            </button>,
                            document.querySelector('body')!
                        )}
                    </Modal>
                )}
            </>
        )
    }
)
