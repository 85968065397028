import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as DownloadIcon } from '../../../assets/download.svg'
import { BASE_FILE_URL } from '../../../api/endpoints'
import { File } from '../../../types/file'
import { cn } from '../../../utils/cn'
import styles from './AttachedDocuments.module.css'

export interface AttachedDocumentsProps {
    localeFiles: File[]
    otherFiles: File[]
    className?: string
    title: string
}

export function AttachedDocuments({ localeFiles, otherFiles, title, className }: AttachedDocumentsProps) {
    const { t } = useTranslation()

    const [showOtherLanguages, setShowOtherLanguages] = useState(false)

    if (!localeFiles?.length && !otherFiles?.length) {
        return null
    }

    return (
        <div className={className}>
            <h3 className={styles.header}>{title}</h3>
            <ul className={cn(styles.padding, styles.docsList)}>
                {localeFiles.map(item => (
                    <li key={item.id} className={styles.docsItem}>
                        <a href={`${BASE_FILE_URL}/${item.name}`} className={styles.docsLink} target="_blank" rel="noreferrer">
                            <span className={styles.docName}>
                                <DownloadIcon className={styles.download} /> {item.originalName || item.name}
                            </span>
                            <span className={styles.docSize}>{formatSize(item.size)}</span>
                        </a>
                    </li>
                ))}
                {showOtherLanguages &&
                    otherFiles.map(item => (
                        <li key={item.id} className={styles.docsItem}>
                            <a href={`${BASE_FILE_URL}/${item.name}`} className={styles.docsLink} target="_blank" rel="noreferrer">
                                <span className={styles.docName}>
                                    <DownloadIcon className={styles.download} /> {item.originalName || item.name}
                                </span>
                                <span className={styles.docSize}>{formatSize(item.size)}</span>
                            </a>
                        </li>
                    ))}
            </ul>
            {!showOtherLanguages && otherFiles?.length > 0 && (
                <button className={cn(styles.padding, styles.showOthers)} onClick={() => setShowOtherLanguages(true)}>
                    {t('Show Other Languages')}
                </button>
            )}
        </div>
    )
}

function formatSize(size: number | undefined) {
    if (!size) {
        return ''
    }
    if (size > 1024 * 1024 * 1024) {
        return `${(size / (1024 * 1024 * 1024)).toFixed(1)} Gb`
    }
    if (size > 1024 * 1024) {
        return `${(size / (1024 * 1024)).toFixed(1)} Mb`
    }
    if (size > 1024) {
        return `${(size / 1024).toFixed(1)} Kb`
    }
    return `${(size / 1024).toFixed(1)} B`
}
