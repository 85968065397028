import { Form, Popover, Tabs } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { Button } from '../../components/ui/Button/Button'
import { Input } from '../../components/ui/Input/Input'
import { useDispatch, useSelector } from '../../reducers/store'
import styles from './RegionalCenterManagersAdminEditPage.module.css'
import { useHistory, useParams } from 'react-router-dom'
import FormItem from 'antd/lib/form/FormItem'
import { FileUpload } from '../../components/ui/FileUpload/FileUpload'
import { useRCManager, useRCManagers } from '../../hooks/useRCManagers'
import { addRCManager, updateRCManager } from '../../actions/rcmanagers'
import { RCManager } from '../../types/rcmanager'
import { Dropdown } from '../../components/ui/Dropdown/Dropdown'
import TextArea from 'antd/lib/input/TextArea'
import { useLanguages } from '../../hooks/useLanguages'
import { useToggle } from '../../hooks/useToggle'
import { useRegionalCenters } from '../../hooks/useRegionalCenters'
import useGtmPageLoad from '../../hooks/useGtmPageLoad'

export const RegionalCenterManagersAdminEditPage = () => {
    useGtmPageLoad('RegionalCenterManagersAdminEditPage')
    const dispatch = useDispatch()
    useLanguages()
    const languages = useSelector(store => store.languages.languages)
    const history = useHistory()
    const [form] = Form.useForm()
    const ref = useRef(null)
    const [locales, setLocales] = useState<Record<string, any>>({})
    const { id } = useParams<{ id: string }>()
    const [showPopover, togglePopover] = useToggle()
    const [active, setActive] = useState('en')
    const [selectedRC, setSelectedRC] = useState<string>()
    const isNew = id === 'new'
    useRCManager(id)
    useRCManagers()
    useRegionalCenters()
    const data = useSelector(store => store.regionalCenters)
    const { centers, selectedManager } = data
    const handleCancel = () => {
        history.push('/rc-managers-admin')
    }

    const rcOptions = centers.map(item => ({ value: item.id, label: item.name }))

    useEffect(() => {
        if (selectedManager) {
            setLocales(selectedManager.localizations ?? {})
        }
    }, [selectedManager])

    const handleAdd = (values: Record<string, any>) => {
        const rcId = selectedRC || selectedManager?.regionalCenter?.id.toString()
        let photoItem = typeof values.photo === 'string' ? values.photo : values.photo?.[0] || null
        const manager: RCManager = {
            ...(selectedManager as RCManager),
            biography: values.biography,
            firstName: values.firstName,
            lastName: values.lastName,
            ord: values.ord,
            photo: photoItem,
            title: values.title,
            regionalCenter: centers?.find(item => item.id.toString() === rcId),
            localizations: values.localizations
        }
        Object.entries(manager.localizations ?? {}).forEach(([key, value]) => {
            value.locale = key
        })
        dispatch(addRCManager({ manager, onSuccess: handleCancel }))
    }

    const handleUpdate = (values: Record<string, any>) => {
        if (values.localizations) {
            Object.keys(values.localizations).forEach(key => {
                values.localizations[key].locale = key
            })
        }
        const rcId = selectedRC || selectedManager?.regionalCenter?.id.toString()
        let photoItem = typeof values.photo === 'string' ? values.photo : values.photo?.[0] || null
        const manager: RCManager = {
            ...(selectedManager as RCManager),
            biography: values.biography,
            firstName: values.firstName,
            lastName: values.lastName,
            ord: values.ord,
            photo: photoItem,
            title: values.title,
            regionalCenter: centers?.find(item => item.id.toString() === rcId),
            localizations: {
                ...locales,
                ...values.localizations
            }
        }
        dispatch(updateRCManager({ manager, onSuccess: handleCancel }))
    }

    const handleAddLocale = (value: any, item: any) => {
        setLocales({
            ...locales,
            [value]: { locale: value }
        })
        setActive(value)
        togglePopover()
    }

    const opts = languages
        .filter(l => {
            return l.code !== 'en' && !Object.keys(locales).includes(l.code)
        })
        .map((l: any) => ({ value: l.code, label: l.name }))
    const Content = <Dropdown options={opts as any} onSelect={handleAddLocale} />

    const Add = <span ref={ref}>+ Add</span>

    if (!selectedManager && !isNew) {
        return null
    }

    const handleChangeRC = (value: any, item: any) => {
        setSelectedRC(value)
    }

    const tabs = Object.entries(locales).map(([key, item]) => {
        return (
            <Tabs.TabPane key={key} tab={languages.find(l => l.code === key)?.name}>
                <Form
                    className={styles.form}
                    layout="vertical"
                    form={form}
                    onFinish={isNew ? handleAdd : handleUpdate}
                    initialValues={selectedManager}
                >
                    <FormItem label="Title" name={['localizations', key, 'title']} required wrapperCol={{ span: 8 }}>
                        <Input />
                    </FormItem>
                    <FormItem label="First Name" name={['localizations', key, 'firstName']} required wrapperCol={{ span: 8 }}>
                        <Input />
                    </FormItem>
                    <FormItem label="Last Name" name={['localizations', key, 'lastName']} required wrapperCol={{ span: 8 }}>
                        <Input />
                    </FormItem>
                    <FormItem label="Biography" name={['localizations', key, 'biography']} wrapperCol={{ span: 8 }}>
                        <TextArea rows={9} />
                    </FormItem>
                </Form>
            </Tabs.TabPane>
        )
    })

    const defaultPane = (
        <Tabs.TabPane key="en" tab="English" closable={false}>
            <Form
                className={styles.form}
                form={form}
                layout="vertical"
                onFinish={isNew ? handleAdd : handleUpdate}
                initialValues={selectedManager}
                autoComplete="off"
            >
                <FormItem label="Title" name="title" required wrapperCol={{ span: 8 }}>
                    <Input />
                </FormItem>
                <FormItem label="First Name" name="firstName" required wrapperCol={{ span: 8 }}>
                    <Input />
                </FormItem>
                <FormItem label="Last Name" name="lastName" required wrapperCol={{ span: 8 }}>
                    <Input />
                </FormItem>
                <FormItem label="Biography" name="biography" wrapperCol={{ span: 8 }}>
                    <TextArea rows={9} />
                </FormItem>
                <FormItem
                    label="Regional Center"
                    name="regionalCenterId"
                    required
                    wrapperCol={{ span: 8 }}
                    initialValue={selectedManager?.regionalCenter?.id.toString()}
                >
                    <Dropdown options={rcOptions} onChange={handleChangeRC} />
                </FormItem>
                <FormItem label="Photo" name="photo" wrapperCol={{ span: 8 }}>
                    <FileUpload preview type="image" />
                </FormItem>
                <FormItem label="Order" name="ord" wrapperCol={{ span: 8 }}>
                    <Input />
                </FormItem>
            </Form>
        </Tabs.TabPane>
    )

    const handleTabEdit = (e: string | React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>, action: 'add' | 'remove') => {
        if (action === 'add') {
            togglePopover()
        } else {
            const newLocales = { ...locales }
            delete newLocales[e as string]
            setLocales(newLocales)
        }
    }

    return (
        <div className={styles.container}>
            <Popover content={Content} visible={showPopover} onVisibleChange={togglePopover} />
            <Tabs type="editable-card" activeKey={active} onEdit={handleTabEdit} addIcon={Add} onChange={key => setActive(key)}>
                {defaultPane}
                {tabs}
            </Tabs>
            <div className={styles.footer}>
                <Button type="ghost" onClick={handleCancel} className={styles.button}>
                    Cancel
                </Button>
                <Button onClick={form.submit} style={{ width: 'auto' }} className={styles.button}>
                    Save
                </Button>
            </div>
        </div>
    )
}
