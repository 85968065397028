import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useSelector } from '../../reducers/store'

import { getLocaleValue } from '../../utils/string'
import { cn } from '../../utils/cn'

import { usePracticeAreas } from '../../hooks/usePracticeAreas'
import { useVisas } from '../../hooks/useVisa'

import styles from './Practice.module.css'

export type TPractice = 'Visa' | 'Area'

export interface PracticeProps {
    practice: Record<string, string>
    mode?: TPractice
}

export const Practice = ({ practice = {}, mode = 'Visa' }: PracticeProps) => {
    const { t } = useTranslation()
    useVisas()
    usePracticeAreas()
    const lang = useSelector(store => store.session.lang)
    const visas = useSelector(store => store.experts.visas)
    const practiceAreas = useSelector(store => store.experts.practiceAreas)
    const data = mode === 'Visa' ? visas : practiceAreas
    const showSuperExpert = Object.values(practice).includes('super-expert')
    const showExpert = Object.values(practice).includes('expert')
    const showAdditional = Object.values(practice).includes('additional')

    const renderBlock = useCallback(
        (name: string, practice: Record<string, string>) => {
            return Object.entries(practice)
                .filter(([_, item]) => item === name)
                .map(([key, value]) => {
                    const checkData = data?.find(item => (mode === 'Visa' ? item.code === key : item.id === key))
                    const label = getLocaleValue(checkData, 'label', lang)
                    const description = getLocaleValue(checkData, 'description', lang)
                    const code = getLocaleValue(checkData, 'code', lang)
                    return (
                        <li key={key} className={styles.item}>
                            {mode === 'Visa' && (
                                <>
                                    <span className={mode === 'Visa' ? styles.key : styles.keyArea}>{label}</span>
                                    <span className={styles.value}>{description}</span>
                                </>
                            )}
                            {mode === 'Area' && <span className={styles.code}>{code}</span>}
                        </li>
                    )
                })
        },
        [data, lang, mode]
    )

    return (
        <div className={styles.container}>
            {showSuperExpert && (
                <div className={styles.block}>
                    <h4 className={styles.header}>{t('Super Expert (more than 100 cases)')}</h4>
                    <ul className={styles.visas}>{renderBlock('super-expert', practice)}</ul>
                </div>
            )}
            {showAdditional && (
                <div className={cn(styles.block, styles.desktop)}>
                    <h4 className={styles.header}>{t('Additional area of expertise')}</h4>
                    <ul className={styles.visas}>{renderBlock('additional', practice)}</ul>
                </div>
            )}
            {showExpert && (
                <div className={styles.block}>
                    <h4 className={styles.header}>{t('Expert (26-100 cases)')}</h4>
                    <ul className={styles.visas}>{renderBlock('expert', practice)}</ul>
                </div>
            )}
            {showAdditional && ( // mobile blocks has another sort
                <div className={cn(styles.block, styles.mobile)}>
                    <h4 className={styles.header}>{t('Additional area of expertise')}</h4>
                    <ul className={styles.visas}>{renderBlock('additional', practice)}</ul>
                </div>
            )}
        </div>
    )
}
