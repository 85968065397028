import React, { useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import { ReviewPreview } from '../../components/ui/Reviews/ReviewPreview'
import { useExpert } from '../../hooks/useExperts'
import { useSelector } from '../../reducers/store'
import { cn } from '../../utils/cn'
import styles from './ExpertReviewsPage.module.css'
import { ReactComponent as StarFilled } from '../../assets/star-filled.svg'
import { ReactComponent as StarEmpty } from '../../assets/star-empty.svg'
import { ReactComponent as StarHalf } from '../../assets/star-half.svg'
import { getAverageRating } from '../../utils/experts'
import { useTranslation } from 'react-i18next'
import { ReviewBrowser } from '../../components/ui/Reviews/ReviewBrowser'
import useGtmPageLoad from '../../hooks/useGtmPageLoad'

export function ExpertReviewsPage() {
    useGtmPageLoad('ExpertReviewsPage')
    const { id: routeId } = useParams<{ id: string }>()
    useExpert(routeId)
    const { t } = useTranslation()
    const expert = useSelector(store => store.experts.selectedExpert)
    const hintText = !expert?.reviews?.length ? t('No reviews') : t('N reviews', { count: expert.reviews?.length ?? 0 })
    const average = getAverageRating(expert)
    const [previewIndex, setPreviewIndex] = useState<number | undefined>()
    const handlePreview = useCallback((index: number) => {
        setPreviewIndex(index)
    }, [])
    const star = useMemo(() => {
        if (average >= 0.75) {
            return <StarFilled className={styles.star} />
        }
        if (average >= 0.25) {
            return <StarHalf className={styles.star} />
        }
        return <StarEmpty className={styles.star} />
    }, [average])

    if (!expert) {
        return null
    }
    return (
        <div className={styles.container}>
            <h1 className={styles.title}>{`Customer reviews for ${expert?.firstName} ${expert?.lastName}`}</h1>
            <div className={styles.total}>
                {star}
                <span className={styles.average}>{(average || 0).toPrecision(2)}</span>
                <span className={styles.reviewsTotal}>({hintText})</span>
            </div>
            <ul className={cn(styles.padding, styles.reviews)}>
                {expert?.reviews
                    ?.filter(item => item.approved)
                    ?.map((item, index) => (
                        <li key={item.id} className={styles.reviewItem} onClick={() => handlePreview(index)}>
                            <ReviewPreview review={item} />
                        </li>
                    ))}
            </ul>
            <div className={cn(styles.padding, styles.mobile)}>
                <ReviewBrowser
                    disabled={!expert.reviews?.length}
                    reviews={expert.reviews}
                    initialIndex={previewIndex}
                    initialShow={previewIndex !== undefined}
                    onClose={() => setPreviewIndex(undefined)}
                    hideButton={true}
                />
            </div>
        </div>
    )
}
