import { createSlice } from '@reduxjs/toolkit'
import { addLawFirm, fetchLawFirms, fetchLawFirm } from '../actions/lawFirms'
import { Filter, Sorter } from '../types/filter'
import { LawFirm } from '../types/lawFirm'

type LawFirmSlice = {
    firms: LawFirm[]
    selectedFirm?: LawFirm
    pending?: boolean
    filters: Filter[]
    sorters: Sorter[]
}

export const lawFirmsReducer = createSlice({
    name: 'lawFirms',
    initialState: {
        firms: [],
        filters: [],
        sorters: []
    } as LawFirmSlice,
    reducers: {
        clearSelected: state => {
            state.selectedFirm = undefined
        }
    },
    extraReducers: builder => {
        builder
            .addCase(fetchLawFirms.fulfilled, (state, action) => {
                state.firms = action.payload.firms
            })
            .addCase(fetchLawFirm.fulfilled, (state, action) => {
                state.selectedFirm = action.payload.firm
            })
            .addCase(addLawFirm.fulfilled, state => {
                state.selectedFirm = undefined
            })
    }
})

export const { clearSelected } = lawFirmsReducer.actions
