import { createSlice } from '@reduxjs/toolkit'
import { fetchCategoryIndustry, fetchCategoriesIndustry } from '../actions/categoryIndustry'
import { CategoryIndustry } from '../types/categoryIndustry'

type CategoriesIndustrySlice = {
    categoriesIndustry: CategoryIndustry[]
    selectedCategory?: CategoryIndustry
}

export const categoriesIndustryReducer = createSlice({
    name: 'categoriesIndustry',
    initialState: {
        categoriesIndustry: []
    } as CategoriesIndustrySlice,
    reducers: {
        clearSelectedCategory: state => {
            state.selectedCategory = undefined
        }
    },
    extraReducers: builder => {
        builder
            .addCase(fetchCategoriesIndustry.fulfilled, (state, action) => {
                state.categoriesIndustry = action.payload.category
            })
            .addCase(fetchCategoryIndustry.fulfilled, (state, action) => {
                state.selectedCategory = action.payload.category
            })
    }
})

export const { clearSelectedCategory } = categoriesIndustryReducer.actions
