import React, { useCallback } from 'react'
import { BottomSheet } from '../ui/BottomSheet/BottomSheet'
import { ReactComponent as Bell } from '../../assets/bell.svg'
import { ReactComponent as LogoIcon } from '../../assets/logo-icon.svg'
import styles from './Notifications.module.css'
import { Avatar, Badge, Dropdown, Empty } from 'antd'
import { useDispatch, useSelector } from '../../reducers/store'
import { readAllNotifications, readNotification } from '../../actions/notifications'
import { useTranslation } from 'react-i18next'
import { BASE_FILE_URL } from '../../api/endpoints'
import { toText } from '../../utils/datetime'
import { cn } from '../../utils/cn'
import { Link } from 'react-router-dom'
import { useToggle } from '../../hooks/useToggle'
import { Button } from '../ui/Button/Button'
import { useAssistants } from '../../hooks/useAssistants'
import { SimpleRichEditor } from '../ui/SimpleRichEditor/SimpleRichEditor'
import { getLocaleValue } from '../../utils/string'

export interface NotificationsProps {
    className?: string
    getPopupContainer?: (node: HTMLElement) => HTMLElement
}

export const Notifications = ({ className, getPopupContainer }: NotificationsProps) => {
    const dispatch = useDispatch()
    useAssistants()
    const [show, toggleShow] = useToggle()
    const { t } = useTranslation()
    const locale = useSelector(store => store.session.lang)
    const notifications = useSelector(store => store.session.notifications)
    const assistants = useSelector(store => store.assistants.assistants)
    const handleRead = useCallback(
        (notificationId: number) => {
            dispatch(readNotification(notificationId))
        },
        [dispatch]
    )
    const isDesktop = window.screen.width > 960
    const handleReadAll = useCallback(() => {
        dispatch(readAllNotifications())
    }, [dispatch])

    const content = (
        <div className={styles.container}>
            <h2 className={styles.title}>
                {t('Notifications')}
                <span className={styles.count}>{notifications.length}</span>
            </h2>
            {!notifications.length && <Empty className={styles.empty} />}
            {notifications.length > 0 && (
                <ul className={styles.list}>
                    {notifications.map(item => {
                        const assistantAva = assistants.find(a => a.id === item.assistant?.id)?.avatarUrl
                        const expertAva = item.expert?.photos?.[0]?.photo
                        const content = (
                            <li key={item.id} className={styles.card}>
                                <div className={styles.avatarContainer}>
                                    {!['ASSISTANT_COMMENT', 'FACELESS_MESSAGE'].includes(item.type) && expertAva && (
                                        <Avatar size={48} src={`${BASE_FILE_URL}/${expertAva}`} />
                                    )}
                                    {assistantAva && <Avatar size={48} src={assistantAva} />}
                                    {!item.expert?.photos?.[0] && !assistantAva && <LogoIcon />}
                                </div>
                                <div className={cn(styles.content, { [styles.read]: !!item.read })}>
                                    {item.type === 'LEAVE_REVIEW' && !item.read && (
                                        <Link to={`/experts/${item.expert?.id}/write-review`} onClick={toggleShow}>
                                            <p>
                                                {t('leave review text notification')}
                                                <b>
                                                    {getLocaleValue(item.expert, 'firstName', locale)}{' '}
                                                    {getLocaleValue(item.expert, 'lastName', locale)}
                                                </b>
                                            </p>
                                        </Link>
                                    )}
                                    {item.type === 'LEAVE_REVIEW' && item.read && (
                                        <p>
                                            {t('leave review text notification')}
                                            <b>
                                                {getLocaleValue(item.expert, 'firstName', locale)}{' '}
                                                {getLocaleValue(item.expert, 'lastName', locale)}
                                            </b>
                                        </p>
                                    )}
                                    {item.type === 'APPLICATION_SENT' && (
                                        <p className={styles.notification} onClick={() => handleRead(item.id)}>
                                            {t('leave review text notification')}
                                            <b>
                                                {getLocaleValue(item.expert, 'firstName', locale)}{' '}
                                                {getLocaleValue(item.expert, 'lastName', locale)}
                                            </b>{' '}
                                        </p>
                                    )}
                                    {['ASSISTANT_COMMENT', 'FACELESS_MESSAGE'].includes(item.type) && item.text && (
                                        <SimpleRichEditor readOnly className={styles.notification} value={item.text} />
                                    )}
                                    <div className={styles.time}>{toText(new Date(item.created))}</div>
                                </div>
                            </li>
                        )
                        if (item.job?.id && item.type !== 'LEAVE_REVIEW') {
                            return (
                                <Link
                                    to={`/job/${item.job?.id}`}
                                    onClick={() => {
                                        handleRead(item.id)
                                        toggleShow()
                                    }}
                                >
                                    {content}
                                </Link>
                            )
                        }
                        return content
                    })}
                </ul>
            )}
        </div>
    )

    const footer = (
        <Button className={styles.button} type="ghost" onClick={handleReadAll}>
            {t('Mark All Notifications As Read')}
        </Button>
    )

    return !isDesktop ? (
        <BottomSheet content={content} show={show} onToggle={toggleShow} size="large" footer={footer}>
            <Badge dot={notifications.some(item => !item.read)} className={className}>
                <Bell />
            </Badge>
        </BottomSheet>
    ) : (
        <Dropdown
            overlay={
                <div className={styles.overlay}>
                    {content}
                    {footer}
                </div>
            }
            visible={show}
            onVisibleChange={toggleShow}
            trigger={['click']}
            placement="bottomRight"
        >
            <Badge dot={notifications.some(item => !item.read)} className={className}>
                <button className={styles.bell}>
                    <Bell />
                </button>
            </Badge>
        </Dropdown>
    )
}
