import React, { memo, SyntheticEvent, useCallback, useRef, useState } from 'react'
import { BASE_FILE_URL } from '../../../api/endpoints'
import { Video } from '../../../types/file'
import { cn } from '../../../utils/cn'
import styles from './VideoPlayer.module.css'
import { ReactComponent as PlayIcon } from '../../../assets/play.svg'
import { useToggle } from '../../../hooks/useToggle'
import { CarouselRef } from 'antd/lib/carousel'
import { Carousel } from '../Carousel/Carousel'

export interface VideoPlayerProps {
    video?: Video[]
    className?: string
    replaceDescription?: (index: number) => React.ReactNode
    dots?: boolean
}

export const VideoPlayerT = ({ video, className, replaceDescription, dots }: VideoPlayerProps) => {
    const [activeVideo, setActiveVideo] = useState<Video | null>(null)
    const time = useRef<HTMLDivElement | null>(null)
    const carouselRef = useRef<CarouselRef>(null)
    const containerRef = useRef<HTMLDivElement>(null)
    const [currentVideoIndex, setCurrentVideoIndex] = useState(0)
    const [playing, togglePlaying, setPlaying] = useToggle()
    const isDesktop = window.screen.width > 960
    const videoSelector = isDesktop ? `.${styles.video}` : `.slick-current .${styles.video}`
    const durationSelector = isDesktop ? `.${styles.time}` : `.slick-current .${styles.time}`

    const updateTime = useCallback(
        (duration?: number) => {
            const val = getTime(duration ?? containerRef.current?.querySelector<HTMLVideoElement>(videoSelector)?.duration)
            const times = containerRef.current?.querySelectorAll<HTMLVideoElement>(`.${styles.time}`)
            times?.forEach(item => {
                item.innerText = val
            })
        },
        [videoSelector]
    )

    const handlePlay = useCallback(() => {
        if (playing) {
            containerRef.current?.querySelector<HTMLVideoElement>(videoSelector)?.pause()
        } else {
            containerRef.current?.querySelector<HTMLVideoElement>(videoSelector)?.play()
        }
        togglePlaying()
    }, [playing, togglePlaying, videoSelector])

    const handleEnd = useCallback(() => {
        togglePlaying()
    }, [togglePlaying])

    const handleTimeUpdate = useCallback(() => {
        const video = containerRef.current?.querySelector<HTMLVideoElement>(videoSelector)!
        updateTime(video.duration - video.currentTime)
    }, [updateTime, videoSelector])

    const handleSwipe = useCallback(
        (targetIndex: number) => {
            if (!carouselRef.current) {
                return
            }
            carouselRef.current.goTo(targetIndex)
            setCurrentVideoIndex(targetIndex)
            time.current = containerRef.current?.querySelector<HTMLVideoElement>(durationSelector) as any
            updateTime()
        },
        [updateTime, durationSelector]
    )

    const handleMeta = useCallback(
        (e: SyntheticEvent<HTMLVideoElement>) => {
            if (e.currentTarget.dataset.id === activeVideo?.id) {
                updateTime()
            }
        },
        [updateTime, activeVideo?.id]
    )

    const handleInit = useCallback(() => {
        if (video?.[0]) {
            setActiveVideo(video?.[0])
            time.current = containerRef.current?.querySelector<HTMLVideoElement>(durationSelector) as any
            updateTime()
        }
    }, [updateTime, video, durationSelector])

    const handleBeforeChange = useCallback(() => {
        containerRef.current?.querySelector<HTMLVideoElement>(videoSelector)?.pause()
        setPlaying(false)
    }, [setPlaying, videoSelector])

    const initTime = (e: HTMLDivElement) => {
        if (e) {
            updateTime()
        }
    }

    if (!video?.length) {
        return null
    }

    return (
        <div ref={containerRef} className={cn(styles.container, className)} onClick={playing ? handlePlay : undefined}>
            <Carousel
                slidesToShow={1}
                className={styles.carousel}
                dots={dots}
                onInit={handleInit}
                beforeChange={handleBeforeChange}
                afterChange={handleSwipe}
                ref={carouselRef}
                paddedDots
                showModal={false}
            >
                {video.map((item, index) => (
                    <div className={styles.videoHolder} key={`${BASE_FILE_URL}/${item.id}`}>
                        <video
                            data-id={item.id}
                            src={`${BASE_FILE_URL}/${item.id}#t=0.5`}
                            className={styles.video}
                            preload={index === 0 ? 'auto' : 'auto'}
                            onEnded={handleEnd}
                            onTimeUpdate={handleTimeUpdate}
                            onLoadedMetadata={handleMeta}
                        />
                        {index === currentVideoIndex && <div data-id={item.id} className={styles.time} ref={initTime} />}
                        {!playing && (
                            <button className={styles.button} onClick={handlePlay}>
                                <PlayIcon className={styles.playIcon} />
                            </button>
                        )}
                    </div>
                ))}
            </Carousel>
            <div className={styles.info}>
                <div className={cn(styles.header, { [styles.black]: !!replaceDescription })}>{video?.[currentVideoIndex].name}</div>
                <div className={styles.description}>
                    {replaceDescription?.(currentVideoIndex) || video?.[currentVideoIndex].description}
                </div>
            </div>
        </div>
    )
}

function getTime(duration?: number) {
    if (!duration) {
        return `0:00`
    }
    const minutes = Math.floor(duration / 60)
    const seconds = Math.floor(duration > 0 ? duration % 60 : duration)
    return `${minutes}:${seconds < 10 ? String(seconds).padStart(2, '0') : seconds}`
}

export const VideoPlayer = memo(VideoPlayerT)
