import { CalendlyUser } from '../types/assistants'
import { sendGet, sendPut } from '../utils/api'

export function assistantsGetAll(thunkAPI: any): Promise<CalendlyUser[]> {
    return sendGet(`/assistants`, thunkAPI)
}

export function assistantsGet(assistantId: string, thunkAPI: any): Promise<CalendlyUser> {
    return sendGet(`/assistants/${assistantId}`, thunkAPI)
}

export function assistantsUpdate(assistant: CalendlyUser, thunkAPI: any) {
    return sendPut(`/assistants/${assistant.id}`, thunkAPI, assistant)
}
