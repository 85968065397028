import React, { useCallback, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import Table, { ColumnsType } from 'antd/lib/table'

import { Button } from '../../components/ui/Button/Button'

import { useProposalsDirect } from '../../hooks/useProposalsDirect'

import { useDispatch, useSelector } from '../../reducers/store'

import { deleteProposalDirect } from '../../actions/jobs'

// import { ReactComponent as PlusIcon } from '../../assets/plus.svg'

import { ProposalDirect } from '../../types/proposalsDitect'

import styles from './ProposalsDirectAdminPage.module.css'
import useGtmPageLoad from '../../hooks/useGtmPageLoad'

export const ProposalsDirectAdminPage = () => {
    useGtmPageLoad('ProposalsDirectAdminPage')
    const dispatch = useDispatch()
    const [selectedItems, setSelectedItems] = useState<string[]>([])
    useProposalsDirect()
    const data = useSelector(store => store.jobs.proposalsDirect)

    const assistantFilterOptions = useMemo(() => {
        const assistantsNames = data?.map(item => item.calendlyAssistantName) || []
        const uniqueAssistantsNames = Array.from(new Set(assistantsNames.map(item => JSON.stringify(item))))
            .map(item => JSON.parse(item))
            ?.filter(item => item !== null)
        return uniqueAssistantsNames?.map(item => ({ text: item, value: item || '' }))
    }, [data])

    const renderId = useCallback((value: any, item: ProposalDirect) => {
        return <Link to={`/proposals-direct/${item.id}`}>{item.id}</Link>
    }, [])

    // Proposal initiator priority
    // 1. RC
    // 2. Project
    // 3. Expert

    const renderProposalTypeItem = useCallback((value: any, item: ProposalDirect) => {
        if (item.regionalCenter?.id) {
            return 'RC'
        }
        if (item.project?.id) {
            return 'EB-project'
        }
        if (item.expert?.id) {
            return 'expert'
        }
        return ''
    }, [])

    const renderProposalNameItem = useCallback((value: any, item: ProposalDirect) => {
        if (item.regionalCenter?.id) {
            return item.regionalCenter.name
        }
        if (item.project?.id) {
            return item.project.name
        }
        if (item.expert?.id) {
            return item.expert.firstName + ' ' + item.expert.lastName
        }
        return ''
    }, [])

    const renderUserId = useCallback((value: string, item: ProposalDirect) => {
        return item.user?.id || ''
    }, [])

    const renderCalendlyDate = useCallback((value: string) => {
        return value?.length > 0
            ? new Date(value).toLocaleDateString('en-Us', {
                  day: 'numeric',
                  month: 'long',
                  year: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit'
              })
            : ''
    }, [])

    const columns: ColumnsType<ProposalDirect> = useMemo(() => {
        return [
            {
                dataIndex: 'id',
                title: 'Id',
                render: renderId,
                sorter: (a, b) => Number(a.id) - Number(b.id),
                width: 70
            },
            {
                dataIndex: 'id',
                title: 'Proposal type',
                render: renderProposalTypeItem,
                width: 100
            },
            {
                dataIndex: 'id',
                title: 'Proposal name',
                render: renderProposalNameItem,
                width: 170
            },
            {
                dataIndex: 'id',
                title: 'User id',
                render: renderUserId
            },
            {
                dataIndex: 'calendlyUserName',
                title: 'Calendly name',
                width: 170
            },
            {
                dataIndex: 'calendlyUserEmail',
                title: 'Calendly contact'
            },
            {
                dataIndex: 'calendlyUserResponseDate',
                title: 'Calendly response',
                render: renderCalendlyDate,
                sorter: (a, b) =>
                    Number(new Date(a.calendlyUserResponseDate || '').getTime() || 0) -
                    Number(new Date(b.calendlyUserResponseDate || '').getTime() || 0),
                width: 160
            },
            {
                dataIndex: 'calendlyDate',
                title: 'Calendly meeting',
                render: renderCalendlyDate,
                sorter: (a, b) =>
                    Number(new Date(a.calendlyDate || '').getTime() || 0) - Number(new Date(b.calendlyDate || '').getTime() || 0),
                width: 160
            },
            {
                dataIndex: 'calendlyUserPrefferedLanguage',
                title: 'Language'
            },
            {
                dataIndex: 'calendlyAssistantName',
                title: 'Calendly assistant',
                filters: assistantFilterOptions || [],
                onFilter: (value, record) => record.calendlyAssistantName === value,
                width: 170
            },
            {
                dataIndex: 'status',
                title: 'Status'
            },
            {
                dataIndex: 'manuallyStatus',
                title: 'Status text',
                width: 200
            }
        ]
    }, [renderId, renderProposalTypeItem, renderProposalNameItem, renderUserId, renderCalendlyDate, assistantFilterOptions])

    const handleDelete = () => {
        dispatch(deleteProposalDirect(selectedItems))
        setSelectedItems([])
    }

    const handleSelect = (selectedKeys: (string | number)[]) => {
        setSelectedItems(selectedKeys.map(key => (typeof key !== 'string' ? key.toString() : key)))
    }

    return (
        <div className={styles.container}>
            <div className={styles.controls}>
                {/*<Link to="/proposals-direct/new">*/}
                {/*    <Button icon={<PlusIcon />} style={{ width: 'auto' }} className={styles.button}>*/}
                {/*        Add*/}
                {/*    </Button>*/}
                {/*</Link>*/}
                <Button style={{ width: 'auto' }} type="ghost" className={styles.button} onClick={handleDelete}>
                    Delete
                </Button>
            </div>
            <div style={{ overflow: 'auto' }}>
                <Table columns={columns} dataSource={data} rowSelection={{ onChange: handleSelect }} rowKey="id" />
            </div>
        </div>
    )
}
