import React, { useMemo } from 'react'
import { Project, ProjectType, ProjectTypeValue } from '../../types/project'
import { File, Video } from '../../types/file'
import styles from './ProjectDetailsCard.module.css'
import { ReactComponent as LocationMarkerIcon } from '../../assets/location-marker.svg'
import { Tags } from '../ui/Tags/Tags'
import { Link } from 'react-router-dom'
import { Avatar } from 'antd'
import { StatsTable, StatsTableItem } from '../ui/StatsTable/StatsTable'
import { StatsBlock } from '../ui/StatsBlock/StatsBlock'
import { Chart } from '../Chart/Chart'
import { LearnMore } from '../LearnMore/LearnMore'
import { Progress } from '../ui/Progress/Progress'
import { RegionalCenterSummary } from '../RegionalCenterSummary/RegionalCenterSummary'
import { ShortText } from '../ui/ShortText/ShortText'
import { BASE_FILE_URL, DIRECT_PROJECT_RISK_INVESTING } from '../../api/endpoints'
import { useProjectTags } from '../../hooks/useProjectTags'
import { cn } from '../../utils/cn'
import { BottomSheet } from '../ui/BottomSheet/BottomSheet'
import { useTranslation } from 'react-i18next'
import { useLocalizedStates } from '../../hooks/useLocalizedStates'
import { getLocaleValue } from '../../utils/string'
import { useSelector } from '../../reducers/store'
import { Carousel } from '../ui/Carousel/Carousel'
import { AttachedDocuments } from '../ui/AttachedDocuments/AttachedDocuments'
import { SetAppointmentButton } from '../ui/SetAppointmentButton/SetAppointmentButton'
import { VideoPlayer } from '../ui/VideoPlayer/VideoPlayer'
import { Button } from '../ui/Button/Button'
import { useToggle } from '../../hooks/useToggle'

export interface ProjectDetailsCardProps {
    project: Project
}

export const ProjectDetailsCard = ({ project }: ProjectDetailsCardProps) => {
    const { t } = useTranslation()
    const lang = useSelector(store => store.session.lang) ?? 'en'
    const [showAllVideos, toggleShowAllVideos] = useToggle(false)
    const investmentTypes: Record<string, string> = useMemo(
        () => ({
            loan: t('Loan'),
            preferred_equity: t('Preferred Equity'),
            equity: t('Equity'),
            seniorLoan: t('Senior Loan'),
            mezzanineLoan: t('Mezzanine Loan')
        }),
        [t]
    )

    const states = useLocalizedStates()
    const partnerHint = useMemo(
        () => ({
            DEVELOPER: { label: t('Developer'), hint: t('(i) in Developer') },
            ARCHITECTURAL_BUREAU: { label: t('Architectural bureau'), hint: t('(i) in Architectural bureau') },
            BORROWER: { label: t('Borrower'), hint: t('(i) in Borrower') },
            ACCOUNTING_FIRM: { label: t('Accounting firm'), hint: t('(i) in Accounting firm') },
            ESCROW_AGENT: { label: t('Escrow agent'), hint: t('(i) in Escrow agent') }
        }),
        [t]
    )
    const { requiredInvestment, administrativeFee, specialOffer, total } = project
    const tags = useProjectTags(project)
    const locale = useSelector(store => store.session.lang)
    const stats = useMemo<StatsTableItem[]>(() => {
        return [
            { name: t('Required Investment'), value: requiredInvestment?.value || 0, type: 'money' },
            { name: t('Administrative Fee'), value: administrativeFee, type: 'money' },
            {
                name: t('Citizon Special Offer'),
                value: specialOffer,
                color: 'red' as const,
                type: 'money',
                star: !!project?.disclaimer?.length && !!project?.specialOffer
            },
            { name: t('Total per Investor'), value: total, color: 'green' as const, type: 'money' }
        ]
    }, [requiredInvestment, administrativeFee, specialOffer, total, t, project?.disclaimer, project?.specialOffer])

    const projectTimeline = useMemo(
        () => ({
            [t(project.constructionStartActual ? 'Construction Start Actual' : 'Construction Start Planned')]:
                project.constructionStartActual ? project.constructionStartActual : project.constructionStartPlanned,
            [t(project.constructionEndActual ? 'End Actual' : 'End Planned')]: project.constructionEndActual
                ? project.constructionEndActual
                : project.constructionEndPlanned
        }),
        [
            project.constructionStartPlanned,
            project.constructionEndPlanned,
            project.constructionEndActual,
            project.constructionStartActual,
            t
        ]
    )

    const financialIndications = useMemo(
        () => ({
            [t('Interest Rate')]: getLocaleValue(project, 'interestRate', locale),
            [t('Investment Term')]: getLocaleValue(project, 'investmentTerm', locale)
        }),
        [project, t, locale]
    )

    const investmentType = useMemo(
        () => ({
            [t('EB-5 Units')]: project.eb5Units
        }),
        [project, t]
    )

    const investmentTypeDesktop = useMemo(
        () => ({
            [t('Investment Type')]: investmentTypes[project.investmentType],
            [t('EB-5 Units')]: project.eb5Units
        }),
        [project.eb5Units, project.investmentType, investmentTypes, t]
    )

    const eb5Jobs = useMemo(
        () => [
            { name: t('Required'), value: project.jobsRequired },
            { name: t('Estimated'), value: project.jobsEstimated, alternate: true }
        ],
        [project.jobsRequired, project.jobsEstimated, t]
    )

    const localeFiles: File[] = useMemo(() => getLocaleValue(project, 'files', locale), [locale, project])
    const videos = getLocaleValue(project, 'video', locale) as Video[] | undefined

    const otherFiles = useMemo(() => {
        const result = Object.values(project.localizations || {})
            .filter(item => item.locale !== locale && item.files?.length > 0)
            .flatMap(item => item.files)
        if (locale !== 'en') {
            project.files?.forEach(item => result.push(item))
        }
        return result
    }, [project.files, project.localizations, locale])

    const handleSetAppointmentClick = () => {
        ;(window as any).dataLayer.push({ event: 'contactRegionalCenter' })
    }

    return (
        <div className={styles.container}>
            <Carousel slidesToShow={1} className={cn(styles.carousel, styles.padding, styles.mobile)}>
                {project.photos.map(item => (
                    <img key={item} className={styles.img} src={`${BASE_FILE_URL}/${item}`} alt="Project" />
                ))}
            </Carousel>
            <div className={styles.innerContainer}>
                <div className={styles.content}>
                    <span
                        className={cn(styles.projectTypeBadge, {
                            [styles.backBlue]: project.projectType === ProjectType.RegionalCenter,
                            [styles.backViolet]: project.projectType === ProjectType.DirectProject
                        })}
                    >
                        {t(ProjectTypeValue[project.projectType!])}
                    </span>
                    <h1 className={styles.name}>
                        <Link to={`/projects/${project.id}`}>{getLocaleValue(project, 'name', locale)}</Link>
                    </h1>
                    <span className={styles.address}>
                        <LocationMarkerIcon className={styles.locationMarker} />
                        <span>
                            {getLocaleValue(project, 'address', locale)}
                            {project.state && `, ${states[project.state]}`}
                        </span>
                    </span>
                    <Tags className={styles.tags} items={tags} />
                    <StatsTable className={cn(styles.stats, styles.mobile)} items={stats} />
                    {!!project.disclaimer?.length && !!project.specialOffer && (
                        <div className={cn(styles.disclaimer, styles.mobile)}>{'*' + getLocaleValue(project, 'disclaimer', locale)}</div>
                    )}
                    <ShortText className={cn(styles.padding, styles.description)} rows={6}>
                        {getLocaleValue(project, 'description', locale)}
                    </ShortText>
                    <StatsBlock title={t('Project Timeline')} items={projectTimeline} className={styles.timeline} />

                    <div className={cn(styles.desktop, styles.desktopIndicators)}>
                        <StatsBlock title={t('Financial Indicators')} items={financialIndications} className={styles.financial} />
                        <StatsBlock
                            title={t('Investment Type')}
                            titleInfo={
                                <span>
                                    {t('(i) in Investment Type')}
                                    <Link to="/wiki/regionalCenter">{t('(i) in Investment Type Link')}</Link>
                                </span>
                            }
                            items={investmentTypeDesktop}
                        />
                    </div>
                    <div className={styles.desktopTitles} />
                    <div className={styles.desktopChart}>
                        <div className={styles.desktopBlock}>
                            <h2 className={styles.title} style={{ marginBottom: '16px' }}>
                                {t('Capital Stack')}
                                <BottomSheet
                                    content={t('(i) in Capital Stack')}
                                    className={styles.icon}
                                    size="dynamic"
                                    title={t('Capital Stack')}
                                />
                            </h2>
                            <Chart items={project.capitalStack} />
                        </div>

                        <StatsBlock title={t('Financial Indicators')} items={financialIndications} className={styles.mobile} />
                        <StatsBlock
                            title={t('Investment Type')}
                            titleInfo={t('(i) in Investment Type')}
                            majorValue={investmentTypes[project.investmentType]}
                            items={investmentType}
                            className={cn(styles.mobile, styles.desktopBlock)}
                            size="dynamic"
                        />
                        <div style={{ flexGrow: 1 }} className={styles.ebProjects}>
                            <h2 className={cn(styles.title)} style={{ marginBottom: '16px' }}>
                                {t('EB-5 Jobs')}
                                <BottomSheet
                                    content={t('(i) in EB-5 Jobs')}
                                    className={styles.icon}
                                    size="dynamic"
                                    title={t('EB-5 Jobs')}
                                />
                            </h2>
                            <Progress className={cn(styles.padding, styles.progress)} items={eb5Jobs} />
                        </div>
                    </div>
                    {project.regionalCenter && (
                        <>
                            <h2
                                className={cn(styles.desktop, styles.title, styles.bigTitle)}
                                style={{ marginBottom: '0px', marginTop: '56px' }}
                            >
                                {project.projectType === ProjectType.DirectProject ? t('Administrated by') : t('Belong to Regional Center')}
                                <BottomSheet
                                    content={getLocaleValue(project.regionalCenter, 'description', locale)}
                                    className={styles.icon}
                                    size="small"
                                    title={t('Regional Center')}
                                />
                            </h2>
                            <RegionalCenterSummary
                                title={
                                    project.projectType === ProjectType.DirectProject
                                        ? t('Administrated by')
                                        : t('Belong to Regional Center')
                                }
                                projectId={project.id}
                                regionalCenter={project.regionalCenter}
                                className={styles.summary}
                            />
                        </>
                    )}
                    {project.projectPartner?.length! > 0 && (
                        <div className={styles.partners}>
                            <h2 className={cn(styles.desktop, styles.partnersTitle)}>{t('Project Partners')}</h2>
                            <h2 className={cn(styles.mobile, styles.partnersTitle)}>{t('Partners')}</h2>
                            <ul className={styles.partnersList}>
                                {project.projectPartner?.map(item => {
                                    return (
                                        <div className={styles.partner} key={item.id}>
                                            <div className={cn(styles.developer, styles.padding)}>
                                                <Avatar size={48} src={`${BASE_FILE_URL}/${item.logo}`} className={styles.developerLogo} />
                                                <div className={styles.developerName}>
                                                    {getLocaleValue(item, 'name', lang)}
                                                    <div className={styles.developerHint}>
                                                        {partnerHint[item.type]?.label}
                                                        <BottomSheet
                                                            title={partnerHint[item.type]?.label}
                                                            content={partnerHint[item.type]?.hint}
                                                            size="dynamic"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <ShortText className={cn(styles.padding, styles.bottomPadding, styles.partnerDesc)} rows={3}>
                                                {getLocaleValue(item, 'biography', lang)}
                                            </ShortText>
                                        </div>
                                    )
                                })}
                            </ul>
                        </div>
                    )}

                    {videos?.length! > 0 && (
                        <>
                            <h3 className={cn(styles.header, styles.video)}>{t('Videos')}</h3>
                            <VideoPlayer video={videos} className={cn(styles.mobile, styles.padding)} />
                            <ul className={cn(styles.desktopVideos, styles.padding)}>
                                {videos
                                    ?.filter((_, index) => (showAllVideos ? true : index < 4))
                                    ?.map((item: Video) => {
                                        return <VideoPlayer key={item.id} video={[item]} className={styles.videoItem} />
                                    })}
                            </ul>
                            {videos?.length! > 4 && !showAllVideos && (
                                <Button type="ghost" onClick={toggleShowAllVideos} className={cn(styles.desktopVideos, styles.desktop)}>
                                    {t('Watch all N videos', { count: videos?.length })}
                                </Button>
                            )}
                        </>
                    )}

                    <AttachedDocuments title={t('Learn More About the Project')} localeFiles={localeFiles} otherFiles={otherFiles} />
                    <LearnMore
                        title={t(project.projectType === ProjectType.DirectProject ? `Info about risks - DP` : `Info about risks`)}
                        button={t('Learn More')}
                        link={project.projectType === ProjectType.DirectProject ? `${DIRECT_PROJECT_RISK_INVESTING}?lang=${lang}` : ''}
                    />
                </div>
                <div className={cn(styles.desktop, styles.desktopFloatBlock)}>
                    <h3 className={styles.header}>{t('EB-5 Investment')}</h3>
                    <StatsTable className={cn(styles.stats, styles.floatStats)} items={stats} />
                    {!!project.disclaimer?.length && !!project.specialOffer && (
                        <div className={styles.disclaimer}>{'*' + getLocaleValue(project, 'disclaimer', locale)}</div>
                    )}
                    <SetAppointmentButton
                        float={false}
                        className={styles.padding}
                        noSubject={true}
                        noAssistant={true}
                        size="small"
                        textFor="regionalCenter"
                        onClick={handleSetAppointmentClick}
                    />
                </div>
            </div>
        </div>
    )
}
