import React from 'react'
import { cn } from '../../../utils/cn'
import styles from './Progress.module.css'

export interface ProgressProps {
    items: { name: string; value: number | undefined; alternate?: boolean }[]
    title?: string
    titleDescription?: string
    className?: string
}

export const Progress = ({ items, title, titleDescription, className }: ProgressProps) => {
    const max = Math.max(...items.map(item => item.value ?? 0))
    return (
        <div className={className}>
            {items.map(item => {
                if (!item.value) {
                    return null
                }
                const val = item.value ?? 0
                const width = val > 0 ? (val / max) * 100 : 0
                return (
                    <div key={item.name} className={styles.container}>
                        <span className={cn(styles.desktop, styles.label)}>{item.name}</span>
                        <div className={styles.row}>
                            <div className={cn(styles.bar, { [styles.alternate]: !!item.alternate })} style={{ width: `${width}%` }}>
                                <div className={styles.front} style={{ width: `${width}%` }}>
                                    <span className={cn(styles.mobile, styles.label)}>{item.name}</span>
                                </div>
                                <div className={styles.back} style={{ width: `100%`, position: 'absolute' }}>
                                    <span className={cn(styles.mobile, styles.label)}>{item.name}</span>
                                </div>
                            </div>
                            <span className={cn(styles.desktop, styles.value)}>{item.value}</span>
                        </div>
                        <span className={cn(styles.mobile, styles.value)}>{item.value}</span>
                    </div>
                )
            })}
        </div>
    )
}
