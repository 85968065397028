import { Form } from 'antd'
import React, { memo, ReactNode, useCallback, useEffect, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useChat } from '../../../hooks/useChat'
import { useDispatch, useSelector } from '../../../reducers/store'
import { addCallPayload } from '../../../reducers/session'
import { AssistantItem } from '../AssistantItem/AssistantItem'
import { BottomSheet, TBottomSheetSizes } from '../BottomSheet/BottomSheet'
import { Button } from '../Button/Button'
import { FloatButton } from '../Button/FloatButton'
import { Dropdown } from '../Dropdown/Dropdown'
import styles from './SetAppointmentButton.module.css'
import { useTranslation } from 'react-i18next'
import { RadioGroup } from '../RadioGroup/RadioGroup'
import { setSelectedAssistant } from '../../../reducers/assisntants'
import { cn } from '../../../utils/cn'
import { getAppointmentText } from './utility'

export interface SetAppointmentButtonProps {
    chat?: boolean
    back?: boolean
    title?: string
    float?: boolean
    className?: string
    noSubject?: boolean
    size?: TBottomSheetSizes
    textFor?: TAppointmentText
    noAssistant?: boolean
    onClose?: () => void
    onClick?: () => void
}

export type TAppointmentText = 'expert' | 'regionalCenter' | 'consultant'

export const SetAppointmentButtonInternal = ({
    title,
    chat,
    back,
    float,
    className,
    size,
    textFor = 'expert',
    noSubject = false,
    noAssistant = false,
    onClick,
    onClose
}: SetAppointmentButtonProps) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const showChat = useChat()
    const assistants = useSelector(store => store.assistants.assistants)
    const assistant = useSelector(store => store.assistants.selectedAssistant)
    const user = useSelector(store => store.session.user)
    useEffect(() => {
        if (!assistant && assistants?.[0]) {
            dispatch(setSelectedAssistant({ assistant: assistants[0] }))
        }
    }, [dispatch, assistant, assistants])
    const jobs = useSelector(store => store.jobs.jobs)
    const jobsOptions = jobs?.map(item => ({ value: item.id, label: item.title }))
    const preselectedJob = useSelector(store => store.session.scheduledCallPayload.jobId)

    const languageOptions = useMemo(
        () => [
            { value: 'en', label: t('English') },
            { value: 'es', label: t('Spanish') },
            { value: 'ru', label: t('Russian') }
        ],
        [t]
    )

    const handleSubject = useCallback(
        (value: string) => {
            dispatch(
                addCallPayload({
                    jobId: value
                })
            )
        },
        [dispatch]
    )

    const handleChange = useCallback(
        (values: Record<string, any>) => {
            if (values.language && assistants) {
                const matching = assistants.find(item => item.languages?.[values.language]) ?? assistants[0]
                dispatch(setSelectedAssistant({ assistant: matching }))
            }
        },
        [assistants, dispatch]
    )

    if (!assistant) {
        return null
    }

    const content: ReactNode = (
        <div className={styles.container}>
            <h2 className={styles.title}>{t('Let us lead you')}</h2>
            {!noAssistant && (
                <AssistantItem ghost avatarUrl={assistant.avatarUrl} title={assistant.name} description="Your personal assistant" />
            )}
            <div className={styles.chooseTextArea}>
                <span className={styles.choose}>{t(getAppointmentText(textFor)[0])}</span>
                <span className={styles.choose_bold}>{t(getAppointmentText(textFor)[1])}</span>
                <span className={styles.choose}>{t(getAppointmentText(textFor)[2])}</span>
            </div>
            <Form onValuesChange={handleChange} layout="vertical" className={styles.form}>
                {!noSubject && !!jobsOptions?.length && (
                    <Form.Item className={styles.subject} label={t('Subject')} name="category" initialValue={preselectedJob}>
                        <Dropdown options={jobsOptions} onChange={handleSubject} mode="multiple" placeholder="" />
                    </Form.Item>
                )}
                <Form.Item label={t('Preferred Language')} className={styles.subject} name="language" initialValue="en">
                    <RadioGroup options={languageOptions} className={styles.languages} />
                </Form.Item>
            </Form>
        </div>
    )
    const controls = (
        <div className={cn(styles.buttonsContainer, className, { [styles.back]: !!back })}>
            <BottomSheet
                content={content}
                size={size || 'xmedium'}
                closable
                footer={
                    <Link to="/appointment">
                        <Button className={styles.chooseButton}>{t('Choose Time')}</Button>
                    </Link>
                }
            >
                <Button className={cn(styles.setAppointment, { [styles.horizontal]: !!chat })} onClick={onClick}>
                    {title ?? t('Set Appointment')}
                </Button>
            </BottomSheet>
            {chat && (
                <Button type="ghost" className={styles.chat} onClick={showChat}>
                    {t('Chat')}
                </Button>
            )}
            {back && (
                <Button type="ghost" onClick={onClose}>
                    {t('Back')}
                </Button>
            )}
        </div>
    )
    return user ? float !== false ? <FloatButton className={className}>{controls}</FloatButton> : controls : <></>
}

export const SetAppointmentButton = memo(SetAppointmentButtonInternal)
