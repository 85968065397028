import React, { ReactNode } from 'react'
import { Button, Typography } from 'antd'
import { EllipsisConfig } from 'antd/lib/typography/Base'
import { cn } from '../../../utils/cn'
import styles from './ShortText.module.css'
import { useToggle } from '../../../hooks/useToggle'
import { useTranslation } from 'react-i18next'

export interface ShortTextProps {
    children?: ReactNode
    ellipsis?: EllipsisConfig
    rows?: number
    expandable?: boolean
    className?: string
}

export const ShortText = ({ children, ellipsis: propsEllipsis, rows = 5, expandable = true, className }: ShortTextProps) => {
    const [show, toggleShow] = useToggle(false)
    const { t } = useTranslation()
    const symbol = (
        <Button className={styles.button} type="link">
            {t('Read more')}
        </Button>
    )
    const ellipsis = propsEllipsis ?? {
        rows,
        symbol,
        expandable,
        onExpand: toggleShow
    }
    return (
        <div className={cn(styles.container, className)}>
            {!show && (
                <Typography.Paragraph className={styles.text} ellipsis={ellipsis}>
                    {children}
                </Typography.Paragraph>
            )}
            {show && <Typography.Paragraph className={styles.text}>{children}</Typography.Paragraph>}
            {show && (
                <Button className={styles.button} type="link" onClickCapture={toggleShow}>
                    {t('Read Less')}
                </Button>
            )}
        </div>
    )
}
