import React, { ChangeEvent, cloneElement, ReactElement, useCallback, useEffect, useState } from 'react'
import { ReactComponent as RemoveIcon } from '../../../assets/trash.svg'
import { Button } from '../Button/Button'
import { Input } from '../Input/Input'
import styles from './AddArray.module.css'

export interface AddArrayProps {
    value?: any[]
    keyRenderer?: ReactElement
    valueRenderer?: ReactElement
    orderRenderer?: ReactElement
    fieldName: string
    className?: string
    maxFields?: number
    onChange?: (value: any[]) => void
}

export const AddArray = ({
    value = [],
    fieldName,
    valueRenderer,
    keyRenderer,
    orderRenderer,
    maxFields,
    className,
    onChange
}: AddArrayProps) => {
    const [counter, setCounter] = useState(value ? value.length : 0)
    const [items, setItems] = useState<any[]>(value)

    useEffect(() => {
        onChange?.(items)
    }, [items]) // , onChange

    const handleAdd = useCallback(() => {
        if (maxFields && items?.length > maxFields) {
            return
        }
        if (!items?.find(item => item[fieldName] === '')) {
            setCounter(counter + 1)
            setItems([...items, { [fieldName]: '', lvl: '', ord: '' }])
        }
    }, [maxFields, items, counter])

    const handleRemove = useCallback(
        (key: string) => {
            const newItems = items?.filter(item => item[fieldName] !== key)
            setItems(newItems)
        },
        [items]
    )

    const handleUpdate = (index: number, e: ChangeEvent<HTMLInputElement>, field: any) => {
        const newValue = e?.currentTarget?.value ?? e
        const tuple = items[index]
        const filteredItems: any[] = items.filter(item => item[fieldName] !== tuple[fieldName]) || []
        if (tuple && !filteredItems?.find(item => item[fieldName] === newValue)) {
            const newTuple = { ...tuple, [field]: newValue }
            setItems([...filteredItems, newTuple])
        }
    }

    const kRenderer = (value: string, key: number) => {
        if (keyRenderer) {
            return cloneElement(keyRenderer, {
                onChange: (e: any) => handleUpdate(key, e as any, fieldName),
                value: keyRenderer.props.value ?? value,
                className: styles.item
            })
        }
        return <Input value={value} className={styles.item} onChange={(e: any) => handleUpdate(key, e as any, fieldName)} />
    }

    const renderer = (value: string, key: number) => {
        if (valueRenderer) {
            return cloneElement(valueRenderer, {
                onChange: (e: any) => handleUpdate(key, e as any, 'lvl'),
                value: valueRenderer.props.value ?? value,
                className: styles.item
            })
        }
        return <Input value={value} className={styles.item} onChange={(e: any) => handleUpdate(key, e as any, 'lvl')} />
    }

    const oRenderer = (value: string, key: number) => {
        if (orderRenderer) {
            return cloneElement(orderRenderer, {
                onChange: (e: any) => handleUpdate(key, e as any, 'ord'),
                value: orderRenderer.props.value ?? value,
                className: styles.item
            })
        }
        return <Input value={value} className={styles.item} onChange={(e: any) => handleUpdate(key, e as any, 'ord')} />
    }

    return (
        <div className={className}>
            <Button disabled={maxFields === items?.length} shape="circle" style={{ fontSize: '28px' }} onClick={handleAdd}>
                +
            </Button>
            <ul className={styles.list}>
                {items?.map(({ lang, visa, lvl, ord }, index) => {
                    const field = fieldName === 'visa' ? visa : lang
                    return (
                        <li className={styles.items} key={field + lvl + index.toString()}>
                            <div className={styles.column1}>{kRenderer(field, index)}</div>
                            <div className={styles.column2}>{renderer(lvl, index)}</div>
                            <div className={styles.column3}>{oRenderer(ord, index)}</div>
                            <Button shape="circle" type="ghost" onClick={() => handleRemove(field)}>
                                <RemoveIcon />
                            </Button>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}
