import { RegionalCenter } from '../types/regionalCenter'
import { sendDelete, sendGet, sendPost, sendPut } from '../utils/api'

export function regionalCenters(thunkAPI: any): Promise<RegionalCenter[]> {
    return sendGet(`/regionalCenters`, thunkAPI)
}

export function regionalCenterGet(centerId: string, thunkAPI: any): Promise<RegionalCenter> {
    return sendGet(`/regionalCenters/${centerId}`, thunkAPI)
}

export function regionalCenterCreate(center: RegionalCenter, thunkAPI: any): Promise<RegionalCenter> {
    return sendPost(`/regionalCenters`, thunkAPI, center)
}

export function regionalCenterDelete(ids: string[], thunkAPI: any) {
    return sendDelete(`/regionalCenters`, thunkAPI, ids)
}

export function regionalCenterUpdate(center: RegionalCenter, thunkAPI: any) {
    return sendPut(`/regionalCenters/${center.id}`, thunkAPI, center)
}
