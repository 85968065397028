import React, { useCallback, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import Table, { ColumnsType } from 'antd/lib/table'
import { SelectValue } from 'antd/es/select'

import { Button } from '../../components/ui/Button/Button'
import { Dropdown } from '../../components/ui/Dropdown/Dropdown'

import { useRCManagers } from '../../hooks/useRCManagers'
import { useRegionalCenters } from '../../hooks/useRegionalCenters'

import { deleteRCManager } from '../../actions/rcmanagers'

import { useDispatch, useSelector } from '../../reducers/store'
import { RCManager } from '../../types/rcmanager'

import { ReactComponent as PlusIcon } from '../../assets/plus.svg'

import styles from './RegionalCenterManagersAdminPage.module.css'
import useGtmPageLoad from '../../hooks/useGtmPageLoad'

export const RegionalCenterManagersAdminPage = () => {
    useGtmPageLoad('RegionalCenterManagersAdminPage')
    const dispatch = useDispatch()
    const [selectedItems, setSelectedItems] = useState<string[]>([])
    const [rcFilter, setRcFilter] = useState<string[]>([])
    useRCManagers()
    useRegionalCenters()
    const data = useSelector(store => store.regionalCenters)
    const { centers, managers } = data

    const managersFiltered = useMemo(
        () => managers?.filter(item => rcFilter?.includes(item?.regionalCenter?.id?.toString() || '') || !rcFilter?.length),
        [managers, rcFilter]
    )

    const rcOptions = centers.map(item => ({ value: item.id, label: item.name }))

    const renderFullName = useCallback((value: any, item: RCManager) => {
        return (
            <Link to={`/rc-managers-admin/${item.id}`}>
                {item.firstName} {item.lastName}
            </Link>
        )
    }, [])

    const renderArray = useCallback((value: Record<string, string>, item: RCManager) => {
        return <>{value?.name || ''}</>
    }, [])

    const columns: ColumnsType<RCManager> = useMemo(() => {
        return [
            {
                dataIndex: 'name',
                title: 'Name',
                render: renderFullName
            },
            {
                dataIndex: 'ord',
                title: 'Order'
            },
            {
                dataIndex: 'title',
                title: 'Title'
            },
            {
                dataIndex: 'regionalCenter',
                title: 'RegionalCenter',
                render: renderArray
            }
        ]
    }, [renderFullName, renderArray])

    const handleDelete = () => {
        dispatch(deleteRCManager(selectedItems))
        setSelectedItems([])
    }

    const handleSelect = (selectedKeys: (string | number)[]) => {
        setSelectedItems(selectedKeys.map(key => (typeof key !== 'string' ? key.toString() : key)))
    }

    const handleSearch = (e: SelectValue[]) => {
        if (!e) {
            setRcFilter([])
        } else {
            setRcFilter(e as string[])
        }
    }

    return (
        <div className={styles.container}>
            <div className={styles.controls}>
                <Link to="/rc-managers-admin/new">
                    <Button icon={<PlusIcon />} style={{ width: 'auto' }} className={styles.button}>
                        Add
                    </Button>
                </Link>
                <Button style={{ width: 'auto' }} type="ghost" className={styles.button} onClick={handleDelete}>
                    Delete
                </Button>
                <Dropdown
                    className={styles.dropdown}
                    options={rcOptions}
                    mode="multiple"
                    maxTagCount="responsive"
                    placeholder="Filter by Regional Center"
                    onChange={handleSearch}
                />
            </div>
            <div style={{ overflow: 'auto' }}>
                <Table columns={columns} dataSource={managersFiltered} rowSelection={{ onChange: handleSelect }} rowKey="id" />
            </div>
        </div>
    )
}
