import { Form, Rate, Skeleton } from 'antd'
import FormItem from 'antd/lib/form/FormItem'
import TextArea from 'antd/lib/input/TextArea'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { Button } from '../../components/ui/Button/Button'
import { useExpert } from '../../hooks/useExperts'
import { useDispatch, useSelector } from '../../reducers/store'
import { ReactComponent as StarFilled } from '../../assets/star-filled.svg'
import { ReactComponent as StarEmpty } from '../../assets/star-empty.svg'
import styles from './WriteReviewPage.module.css'
import { useForm } from 'antd/lib/form/Form'
import { addReview } from '../../actions/experts'
import { Expert } from '../../types/expert'
import { Review } from '../../types/Review'
import useGtmPageLoad from '../../hooks/useGtmPageLoad'

export const WriteReviewPage = () => {
    useGtmPageLoad('WriteReviewPage')
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [form] = useForm()
    const { id } = useParams<{ id: string }>()
    const [valid, setValid] = useState(false)
    useExpert(id)
    const history = useHistory()
    const expert = useSelector(item => item.experts.selectedExpert)

    const handleSubmit = useCallback(
        (values: Record<string, any>) => {
            const review: Partial<Review> = {
                expert: {
                    id: expert?.id
                } as Expert,
                rating: values.rating,
                text: values.text
            }
            dispatch(addReview({ review, onSuccess: () => history.push('/success/expert-review') }))
        },
        [expert?.id, history, dispatch]
    )

    if (!expert) {
        return (
            <div className={styles.container}>
                <Skeleton loading paragraph={{ rows: 8 }} />
            </div>
        )
    }

    return (
        <div className={styles.container}>
            <h1 className={styles.title}>{t('Write a review for', { name: expert && `${expert.firstName} ${expert.lastName}` })}</h1>
            <Form
                form={form}
                onFinish={handleSubmit}
                onValuesChange={() => {
                    setValid(form.getFieldValue('rating') > 0 && form.getFieldValue('text')?.length > 0)
                }}
            >
                <div className={styles.rate}>{t('Rate')}</div>
                <FormItem name="rating">
                    <Rate
                        character={({ index, value }: any) => {
                            if (index < value) {
                                return <StarFilled />
                            }
                            return <StarEmpty />
                        }}
                    />
                </FormItem>
                <div className={styles.rate}>{t('Review')}</div>
                <FormItem name="text">
                    <TextArea placeholder={t('Long story short.')} rows={6} />
                </FormItem>
            </Form>
            <div className={styles.submit}>
                <Button disabled={!valid} onClick={form.submit}>
                    {t('Send')}
                </Button>
            </div>
        </div>
    )
}
