import Table, { ColumnsType } from 'antd/lib/table'
import React, { useCallback, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button } from '../../components/ui/Button/Button'
import { useDispatch, useSelector } from '../../reducers/store'
import styles from './LanguagesAdminPage.module.css'
import { ReactComponent as PlusIcon } from '../../assets/plus.svg'
import { useLanguages } from '../../hooks/useLanguages'
import { deleteLanguages } from '../../actions/languages'
import { Language } from '../../types/i18n'
import useGtmPageLoad from '../../hooks/useGtmPageLoad'

export const LanguagesAdminPage = () => {
    useGtmPageLoad('LanguagesAdminPage')
    const dispatch = useDispatch()
    const [selectedItems, setSelectedItems] = useState<string[]>([])
    useLanguages()
    const data = useSelector(store => store.languages.languages)

    const renderLink = useCallback((value: any, item: Language) => {
        return <Link to={`/languages-admin/${item.id}`}>{value}</Link>
    }, [])

    const columns: ColumnsType<Language> = useMemo(() => {
        return [
            {
                dataIndex: 'name',
                title: 'Name',
                render: renderLink
            },
            { dataIndex: 'code', title: 'Code' }
        ]
    }, [renderLink])

    const handleDelete = () => {
        dispatch(deleteLanguages(selectedItems))
        setSelectedItems([])
    }

    const handleSelect = (selectedKeys: (string | number)[]) => {
        setSelectedItems(selectedKeys.map(key => (typeof key !== 'string' ? key.toString() : key)))
    }

    return (
        <div className={styles.container}>
            <div className={styles.controls}>
                <Link to="/languages-admin/new">
                    <Button icon={<PlusIcon />} style={{ width: 'auto' }} className={styles.button}>
                        Add
                    </Button>
                </Link>
                <Button style={{ width: 'auto' }} type="ghost" className={styles.button} onClick={handleDelete}>
                    Delete
                </Button>
            </div>
            <div style={{ overflow: 'auto' }}>
                <Table columns={columns} dataSource={data} rowSelection={{ onChange: handleSelect }} rowKey="id" />
            </div>
        </div>
    )
}
