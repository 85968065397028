import { useEffect } from 'react'
import { fetchLawFirm, fetchLawFirms } from '../actions/lawFirms'
import { clearSelected } from '../reducers/lawFirms'
import { useDispatch } from '../reducers/store'

export function useLawFirms() {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchLawFirms())
    }, [dispatch])
}

export function useLawFirm(firmId: string) {
    const dispatch = useDispatch()
    useEffect(() => {
        if (firmId !== 'new') {
            dispatch(fetchLawFirm(firmId))
        }
        return () => {
            dispatch(clearSelected())
        }
    }, [firmId, dispatch])
}
