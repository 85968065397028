import Table, { ColumnsType } from 'antd/lib/table'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Button } from '../../components/ui/Button/Button'
import { useDispatch, useSelector } from '../../reducers/store'
import styles from './ProposalsAdminPage.module.css'
import { Proposal } from '../../types/jobs'
import { Expert } from '../../types/expert'
import { ReactComponent as PlusIcon } from '../../assets/plus.svg'
import { deleteProposals, updateJob } from '../../actions/jobs'
import { useAssistants } from '../../hooks/useAssistants'
import { useAllJobs } from '../../hooks/useJobs'
import useGtmPageLoad from '../../hooks/useGtmPageLoad'

// TODO: Will be delete in favor ProposalsDirectAdminPage. CD-121

export const ProposalsAdminPage = () => {
    useGtmPageLoad('ProposalsAdminPage')
    const dispatch = useDispatch()
    const [selectedItems, setSelectedItems] = useState<string[]>([])
    const { id } = useParams<{ id: string }>()
    useAssistants()
    useAllJobs()
    const assistants = useSelector(store => store.assistants.assistants)
    const jobs = useSelector(store => store.jobs.jobs)
    const job = jobs.find(item => item.id === id)
    const data = useMemo(() => {
        return job?.proposals
    }, [job])

    useEffect(() => {
        if (job && !job.read) {
            dispatch(
                updateJob({
                    job: {
                        id,
                        read: true
                    }
                })
            )
        }
    }, [dispatch, job, id])

    const renderExpert = useCallback(
        (value: Expert, item: Proposal) => {
            const expert = item.expert && `${value.firstName} ${value.lastName}`
            const assistant = item.assistant && assistants.find(a => a.id === item.assistant?.id)?.name
            return <Link to={`/jobs-admin/${id}/proposals/${item.id}`}>{expert || assistant || 'Citizon'}</Link>
        },
        [assistants, id]
    )

    const renderType = useCallback((value: any, item: Proposal) => {
        return <span>{item.initiatedByUser ? 'Invitation' : 'Proposal'}</span>
    }, [])

    const columns: ColumnsType<Proposal> = useMemo(() => {
        return [
            {
                dataIndex: 'initiatedByUser',
                title: 'Type',
                render: renderType
            },
            {
                dataIndex: 'expert',
                title: 'Expert',
                render: renderExpert
            },
            {
                dataIndex: 'status',
                title: 'Status'
            }
        ]
    }, [renderExpert, renderType])

    const handleDelete = () => {
        dispatch(deleteProposals(selectedItems))
        setSelectedItems([])
    }

    const handleSelect = (selectedKeys: (string | number)[]) => {
        setSelectedItems(selectedKeys.map(key => (typeof key !== 'string' ? key.toString() : key)))
    }

    const getCheckboxProps = useCallback((record: Proposal) => {
        return {
            disabled: record.initiatedByUser
        }
    }, [])

    return (
        <div className={styles.container}>
            <div className={styles.controls}>
                <Link to={`/jobs-admin/${id}/proposals/new`}>
                    <Button icon={<PlusIcon />} style={{ width: 'auto' }} className={styles.button}>
                        Add
                    </Button>
                </Link>
                <Button style={{ width: 'auto' }} type="ghost" className={styles.button} onClick={handleDelete}>
                    Delete
                </Button>
            </div>
            <div style={{ overflow: 'auto' }}>
                <Table columns={columns} dataSource={data} rowSelection={{ onChange: handleSelect, getCheckboxProps }} rowKey="id" />
            </div>
        </div>
    )
}
