import { createAsyncThunk } from '@reduxjs/toolkit'
import { projectCategories } from '../api/endpoints'
import { RootState } from '../reducers/store'
import { ProjectCategory } from '../types/project'

export const fetchProjectCategories = createAsyncThunk<{ categories: ProjectCategory[] }, void, { state: RootState }>(
    'fetchProjectCategories',
    async (_, thunkAPI) => {
        const response = await projectCategories(thunkAPI)
        return { categories: response }
    }
)
