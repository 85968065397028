import React from 'react'
import { Drawer, Button as AntdButton } from 'antd'
import { ReactComponent as FiltersIcon } from './filters.svg'
import styles from './FiltersButton.module.css'
import { useToggle } from '../../hooks/useToggle'
import { ProjectFilters } from './ProjectFilters'
import { ExpertFilters } from './ExpertFilters'
import { cn } from '../../utils/cn'

export interface FiltersButtonProps {
    type: 'projects' | 'experts'
    className?: string
}

export const FiltersButton = ({ type, className }: FiltersButtonProps) => {
    const [show, toggleShow] = useToggle()

    return (
        <>
            <AntdButton className={cn(styles.button, className)} onClick={toggleShow}>
                <FiltersIcon />
            </AntdButton>
            <Drawer
                destroyOnClose
                placement="left"
                className={styles.drawer}
                width="100%"
                visible={show}
                onClose={toggleShow}
                closable={false}
            >
                {type === 'projects' && <ProjectFilters onClose={toggleShow} />}
                {type === 'experts' && <ExpertFilters onClose={toggleShow} />}
            </Drawer>
        </>
    )
}
