import Table, { ColumnsType } from 'antd/lib/table'
import React, { useCallback, useMemo, useState } from 'react'
// import { Link } from 'react-router-dom'
import { Button } from '../../components/ui/Button/Button'
import { useDispatch, useSelector } from '../../reducers/store'
import styles from './RequestsAdminPage.module.css'
// import { ReactComponent as PlusIcon } from '../../assets/plus.svg'
import { deleteExpertises } from '../../actions/languages'
import { useRequests } from '../../hooks/useRequests'
import { Request } from '../../types/request'
import useGtmPageLoad from '../../hooks/useGtmPageLoad'
import { User } from '../../types/person'
import { Link } from 'react-router-dom'
import availableOptions from '../../components/CountrySelect/countries.json'
import { languageCode } from '../../constants/languages'
import { Typography } from 'antd'

export const RequestsAdminPage = () => {
    useGtmPageLoad('RequestsAdminPage')
    const dispatch = useDispatch()
    const [selectedItems, setSelectedItems] = useState<string[]>([])
    useRequests()
    const data = useSelector(store => store.experts.requests)

    const renderId = useCallback((value: any, item: Request) => {
        return <Link to={`/requests-admin/${item.id}`}>{item.id}</Link>
    }, [])

    const renderDate = useCallback((value: string) => {
        return value?.length > 0
            ? new Date(value).toLocaleDateString('en-Us', {
                  day: 'numeric',
                  month: 'long',
                  year: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit'
              })
            : ''
    }, [])

    const categoryFilterOptions = useMemo(() => {
        const uniqueCategories = Array.from(new Set(data?.map(item => item.category).map(item => JSON.stringify(item))))
            .map(item => JSON.parse(item))
            ?.filter(item => item !== null)
        return uniqueCategories?.map(item => ({ text: item, value: item || '' }))
    }, [data])

    const columns: ColumnsType<Request> = useMemo(() => {
        return [
            {
                dataIndex: 'id',
                title: 'id',
                render: renderId,
                sorter: (a, b) => Number(a.id) - Number(b.id),
                width: 70
            },
            {
                dataIndex: 'user',
                title: 'User name',
                render: (value: User) => `${value.firstName || ''} ${value.lastName || ''}`
            },
            {
                dataIndex: 'user',
                title: 'User contact',
                render: (value: User) => value.email || value.phone
            },
            {
                dataIndex: 'created',
                title: 'Date & time of request',
                render: renderDate,
                sorter: (a, b) => Number(new Date(a.created || '').getTime() || 0) - Number(new Date(b.created || '').getTime() || 0),
                width: 160
            },
            {
                dataIndex: 'title',
                title: 'Request’s title',
                render: (value: string) => <Typography.Paragraph ellipsis={{ rows: 3, expandable: false }}>{value}</Typography.Paragraph>
            },
            {
                dataIndex: 'description',
                title: 'Request’s description',
                render: (value: string) => <Typography.Paragraph ellipsis={{ rows: 3, expandable: false }}>{value}</Typography.Paragraph>
            },
            {
                dataIndex: 'category',
                title: 'Category',
                filters: categoryFilterOptions || [],
                onFilter: (value, record) => record.category === value
            },
            {
                dataIndex: 'user',
                title: 'Language',
                render: (value: User) => languageCode[value.language || '']?.language || ''
            },
            {
                dataIndex: 'country',
                title: 'Country',
                render: (value: string) => (availableOptions as any)[value || '']
            }
        ]
    }, [renderId, renderDate, categoryFilterOptions])

    const handleDelete = () => {
        dispatch(deleteExpertises(selectedItems))
        setSelectedItems([])
    }

    const handleSelect = (selectedKeys: (string | number)[]) => {
        setSelectedItems(selectedKeys.map(key => (typeof key !== 'string' ? key.toString() : key)))
    }

    return (
        <div className={styles.container}>
            <div className={styles.controls}>
                {/*<Link to="/requests-admin/new">*/}
                {/*    <Button icon={<PlusIcon />} style={{ width: 'auto' }} className={styles.button}>*/}
                {/*        Add*/}
                {/*    </Button>*/}
                {/*</Link>*/}
                <Button style={{ width: 'auto' }} type="ghost" className={styles.button} onClick={handleDelete}>
                    Delete
                </Button>
            </div>
            <div style={{ overflow: 'auto' }}>
                <Table columns={columns} dataSource={data} rowSelection={{ onChange: handleSelect }} rowKey="id" />
            </div>
        </div>
    )
}
