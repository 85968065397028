import React, { useCallback, useMemo, useState } from 'react'
import { Expert } from '../../types/expert'
import styles from './ExpertDetailsCard.module.css'
import { BASE_FILE_URL } from '../../api/endpoints'
import { cn } from '../../utils/cn'
import { StatsTable } from '../ui/StatsTable/StatsTable'
import { StatsTableItem } from '../ui/StatsTable/StatsTable'
import { CurrentTime } from '../ui/CurrentTime/CurrentTime'
import { capitalize, getLocaleValue, textForm } from '../../utils/string'
import { ShortText } from '../../stories/components/ShortText/ShortText.stories'
import { Practice } from '../Practice/Practice'
import { ReactComponent as CheckIcon } from '../../assets/check.svg'
import { StatsBlockText } from '../ui/StatsBlock/StatsBlockText'
import { LawFirmSummary } from '../LawFirmSummary/LawFirmSummary'
import { StatsBlock } from '../ui/StatsBlock/StatsBlock'
import { VideoPlayer } from '../ui/VideoPlayer/VideoPlayer'
import { useSelector } from '../../reducers/store'
import { ReviewPreview } from '../ui/Reviews/ReviewPreview'
import { ReviewBrowser } from '../ui/Reviews/ReviewBrowser'
import { useTranslation } from 'react-i18next'
import { useExpertises } from '../../hooks/useLanguages'
import { useLocalizedStates } from '../../hooks/useLocalizedStates'
import { ShortReview } from '../ui/Reviews/ShortReview'
import { Carousel } from '../ui/Carousel/Carousel'
import { SetAppointmentButton } from '../ui/SetAppointmentButton/SetAppointmentButton'
import { Video } from '../../types/file'
import { Button } from '../ui/Button/Button'
import { useToggle } from '../../hooks/useToggle'
import { BlogArticle } from '../../types/blog'
import { Link } from 'react-router-dom'
import { isNotEmptyArray, isNotEmptyObject } from '../../utils/data'
import { EJobTitleNames } from '../../constants/experts'
import { sortArrayByField } from '../../utils/array'
import { ILanguageItem } from '../../types/languages'
import { IVisaPractice } from '../../types/visa'
import { PracticeArray } from '../PracticeArray/PracticeArray'
import { EMPTY_ARRAY } from '../../constants/common'

export interface ExpertDetailsCardProps {
    expert: Expert
}

export const ExpertDetailsCard = ({ expert }: ExpertDetailsCardProps) => {
    const renderCurrentTime = useCallback(({ item }) => {
        return <CurrentTime showOffset timezone={item.value as number} />
    }, [])
    useExpertises()
    const states = useLocalizedStates()
    const { t } = useTranslation()
    const [showAllVideos, toggleShowAllVideos] = useToggle(false)
    const isDesktop = window.screen.width > 960
    const locale = useSelector(store => store.session.lang)
    const { languages, expertises } = useSelector(store => store.languages)
    const location = getLocaleValue(expert, 'location', locale)
    const displayedLocation = location && expert.state ? `${location}, ${expert.state}` : location ?? ''
    const isEducation = useMemo(() => expert?.jobTitle?.name === EJobTitleNames.EducationExpert, [expert])
    const expertLanguages: ILanguageItem[] = sortArrayByField(expert.languages, 'ord')
    const expertVisas: IVisaPractice[] = isNotEmptyArray(expert.visaPracticeSet)
        ? sortArrayByField(expert.visaPracticeSet, 'ord')
        : EMPTY_ARRAY
    const locationInfo: StatsTableItem[] = [
        { name: t('Location'), value: displayedLocation },
        {
            name: t('Local time'),
            value: expert.timezone,
            render: renderCurrentTime
        },
        {
            name: t('Languages\nspoken'),
            value: expertLanguages
                ?.map(itemLang => {
                    const expertise = expertises?.find(item => item.level === itemLang.lvl)
                    const matchingLanguage =
                        getLocaleValue(
                            languages?.find(l => l.code === itemLang.lang),
                            'name',
                            locale
                        ) ?? ''
                    return `${capitalize(matchingLanguage)} ${getLocaleValue(expertise, 'level', locale)}`
                })
                .join(', ')
        }
    ]
    const experience = isEducation ? expert?.experience : getLocaleValue(expert, 'experience', locale) ?? {}
    const experienceName = Object.keys(experience)[0]
    const experienceInfo: Record<string, string> =
        (isNotEmptyObject(experienceName) &&
            !!Object.values(experience)[0] && {
                [isEducation ? t('Relevant Practice') : t('Attorney Practice')]: `${Object.values(experience)[0]} ${textForm(
                    Number(Object.values(experience)[0]) || 1,
                    [t('year'), t('years234'), t('years')]
                )}`
            }) ||
        {}

    const cost = useMemo<StatsTableItem[]>(() => {
        const specialOffer = getLocaleValue(expert, 'specialOffer', locale)
        const totalConst = (!!specialOffer && getLocaleValue(expert, 'totalConst', locale)) || 0
        const params: StatsTableItem[] = [
            {
                name: `${t('First consultation fee (')}${expert.firstConsultationDuration || 60}${t('min)')}`,
                value: getLocaleValue(expert, 'firstConsultationFee', locale),
                type: 'money'
            }
        ]
        if (specialOffer > 0) {
            params.push(
                {
                    name: t('Citizon special offer'),
                    value: specialOffer,
                    type: 'money',
                    color: 'red' as const
                },
                {
                    name: t('TotalFee'),
                    value: totalConst,
                    color: 'green' as const,
                    type: 'money'
                }
            )
        }
        return params
    }, [t, expert, locale])

    const authorizedIn = expert.authorizedIn
        ?.map(item => {
            return item === 'all' ? t('Federal Level (all states)') : states[item]
        })
        .join(', ')

    const licensedIn = expert.licensedIn
        ?.map(item => {
            return item === 'all' ? t('Federal Level (all states)') : states[item]
        })
        ?.join(', ')
    const videos = getLocaleValue(expert, 'video', locale) as Video[] | undefined
    const articles = getLocaleValue(expert, 'articles', locale) as BlogArticle[] | undefined

    const approvedReviews = useMemo(() => expert?.reviews?.filter(item => item.approved) || [], [expert])

    const [previewIndex, setPreviewIndex] = useState<number | undefined>()
    const handlePreview = useCallback((index: number) => {
        setPreviewIndex(index)
    }, [])

    const photos = sortArrayByField(expert?.photos, 'ord')

    const handleSetAppointmentClick = () => {
        ;(window as any).dataLayer.push({ event: 'setAppointment', expertId: expert.id })
    }

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.profileHeader}>
                    <Carousel slidesToShow={1} className={styles.carousel} dots showGallery={false}>
                        {photos?.map((item, index) => (
                            <img
                                key={item.photo + index.toString()}
                                className={styles.img}
                                src={`${BASE_FILE_URL}/${item.photo}`}
                                alt="Expert avatar"
                            />
                        ))}
                    </Carousel>
                    <div className={styles.profileDescription}>
                        <h1 className={styles.name}>
                            {getLocaleValue(expert, 'firstName', locale)} {getLocaleValue(expert, 'lastName', locale)}
                        </h1>
                        <span className={cn(styles.padding, styles.title)}>{t(expert?.jobTitle?.name || '')}</span>
                        <div className={styles.reviewLicense}>
                            <ShortReview
                                expert={expert}
                                supertShort
                                className={cn(styles.padding, styles.star)}
                                hint={content => `(${content})`}
                            />
                            {expert.licensedIn && (
                                <span className={cn(styles.padding, styles.license)}>
                                    <CheckIcon className={styles.icon} />
                                    {t('Licence’s Info')}
                                </span>
                            )}
                        </div>
                        <StatsTable className={cn(styles.padding, styles.table, styles.locationInfo)} items={locationInfo} />
                        {/* Experience desktop */}
                        {isNotEmptyObject(experienceInfo) && (
                            <StatsBlock
                                title={t('Experience')}
                                titleInfo={getLocaleValue(expert, 'experienceDescription', locale)}
                                items={experienceInfo}
                                className={styles.desktopExperience}
                                size="dynamic"
                            />
                        )}
                    </div>
                </div>
                {!!expert.biography?.length && (
                    <div className={styles.biography}>
                        <ShortText className={styles.description} rows={6} expandable={true}>
                            {getLocaleValue(expert, 'biography', locale)}
                        </ShortText>
                    </div>
                )}
                {/* Experience mobile */}
                {isNotEmptyObject(experienceInfo) && (
                    <StatsBlock
                        title={t('Experience')}
                        titleInfo={getLocaleValue(expert, 'experienceDescription', locale)}
                        items={experienceInfo}
                        className={styles.mobileExperience}
                        size="dynamic"
                    />
                )}
                {isNotEmptyObject(expert.practiceAreasSet) && (
                    <>
                        <h3 className={cn(styles.header, styles.practice)}>{t('Practice areas')}</h3>
                        <Practice practice={expert.practiceAreasSet} mode="Area" />
                    </>
                )}
                {isNotEmptyArray(expertVisas) && (
                    <>
                        <h3 className={cn(styles.header, styles.practice)}>{t('Visa Practice')}</h3>
                        <PracticeArray practice={expertVisas} mode="Visa" />
                    </>
                )}
                {licensedIn && (
                    <StatsBlockText
                        title={t('License')}
                        minorValues={[t('Authorized to practice law at'), t('Licensed in')]}
                        majorValues={[authorizedIn, licensedIn]}
                        className={styles.mobile}
                    />
                )}

                {!!cost?.length && (
                    <>
                        <h3 className={cn(styles.mobile, styles.header)}>{t('Cost')}</h3>
                        <StatsTable className={cn(styles.mobile, styles.padding, styles.table, styles.mobileFees)} items={cost} />
                    </>
                )}
                {approvedReviews?.length > 0 && (
                    <>
                        <h3 className={cn(styles.header, styles.reviewsTitle)}>{t('Customers Review')}</h3>
                        <ul className={cn(styles.padding, styles.reviews)}>
                            {approvedReviews
                                ?.filter((item, index) => {
                                    if (isDesktop) {
                                        return index < 4
                                    }
                                    return index < 3
                                })
                                ?.map((item, index) => (
                                    <li key={item.id} className={styles.reviewItem} onClick={() => handlePreview(index)}>
                                        <ReviewPreview review={item} />
                                    </li>
                                ))}
                        </ul>
                        <div className={cn(styles.padding, styles.mobile)}>
                            <ReviewBrowser
                                disabled={!approvedReviews?.length}
                                reviews={approvedReviews}
                                initialIndex={previewIndex}
                                initialShow={previewIndex !== undefined}
                                onClose={() => setPreviewIndex(undefined)}
                                hideButton={true}
                            />
                        </div>
                        <Link to={`/experts/${expert.id}/reviews`} className={cn(styles.padding)}>
                            <ReviewBrowser
                                style={{ pointerEvents: 'none' }}
                                disabled={!approvedReviews?.length}
                                reviews={approvedReviews}
                                clickable={false}
                            />
                        </Link>
                    </>
                )}
                {expert.lawFirm && (
                    <>
                        <h3 className={cn(styles.header, styles.associated)}>{t('Belong to company')}</h3>
                        <LawFirmSummary lawFirm={expert.lawFirm} className={styles.lawFirm} />
                    </>
                )}
                {videos?.length! > 0 && (
                    <>
                        <h3 className={cn(styles.header, styles.video)}>{t('Videos')}</h3>
                        <VideoPlayer video={videos} className={cn(styles.mobile, styles.padding, styles.dots)} />
                        <ul className={cn(styles.desktopVideos, styles.padding, styles.desktop)}>
                            {videos
                                ?.filter((_, index) => (showAllVideos ? true : index < 4))
                                ?.map((item: Video) => {
                                    return <VideoPlayer key={item.id} video={[item]} className={styles.videoItem} />
                                })}
                        </ul>
                        {videos?.length! > 4 && !showAllVideos && (
                            <Button type="ghost" onClick={toggleShowAllVideos} className={cn(styles.desktop, styles.showAllVideo)}>
                                {t('Watch all N videos', { count: videos?.length })}
                            </Button>
                        )}
                    </>
                )}
                {articles?.length! > 0 && (
                    <>
                        <h3 className={cn(styles.header, styles.articlesHeader)}>{t('Articles')}</h3>
                        <ul className={cn(styles.padding, styles.articles)}>
                            {articles?.map(item => {
                                return (
                                    <li key={item.id}>
                                        <Link to={`/blog/${item.id}`}>{getLocaleValue(item, 'title', locale)}</Link>
                                    </li>
                                )
                            })}
                        </ul>
                    </>
                )}
            </div>

            {!!cost?.length && (
                <div className={cn(styles.desktop, styles.desktopFloatBlock)}>
                    <h3 className={styles.header}>{t('Cost')}</h3>
                    <StatsTable className={cn(styles.padding, styles.table, styles.desktopFees)} items={cost} />
                    <SetAppointmentButton float={false} className={styles.padding} noAssistant={true} onClick={handleSetAppointmentClick} />
                </div>
            )}
        </div>
    )
}
