import Table, { ColumnsType } from 'antd/lib/table'
import React, { useCallback, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button } from '../../components/ui/Button/Button'
import { useDispatch, useSelector } from '../../reducers/store'
import styles from './ExpertsAdminPage.module.css'
import { ReactComponent as PlusIcon } from '../../assets/plus.svg'
import { useExperts } from '../../hooks/useExperts'
import { Expert } from '../../types/expert'
import { deleteExperts } from '../../actions/experts'
import { useTranslation } from 'react-i18next'
import { ILanguageItem } from '../../types/languages'
import useGtmPageLoad from '../../hooks/useGtmPageLoad'

export const ExpertsAdminPage = () => {
    useGtmPageLoad('ExpertsAdminPage')
    const dispatch = useDispatch()
    const [selectedItems, setSelectedItems] = useState<string[]>([])
    useExperts()
    const { t } = useTranslation()
    const data = useSelector(store => store.experts.experts)

    const renderFullName = useCallback((value: any, item: Expert) => {
        return (
            <Link to={`/experts-admin/${item.id}`}>
                {item.firstName} {item.lastName}
            </Link>
        )
    }, [])

    const renderArray = useCallback((value: ILanguageItem[], item: Expert) => {
        return <>{value?.map(i => i.lang).join(', ')}</>
    }, [])

    const renderLawFirm = useCallback((value: any, item: Expert) => {
        return <>{item.lawFirm?.name}</>
    }, [])

    const columns: ColumnsType<Expert> = useMemo(() => {
        return [
            {
                dataIndex: 'name',
                title: 'Name',
                render: renderFullName
            },
            { dataIndex: 'title', title: 'Title' },
            {
                dataIndex: 'languages',
                title: 'Languages',
                render: renderArray
            },
            { dataIndex: 'location', title: t('Location') },
            { dataIndex: 'state', title: 'State' },
            { dataIndex: 'timezone', title: 'Time Zone' },
            { dataIndex: 'firstConsultationFee', title: 'First Consultation Fee' },
            { dataIndex: 'specialOffer', title: 'Special Offer' },
            { dataIndex: 'company', title: 'Company', render: renderLawFirm },
            { dataIndex: 'authorizedIn', title: 'Authorized In' },
            { dataIndex: 'licensedIn', title: t('Licensed in') }
        ]
    }, [renderFullName, renderArray, renderLawFirm, t])

    const handleDelete = () => {
        dispatch(deleteExperts(selectedItems))
        setSelectedItems([])
    }

    const handleSelect = (selectedKeys: (string | number)[]) => {
        setSelectedItems(selectedKeys.map(key => (typeof key !== 'string' ? key.toString() : key)))
    }

    return (
        <div className={styles.container}>
            <div className={styles.controls}>
                <Link to="/experts-admin/new">
                    <Button icon={<PlusIcon />} style={{ width: 'auto' }} className={styles.button}>
                        Add
                    </Button>
                </Link>
                <Button style={{ width: 'auto' }} type="ghost" className={styles.button} onClick={handleDelete}>
                    Delete
                </Button>
            </div>
            <div style={{ overflow: 'auto' }}>
                <Table columns={columns} dataSource={data} rowSelection={{ onChange: handleSelect }} rowKey="id" />
            </div>
        </div>
    )
}
