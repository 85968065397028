import { Form, Tabs } from 'antd'
import React, { useState } from 'react'
import { Button } from '../../components/ui/Button/Button'
import { Input } from '../../components/ui/Input/Input'
import { useDispatch, useSelector } from '../../reducers/store'
import styles from './RequestsAdminEditPage.module.css'
import { useHistory, useParams } from 'react-router-dom'
import FormItem from 'antd/lib/form/FormItem'
import { useLanguages } from '../../hooks/useLanguages'
import { Request } from '../../types/request'
import TextArea from 'antd/lib/input/TextArea'
import useGtmPageLoad from '../../hooks/useGtmPageLoad'
import { useRequest } from '../../hooks/useRequests'
import { addRequest, updateRequest } from '../../actions/requests'
import availableOptions from '../../components/CountrySelect/countries.json'
import { languageCode } from '../../constants/languages'

export const RequestsAdminEditPage = () => {
    useGtmPageLoad('RequestsAdminEditPage')
    const dispatch = useDispatch()
    useLanguages()
    const history = useHistory()
    const [form] = Form.useForm()
    const { id } = useParams<{ id: string }>()
    const isNew = id === 'new'
    useRequest(id)
    const selectedRequest = useSelector(store => store.experts.selectedRequest)

    const [active] = useState('en')

    const handleCancel = () => {
        history.push('/requests-admin')
    }

    const handleAdd = (values: Record<string, any>) => {
        const request: Partial<Request> = {
            ...values
        }
        dispatch(addRequest({ request, onSuccess: handleCancel }))
    }

    const handleUpdate = (values: Record<string, any>) => {
        if (values.localizations) {
            Object.keys(values.localizations).forEach(key => {
                values.localizations[key].locale = key
            })
        }
        const request = {
            ...values,
            id: Number.parseInt(selectedRequest!.id)
        } as any
        dispatch(updateRequest({ request, onSuccess: handleCancel }))
    }

    if (!selectedRequest && !isNew) {
        return null
    }

    const renderCreatedDate = (value: string) => {
        return new Date(value).toLocaleDateString('en-Us', { day: 'numeric', month: 'long', year: 'numeric' })
    }

    const defaultPane = (
        <Tabs.TabPane key="en" tab="English" closable={false}>
            <Form
                className={styles.form}
                form={form}
                layout="vertical"
                onFinish={isNew ? handleAdd : handleUpdate}
                initialValues={selectedRequest}
                autoComplete="off"
            >
                <FormItem label="id" name="id" wrapperCol={{ span: 8 }}>
                    <Input disabled={true} />
                </FormItem>
                <FormItem label="User name" wrapperCol={{ span: 8 }}>
                    <Input disabled={true} value={`${selectedRequest?.user.firstName || ''} ${selectedRequest?.user.lastName || ''}`} />
                </FormItem>
                <FormItem label="User contact" wrapperCol={{ span: 8 }}>
                    <Input disabled={true} value={selectedRequest?.user.email || selectedRequest?.user.phone || ''} />
                </FormItem>
                <FormItem label="Date & time of request" wrapperCol={{ span: 8 }}>
                    <Input disabled={true} value={renderCreatedDate(selectedRequest?.created || '')} />
                </FormItem>
                <FormItem label="Request’s title" name="title" wrapperCol={{ span: 8 }}>
                    <Input disabled={true} />
                </FormItem>
                <FormItem label="Description" name="description" wrapperCol={{ span: 8 }}>
                    <TextArea disabled={true} rows={9} />
                </FormItem>
                <FormItem label="Category" name="category" wrapperCol={{ span: 8 }}>
                    <Input disabled={true} />
                </FormItem>
                <FormItem label="Language" wrapperCol={{ span: 8 }}>
                    <Input disabled={true} value={languageCode[selectedRequest?.user.language || '']?.language || ''} />
                </FormItem>
                <FormItem label="Country" wrapperCol={{ span: 8 }}>
                    <Input disabled={true} value={(availableOptions as any)[selectedRequest?.country || '']} />
                </FormItem>
            </Form>
        </Tabs.TabPane>
    )

    return (
        <div className={styles.container}>
            <Tabs type="editable-card" activeKey={active}>
                {defaultPane}
            </Tabs>
            <div className={styles.footer}>
                <Button type="ghost" onClick={handleCancel} className={styles.button}>
                    Cancel
                </Button>
                <Button disabled={true} onClick={form.submit} style={{ width: 'auto' }} className={styles.button}>
                    Save
                </Button>
            </div>
        </div>
    )
}
