import Table, { ColumnsType } from 'antd/lib/table'
import React, { useCallback, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Button } from '../../components/ui/Button/Button'
import { useSelector } from '../../reducers/store'
import styles from './AssistantsAdminPage.module.css'
import { ReactComponent as PlusIcon } from '../../assets/plus.svg'
import { getId, useAssistants } from '../../hooks/useAssistants'
import { CalendlyUser } from '../../types/assistants'
import Avatar from 'antd/lib/avatar/avatar'
import useGtmPageLoad from '../../hooks/useGtmPageLoad'

export const AssistantsAdminPage = () => {
    useGtmPageLoad('AssistantsAdminPage')
    useAssistants()
    const data = useSelector(store => store.assistants.assistants)

    const renderAvatar = useCallback((value: any, item: CalendlyUser) => {
        return <Avatar size="large" src={value} />
    }, [])

    const renderLink = useCallback((value: any, item: CalendlyUser) => {
        return <Link to={`/assistants-admin/${getId(item.uri)}`}>{value}</Link>
    }, [])

    const columns: ColumnsType<CalendlyUser> = useMemo(() => {
        return [
            {
                dataIndex: 'avatarUrl',
                title: 'Photo',
                render: renderAvatar
            },
            {
                dataIndex: 'name',
                title: 'Name',
                render: renderLink
            },
            {
                dataIndex: 'email',
                title: 'Email'
            },
            {
                dataIndex: 'timezone',
                title: 'Timezone'
            }
        ]
    }, [renderAvatar, renderLink])

    return (
        <div className={styles.container}>
            <div className={styles.controls}>
                <Link to="/experts-admin/new">
                    <Button icon={<PlusIcon />} style={{ width: 'auto' }} className={styles.button}>
                        Add
                    </Button>
                </Link>
            </div>
            <div style={{ overflow: 'auto' }}>
                <Table columns={columns} dataSource={data} rowKey="id" />
            </div>
        </div>
    )
}
