import { Form, Popover, Tabs } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { Button } from '../../components/ui/Button/Button'
import { Input } from '../../components/ui/Input/Input'
import { useDispatch, useSelector } from '../../reducers/store'
import styles from './VisaAdminEditPage.module.css'
import { useHistory, useParams } from 'react-router-dom'
import FormItem from 'antd/lib/form/FormItem'
import { Dropdown } from '../../components/ui/Dropdown/Dropdown'
import { useLanguages } from '../../hooks/useLanguages'
import { useToggle } from '../../hooks/useToggle'
import { useVisa } from '../../hooks/useVisa'
import { Visa } from '../../types/visa'
import { addVisa, updateVisa } from '../../actions/visa'
import TextArea from 'antd/lib/input/TextArea'
import useGtmPageLoad from '../../hooks/useGtmPageLoad'

export const VisaAdminEditPage = () => {
    useGtmPageLoad('VisaAdminEditPage')
    const dispatch = useDispatch()
    useLanguages()
    const languages = useSelector(store => store.languages.languages)
    const history = useHistory()
    const [form] = Form.useForm()
    const { id } = useParams<{ id: string }>()
    const isNew = id === 'new'
    const ref = useRef(null)
    useVisa(id)
    const selectedVisa = useSelector(store => store.experts.selectedVisa)
    const [locales, setLocales] = useState<Record<string, any>>({})

    const [showPopover, togglePopover] = useToggle()

    const [active, setActive] = useState('en')
    useEffect(() => {
        if (selectedVisa) {
            setLocales(selectedVisa.localizations ?? {})
        }
    }, [selectedVisa])

    const handleCancel = () => {
        history.push('/visa-admin')
    }

    const handleAdd = (values: Record<string, any>) => {
        const visa: Partial<Visa> = {
            ...values
        }
        Object.entries(visa.localizations ?? {}).forEach(([key, value]) => {
            value.locale = key
        })
        dispatch(addVisa({ visa, onSuccess: handleCancel }))
    }

    const handleUpdate = (values: Record<string, any>) => {
        if (values.localizations) {
            Object.keys(values.localizations).forEach(key => {
                values.localizations[key].locale = key
            })
        }
        const visa = {
            ...values,
            localizations: {
                ...locales,
                ...values.localizations
            },
            id: Number.parseInt(selectedVisa!.id)
        } as any
        dispatch(updateVisa({ visa, onSuccess: handleCancel }))
    }

    if (!selectedVisa && !isNew) {
        return null
    }

    const defaultPane = (
        <Tabs.TabPane key="en" tab="English" closable={false}>
            <Form
                className={styles.form}
                form={form}
                layout="vertical"
                onFinish={isNew ? handleAdd : handleUpdate}
                initialValues={selectedVisa}
                autoComplete="off"
            >
                <FormItem label="Code" name="code" wrapperCol={{ span: 8 }}>
                    <Input />
                </FormItem>
                <FormItem label="Label" name="label" wrapperCol={{ span: 8 }}>
                    <Input />
                </FormItem>
                <FormItem label="Description" name="description" wrapperCol={{ span: 8 }}>
                    <TextArea />
                </FormItem>
            </Form>
        </Tabs.TabPane>
    )

    const handleAddLocale = (value: any, item: any) => {
        setLocales({
            ...locales,
            [value]: { locale: value }
        })
        setActive(value)
        togglePopover()
    }

    const opts = languages
        .filter(l => {
            return l.code !== 'en' && !Object.keys(locales).includes(l.code)
        })
        .map((l: any) => ({ value: l.code, label: l.name }))
    const Content = <Dropdown options={opts as any} onSelect={handleAddLocale} />

    const Add = <span ref={ref}>+ Add</span>

    const tabs = Object.entries(locales).map(([key, item]) => {
        return (
            <Tabs.TabPane key={key} tab={languages.find(l => l.code === key)?.name}>
                <Form
                    className={styles.form}
                    layout="vertical"
                    form={form}
                    onFinish={isNew ? handleAdd : handleUpdate}
                    initialValues={selectedVisa}
                >
                    <FormItem label="Code" name={['localizations', key, 'code']} wrapperCol={{ span: 8 }}>
                        <Input />
                    </FormItem>
                    <FormItem label="Label" name={['localizations', key, 'label']} wrapperCol={{ span: 8 }}>
                        <Input />
                    </FormItem>
                    <FormItem label="Description" name={['localizations', key, 'description']} wrapperCol={{ span: 8 }}>
                        <Input />
                    </FormItem>
                </Form>
            </Tabs.TabPane>
        )
    })

    const handleTabEdit = (e: string | React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>, action: 'add' | 'remove') => {
        if (action === 'add') {
            togglePopover()
        } else {
            const newLocales = { ...locales }
            delete newLocales[e as string]
            setLocales(newLocales)
        }
    }

    return (
        <div className={styles.container}>
            <Popover content={Content} visible={showPopover} onVisibleChange={togglePopover} />
            <Tabs type="editable-card" activeKey={active} onEdit={handleTabEdit} addIcon={Add} onChange={key => setActive(key)}>
                {defaultPane}
                {tabs}
            </Tabs>
            <div className={styles.footer}>
                <Button type="ghost" onClick={handleCancel} className={styles.button}>
                    Cancel
                </Button>
                <Button onClick={form.submit} style={{ width: 'auto' }} className={styles.button}>
                    Save
                </Button>
            </div>
        </div>
    )
}
