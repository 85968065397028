import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { addProject, fetchProjects, fetchProject, fetchProjectCategories, updateProject, fetchMyProjects } from '../actions'
import { Filter, Sorter } from '../types/filter'
import { Project, ProjectCategory } from '../types/project'
import { ReqInvestment } from '../types/reqInvestment'
import { fetchfetchReqInvestments, fetchReqInvestment } from '../actions/reqInvestment'

type ProjectsSlice = {
    projects: Project[]
    selectedProject?: Project
    myProjects?: Project[]
    pending?: boolean
    filters: Filter[]
    sorters: Sorter[]
    categories?: ProjectCategory[]
    requiredInvestment?: ReqInvestment[]
    selectedRequiredInvestment?: ReqInvestment
}

export const projectsReducer = createSlice({
    name: 'projects',
    initialState: {
        projects: [],
        filters: [],
        sorters: [
            {
                fieldName: 'status',
                direction: 'desc'
            },
            {
                fieldName: 'constructionEndPlanned',
                direction: 'asc'
            }
        ],
        categories: []
    } as ProjectsSlice,
    reducers: {
        addFilter: (state, action: PayloadAction<Filter>) => {
            const existing = state.filters.find(item => item.path === action.payload.path)
            if (existing) {
                existing.value = action.payload.value
            } else {
                state.filters.push(action.payload)
            }
        },
        removeFilter: (state, action: PayloadAction<string>) => {
            state.filters = state.filters.filter(item => item.path !== action.payload)
        },
        removeAllFilters: state => {
            state.filters = []
        },
        updateSorter: (state, action: PayloadAction<Sorter>) => {
            state.sorters = state.sorters.map(item => (item.fieldName === action.payload.fieldName ? action.payload : item))
        },
        clearSelected: state => {
            state.selectedProject = undefined
        },
        clearSelectedReqInvestment: state => {
            state.selectedRequiredInvestment = undefined
        }
    },
    extraReducers: builder => {
        builder
            .addCase(fetchProjects.pending, (state, action) => {
                state.pending = true
            })
            .addCase(fetchProjects.fulfilled, (state, action) => {
                state.projects = action.payload.projects
                state.pending = false
            })
            .addCase(addProject.pending, (state, action) => {
                state.pending = true
                state.selectedProject = undefined
            })
            .addCase(updateProject.fulfilled, state => {
                state.selectedProject = undefined
            })
            .addCase(addProject.fulfilled, (state, action) => {
                state.pending = false
                state.selectedProject = undefined
            })
            .addCase(fetchProject.fulfilled, (state, action) => {
                state.selectedProject = action.payload.project
            })
            .addCase(fetchProjectCategories.fulfilled, (state, action) => {
                state.categories = action.payload.categories
            })
            .addCase(fetchMyProjects.fulfilled, (state, action) => {
                state.myProjects = action.payload.projects
            })
            .addCase(fetchfetchReqInvestments.fulfilled, (state, action) => {
                state.requiredInvestment = action.payload.invests
            })
            .addCase(fetchReqInvestment.fulfilled, (state, action) => {
                state.selectedRequiredInvestment = action.payload.invest
            })
    }
})

export const { addFilter, removeFilter, removeAllFilters, clearSelected, updateSorter, clearSelectedReqInvestment } =
    projectsReducer.actions
