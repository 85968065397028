import { configureStore } from '@reduxjs/toolkit'
import { useDispatch as useDispatchRedux, TypedUseSelectorHook, useSelector as useSelectorRedux } from 'react-redux'
import { lawFirmsReducer } from './lawFirms'
import { pagesReducer } from './pages'
import { projectsReducer } from './projects'
import { regionalCentersReducer } from './regionalCenters'
import { expertsReducer } from './experts'
import { assistantsReducer } from './assisntants'
import { languagesReducer } from './languages'
import { sessionReducer } from './session'
import { developersReducer } from './developers'
import { jobsReducer } from './jobs'
import { blogReducer } from './blog'
import { wikiReducer } from './wiki'
import { categoriesIndustryReducer } from './categoryIndustry'

export const store = configureStore({
    reducer: {
        pages: pagesReducer.reducer,
        projects: projectsReducer.reducer,
        regionalCenters: regionalCentersReducer.reducer,
        lawFirms: lawFirmsReducer.reducer,
        experts: expertsReducer.reducer,
        assistants: assistantsReducer.reducer,
        developers: developersReducer.reducer,
        jobs: jobsReducer.reducer,
        blog: blogReducer.reducer,
        wiki: wikiReducer.reducer,
        languages: languagesReducer.reducer,
        categoriesIndustry: categoriesIndustryReducer.reducer,
        session: sessionReducer.reducer
    }
})

export type RootState = ReturnType<typeof store.getState>
export type Dispatch = typeof store.dispatch
export const useDispatch = () => useDispatchRedux<Dispatch>()
export const useSelector: TypedUseSelectorHook<RootState> = useSelectorRedux
