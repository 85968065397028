import { createAsyncThunk } from '@reduxjs/toolkit'
import { languagesGetAll, languagesCreate, languagesGet, languagesDelete, languagesUpdate } from '../api/languages'
import { RootState } from '../reducers/store'
import { Language, LanguageExpertise } from '../types/i18n'
import { sendDelete, sendGet, sendPost, sendPut } from '../utils/api'

export const fetchLanguages = createAsyncThunk<{ languages: Language[] }, void, { state: RootState }>(
    'fetchLanguages',
    async (_, thunkAPI) => {
        const state = thunkAPI.getState() as RootState
        const response = await languagesGetAll(thunkAPI, state.lawFirms.filters, state.lawFirms.sorters)
        return { languages: response }
    }
)

export const fetchLanguage = createAsyncThunk<{ language: Language }, string, { state: RootState }>(
    'fetchLanguage',
    async (languageId: string, thunkAPI) => {
        const response = await languagesGet(languageId, thunkAPI)
        return { language: response }
    }
)

export const addLanguage = createAsyncThunk<
    { language: Language },
    { language: Partial<Language>; onSuccess: () => void },
    { state: RootState }
>('addLanguage', async ({ language, onSuccess }, thunkAPI) => {
    const response = await languagesCreate(language as Language, thunkAPI)
    onSuccess()
    thunkAPI.dispatch(fetchLanguages())
    return { language: response }
})

export const updateLanguage = createAsyncThunk<{ language: Language }, { language: Language; onSuccess: () => void }, { state: RootState }>(
    'updateLanguage',
    async ({ language, onSuccess }, thunkAPI) => {
        const response = await languagesUpdate(language, thunkAPI)
        onSuccess()
        return { language: response }
    }
)

export const deleteLanguages = createAsyncThunk<{}, string[], { state: RootState }>('deleteLanguages', async (ids, thunkAPI) => {
    await languagesDelete(ids, thunkAPI)
    thunkAPI.dispatch(fetchLanguages())
    return {}
})

export const fetchExpertises = createAsyncThunk<{ expertises: LanguageExpertise[] }, void, { state: RootState }>(
    'fetchLanguageExpertises',
    async (_, thunkAPI) => {
        const response = await sendGet('/languageExpertises', thunkAPI)
        return { expertises: response }
    }
)

export const fetchExpertise = createAsyncThunk<{ expertise: LanguageExpertise }, string, { state: RootState }>(
    'fetchLanguageExpertise',
    async (id: string, thunkAPI) => {
        const response = await sendGet(`/languageExpertises/${id}`, thunkAPI)
        return { expertise: response }
    }
)

export const addExpertise = createAsyncThunk<
    { expertise: LanguageExpertise },
    { expertise: Partial<LanguageExpertise>; onSuccess: () => void },
    { state: RootState }
>('addLanguageExpertises', async ({ expertise, onSuccess }, thunkAPI) => {
    const response = await sendPost(`/languageExpertises`, thunkAPI, expertise)
    onSuccess()
    thunkAPI.dispatch(fetchExpertises())
    return { expertise: response }
})

export const updateExpertise = createAsyncThunk<
    { expertise: LanguageExpertise },
    { expertise: LanguageExpertise; onSuccess: () => void },
    { state: RootState }
>('updateLanguageExpertises', async ({ expertise, onSuccess }, thunkAPI) => {
    const response = await sendPut(`/languageExpertises/${expertise.id}`, thunkAPI, expertise)
    onSuccess()
    return { expertise: response }
})

export const deleteExpertises = createAsyncThunk<{}, string[], { state: RootState }>('deleteExpertises', async (ids, thunkAPI) => {
    await sendDelete('/languageExpertises', thunkAPI, ids)
    thunkAPI.dispatch(fetchExpertises())
    return {}
})
