import { useEffect } from 'react'
import { fetchBlogArticle, fetchBlogArticles } from '../actions/blog'
import { clearSelected } from '../reducers/blog'
import { useDispatch } from '../reducers/store'

export function useBlogArticles() {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchBlogArticles())
    }, [dispatch])
}

export function useBlogArticle(id: string) {
    const dispatch = useDispatch()
    useEffect(() => {
        if (id !== 'new') {
            dispatch(fetchBlogArticle(id))
        }
        return () => {
            dispatch(clearSelected())
        }
    }, [id, dispatch])
}
