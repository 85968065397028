import { useEffect } from 'react'
import { fetchDeveloper, fetchDevelopers } from '../actions/developers'
import { clearSelected } from '../reducers/developers'
import { useDispatch } from '../reducers/store'

export function useDevelopers() {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchDevelopers())
    }, [dispatch])
}

export function useDeveloper(developerId: string) {
    const dispatch = useDispatch()
    useEffect(() => {
        if (developerId !== 'new') {
            dispatch(fetchDeveloper(developerId))
        }
        return () => {
            dispatch(clearSelected())
        }
    }, [developerId, dispatch])
}
