import { Drawer, Modal } from 'antd'
import React, { ReactNode, useCallback, useState, cloneElement, ReactElement } from 'react'
import styles from './BottomSheet.module.css'
import { ReactComponent as CloseIcon } from '../../../assets/x.svg'
import { Button } from '../Button/Button'
import { cn } from '../../../utils/cn'
import { useTranslation } from 'react-i18next'

export type TBottomSheetSizes = 'small' | 'medium' | 'xmedium' | 'large' | 'veryLarge' | 'dynamic' | '25' | '30' | '40' | '50'

export interface BottomSheetProps {
    title?: ReactNode
    content?: ReactNode
    className?: string
    classNameContent?: string
    children?: ReactElement
    closable?: boolean
    size?: TBottomSheetSizes
    footer?: ReactElement | boolean
    show?: boolean
    destroyOnClose?: boolean
    onToggle?: () => void
}

const sizes: Record<string, string | number | undefined> = {
    25: '25%',
    30: '30%',
    40: '40%',
    50: '50%',
    small: '58%',
    xmedium: '65%',
    medium: '76%',
    large: '84%',
    veryLarge: '97%',
    dynamic: undefined
}

export const BottomSheet = ({
    title = '',
    children,
    className,
    classNameContent,
    content,
    size = 'small',
    closable = true,
    footer = true,
    show: defaultShow,
    destroyOnClose,
    onToggle
}: BottomSheetProps) => {
    const [show, setShow] = useState(defaultShow)
    const toggleShow = useCallback(() => {
        setShow(!show)
    }, [show, setShow])
    const { t } = useTranslation()
    const header = typeof title === 'string' && title! ? <h2 className={styles.title}>{title}</h2> : title

    const handleClick = useCallback(() => {
        children?.props?.onClick?.()
        onToggle?.() ?? toggleShow?.()
    }, [children, onToggle, toggleShow])

    return (
        <>
            {children && cloneElement(children, { onClick: handleClick })}
            {(defaultShow ?? show) && (
                <Modal
                    visible={defaultShow ?? show}
                    footer={null}
                    onCancel={onToggle ?? toggleShow}
                    className={styles.modal}
                    destroyOnClose={true}
                    closeIcon={<CloseIcon />}
                >
                    {header}
                    {typeof content === 'string' && <div className={cn(styles.content, classNameContent)}>{content}</div>}
                    {typeof content !== 'string' && content}
                    {typeof footer !== 'boolean' && footer}
                </Modal>
            )}

            <Drawer
                height={sizes[size]}
                className={cn(styles.drawer, { [styles.dynamic]: size === 'dynamic' })}
                placement="bottom"
                visible={defaultShow ?? show}
                onClose={onToggle ?? toggleShow}
                maskClosable
                title={header}
                forceRender
                destroyOnClose={destroyOnClose}
                closeIcon={closable && <CloseIcon className={styles.icon} />}
            >
                <div className={styles.container}>
                    {typeof content === 'string' && <div className={cn(styles.content, classNameContent)}>{content}</div>}
                    {typeof content !== 'string' && content}
                    {footer && (
                        <footer className={styles.footer}>
                            {typeof footer !== 'boolean' &&
                                cloneElement(footer, {
                                    onClick: () => {
                                        footer.props?.onClick?.()
                                        toggleShow()
                                    }
                                })}
                            <Button className={cn(styles.button, styles.backButton)} type="ghost" onClick={onToggle ?? toggleShow}>
                                {t('Back')}
                            </Button>
                        </footer>
                    )}
                </div>
            </Drawer>
        </>
    )
}
