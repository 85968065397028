import React, { useCallback, useEffect, useState } from 'react'
import { Modal } from 'antd'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Button } from '../ui/Button/Button'
import { LegalFooter } from '../ui/LegalFooter/LegalFooter'

import { clearError } from '../../reducers/session'
import { useDispatch, useSelector } from '../../reducers/store'

import { EError } from '../../constants/error'

import { ReactComponent as CloseIcon } from '../../assets/x.svg'

import styles from './CountryErrorModal.module.css'

export const CountryErrorModal = () => {
    const { t } = useTranslation()
    const history = useHistory()
    const dispatch = useDispatch()
    const errorData = useSelector(store => store.session.error)

    const [modalVisible, setModalVisible] = useState<boolean>(false)

    useEffect(() => {
        if (errorData) {
            setModalVisible(errorData?.errorCode === EError.Error423)
        }
    }, [errorData])

    const onCloseErrorModal = useCallback(() => {
        dispatch(clearError())
        history.push('/projects')
        setModalVisible(false)
    }, [dispatch, history])

    const modalContent = (
        <div className={styles.content}>
            <div className={styles.title}>{t('Sorry!')}</div>

            <div className={styles.text}>{t('Content not available for your country')}</div>
            <Button className={styles.button} onClick={onCloseErrorModal} type="ghost">
                {t('Back to EB-5 Project List')}
            </Button>
            <div className={styles.mobile}>
                <LegalFooter />
            </div>
        </div>
    )

    return !modalVisible ? (
        <div />
    ) : (
        <Modal
            visible={true}
            footer={null}
            onCancel={onCloseErrorModal}
            className={styles.modal}
            destroyOnClose={true}
            closeIcon={<CloseIcon />}
        >
            {modalContent}
        </Modal>
    )
}
