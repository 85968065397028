import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from '../../reducers/store'
import Table, { ColumnsType } from 'antd/lib/table'
import { Link } from 'react-router-dom'

import { Button } from '../../components/ui/Button/Button'

import { deletePracticeArea } from '../../actions/practiceAreas'

import { usePracticeAreas } from '../../hooks/usePracticeAreas'

import { PracticeAreas } from '../../types/practiceAreas'

import { ReactComponent as PlusIcon } from '../../assets/plus.svg'

import styles from './PracticeAreasAdminPage.module.css'
import useGtmPageLoad from '../../hooks/useGtmPageLoad'

export const PracticeAreasAdminPage = () => {
    useGtmPageLoad('PracticeAreasAdminPage')
    const dispatch = useDispatch()
    const [selectedItems, setSelectedItems] = useState<string[]>([])
    usePracticeAreas()
    const data = useSelector(store => store.experts.practiceAreas)

    const renderLink = useCallback((value: any, item: PracticeAreas) => {
        return <Link to={`/practice-areas-admin/${item.id}`}>{value}</Link>
    }, [])

    const columns: ColumnsType<PracticeAreas> = useMemo(() => {
        return [
            {
                dataIndex: 'code',
                title: 'Code',
                render: renderLink
            },
            {
                dataIndex: 'description',
                title: 'Description'
            }
        ]
    }, [renderLink])

    const handleDelete = () => {
        dispatch(deletePracticeArea(selectedItems))
        setSelectedItems([])
    }

    const handleSelect = (selectedKeys: (string | number)[]) => {
        setSelectedItems(selectedKeys.map(key => (typeof key !== 'string' ? key.toString() : key)))
    }

    return (
        <div className={styles.container}>
            <div className={styles.controls}>
                <Link to="/practice-areas-admin/new">
                    <Button icon={<PlusIcon />} style={{ width: 'auto' }} className={styles.button}>
                        Add
                    </Button>
                </Link>
                <Button style={{ width: 'auto' }} type="ghost" className={styles.button} onClick={handleDelete}>
                    Delete
                </Button>
            </div>
            <div style={{ overflow: 'auto' }}>
                <Table columns={columns} dataSource={data} rowSelection={{ onChange: handleSelect }} rowKey="id" />
            </div>
        </div>
    )
}
