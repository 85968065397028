import { Popover } from 'antd'
import React from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import { useSelector } from '../../reducers/store'
import { cn } from '../../utils/cn'
import { SearchInput } from '../SearchBar/SearchInput'
import { ReactComponent as SearchIcon } from '../../assets/search-icon.svg'
import styles from './Navigation.module.css'

interface NavigationProps {
    className?: string
    /**
     * Fires when menu item selected
     */
    onSelect?: () => void
}

/**
 * Navigation menu accessible from application bar
 */
export const Navigation = ({ className, onSelect }: NavigationProps) => {
    const m = useRouteMatch()
    const user = useSelector(store => store.session.user)
    const pages = useSelector(store => store.pages.pages)?.filter(item => {
        const requiredRoles = item.roles ?? ['USER']
        const userRoles = user?.roles ?? ['USER']
        return item.navigation && userRoles.some(role => requiredRoles.includes(role))
    })

    const desktopPages = (
        <ul className={styles.resourceList}>
            {pages
                .filter(item => ['/experts', '/projects'].includes(item.url) === false)
                .map(item => {
                    return (
                        <li key={item.navigation} className={styles.resourceItem}>
                            <Link to={item.url}>{item.navigation}</Link>
                        </li>
                    )
                })}
        </ul>
    )

    return (
        <nav className={cn(className, styles.container)}>
            <div className={styles.controls}>
                <button className={cn(styles.desktop, styles.searchIcon)}>
                    <SearchIcon />
                </button>
                <SearchInput className={cn(styles.mobile, styles.input)} placeholder="Search" />
            </div>
            <ul className={styles.items}>
                {pages.map(item => {
                    const matching = pages.find(page => page.url === m.path)
                    const selected = [matching?.url, matching?.group].includes(item.url)
                    return (
                        <li
                            key={item.navigation}
                            className={cn(styles.item, {
                                [styles.selected]: selected,
                                [styles.mobile]: ['/experts', '/projects'].includes(item.url) === false
                            })}
                        >
                            <Link to={item.url} onClick={onSelect}>
                                {item.navigation}
                            </Link>
                        </li>
                    )
                })}
                <li key="resources" className={cn(styles.item, styles.resources)}>
                    <Popover trigger="click" content={desktopPages}>
                        Resources
                    </Popover>
                </li>
            </ul>
        </nav>
    )
}
