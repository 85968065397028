import { Filter, Sorter } from '../types/filter'
import { LawFirm } from '../types/lawFirm'
import { sendDelete, sendGet, sendPost, sendPut } from '../utils/api'

export function firmsGetAll(thunkAPI: any, filters?: Filter[], sorters?: Sorter[]): Promise<LawFirm[]> {
    const params = new URLSearchParams()
    filters?.forEach(filter => {
        params.append(`_${filter.path}.${filter.operation}`, filter.value)
    })
    if (sorters?.length) {
        params.append(`sort`, sorters.map(item => `${item.fieldName}.${item.direction}`).join(','))
    }
    return sendGet(`/law-firm?${params.toString()}`, thunkAPI)
}

export function firmsGet(firmId: string, thunkAPI: any): Promise<LawFirm> {
    return sendGet(`/law-firm/${firmId}`, thunkAPI)
}

export function firmsCreate(firm: LawFirm, thunkAPI: any): Promise<LawFirm> {
    return sendPost(`/law-firm`, thunkAPI, firm)
}

export function firmsUpdate(firm: LawFirm, thunkAPI: any): Promise<LawFirm> {
    return sendPut(`/law-firm/${firm.id}`, thunkAPI, firm)
}

export function firmsDelete(ids: string[], thunkAPI: any) {
    return sendDelete(`/law-firm`, thunkAPI, ids)
}
