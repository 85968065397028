import React from 'react'
import { PageHeader as AntPageHeader } from 'antd'
import styles from './PageHeader.module.css'
import { cn } from '../../utils/cn'
import { useSelector } from '../../reducers/store'
import { BASE_FILE_URL } from '../../api/endpoints'
import { BackTitle } from '../ui/Back/BackTitle'

export interface PageHeaderProps {
    /**
     * Text to display
     */
    title?: string
    /**
     * An image that will be shown as a background for the header
     *
     * TODO: Url?
     */
    backgroundImage?: boolean
    /**
     * A logo that will be shown over background image; if true, `backgroundImage` is locked to `true`
     */
    logo?: boolean
    className?: string
    /**
     * Back icon click event
     */
    onBack?: () => void
}

/**
 * Page title, button to navigate to previous screen and optionally a header image (logo?)
 *
 * All additional properties will be passed to underlying https://ant.design/components/page-header/ component
 */
export const PageHeader = ({ title = '', backgroundImage = false, logo = false, className, onBack, ...rest }: PageHeaderProps) => {
    const logoSrc = useSelector(store => store.regionalCenters.selectedCenter)?.logo
    const back = <BackTitle title={title} />
    return (
        <div className={cn(styles.header, className)}>
            <AntPageHeader title=" " backIcon={back} onBack={title ? onBack : undefined} {...rest} />
            {(backgroundImage || logo) && (
                <div className={styles.backgroundContainer}>
                    <div className={styles.background} />
                </div>
            )}
            <div className={styles.logoContainer}>
                {logo && (
                    <div className={styles.logo}>
                        <img src={`${BASE_FILE_URL}/${logoSrc}`} alt="Citizon logo" />
                    </div>
                )}
            </div>
        </div>
    )
}
