import React, { useCallback, useState } from 'react'
import { Collapse } from 'antd'
import { useHistory } from 'react-router-dom'
import CollapsePanel from 'antd/lib/collapse/CollapsePanel'

import { BottomSheet } from '../ui/BottomSheet/BottomSheet'
import { Button } from '../ui/Button/Button'

import { clearError } from '../../reducers/session'
import { useDispatch, useSelector } from '../../reducers/store'

import { EUserRoles } from '../../constants/session'
import { EError } from '../../constants/error'
import { isNotEmptyObject } from '../../utils/data'

import { ReactComponent as ErrorIcon } from '../../assets/error-cross.svg'

import styles from './ErrorModal.module.css'

export const ErrorModal = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const roles = useSelector(store => store.session.user?.roles)
    const errorData = useSelector(store => store.session.error)
    // Dont show country error status 423
    const isVisibleModal =
        isNotEmptyObject(errorData) &&
        roles?.includes(EUserRoles.Admin) &&
        errorData?.errorCode !== EError.Error423 &&
        errorData?.errorCode !== EError.TokenExpired
    const [copySuccess, setCopySuccess] = useState<boolean>(false)

    const copyToClipboard = () => {
        let textField = document.createElement('textarea')
        textField.innerText = errorData?.errorMessage || 'No error text'
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()
        setCopySuccess(true)
    }

    const onCloseErrorModal = useCallback(() => {
        dispatch(clearError())
        history.push('/')
    }, [dispatch, history])

    const modalContent = (
        <div className={styles.content}>
            <ErrorIcon className={styles.image} />
            <div className={styles.title}>Error {errorData?.errorCode}</div>
            {!!errorData?.errorMessage?.length && (
                <div>
                    <Button className={styles.copy} onClick={copyToClipboard} type="ghost">
                        {!copySuccess ? 'Copy to clickboard' : 'coped'}
                    </Button>
                    <Collapse>
                        <CollapsePanel header="Error message text" key="1">
                            <div className={styles.text}>{errorData.errorMessage}</div>
                        </CollapsePanel>
                    </Collapse>
                </div>
            )}
            <Button className={styles.btn} onClick={onCloseErrorModal}>
                OK
            </Button>
        </div>
    )

    return !isVisibleModal ? (
        <div />
    ) : (
        <BottomSheet footer={false} closable={true} size="dynamic" show={true} onToggle={onCloseErrorModal} content={modalContent} />
    )
}
