import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from '../reducers/store'
import { BlogArticle } from '../types/blog'
import { sendDelete, sendGet, sendPost, sendPut } from '../utils/api'

export const fetchBlogArticles = createAsyncThunk<{ articles: BlogArticle[] }, void, { state: RootState }>(
    'fetchBlogArticles',
    async (_, thunkAPI) => {
        const response = await sendGet('/blog?sort=date.desc', thunkAPI)
        return { articles: response }
    }
)

export const fetchBlogArticle = createAsyncThunk('fetchBlogArticle', async (id: string, thunkAPI) => {
    const response = await sendGet(`/blog/${id}`, thunkAPI)
    return { article: response }
})

export const addBlogArticle = createAsyncThunk<
    { article: BlogArticle },
    { article: Partial<BlogArticle>; onSuccess: () => void },
    { state: RootState }
>('addBlogArticle', async ({ article, onSuccess }, thunkAPI) => {
    const response = await sendPost('/blog', thunkAPI, article)
    onSuccess()
    thunkAPI.dispatch(fetchBlogArticles())
    return { article: response }
})

export const updateBlogArticle = createAsyncThunk<
    { article: BlogArticle },
    { article: Partial<BlogArticle>; onSuccess: () => void },
    { state: RootState }
>('updateBlogArticle', async ({ article, onSuccess }, thunkAPI) => {
    const response = await sendPut(`/blog/${article.id}`, thunkAPI, article)
    onSuccess()
    return { article: response }
})

export const deleteBlogArticles = createAsyncThunk<{}, string[], { state: RootState }>('deleteBlogArticles', async (ids, thunkAPI) => {
    await sendDelete('/blog', thunkAPI, ids)
    thunkAPI.dispatch(fetchBlogArticles())
    return {}
})
