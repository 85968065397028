import React, { useCallback, useMemo, useRef } from 'react'
import { Link } from 'react-router-dom'
import { Drawer, Dropdown as AntdDropdown } from 'antd'
import { ReactComponent as Menu } from './menu.svg'
import { ReactComponent as Logo } from '../../assets/citizon-logo.svg'
import { ReactComponent as LanguageIcon } from '../../assets/language.svg'
import { ReactComponent as UserIcon } from '../../assets/user.svg'
import styles from './AppBar.module.css'
import { Navigation } from '../Navigation/Navigation'
import { cn } from '../../utils/cn'
import { Dropdown } from '../ui/Dropdown/Dropdown'
import { changeLanguage } from '../../reducers/session'
import { capitalize, getLocaleValue } from '../../utils/string'
import { useSelector } from '../../reducers/store'
import { useDispatch } from 'react-redux'
import { useToggle } from '../../hooks/useToggle'
import { ProfileMenu } from '../ProfileMenu/ProfileMenu'
import i18n from 'i18next'
import { Notifications } from '../Notifications/Notifications'
import { LANDING_PAGE } from '../../constants/app'
import { Button } from '../ui/Button/Button'
import { useHistory } from 'react-router-dom'

/**
 * Application bar
 *
 * Handles navigation menu, logo, user profile and selected language
 */
export const AppBar = () => {
    const history = useHistory()
    const currentLocation = history.location.pathname
    const [showMenu, toggleShowMenu] = useToggle()
    const [showProfile, toggleShowProfile] = useToggle()
    const user = useSelector(store => store.session.user)
    const dispatch = useDispatch()
    const languages = useSelector(store => store.languages.languages)
    const locale = useSelector(store => store.session.lang)
    const lang = useSelector(store => store.session.lang) ?? 'en'
    const isDesktop = window.screen.width > 960
    const handleChange = (code: string) => {
        i18n.changeLanguage(code).then(() => {
            localStorage.setItem('lang', code)
            dispatch(changeLanguage({ code }))
        })
    }
    const [showLang, toggleShowLang] = useToggle()

    const hideButtons = useMemo(() => currentLocation === '/login', [currentLocation])

    const ref = useRef<HTMLDivElement>(null)
    const langRef = useRef<HTMLDivElement>(null)
    const handleToggleLang = useCallback(() => {
        ref.current?.querySelectorAll('.rc-virtual-list-holder-inner > div').forEach(item => {
            item.setAttribute('title', '')
        })
        toggleShowLang()
    }, [toggleShowLang])

    const handleClickLogin = useCallback(() => history.push('/login'), [history])

    return (
        <header className={cn(styles.container, { shadow: !showMenu })}>
            <div className={styles.content} ref={ref}>
                <div className={styles.left}>
                    <Menu className={styles.menu} onClick={toggleShowMenu} />
                    {user ? (
                        <Link to="/home">
                            <Logo />
                        </Link>
                    ) : (
                        // requested redirect to exact url
                        <a href={LANDING_PAGE}>
                            <Logo />
                        </a>
                    )}
                    {!hideButtons && <Navigation className={styles.desktop} />}
                </div>
                <div className={cn(styles.langContainer, { [styles.langMargin]: hideButtons })} ref={langRef} onClick={toggleShowLang}>
                    <LanguageIcon />
                    <Dropdown
                        getPopupContainer={() => langRef?.current!}
                        value={lang}
                        virtual={false}
                        className={styles.lang}
                        options={languages?.map(l => ({
                            value: l.code,
                            label: isDesktop ? getLocaleValue(l, 'name', locale) : capitalize(l.code)
                        }))}
                        style={{ pointerEvents: 'none' }}
                        open={showLang}
                        onClick={null as any}
                        onDropdownVisibleChange={handleToggleLang}
                        onSelect={handleChange}
                        dropdownRender={node => <div>{node}</div>}
                    />
                </div>
                {user && (
                    <>
                        <Notifications className={styles.notifications} getPopupContainer={e => ref.current ?? e} />
                        <div className={styles.right}>
                            {user && (
                                <>
                                    <button className={cn(styles.mobile, styles.avatar)} onClick={toggleShowProfile}>
                                        <UserIcon />
                                    </button>
                                    <AntdDropdown overlay={<ProfileMenu />} trigger={['click']} placement="bottomRight">
                                        <button className={cn(styles.desktop, styles.avatar)}>
                                            <UserIcon className={styles.user} />
                                        </button>
                                    </AntdDropdown>
                                </>
                            )}
                        </div>
                    </>
                )}
                {!user && !hideButtons && (
                    <Button className={styles.buttonLogin} onClick={handleClickLogin}>
                        Log In
                    </Button>
                )}
                <Drawer
                    title={undefined}
                    className={styles.drawer}
                    placement="top"
                    height="auto"
                    destroyOnClose={true}
                    closable={false}
                    maskClosable
                    onClose={toggleShowProfile}
                    visible={showProfile}
                    zIndex={1010}
                >
                    <ProfileMenu onSignOut={toggleShowProfile} />
                </Drawer>
                <Drawer
                    title={undefined}
                    className={styles.drawer}
                    placement="top"
                    height="auto"
                    destroyOnClose={true}
                    closable={false}
                    maskClosable
                    onClose={toggleShowMenu}
                    visible={showMenu}
                    zIndex={1010}
                >
                    {!hideButtons && <Navigation onSelect={toggleShowMenu} />}
                </Drawer>
            </div>
        </header>
    )
}
