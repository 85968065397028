import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { addExpert, fetchExperts, fetchExpert, fetchJobTitles, fetchJobTitle, fetchMyExperts } from '../actions/experts'
import { fetchPracticeAreas, fetchPracticeArea } from '../actions/practiceAreas'
import { fetchVisa, fetchVisas } from '../actions/visa'
import { fetchReview, fetchReviews } from '../actions/review'
import { fetchRequest, fetchRequests } from '../actions/requests'

import { PracticeAreas } from '../types/practiceAreas'
import { Filter, Sorter } from '../types/filter'
import { Expert, JobTitle } from '../types/expert'
import { Visa } from '../types/visa'
import { Review } from '../types/Review'
import { Request } from '../types/request'

type ExpertsSlice = {
    experts: Expert[]
    myExperts: Expert[]
    selectedExpert?: Expert
    pending?: boolean
    filters: Filter[]
    sorters: Sorter[]
    jobTitles: JobTitle[]
    selectedJobTitle?: JobTitle
    visas: Visa[]
    selectedVisa?: Visa
    reviews: Review[]
    selectedReview?: Review
    practiceAreas: PracticeAreas[]
    selectedRequest?: Request
    requests: Request[]
    selectedPracticeArea?: PracticeAreas
}

export const expertsReducer = createSlice({
    name: 'experts',
    initialState: {
        experts: [],
        myExperts: [],
        filters: [],
        sorters: [],
        jobTitles: [],
        visas: [],
        requests: [],
        reviews: [],
        practiceAreas: []
    } as ExpertsSlice,
    reducers: {
        addFilter: (state, action: PayloadAction<Filter>) => {
            const existing = state.filters.find(item => item.path === action.payload.path)
            if (existing) {
                existing.value = action.payload.value
            } else {
                state.filters.push(action.payload)
            }
        },
        removeFilter: (state, action: PayloadAction<string>) => {
            state.filters = state.filters.filter(item => item.path !== action.payload)
        },
        removeAllFilters: state => {
            state.filters = []
        },
        clearSelected: state => {
            state.selectedExpert = undefined
        },
        clearSelectedTitle: state => {
            state.selectedJobTitle = undefined
        },
        clearSelectedVisa: state => {
            state.selectedVisa = undefined
        },
        clearSelectedPracticeArea: state => {
            state.selectedPracticeArea = undefined
        },
        clearSelectedReview: state => {
            state.selectedReview = undefined
        },
        clearSelectedRequest: state => {
            state.selectedRequest = undefined
        }
    },
    extraReducers: builder => {
        builder
            .addCase(fetchExperts.fulfilled, (state, action) => {
                state.experts = action.payload.experts
            })
            .addCase(fetchMyExperts.fulfilled, (state, action) => {
                state.myExperts = action.payload.experts
            })
            .addCase(fetchExpert.fulfilled, (state, action) => {
                state.selectedExpert = action.payload.expert
            })
            .addCase(addExpert.fulfilled, state => {
                state.selectedExpert = undefined
            })
            .addCase(fetchJobTitles.fulfilled, (state, action) => {
                state.jobTitles = action.payload.titles
            })
            .addCase(fetchJobTitle.fulfilled, (state, action) => {
                state.selectedJobTitle = action.payload.title
            })
            .addCase(fetchVisas.fulfilled, (state, action) => {
                state.visas = action.payload.visas
            })
            .addCase(fetchVisa.fulfilled, (state, action) => {
                state.selectedVisa = action.payload.visa
            })
            .addCase(fetchPracticeAreas.fulfilled, (state, action) => {
                state.practiceAreas = action.payload.practiceAreas
            })
            .addCase(fetchPracticeArea.fulfilled, (state, action) => {
                state.selectedPracticeArea = action.payload.practiceArea
            })
            .addCase(fetchReviews.fulfilled, (state, action) => {
                state.reviews = action.payload.reviews
            })
            .addCase(fetchReview.fulfilled, (state, action) => {
                state.selectedReview = action.payload.review
            })
            .addCase(fetchRequests.fulfilled, (state, action) => {
                state.requests = action.payload.requests
            })
            .addCase(fetchRequest.fulfilled, (state, action) => {
                state.selectedRequest = action.payload.request
            })
    }
})

export const {
    clearSelected,
    addFilter,
    removeAllFilters,
    removeFilter,
    clearSelectedTitle,
    clearSelectedVisa,
    clearSelectedPracticeArea,
    clearSelectedReview,
    clearSelectedRequest
} = expertsReducer.actions
