import { Job } from '../types/jobs'
import { sendGet, sendPost } from '../utils/api'

export function jobsGetAll(thunkAPI: any): Promise<Job[]> {
    return sendGet(`/jobs`, thunkAPI)
}

export function jobsGet(jobId: string, thunkAPI: any): Promise<Job> {
    return sendGet(`/jobs/${jobId}`, thunkAPI)
}

export function jobsCreate(job: Job, thunkAPI: any): Promise<Job> {
    return sendPost(`/jobs`, thunkAPI, job)
}
