import { createSlice } from '@reduxjs/toolkit'
import { addBlogArticle, fetchBlogArticle, fetchBlogArticles } from '../actions/blog'
import { BlogArticle } from '../types/blog'
import { Filter, Sorter } from '../types/filter'

type BlogSlice = {
    articles: BlogArticle[]
    selectedArticle?: BlogArticle
    pending?: boolean
    filters: Filter[]
    sorters: Sorter[]
}

export const blogReducer = createSlice({
    name: 'blog',
    initialState: {
        articles: [],
        filters: [],
        sorters: []
    } as BlogSlice,
    reducers: {
        clearSelected: state => {
            state.selectedArticle = undefined
        }
    },
    extraReducers: builder => {
        builder
            .addCase(fetchBlogArticles.fulfilled, (state, action) => {
                state.articles = action.payload.articles
            })
            .addCase(fetchBlogArticle.fulfilled, (state, action) => {
                state.selectedArticle = action.payload.article
            })
            .addCase(addBlogArticle.fulfilled, state => {
                state.selectedArticle = undefined
            })
    }
})

export const { clearSelected } = blogReducer.actions
