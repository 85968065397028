import { useEffect } from 'react'
import { fetchMyProjects, fetchProject, fetchProjectCategories, fetchProjects } from '../actions'
import { addFilter, clearSelected, removeAllFilters } from '../reducers/projects'
import { useDispatch } from '../reducers/store'
import { Filter, Sorter } from '../types/filter'

export function useProjects(filter?: Filter, sorter?: Sorter) {
    const dispatch = useDispatch()
    useEffect(() => {
        if (filter) {
            dispatch(addFilter(filter))
        }
        dispatch(fetchProjects())
        return () => {
            dispatch(removeAllFilters())
        }
    }, [dispatch, filter, sorter])
}

export function useProject(projectId: string) {
    const dispatch = useDispatch()
    useEffect(() => {
        if (projectId !== 'new') {
            dispatch(fetchProject(projectId))
        }
        return () => {
            dispatch(clearSelected())
        }
    }, [projectId, dispatch])
}

export function useProjectCategories() {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchProjectCategories())
    }, [dispatch])
}

export function useMyProjects() {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchMyProjects())
    }, [dispatch])
}
