import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from '../reducers/store'
import { Visa } from '../types/visa'
import { sendDelete, sendGet, sendPost, sendPut } from '../utils/api'

export const fetchVisas = createAsyncThunk<{ visas: Visa[] }, void, { state: RootState }>('fetchVisas', async (_, thunkAPI) => {
    const response = await sendGet(`/visaTypes`, thunkAPI)
    return { visas: response }
})

export const fetchVisa = createAsyncThunk<{ visa: Visa }, string, { state: RootState }>('fetchVisa', async (id, thunkAPI) => {
    const response = await sendGet(`/visaTypes/${id}`, thunkAPI)
    return { visa: response }
})

export const addVisa = createAsyncThunk<{ visa: Visa }, { visa: Partial<Visa>; onSuccess: () => void }, { state: RootState }>(
    'addVisa',
    async ({ visa, onSuccess }, thunkAPI) => {
        const response = await sendPost(`/visaTypes`, thunkAPI, visa)
        onSuccess?.()
        return { visa: response }
    }
)

export const updateVisa = createAsyncThunk<{ visa: Visa }, { visa: Partial<Visa>; onSuccess: () => void }, { state: RootState }>(
    'updateVisa',
    async ({ visa, onSuccess }, thunkAPI) => {
        const response = await sendPut(`/visaTypes/${visa.id}`, thunkAPI, visa)
        onSuccess?.()
        return { visa: response }
    }
)

export const deleteVisa = createAsyncThunk<{}, string[], { state: RootState }>('deleteVisas', async (ids, thunkAPI) => {
    await sendDelete(`/visaTypes`, thunkAPI, ids)
    thunkAPI.dispatch(fetchVisas())
    return {}
})
