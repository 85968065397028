import { createAsyncThunk } from '@reduxjs/toolkit'
import { reviews, reviewsGet, reviewsCreate, reviewsDelete, reviewsUpdate } from '../api/review'
import { RootState } from '../reducers/store'
import { Review } from '../types/Review'

export const fetchReviews = createAsyncThunk<{ reviews: Review[] }, void, { state: RootState }>('fetchReviews', async (_, thunkAPI) => {
    const response = await reviews(thunkAPI)
    return { reviews: response }
})

export const fetchReview = createAsyncThunk<{ review: Review }, string, { state: RootState }>(
    'fetchReview',
    async (reviewId: string, thunkAPI) => {
        const response = await reviewsGet(reviewId, thunkAPI)
        return { review: response }
    }
)

export const addReview = createAsyncThunk<{ review: Review }, { review: Partial<Review>; onSuccess: () => void }, { state: RootState }>(
    'addReview',
    async ({ review, onSuccess }, thunkAPI) => {
        const response = await reviewsCreate(review as Review, thunkAPI)
        onSuccess()
        thunkAPI.dispatch(fetchReviews())
        return { review: response }
    }
)

export const deleteReview = createAsyncThunk<{}, string[], { state: RootState }>('deleteReview', async (ids, thunkAPI) => {
    await reviewsDelete(ids, thunkAPI)
    thunkAPI.dispatch(fetchReviews())
    return {}
})

export const updateReview = createAsyncThunk<{ review: Review }, { review: Review; onSuccess: () => void }, { state: RootState }>(
    'updateReview',
    async ({ review, onSuccess }, thunkAPI) => {
        const response = await reviewsUpdate(review, thunkAPI)
        onSuccess()
        return { review: response }
    }
)
