import { CheckboxOptionType, Form, Popover, Switch, Tabs } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { addProject, updateProject } from '../../actions'
import { Button } from '../../components/ui/Button/Button'
import { Input } from '../../components/ui/Input/Input'
import { useDispatch, useSelector } from '../../reducers/store'
import styles from './ProjectsAdminEditPage.module.css'
import { useProject, useProjectCategories } from '../../hooks/useProjects'
import { StatesSelect } from '../../components/StatesSelect/StatesSelect'
import { useHistory, useParams } from 'react-router-dom'
import { Dropdown } from '../../components/ui/Dropdown/Dropdown'
import { Project, ProjectType } from '../../types/project'
import FormItem from 'antd/lib/form/FormItem'
import { RadioGroup } from '../../components/ui/RadioGroup/RadioGroup'
import { useRegionalCenters } from '../../hooks/useRegionalCenters'
import TextArea from 'antd/lib/input/TextArea'
import { FileUpload } from '../../components/ui/FileUpload/FileUpload'
import { AddJson } from '../../components/ui/AddJson/AddJson'
import { useDevelopers } from '../../hooks/useDevelopers'
import { HOME_PAGE } from '../../api/endpoints'
import { useLanguages } from '../../hooks/useLanguages'
import { useToggle } from '../../hooks/useToggle'
import { VideoUpload } from '../../components/ui/VideoUpload/VideoUpload'
import { useReqInvestments } from '../../hooks/useReqInvestment'
import useGtmPageLoad from '../../hooks/useGtmPageLoad'

const statusOptions: CheckboxOptionType[] = [
    { value: 'open', label: 'Open' },
    { value: 'closed', label: 'Closed' }
]

const projectTypeOptions: CheckboxOptionType[] = [
    { value: ProjectType.RegionalCenter, label: 'Regional Center' },
    { value: ProjectType.DirectProject, label: 'Direct Project' }
]

const teaOptions: CheckboxOptionType[] = [
    { value: true, label: 'TEA' },
    { value: false, label: 'non TEA' }
]

export const ProjectsAdminEditPage = () => {
    useGtmPageLoad('ProjectsAdminEditPage')
    const dispatch = useDispatch()
    const history = useHistory()
    const [form] = Form.useForm()
    const { id } = useParams<{ id: string }>()
    const isNew = id === 'new'
    useProject(id)
    useProjectCategories()
    useRegionalCenters()
    useDevelopers()
    useLanguages()
    useReqInvestments()
    const ref = useRef(null)
    const [locales, setLocales] = useState<Record<string, any>>({})
    const [showPopover, togglePopover] = useToggle()
    const [active, setActive] = useState('en')
    const languages = useSelector(store => store.languages.languages)
    const selectedProject = useSelector(store => store.projects.selectedProject)
    const categories = useSelector(store => store.projects.categories)?.map(item => ({ ...item, id: Number.parseInt(item.id, 10) }))
    const categoriesOptions = categories?.map(item => ({ value: item.id, label: item.name }))
    const requiredInvestmentData = useSelector(store => store.projects.requiredInvestment)
    const requiredInvestmentOptions = requiredInvestmentData?.map(item => ({ value: item.id, label: item.name }))
    const centers = useSelector(store => store.regionalCenters.centers)
    const centersOptions = centers?.map(item => ({ value: item.id, label: item.name }))
    const developers = useSelector(store => store.developers.developers)?.map(item => ({ ...item, id: Number.parseInt(item.id, 10) }))
    const developersOptions = developers?.map(item => ({ value: item.id, label: item.name }))

    const investmentTypes = [
        { value: 'loan', label: 'Loan' },
        { value: 'preferred_equity', label: 'Preferred Equity' },
        { value: 'equity', label: 'Equity' },
        { value: 'seniorLoan', label: 'Senior Loan' },
        { value: 'mezzanineLoan', label: 'Mezzanine Loan' }
    ]

    const handleCancel = () => {
        history.push('/projects-admin')
    }
    useEffect(() => {
        if (selectedProject) {
            setLocales(selectedProject.localizations ?? {})
        }
    }, [selectedProject])
    const handleAdd = (values: Record<string, any>) => {
        if (values.localizations) {
            Object.keys(values.localizations).forEach(key => {
                values.localizations[key].locale = key
            })
        }
        const valueCategoriesId = values.categories?.map((item: any) => item.id || item)
        const valuePartnersId = values.projectPartner?.map((item: any) => item.id || item)
        const project: Partial<Project> = {
            ...values,
            status: values.status ?? 'open',
            requiredInvestment: values.requiredInvestment,
            investmentType: values.investmentType ?? 'loan',
            regionalCenter: values.regionalCenterId
                ? ({
                      id: values.regionalCenterId
                  } as any)
                : undefined,
            projectPartner: developers?.filter(item => valuePartnersId?.includes(item.id)) as any,
            hideInUsa: !!values?.hideInUsa,
            categories: categories?.filter(item => valueCategoriesId?.includes(item.id)) as any
        }
        Object.entries(project.localizations ?? {}).forEach(([key, value]) => {
            value.locale = key
        })
        dispatch(addProject({ project, onSuccess: handleCancel }))
    }

    const handleUpdate = (values: Record<string, any>) => {
        if (values.localizations) {
            Object.keys(values.localizations).forEach(key => {
                values.localizations[key].locale = key
            })
        }
        const valueCategoriesId = values.categories?.map((item: any) => item.id || item)
        const valuePartnersId = values.projectPartner?.map((item: any) => item.id || item)
        const project = {
            ...(selectedProject as Project),
            ...values,
            localizations: {
                ...locales,
                ...values.localizations
            },
            categories: categories?.filter(item => valueCategoriesId?.includes(item.id)) as any,
            requiredInvestment: requiredInvestmentData?.find(item => values.requiredInvestmentId?.includes(item.id)) as any,
            projectPartner: developers?.filter(item => valuePartnersId?.includes(item.id)) as any,
            hideInUsa: !!values?.hideInUsa,
            regionalCenter: {
                id: values.regionalCenterId
            } as any
        }
        dispatch(updateProject({ project, onSuccess: handleCancel }))
    }

    if ((!selectedProject && !isNew) || !categories?.length) {
        return null
    }
    const { categories: selectedCategories, requiredInvestment, projectPartner: selectedPartners } = selectedProject ?? {}
    const initialValueCategories = selectedCategories?.map(item => ({ value: Number.parseInt(item.id, 10), label: item.name }))
    const initialValuePartners = selectedPartners?.map(item => item.id.toString())

    const handleAddLocale = (value: any, item: any) => {
        setLocales({
            ...locales,
            [value]: { locale: value }
        })
        setActive(value)
        togglePopover()
    }

    const opts = languages
        .filter(l => {
            return l.code !== 'en' && !Object.keys(locales).includes(l.code)
        })
        .map((l: any) => ({ value: l.code, label: l.name }))
    const Content = <Dropdown options={opts as any} onSelect={handleAddLocale} />

    const Add = <span ref={ref}>+ Add</span>

    const tabs = Object.entries(locales).map(([key, item]) => {
        return (
            <Tabs.TabPane key={key} tab={languages.find(l => l.code === key)?.name}>
                <Form
                    className={styles.form}
                    layout="vertical"
                    form={form}
                    onFinish={isNew ? handleAdd : handleUpdate}
                    initialValues={selectedProject}
                >
                    <FormItem label="Name" name={['localizations', key, 'name']} rules={[{ required: true }]} wrapperCol={{ span: 8 }}>
                        <Input />
                    </FormItem>
                    <FormItem label="City" name={['localizations', key, 'address']} rules={[{ required: true }]} wrapperCol={{ span: 8 }}>
                        <Input />
                    </FormItem>
                    <FormItem
                        label="Interest Rate"
                        name={['localizations', key, 'interestRate']}
                        rules={[{ required: true }]}
                        wrapperCol={{ span: 8 }}
                    >
                        <Input />
                    </FormItem>
                    <FormItem
                        label="Investment Term"
                        name={['localizations', key, 'investmentTerm']}
                        rules={[{ required: true }]}
                        wrapperCol={{ span: 8 }}
                    >
                        <Input />
                    </FormItem>
                    <FormItem label="Disclaimer" name={['localizations', key, 'disclaimer']} wrapperCol={{ span: 8 }}>
                        <Input />
                    </FormItem>
                    <FormItem label="Description" name={['localizations', key, 'description']} wrapperCol={{ span: 8 }}>
                        <TextArea />
                    </FormItem>
                    <FormItem label="Files" name={['localizations', key, 'files']} wrapperCol={{ span: 8 }}>
                        <FileUpload mode="object" />
                    </FormItem>
                </Form>
            </Tabs.TabPane>
        )
    })

    const defaultPane = (
        <Tabs.TabPane key="en" tab="English" closable={false}>
            <Form
                className={styles.form}
                form={form}
                layout="vertical"
                onFinish={isNew ? handleAdd : handleUpdate}
                initialValues={selectedProject}
                autoComplete="off"
            >
                <Form.Item label="Name" name="name" rules={[{ required: true }]} wrapperCol={{ span: 8 }}>
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Project Type"
                    name="projectType"
                    rules={[{ required: true }]}
                    wrapperCol={{ span: 8 }}
                    initialValue={projectTypeOptions[0].value}
                >
                    <RadioGroup options={projectTypeOptions} />
                </Form.Item>
                <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.projectType !== currentValues.projectType}>
                    {({ getFieldValue }) => (
                        <Form.Item
                            label="Regional Center"
                            name="regionalCenterId"
                            rules={[{ required: getFieldValue('projectType') === ProjectType.RegionalCenter }]}
                            wrapperCol={{ span: 8 }}
                            initialValue={selectedProject?.regionalCenter?.id.toString()}
                        >
                            <Dropdown options={centersOptions} />
                        </Form.Item>
                    )}
                </Form.Item>
                <Form.Item label="Status" name="status" wrapperCol={{ span: 8 }}>
                    <RadioGroup options={statusOptions} />
                </Form.Item>
                <Form.Item label="State" name="state" rules={[{ required: true }]} wrapperCol={{ span: 8 }}>
                    <StatesSelect />
                </Form.Item>
                <Form.Item label="City" name="address" wrapperCol={{ span: 8 }}>
                    <Input />
                </Form.Item>
                <Form.Item label="Categories" name="categories" wrapperCol={{ span: 8 }} initialValue={initialValueCategories}>
                    <Dropdown options={categoriesOptions} mode="multiple" maxTagCount="responsive" />
                </Form.Item>
                <FormItem label="TEA" name="tea" wrapperCol={{ span: 8 }} initialValue={false}>
                    <RadioGroup options={teaOptions} />
                </FormItem>
                <FormItem
                    label="Required Investment"
                    name="requiredInvestmentId"
                    wrapperCol={{ span: 8 }}
                    initialValue={requiredInvestment?.name}
                >
                    <Dropdown options={requiredInvestmentOptions} />
                </FormItem>
                <FormItem label="Administrative Fee" name="administrativeFee" wrapperCol={{ span: 8 }}>
                    <Input prefix="$" placeholder="0" type="number" />
                </FormItem>
                <FormItem label="Special Offer" name="specialOffer" wrapperCol={{ span: 8 }}>
                    <Input prefix={'−$'} placeholder="0" type="number" />
                </FormItem>
                <FormItem label="Disclaimer" name="disclaimer" wrapperCol={{ span: 8 }}>
                    <Input />
                </FormItem>
                <FormItem label="Description" name="description" wrapperCol={{ span: 8 }}>
                    <TextArea />
                </FormItem>
                <FormItem label="Construction Start Planned" name="constructionStartPlanned" wrapperCol={{ span: 8 }}>
                    <Input placeholder="Q3 2023" />
                </FormItem>
                <FormItem label="Construction End Planned" name="constructionEndPlanned" wrapperCol={{ span: 8 }}>
                    <Input placeholder="Q3 2023" />
                </FormItem>
                <FormItem label="Construction Start Actual" name="constructionStartActual" wrapperCol={{ span: 8 }}>
                    <Input placeholder="Q3 2023" />
                </FormItem>
                <FormItem label="Construction End Actual" name="constructionEndActual" wrapperCol={{ span: 8 }}>
                    <Input placeholder="Q3 2023" />
                </FormItem>
                <FormItem label="Interest Rate" name="interestRate" wrapperCol={{ span: 8 }}>
                    <Input />
                </FormItem>
                <FormItem label="Investment Type" name="investmentType" wrapperCol={{ span: 8 }}>
                    <Dropdown options={investmentTypes} />
                </FormItem>
                <FormItem label="Investment Term" name="investmentTerm" wrapperCol={{ span: 8 }}>
                    <Input />
                </FormItem>
                <FormItem label="EB-5 Units" name="eb5Units" wrapperCol={{ span: 8 }}>
                    <Input placeholder="0" type="number" />
                </FormItem>
                <FormItem label="EB-5 Jobs Estimated" name="jobsEstimated" wrapperCol={{ span: 8 }}>
                    <Input placeholder="0" type="number" />
                </FormItem>
                <FormItem label="EB-5 Jobs Required" name="jobsRequired" wrapperCol={{ span: 8 }}>
                    <Input placeholder="0" type="number" />
                </FormItem>
                <FormItem label="Capital Stack" name="capitalStack" wrapperCol={{ span: 8 }}>
                    <AddJson valueRenderer={<Input type="number" placeholder="0" />} />
                </FormItem>
                <Form.Item
                    label="Partner"
                    name="projectPartner"
                    rules={[{ required: true }]}
                    wrapperCol={{ span: 8 }}
                    initialValue={initialValuePartners}
                >
                    <Dropdown options={developersOptions} mode="multiple" maxTagCount="responsive" />
                </Form.Item>
                <FormItem label="Photos" name="photos" wrapperCol={{ span: 8 }}>
                    <FileUpload preview type="image" />
                </FormItem>
                <FormItem label="Files" name="files" wrapperCol={{ span: 8 }}>
                    <FileUpload mode="object" />
                </FormItem>
                <FormItem label="Videos" name="video" wrapperCol={{ span: 8 }}>
                    <VideoUpload />
                </FormItem>
                <FormItem label="Hide in USA" name="hideInUsa" wrapperCol={{ span: 8 }} valuePropName="checked">
                    <Switch className={styles.switch} defaultChecked={selectedProject?.hideInUsa} />
                </FormItem>
                <FormItem label="Published" name="published" wrapperCol={{ span: 8 }} valuePropName="checked">
                    <Switch className={styles.switch} defaultChecked={selectedProject?.published} />
                </FormItem>
            </Form>
        </Tabs.TabPane>
    )

    const handleTabEdit = (e: string | React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>, action: 'add' | 'remove') => {
        if (action === 'add') {
            togglePopover()
        } else {
            const newLocales = { ...locales }
            delete newLocales[e as string]
            setLocales(newLocales)
        }
    }

    return (
        <div className={styles.container}>
            <Popover content={Content} visible={showPopover} onVisibleChange={togglePopover} />
            <Tabs type="editable-card" activeKey={active} onEdit={handleTabEdit} addIcon={Add} onChange={key => setActive(key)}>
                {defaultPane}
                {tabs}
            </Tabs>
            {selectedProject?.id && (
                <a className={styles.link} href={`${HOME_PAGE}/projects/${selectedProject?.id}`}>
                    {`${HOME_PAGE}/projects/${selectedProject?.id}`}
                </a>
            )}
            <div className={styles.footer}>
                <Button type="ghost" onClick={handleCancel} className={styles.button}>
                    Cancel
                </Button>
                <Button onClick={form.submit} style={{ width: 'auto' }} className={styles.button}>
                    Save
                </Button>
            </div>
        </div>
    )
}
