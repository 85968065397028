import { Form, Popover, Tabs } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { Button } from '../../components/ui/Button/Button'
import { Input } from '../../components/ui/Input/Input'
import { useDispatch, useSelector } from '../../reducers/store'
import styles from './DevelopersAdminEditPage.module.css'
import { useHistory, useParams } from 'react-router-dom'
import FormItem from 'antd/lib/form/FormItem'
import { FileUpload } from '../../components/ui/FileUpload/FileUpload'
import { useDeveloper } from '../../hooks/useDevelopers'
import { addDeveloper, updateDeveloper } from '../../actions/developers'
import { Developer } from '../../types/developer'
import { Dropdown } from '../../components/ui/Dropdown/Dropdown'
import { useLanguages } from '../../hooks/useLanguages'
import { useToggle } from '../../hooks/useToggle'
import TextArea from 'antd/lib/input/TextArea'
import { useTranslation } from 'react-i18next'
import { EProjectPartnerType } from '../../constants/projects'
import useGtmPageLoad from '../../hooks/useGtmPageLoad'

export const DevelopersAdminEditPage = () => {
    useGtmPageLoad('DevelopersAdminEditPage')
    const dispatch = useDispatch()
    useLanguages()
    const languages = useSelector(store => store.languages.languages)
    const { t } = useTranslation()
    const projectPartnerTypes = [
        { value: EProjectPartnerType.Developer, label: t('Developer') },
        { value: EProjectPartnerType.ArchitecturalBureau, label: t('Architectural bureau') },
        { value: EProjectPartnerType.Borrower, label: t('Borrower') },
        { value: EProjectPartnerType.AccountingFirm, label: t('Accounting firm') },
        { value: EProjectPartnerType.EscrowAgent, label: t('Escrow agent') }
    ]
    const history = useHistory()
    const [form] = Form.useForm()
    const { id } = useParams<{ id: string }>()
    const isNew = id === 'new'
    const ref = useRef(null)
    useDeveloper(id)
    const selectedDeveloper = useSelector(store => store.developers.selectedDeveloper)
    const [locales, setLocales] = useState<Record<string, any>>({})

    const [showPopover, togglePopover] = useToggle()

    const [active, setActive] = useState('en')
    useEffect(() => {
        if (selectedDeveloper) {
            setLocales(selectedDeveloper.localizations ?? {})
        }
    }, [selectedDeveloper])

    const handleCancel = () => {
        history.push('/developers-admin')
    }

    const handleAdd = (values: Record<string, any>) => {
        const developer: Partial<Developer> = {
            ...values
        }
        Object.entries(developer.localizations ?? {}).forEach(([key, value]) => {
            value.locale = key
        })
        dispatch(addDeveloper({ developer, onSuccess: handleCancel }))
    }

    const handleUpdate = (values: Record<string, any>) => {
        if (values.localizations) {
            Object.keys(values.localizations).forEach(key => {
                values.localizations[key].locale = key
            })
        }
        const developer = {
            ...values,
            localizations: {
                ...locales,
                ...values.localizations
            },
            id: Number.parseInt(selectedDeveloper!.id)
        } as any
        dispatch(updateDeveloper({ developer, onSuccess: handleCancel }))
    }

    if (!selectedDeveloper && !isNew) {
        return null
    }

    const defaultPane = (
        <Tabs.TabPane key="en" tab="English" closable={false}>
            <Form
                className={styles.form}
                form={form}
                layout="vertical"
                onFinish={isNew ? handleAdd : handleUpdate}
                initialValues={selectedDeveloper}
                autoComplete="off"
            >
                <FormItem label="Name" name="name" required wrapperCol={{ span: 8 }}>
                    <Input />
                </FormItem>
                <FormItem label="Biography" name="biography" wrapperCol={{ span: 8 }}>
                    <TextArea />
                </FormItem>
                <FormItem label="Type" name="type" wrapperCol={{ span: 8 }}>
                    <Dropdown options={projectPartnerTypes} />
                </FormItem>
                <FormItem label="Company Logo" name="logo" wrapperCol={{ span: 8 }}>
                    <FileUpload preview single type="image" />
                </FormItem>
            </Form>
        </Tabs.TabPane>
    )

    const handleAddLocale = (value: any, item: any) => {
        setLocales({
            ...locales,
            [value]: { locale: value }
        })
        setActive(value)
        togglePopover()
    }

    const opts = languages
        .filter(l => {
            return l.code !== 'en' && !Object.keys(locales).includes(l.code)
        })
        .map((l: any) => ({ value: l.code, label: l.name }))
    const Content = <Dropdown options={opts as any} onSelect={handleAddLocale} />

    const Add = <span ref={ref}>+ Add</span>

    const tabs = Object.entries(locales).map(([key, item]) => {
        return (
            <Tabs.TabPane key={key} tab={languages.find(l => l.code === key)?.name}>
                <Form
                    className={styles.form}
                    layout="vertical"
                    form={form}
                    onFinish={isNew ? handleAdd : handleUpdate}
                    initialValues={selectedDeveloper}
                >
                    <FormItem label="Name" name={['localizations', key, 'name']} wrapperCol={{ span: 8 }}>
                        <Input />
                    </FormItem>
                    <FormItem label="Biography" name={['localizations', key, 'biography']} wrapperCol={{ span: 8 }}>
                        <TextArea />
                    </FormItem>
                </Form>
            </Tabs.TabPane>
        )
    })

    const handleTabEdit = (e: string | React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>, action: 'add' | 'remove') => {
        if (action === 'add') {
            togglePopover()
        } else {
            const newLocales = { ...locales }
            delete newLocales[e as string]
            setLocales(newLocales)
        }
    }

    return (
        <div className={styles.container}>
            <Popover content={Content} visible={showPopover} onVisibleChange={togglePopover} />
            <Tabs type="editable-card" activeKey={active} onEdit={handleTabEdit} addIcon={Add} onChange={key => setActive(key)}>
                {defaultPane}
                {tabs}
            </Tabs>
            <div className={styles.footer}>
                <Button type="ghost" onClick={handleCancel} className={styles.button}>
                    Cancel
                </Button>
                <Button onClick={form.submit} style={{ width: 'auto' }} className={styles.button}>
                    Save
                </Button>
            </div>
        </div>
    )
}
