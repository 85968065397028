import React, { useEffect, useMemo } from 'react'
import styles from './ExpertProfilePage.module.css'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from '../../reducers/store'
import { ExpertDetailsCard } from '../../components/ExpertDetailsCard/ExpertDetailsCard'
import { useExpert } from '../../hooks/useExperts'
import { SetAppointmentButton } from '../../components/ui/SetAppointmentButton/SetAppointmentButton'
import { addCallPayload } from '../../reducers/session'
import useGtmPageLoad from '../../hooks/useGtmPageLoad'

interface ExpertProfilePageProps {
    id?: string
}
/**
 * This page contains a profile for selected expert
 */
export const ExpertProfilePage = ({ id }: ExpertProfilePageProps) => {
    const user = useSelector(store => store.session.user)
    const { id: routeId } = useParams<{ id: string }>()
    const dispatch = useDispatch()
    useExpert(id || routeId)
    const extra = useMemo(() => ({ pageType: 'expertPage', expertId: id || routeId }), [id, routeId])
    useGtmPageLoad('ExpertProfilePage', extra)
    const expert = useSelector(store => store.experts.selectedExpert)
    useEffect(() => {
        dispatch(
            addCallPayload({
                expertId: expert?.id
            })
        )
    }, [expert?.id, dispatch])

    const handleSetAppointmentClick = () => {
        ;(window as any).dataLayer.push({ event: 'setAppointment', expertId: id || routeId })
    }

    return (
        <div className={styles.container}>
            {expert && <ExpertDetailsCard expert={expert} />}
            {user && (
                <SetAppointmentButton noAssistant={true} className={styles.mobile} onClick={handleSetAppointmentClick} size="dynamic" />
            )}
        </div>
    )
}
