import { useEffect } from 'react'
import { fetchRequest, fetchRequests } from '../actions/requests'
import { clearSelectedRequest } from '../reducers/experts'
import { useDispatch } from '../reducers/store'

export function useRequests() {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchRequests())
    }, [dispatch])
}

export function useRequest(id: string) {
    const dispatch = useDispatch()
    useEffect(() => {
        if (id !== 'new') {
            dispatch(fetchRequest(id))
        }
        return () => {
            dispatch(clearSelectedRequest())
        }
    }, [id, dispatch])
}
