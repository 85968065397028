import React, { ChangeEvent, cloneElement, ReactElement, useCallback, useEffect, useState } from 'react'
import { ReactComponent as RemoveIcon } from '../../../assets/trash.svg'
import { Button } from '../Button/Button'
import { Input } from '../Input/Input'
import styles from './AddJson.module.css'

export interface AddJsonProps {
    value?: Record<string, string>
    keyRenderer?: ReactElement
    valueRenderer?: ReactElement
    className?: string
    maxFields?: number
    onChange?: (value: Record<string, string>) => void
}

export const AddJson = ({ value = {}, valueRenderer, keyRenderer, maxFields, className, onChange }: AddJsonProps) => {
    const [counter, setCounter] = useState(value ? Object.keys(value).length : 0)
    const initialValue: Record<string, string[]> = {}
    Object.entries(value || {}).forEach(([key, v], index) => {
        initialValue[index] = [key, v]
    })
    const [items, setItems] = useState<Record<string, string[]>>(initialValue)

    useEffect(() => {
        const result: Record<string, string> = {}
        Object.values(items).forEach(item => {
            result[item[0]] = item[1]
        })
        onChange?.(result)
    }, [items])

    const handleAdd = useCallback(() => {
        if (maxFields && Object.keys(items).length > maxFields) {
            return
        }
        setCounter(counter + 1)
        setItems({
            ...items,
            [counter + 1]: []
        })
    }, [maxFields, items, counter])

    const handleRemove = useCallback(
        (key: string) => {
            const newItems = { ...items }
            delete newItems[key]
            setItems(newItems)
        },
        [items]
    )

    const handleKey = (index: string, e: ChangeEvent<HTMLInputElement>) => {
        const tuple = items[index]
        tuple[0] = (e.currentTarget?.value ?? e) as string
        setItems({
            ...items,
            [index]: tuple
        })
    }
    const handleValue = (index: string, e: ChangeEvent<HTMLInputElement>) => {
        const tuple = items[index]
        tuple[1] = (e.currentTarget?.value ?? e) as string
        setItems({
            ...items,
            [index]: tuple
        })
    }

    const kRenderer = (value: string, key: string) => {
        if (keyRenderer) {
            return cloneElement(keyRenderer, {
                onChange: (e: any) => handleKey(key, e as any),
                value: keyRenderer.props.value ?? value,
                className: styles.item
            })
        }
        return <Input value={value} className={styles.item} onChange={(e: any) => handleKey(key, e as any)} />
    }

    const renderer = (value: string, key: string) => {
        if (valueRenderer) {
            return cloneElement(valueRenderer, {
                onChange: (e: any) => handleValue(key, e as any),
                value: valueRenderer.props.value ?? value,
                className: styles.item
            })
        }
        return <Input value={value} className={styles.item} onChange={(e: any) => handleValue(key, e as any)} />
    }

    return (
        <div className={className}>
            <Button disabled={maxFields === Object.keys(items).length} shape="circle" style={{ fontSize: '28px' }} onClick={handleAdd}>
                +
            </Button>
            <ul className={styles.list}>
                {Object.entries(items).map(([key, val]) => {
                    return (
                        <li className={styles.items} key={key}>
                            {kRenderer(val[0], key)}
                            {renderer(val[1], key)}
                            <Button shape="circle" type="ghost" onClick={() => handleRemove(key)}>
                                <RemoveIcon />
                            </Button>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}
