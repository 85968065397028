import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { addJob, fetchAllJobs, fetchJob, fetchJobs, fetchProposal, fetchProposalDirect, fetchProposalsDirect } from '../actions/jobs'
import { Filter, Sorter } from '../types/filter'
import { Job, Proposal } from '../types/jobs'
import { ProposalDirect } from '../types/proposalsDitect'

type JobsSlice = {
    jobs: Job[]
    selectedJob?: Job
    pending?: boolean
    selectedProposal?: Proposal
    proposalsDirect?: ProposalDirect[]
    selectedProposalDirect?: ProposalDirect
    filters: Filter[]
    sorters: Sorter[]
}

export const jobsReducer = createSlice({
    name: 'jobs',
    initialState: {
        jobs: [],
        filters: [],
        sorters: []
    } as JobsSlice,
    reducers: {
        clearSelected: state => {
            state.selectedJob = undefined
        },
        clearProposal: state => {
            state.selectedProposal = undefined
        },
        clearProposalDirect: state => {
            state.selectedProposalDirect = undefined
        },
        addFilter: (state, action: PayloadAction<Filter>) => {
            const existing = state.filters.find(item => item.path === action.payload.path)
            if (existing) {
                existing.value = action.payload.value
            } else {
                state.filters.push(action.payload)
            }
        },
        removeFilter: (state, action: PayloadAction<string>) => {
            state.filters = state.filters.filter(item => item.path !== action.payload)
        },
        removeAllFilters: state => {
            state.filters = []
        }
    },
    extraReducers: builder => {
        builder
            .addCase(fetchJobs.fulfilled, (state, action) => {
                state.jobs = action.payload.jobs
            })
            .addCase(fetchAllJobs.fulfilled, (state, action) => {
                state.jobs = action.payload.jobs
            })
            .addCase(fetchJob.fulfilled, (state, action) => {
                state.selectedJob = action.payload.job
            })
            .addCase(addJob.fulfilled, state => {
                state.selectedJob = undefined
            })
            .addCase(fetchProposalsDirect.fulfilled, (state, action) => {
                state.proposalsDirect = action.payload.proposals
            })
            .addCase(fetchProposalDirect.fulfilled, (state, action) => {
                state.selectedProposalDirect = action.payload.proposal
            })
            .addCase(fetchProposal.fulfilled, (state, action) => {
                state.selectedProposal = action.payload.proposal
            })
    }
})

export const { clearSelected, clearProposal, clearProposalDirect, addFilter, removeFilter, removeAllFilters } = jobsReducer.actions
