import { useEffect } from 'react'
import { fetchRegionalCenters, fetchRegionalCenter } from '../actions'
import { clearSelected } from '../reducers/regionalCenters'
import { useDispatch } from '../reducers/store'

export function useRegionalCenters() {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchRegionalCenters())
    }, [dispatch])
}

export function useRegionalCenter(centerId: string) {
    const dispatch = useDispatch()
    useEffect(() => {
        if (centerId !== 'new') {
            dispatch(fetchRegionalCenter(centerId))
        }
        return () => {
            dispatch(clearSelected())
        }
    }, [centerId, dispatch])
}
