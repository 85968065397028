import React, { useCallback, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import Table, { ColumnsType } from 'antd/lib/table'
import { SelectValue } from 'antd/es/select'

import { Button } from '../../components/ui/Button/Button'
import { Dropdown } from '../../components/ui/Dropdown/Dropdown'

import { useReviews } from '../../hooks/useReview'
import { useExperts } from '../../hooks/useExperts'

import { useDispatch, useSelector } from '../../reducers/store'

import { deleteReview } from '../../actions/review'

import { ReactComponent as PlusIcon } from '../../assets/plus.svg'

import { Review } from '../../types/Review'

import styles from './ReviewAdminPage.module.css'
import useGtmPageLoad from '../../hooks/useGtmPageLoad'

export const ReviewAdminPage = () => {
    useGtmPageLoad('ReviewAdminPage')
    const dispatch = useDispatch()
    const [selectedItems, setSelectedItems] = useState<string[]>([])
    const [expertFilter, setExpertFilter] = useState<string[]>([])
    useExperts()
    useReviews()
    const data = useSelector(store => store.experts)
    const { experts, reviews } = data

    const reviewsFiltered = useMemo(
        () => reviews?.filter(item => expertFilter?.includes(item?.expert?.id?.toString() || '') || !expertFilter?.length),
        [reviews, expertFilter]
    )

    const expertsOptions = experts.map(item => ({ value: item.id, label: `${item.firstName || ''} ${item.lastName || ''}` }))

    const renderId = useCallback((value: any, item: Review) => {
        return <Link to={`/review-admin/${item.id}`}>{item.id}</Link>
    }, [])

    const renderUser = useCallback((value: Record<string, string>, item: Review) => {
        return (
            <>
                {value?.firstName || ''} {value?.lastName || ''}
            </>
        )
    }, [])

    const renderExpert = useCallback(
        (value: Record<string, string>, item: Review) => {
            const currentExpert = experts?.find(expertItem => expertItem.id === item.expert?.id?.toString())
            return (
                <>
                    {currentExpert?.firstName || ''} {currentExpert?.lastName || ''}
                </>
            )
        },
        [experts]
    )

    const renderCreatedDate = useCallback((value: string, item: Review) => {
        return new Date(value).toLocaleDateString('en-Us', { day: 'numeric', month: 'long', year: 'numeric' })
    }, [])

    const renderBoolean = useCallback((value: boolean, item: Review) => {
        return value ? 'Yes' : 'No'
    }, [])

    const columns: ColumnsType<Review> = useMemo(() => {
        return [
            {
                dataIndex: 'id',
                title: 'Id review',
                render: renderId
            },
            {
                dataIndex: 'user',
                title: 'User created',
                render: renderUser
            },
            {
                dataIndex: 'expert',
                title: 'Expert',
                render: renderExpert
            },
            {
                dataIndex: 'rating',
                title: 'Rating'
            },
            {
                dataIndex: 'created',
                title: 'Created date',
                render: renderCreatedDate
            },
            {
                dataIndex: 'createdByAdmin',
                title: 'Admin create',
                render: renderBoolean
            },
            {
                dataIndex: 'approved',
                title: 'Approved',
                render: renderBoolean
            }
        ]
    }, [renderId, renderUser, renderExpert, renderCreatedDate, renderBoolean])

    const handleDelete = () => {
        dispatch(deleteReview(selectedItems))
        setSelectedItems([])
    }

    const handleSelect = (selectedKeys: (string | number)[]) => {
        setSelectedItems(selectedKeys.map(key => (typeof key !== 'string' ? key.toString() : key)))
    }

    const handleSearch = (e: SelectValue[]) => {
        if (!e) {
            setExpertFilter([])
        } else {
            setExpertFilter(e as string[])
        }
    }

    return (
        <div className={styles.container}>
            <div className={styles.controls}>
                <Link to="/review-admin/new">
                    <Button icon={<PlusIcon />} style={{ width: 'auto' }} className={styles.button}>
                        Add
                    </Button>
                </Link>
                <Button style={{ width: 'auto' }} type="ghost" className={styles.button} onClick={handleDelete}>
                    Delete
                </Button>
                <Dropdown
                    className={styles.dropdown}
                    options={expertsOptions}
                    mode="multiple"
                    maxTagCount="responsive"
                    placeholder="Filter by Expert"
                    onChange={handleSearch}
                />
            </div>
            <div style={{ overflow: 'auto' }}>
                <Table columns={columns} dataSource={reviewsFiltered} rowSelection={{ onChange: handleSelect }} rowKey="id" />
            </div>
        </div>
    )
}
