import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Dropdown, Option } from '../ui/Dropdown/Dropdown'
import availableOptions from './countries.json'

export const CountrySelect = ({ ...rest }) => {
    const { t } = useTranslation()
    const options = Object.entries(availableOptions).map(([code, name]) => {
        return { value: code, label: name }
    })

    const handleSearch = useCallback((input: string, option: Option) => {
        return option.label.toLowerCase().includes(input.toLowerCase())
    }, [])
    return (
        <Dropdown
            placeholder={t('Select your country')}
            maxTagCount="responsive"
            inlineTags
            options={options}
            filterOption={handleSearch as any}
            {...rest}
        />
    )
}
