import { useState } from 'react'
import { RECAPTCHA_KEY_V2 } from '../constants/app'

export function useRecaptcha(recaptchaCallback?: () => void) {
    const [recaptachaMounted, setRecaptchaMounted] = useState(false)
    const [recaptchaV3Failed, setRecaptchaV3Failed] = useState(false)
    const [recaptchaV2Token, setRecaptchaV2Token] = useState('')

    const recaptchaRef = (ref: HTMLDivElement) => {
        if (ref && !recaptachaMounted) {
            const grecaptcha = (window as any).grecaptcha
            grecaptcha.ready(() => {
                grecaptcha.render(ref, {
                    sitekey: RECAPTCHA_KEY_V2,
                    callback: (response: string) => {
                        setRecaptchaV2Token(response)
                        recaptchaCallback?.()
                    }
                })
            })
            setRecaptchaMounted(true)
        }
    }

    return {
        recaptchaV2Token,
        setRecaptchaV2Token,
        recaptchaRef,
        recaptchaV3Failed,
        setRecaptchaV3Failed
    }
}
