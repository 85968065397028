import React from 'react'
import { Story, Meta } from '@storybook/react'
import { ShortText as ShortTextComponent, ShortTextProps } from '../../../components/ui/ShortText/ShortText'
import { StorybookContext } from '../../StorybookContext'
import { rcDescription } from '../../../mock'

export default {
    title: 'Components/Short Text',
    component: ShortTextComponent
} as Meta

export const ShortText: Story<ShortTextProps> = args => (
    <StorybookContext padded>
        <ShortTextComponent {...args} />
    </StorybookContext>
)

ShortText.args = {
    children: rcDescription
}
