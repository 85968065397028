import React, { ComponentType } from 'react'
import { cn } from '../../../utils/cn'
import styles from './StatsTable.module.css'

export type StatsTableItem = {
    name: string
    value: number | string | undefined
    render?: ComponentType<any>
    type?: 'string' | 'money' | 'right'
    color?: 'green' | 'red'
    star?: boolean
}

export interface StatsTableProps {
    items: StatsTableItem[]
    className?: string
    cellClassName?: string
    widths?: string[]
}

export const StatsTable = ({ items, className, cellClassName, widths }: StatsTableProps) => {
    return (
        <div className={cn(styles.container, className)}>
            <table className={styles.table}>
                <colgroup>
                    {widths?.map((item, index) => (
                        <col key={index} style={{ width: item }} />
                    ))}
                </colgroup>
                <tbody>
                    {items.map(item => {
                        const Render = item.render || FormatValue
                        return (
                            <tr key={item.name}>
                                <td className={cn(styles.cell, styles.name, cellClassName)}>
                                    {item.name}
                                    {item.star && '*'}
                                </td>
                                <td
                                    className={cn(styles.cell, styles.value, cellClassName, {
                                        [styles.green]: item.color === 'green',
                                        [styles.red]: item.color === 'red',
                                        [styles.right]: item.type === 'money' || item.type === 'right'
                                    })}
                                >
                                    <Render item={item} />
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}

const FormatValue = ({ item }: { item: StatsTableItem }) => {
    if (item.type === 'money') {
        return (
            <>
                {item.color === 'red' ? '−' : ''}${item.value?.toLocaleString('en-US')}
            </>
        )
    }
    return <>{item.value}</>
}
