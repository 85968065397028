import { createAsyncThunk } from '@reduxjs/toolkit'
import { clearCallPayload } from '../reducers/session'
import { RootState } from '../reducers/store'
import { ScheduleCallRequest, ScheduledCall } from '../types/calendly'
import { sendPost } from '../utils/api'

export const scheduleCall = createAsyncThunk<{ call: ScheduledCall }, ScheduleCallRequest, { state: RootState }>(
    'scheduleCall',
    async (request, thunkAPI) => {
        const response = await sendPost('/setAppointment', thunkAPI, request)
        thunkAPI.dispatch(clearCallPayload())
        return { call: response }
    }
)
