import React from 'react'

interface ErrorBoundaryProps {
    msg?: React.ReactNode
}

export default class ErrorBoundary extends React.Component<ErrorBoundaryProps, { hasError: boolean; error?: Error }> {
    static getDerivedStateFromError(error: Error) {
        return { hasError: true, error }
    }

    constructor(props: ErrorBoundaryProps) {
        super(props)
        this.state = { hasError: false, error: undefined }
    }

    componentDidCatch(error: any, errorInfo: any) {
        console.error(error)
    }

    render() {
        if (this.state.hasError) {
            return (
                <div style={{ padding: '16px' }}>
                    {this.props.msg}
                    <h1 style={{ fontWeight: 'bold', marginBottom: '16px' }}>{this.state.error?.message}</h1>
                    <div style={{ whiteSpace: 'pre-line', wordBreak: 'break-all' }}>{this.state.error?.stack}</div>
                </div>
            )
        }
        return this.props.children
    }
}
