import { Filter, Sorter } from '../types/filter'
import { sendDelete, sendGet, sendPost, sendPut } from '../utils/api'
import { RCManager } from '../types/rcmanager'

export function rcmanagersGetAll(thunkAPI: any, filters?: Filter[], sorters?: Sorter[]): Promise<RCManager[]> {
    const params = new URLSearchParams()
    filters?.forEach(filter => {
        params.append(`_${filter.path}.${filter.operation}`, filter.value)
    })
    if (sorters?.length) {
        params.append(`sort`, sorters.map(item => `${item.fieldName}.${item.direction}`).join(','))
    }
    return sendGet(`/regionalCenterManagers?${params.toString()}`, thunkAPI)
}

export function rcmanagersGet(expertId: string, thunkAPI: any): Promise<RCManager> {
    return sendGet(`/regionalCenterManagers/${expertId}`, thunkAPI)
}

export function rcmanagersCreate(manager: RCManager, thunkAPI: any): Promise<RCManager> {
    return sendPost(`/regionalCenterManagers`, thunkAPI, manager)
}

export function rcmanagerUpdate(manager: RCManager, thunkAPI: any): Promise<RCManager> {
    return sendPut(`/regionalCenterManagers/${manager.id}`, thunkAPI, manager)
}

export function rcmanagersDelete(ids: string[], thunkAPI: any) {
    return sendDelete(`/regionalCenterManagers`, thunkAPI, ids)
}
