import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from '../reducers/store'
import { Request } from '../types/request'
import { sendDelete, sendGet, sendPost, sendPut } from '../utils/api'

export const fetchRequests = createAsyncThunk<{ requests: Request[] }, void, { state: RootState }>('fetchRequests', async (_, thunkAPI) => {
    const response = await sendGet(`/requests-admin`, thunkAPI)
    return { requests: response }
})

export const fetchRequest = createAsyncThunk<{ request: Request }, string, { state: RootState }>('fetchRequest', async (id, thunkAPI) => {
    const response = await sendGet(`/requests-admin/${id}`, thunkAPI)
    return { request: response }
})

export const addRequest = createAsyncThunk<
    { request: Request },
    { request: Partial<Request>; onSuccess: () => void },
    { state: RootState }
>('addRequest', async ({ request, onSuccess }, thunkAPI) => {
    const response = await sendPost(`/requests-admin`, thunkAPI, request)
    onSuccess?.()
    return { request: response }
})

export const updateRequest = createAsyncThunk<
    { request: Request },
    { request: Partial<Request>; onSuccess: () => void },
    { state: RootState }
>('updateRequest', async ({ request, onSuccess }, thunkAPI) => {
    const response = await sendPut(`/requests-admin/${request.id}`, thunkAPI, request)
    onSuccess?.()
    return { request: response }
})

export const deleteRequest = createAsyncThunk<{}, string[], { state: RootState }>('deleteRequest', async (ids, thunkAPI) => {
    await sendDelete(`/requests-admin`, thunkAPI, ids)
    thunkAPI.dispatch(fetchRequests())
    return {}
})
