import React from 'react'
import styles from './BackTitle.module.css'
import { ReactComponent as Back } from './back.svg'
import { cn } from '../../../utils/cn'

export interface BackTitleProps {
    title?: string
    className?: string
    onBack?: () => void
}

export const BackTitle = ({ title, className, onBack }: BackTitleProps) => {
    return (
        <button className={cn(styles.caption, className)} onClick={onBack}>
            <Back className={styles.icon} />
            <span>{title}</span>
        </button>
    )
}
