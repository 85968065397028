import { Form, Popover, Tabs } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { addRegionalCenter, updateRegionalCenter } from '../../actions'
import { Button } from '../../components/ui/Button/Button'
import { Input } from '../../components/ui/Input/Input'
import { useDispatch, useSelector } from '../../reducers/store'
import styles from './RegionalCenterAdminEditPage.module.css'
import { StatesSelect } from '../../components/StatesSelect/StatesSelect'
import { useHistory, useParams } from 'react-router-dom'
import { Dropdown } from '../../components/ui/Dropdown/Dropdown'
import FormItem from 'antd/lib/form/FormItem'
import TextArea from 'antd/lib/input/TextArea'
import { RegionalCenter } from '../../types/regionalCenter'
import { FileUpload } from '../../components/ui/FileUpload/FileUpload'
import { AddPeople } from '../../components/ui/AddPeople/AddPeople'
import { useRegionalCenter } from '../../hooks/useRegionalCenters'
import { LanguagesSelect } from '../../components/LanguagesSelect/LanguagesSelect'
import { useLanguages } from '../../hooks/useLanguages'
import { useToggle } from '../../hooks/useToggle'
import useGtmPageLoad from '../../hooks/useGtmPageLoad'

export const RegionalCenterAdminEditPage = () => {
    useGtmPageLoad('RegionalCenterAdminEditPage')
    const { id: routeId } = useParams<{ id: string }>()
    useRegionalCenter(routeId)
    const isNew = routeId === 'new'
    const regionalCenter = useSelector(store => store.regionalCenters.selectedCenter)
    const dispatch = useDispatch()
    const history = useHistory()
    useLanguages()
    const ref = useRef(null)
    const [locales, setLocales] = useState<Record<string, any>>({})
    const [showPopover, togglePopover] = useToggle()
    const [active, setActive] = useState('en')
    const languages = useSelector(store => store.languages.languages)
    const [form] = Form.useForm()
    const timezoneOptions = new Array(27).fill(0).map((_, index) => {
        const value = index - 12
        return { value, label: `UTC ${value > 0 ? '+' : ''}${value}` }
    })

    useEffect(() => {
        if (regionalCenter) {
            setLocales(regionalCenter.localizations ?? {})
        }
    }, [regionalCenter])

    const handleCancel = () => {
        history.push('/regional-centers-admin')
    }

    const handleAdd = (values: Record<string, any>) => {
        const languages: Record<string, string> = {}
        Object.keys(values.languages ?? {}).forEach(key => {
            languages[key.toLocaleLowerCase()] = values.languages[key]
        })
        const center: Partial<RegionalCenter> = {
            ...values,
            logo: values.logo?.[0]
        }
        if (Object.keys(languages).length > 0) {
            center.languages = languages
        }
        Object.entries(center.localizations ?? {}).forEach(([key, value]) => {
            value.locale = key
        })
        dispatch(addRegionalCenter({ center, onSuccess: handleCancel }))
    }

    const handleUpdate = (values: Record<string, any>) => {
        const languages: Record<string, string> = {}
        Object.keys(values.languages ?? {}).forEach(key => {
            languages[key.toLocaleLowerCase()] = 'yes'
        })
        if (values.localizations) {
            Object.keys(values.localizations).forEach(key => {
                values.localizations[key].locale = key
            })
        }
        const center: RegionalCenter = {
            ...(regionalCenter as RegionalCenter),
            ...values,
            localizations: {
                ...locales,
                ...values.localizations
            }
        }
        if (Object.keys(languages).length > 0) {
            center.languages = languages
        }
        dispatch(updateRegionalCenter({ center, onSuccess: handleCancel }))
    }

    if (!regionalCenter && !isNew) {
        return null
    }

    const handleAddLocale = (value: any, item: any) => {
        setLocales({
            ...locales,
            [value]: { locale: value }
        })
        setActive(value)
        togglePopover()
    }

    const opts = languages
        .filter(l => {
            return l.code !== 'en' && !Object.keys(locales).includes(l.code)
        })
        .map((l: any) => ({ value: l.code, label: l.name }))
    const Content = <Dropdown options={opts as any} onSelect={handleAddLocale} />

    const Add = <span ref={ref}>+ Add</span>

    const tabs = Object.entries(locales).map(([key, item]) => {
        return (
            <Tabs.TabPane key={key} tab={languages.find(l => l.code === key)?.name}>
                <Form
                    className={styles.form}
                    layout="vertical"
                    form={form}
                    onFinish={isNew ? handleAdd : handleUpdate}
                    initialValues={regionalCenter}
                >
                    <FormItem label="City" name={['localizations', key, 'city']} required wrapperCol={{ span: 8 }}>
                        <Input />
                    </FormItem>
                    <Form.Item label="Description" name={['localizations', key, 'description']} wrapperCol={{ span: 8 }}>
                        <TextArea />
                    </Form.Item>
                    <Form.Item label="Raised Capital" name={['localizations', key, 'raised']} wrapperCol={{ span: 8 }}>
                        <Input placeholder="$80+ MLN" />
                    </Form.Item>
                    <Form.Item label="Returned Capital" name={['localizations', key, 'returned']} wrapperCol={{ span: 8 }}>
                        <Input placeholder="$40+ MLN" />
                    </Form.Item>
                    <FormItem label="Files" name={['localizations', key, 'files']} wrapperCol={{ span: 8 }}>
                        <FileUpload mode="object" />
                    </FormItem>
                </Form>
            </Tabs.TabPane>
        )
    })

    const defaultPane = (
        <Tabs.TabPane key="en" tab="English" closable={false}>
            <Form
                className={styles.form}
                form={form}
                layout="vertical"
                onFinish={isNew ? handleAdd : handleUpdate}
                initialValues={regionalCenter}
                autoComplete="off"
            >
                <Form.Item label="Name" name="name" required wrapperCol={{ span: 8 }}>
                    <Input />
                </Form.Item>
                <FormItem label="Logo" name="logo" wrapperCol={{ span: 8 }}>
                    <FileUpload single preview type="image" />
                </FormItem>
                <Form.Item label="State" name="state" required wrapperCol={{ span: 8 }}>
                    <StatesSelect />
                </Form.Item>
                <Form.Item label="City" name="city" wrapperCol={{ span: 8 }}>
                    <Input />
                </Form.Item>
                <Form.Item label="Founded" name="founded" wrapperCol={{ span: 8 }}>
                    <Input />
                </Form.Item>
                <Form.Item label="States" name="states" wrapperCol={{ span: 8 }}>
                    <StatesSelect mode="multiple" maxTagCount={undefined} />
                </Form.Item>
                <FormItem label="Languages" name="languages" wrapperCol={{ span: 8 }}>
                    <LanguagesSelect valueRenderer={<Input defaultValue="yes" disabled />} />
                </FormItem>
                <Form.Item label="Description" name="description" wrapperCol={{ span: 8 }}>
                    <TextArea />
                </Form.Item>
                <Form.Item label="Time Zone" name="timezone" wrapperCol={{ span: 8 }}>
                    <Dropdown options={timezoneOptions} />
                </Form.Item>
                <Form.Item label="Completed projects" name="eb5CompletedProjects" wrapperCol={{ span: 8 }}>
                    <Input placeholder="Number of completed projects" type="number" />
                </Form.Item>
                <Form.Item label="EB-5 Projects In Progress" name="eb5ProgressProjects" wrapperCol={{ span: 8 }}>
                    <Input placeholder="10" type="number" />
                </Form.Item>
                <Form.Item label="I-526 Petitions Approved" name="i526" wrapperCol={{ span: 8 }}>
                    <Input placeholder="10" type="number" />
                </Form.Item>
                <Form.Item label="I-829 Petitions Approved" name="i829" wrapperCol={{ span: 8 }}>
                    <Input placeholder="10" type="number" />
                </Form.Item>
                <Form.Item label="Raised Capital" name="raised" wrapperCol={{ span: 8 }}>
                    <Input placeholder="$80+ MLN" />
                </Form.Item>
                <Form.Item label="Returned Capital" name="returned" wrapperCol={{ span: 8 }}>
                    <Input placeholder="$40+ MLN" />
                </Form.Item>
                <FormItem label="Managers" name="managers" wrapperCol={{ span: 8 }} style={{ flex: 'unset' }}>
                    <AddPeople />
                </FormItem>
                <FormItem label="Files" name="files" wrapperCol={{ span: 8 }}>
                    <FileUpload mode="object" />
                </FormItem>
            </Form>
        </Tabs.TabPane>
    )

    const handleTabEdit = (e: string | React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>, action: 'add' | 'remove') => {
        if (action === 'add') {
            togglePopover()
        } else {
            const newLocales = { ...locales }
            delete newLocales[e as string]
            setLocales(newLocales)
        }
    }

    return (
        <div className={styles.container}>
            <Popover content={Content} visible={showPopover} onVisibleChange={togglePopover} />
            <Tabs type="editable-card" activeKey={active} onEdit={handleTabEdit} addIcon={Add} onChange={key => setActive(key)}>
                {defaultPane}
                {tabs}
            </Tabs>
            <div className={styles.footer}>
                <Button type="ghost" onClick={handleCancel} className={styles.button}>
                    Cancel
                </Button>
                <Button onClick={form.submit} style={{ width: 'auto' }} className={styles.button}>
                    Save
                </Button>
            </div>
        </div>
    )
}
