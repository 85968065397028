import React, { useEffect, useMemo, useState } from 'react'
import i18n from 'i18next'
import './App.css'
import { Page } from './components/Page/Page'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { ProjectsPage } from './pages/ProjectsPage/ProjectsPage'
import { ProjectProfilePage } from './pages/ProjectProfilePage/ProjectProfilePage'
import { RegionalCenterProfilePage } from './pages/RegionalCenterProfilePage/RegionalCenterProfilePage'
import { HomePage } from './pages/HomePage/HomePage'
import { ProjectsAdminPage } from './pages/ProjectsAdminPage/ProjectsAdminPage'
import { ProjectsAdminEditPage } from './pages/ProjectsAdminEditPage/ProjectsAdminEditPage'
import { RegionalCenterAdminPage } from './pages/RegionalCenterAdminPage/RegionalCenterAdminPage'
import { RegionalCenterAdminEditPage } from './pages/RegionalCenterAdminEditPage/RegionalCenterAdminEditPage'
import { Form } from './components/ui/Form/Form'
import { Form as AntdForm, Input } from 'antd'
import { Button } from './components/ui/Button/Button'
import { LawFirmAdminPage } from './pages/LawFirmsAdminPage/LawFirmAdminPage'
import { LawFirmsAdminEditPage } from './pages/LawFirmsAdminEditPage/LawFirmsAdminEditPage'
import { ExpertsAdminPage } from './pages/ExpertsAdminPage/ExpertsAdminPage'
import { ExpertsAdminEditPage } from './pages/ExpertsAdminEditPage/ExpertsAdminEditPage'
import { ExpertsPage } from './pages/ExpertsPage/ExpertsPage'
import { ExpertProfilePage } from './pages/ExpertProfilePage/ExpertProfilePage'
import { AssistantsAdminPage } from './pages/AssistantsAdminPage/AssistantsAdminPage'
import { AssistantsAdminEditPage } from './pages/AssistantsAdminEditPage/AssistantsAdminEditPage'
import { LanguagesAdminEditPage } from './pages/LanguagesAdminEditPage/LanguagesAdminEditPage'
import { LanguagesAdminPage } from './pages/LanguagesAdminPage/LanguagesAdminPage'
import { LoginPage } from './pages/LoginPage/LoginPage'
import { useMe } from './hooks/useMe'
import PrivateRoute from './components/PrivateRoute/PrivateRoute'
import { SuccessPage } from './pages/SuccessPage/SuccessPage'
import { DevelopersAdminPage } from './pages/DevelopersAdminPage/DevelopersAdminPage'
import { DevelopersAdminEditPage } from './pages/DevelopersAdminEditPage/DevelopersAdminEditPage'
import { JobsEditPage } from './pages/JobsEditPage/JobsEditPage'
import { JobsDetailsPage } from './pages/JobsDetailsPage/JobsDetailsPage'
import { SetAppointmentPage } from './pages/SetAppointmentPage/SetAppointmentPage'
import { SettingsPage } from './pages/SettingsPage/SettingsPage'
import { JobsAdminPage } from './pages/JobsAdminPage/JobsAdminPage'
import { ProposalsAdminPage } from './pages/ProposalsAdminPage/ProposalsAdminPage'
import { ProposalsAdminEditPage } from './pages/ProposalsAdminEditPage/ProposalsAdminEditPage'
import { ProposalsDirectAdminPage } from './pages/ProposalsDirectAdminPage/ProposalsDirectAdminPage'
import { ProposalsDirectAdminEditPage } from './pages/ProposalsDirectAdminEditPage/ProposalsDirectAdminEditPage'
import { useTranslation } from 'react-i18next'
import { useStomp } from './hooks/useStomp'
import { WriteReviewPage } from './pages/WriteReviewPage/WriteReviewPage'
import { useDispatch, useSelector } from './reducers/store'
import { fetchNotifications } from './actions/notifications'
import { useLanguages } from './hooks/useLanguages'
import { ExpertTitlesAdminPage } from './pages/ExpertTitlesAdminPage/ExpertTitlesAdminPage'
import { ExpertTitlesAdminEditPage } from './pages/ExpertTitlesAdminEditPage/ExpertTitlesAdminEditPage'
import { LanguagesExpertiseAdminPage } from './pages/LanguagesExpertiseAdminPage/LanguagesExpertiseAdminPage'
import { LanguagesExpertiseAdminEditPage } from './pages/LanguagesExpertiseAdminEditPage/LanguagesExpertiseAdminEditPage'
import { VisaAdminPage } from './pages/VisaAdminPage/VisaAdminPage'
import { VisaAdminEditPage } from './pages/VisaAdminEditPage/VisaAdminEditPage'
import { KnowledgeCenterHomePage } from './pages/KnowledgeCenterPage/KnowledgeCenterHomePage'
import { KnowledgeCenterCategoriesPage } from './pages/KnowledgeCenterPage/KnowledgeCenterCategoriesPage'
import { BlogPage } from './pages/BlogPage/BlogPage'
import { BlogArticlePage } from './pages/BlogPage/BlogArticlePage'
import { KnowledgeCenterArticlePage } from './pages/KnowledgeCenterPage/KnowledgeCenterArticlePage'
import { BlogAdminPage } from './pages/BlogAdminPage/BlogAdminPage'
import { BlogAdminEditPage } from './pages/BlogAdminEditPage/BlogAdminEditPage'
import { WikiAdminPage } from './pages/WikiAdminPage/WikiAdminPage'
import { WikiAdminEditPage } from './pages/WikiAdminEditPage/WikiAdminEditPage'
import { useJobs } from './hooks/useJobs'
import { useAssistants } from './hooks/useAssistants'
import { CategoryIndustryAdminEditPage } from './pages/CategoryIndustryAdminEditPage/CategoryIndustryAdminEditPage'
import { CategoryIndustryAdminPage } from './pages/CategoryIndustryAdminPage/CategoryIndustryAdminPage'
import { ReqInvestmentAdminEditPage } from './pages/ReqInvestmentAdminEditPage/ReqInvestmentAdminEditPage'
import { ReqInvestmentAdminPage } from './pages/ReqInvestmentAdminPage/ReqInvestmentAdminPage'
import { RegionalCenterManagersAdminEditPage } from './pages/RegionalCenterManagersAdminEditPage/RegionalCenterManagersAdminEditPage'
import { RegionalCenterManagersAdminPage } from './pages/RegionalCenterManagersAdminPage/RegionalCenterManagersAdminPage'
import { ExpertReviewsPage } from './pages/ExpertReviewsPage/ExpertReviewsPage'
import { PracticeAreasAdminEditPage } from './pages/PracticeAreasAdminEditPage/PracticeAreasAdminEditPage'
import { PracticeAreasAdminPage } from './pages/PracticeAreasAdminPage/PracticeAreasAdminPage'
import { RequestsAdminEditPage } from './pages/RequestsAdminEditPage/RequestsAdminEditPage'
import { RequestsAdminPage } from './pages/RequestsAdminPage/RequestsAdminPage'
import { ReviewAdminEditPage } from './pages/ReviewAdminEditPage/ReviewAdminEditPage'
import { ReviewAdminPage } from './pages/ReviewAdminPage/ReviewAdminPage'
import { ExpiredPage } from './pages/ExpiredPage/ExpiredPage'

import { changeLanguage } from './reducers/session'
import { HOME_PAGE } from './api/endpoints'
import WindowSize from './components/WindowSize'

function App() {
    const { t } = useTranslation()
    useStomp()
    useLanguages()
    useJobs()
    useAssistants()
    const dispatch = useDispatch()

    const [me, fetchMe] = useMe()

    useEffect(() => {
        if (me) {
            dispatch(fetchNotifications())
        }
    }, [me, dispatch])

    const langCodes = useSelector(store => store.languages.languages)?.map(langItem => langItem.code)

    const params = useMemo(() => new URLSearchParams(window.location.search), [])

    if (params.get('token')) {
        localStorage.setItem('token', params.get('token')!)
        params.delete('token')
    }

    const [langParamPage, setLangParamPage] = useState<string>('')
    const paramLang = useMemo(() => params?.get('lang'), [params])

    useEffect(() => {
        if (!!paramLang?.length) {
            setLangParamPage(paramLang)
        }
    }, [paramLang])

    const storageLang = localStorage.getItem('lang')
    const sessionLang = useSelector(store => store.session.lang)
    const [langState, setLangState] = useState<string>(sessionLang)

    useEffect(() => {
        // Priority:
        // 1. qs url lang
        // 2. localStorage lang
        // 3. store lang
        if (!!langParamPage?.length && langCodes?.includes(langParamPage)) {
            setLangState(langParamPage)
        }
        if (!langParamPage && !!storageLang?.length && storageLang !== sessionLang && langCodes?.includes(storageLang)) {
            setLangState(storageLang)
        }
    }, [langParamPage, langCodes, sessionLang, storageLang])

    useEffect(() => {
        if (!!langState?.length) {
            i18n.changeLanguage(langState).then(() => {
                localStorage.setItem('lang', langState)
                dispatch(changeLanguage({ code: langState }))
            })
        }
    }, [dispatch, langState])

    const token = localStorage.getItem('token')
    useEffect(() => {
        if (token) {
            fetchMe()
        }
    }, [token, fetchMe])

    if (localStorage.getItem('password') !== 'zone' && HOME_PAGE !== 'https://app.citizon.com') {
        return (
            <Form
                layout="vertical"
                style={{ padding: '64px' }}
                onFinish={({ password }) => {
                    if (password === 'zone') {
                        localStorage.setItem('password', password)
                    }
                }}
            >
                <AntdForm.Item label="Password" name="password" wrapperCol={{ span: 8 }}>
                    <Input />
                </AntdForm.Item>
                <AntdForm.Item wrapperCol={{ span: 8 }}>
                    <Button submit>Login</Button>
                </AntdForm.Item>
            </Form>
        )
    }

    return (
        <>
            <div className="App">
                <BrowserRouter>
                    <WindowSize>
                        <Switch>
                            <PrivateRoute path="/projects/:id/:regionalCenterId" semipublic>
                                <Page page={RegionalCenterProfilePage} title={t('Back')} showLogo previous="/projects/:id" semipublic />
                            </PrivateRoute>
                            <PrivateRoute path="/projects/:id" semipublic>
                                <Page page={ProjectProfilePage} title={t('Projects for EB-5')} previous="/projects" semipublic />
                            </PrivateRoute>
                            <PrivateRoute path="/projects" semipublic hideSemipublicLogin>
                                <Page page={ProjectsPage} title={t('Back to Home')} semipublic hideSemipublicLogin />
                            </PrivateRoute>
                            <PrivateRoute path="/projects-admin/:id" admin>
                                <Page page={ProjectsAdminEditPage} title={t('Back')} previous="/projects-admin" />
                            </PrivateRoute>
                            <PrivateRoute path="/projects-admin" admin>
                                <Page page={ProjectsAdminPage} title="Back to Home" />
                            </PrivateRoute>
                            <PrivateRoute path="/regional-centers/:regionalCenterId" semipublic>
                                <Page page={RegionalCenterProfilePage} title={t('Back')} showLogo semipublic />
                            </PrivateRoute>
                            <PrivateRoute path="/regional-centers-admin/:id" admin>
                                <Page page={RegionalCenterAdminEditPage} title={t('Back')} previous="/regional-centers-admin" />
                            </PrivateRoute>
                            <PrivateRoute path="/regional-centers-admin" admin>
                                <Page page={RegionalCenterAdminPage} title={t('Back')} />
                            </PrivateRoute>
                            <PrivateRoute path="/review-admin/:id" admin>
                                <Page page={ReviewAdminEditPage} title={t('Back')} previous="/review-admin" />
                            </PrivateRoute>
                            <PrivateRoute path="/review-admin" admin>
                                <Page page={ReviewAdminPage} title={t('Back')} />
                            </PrivateRoute>
                            <PrivateRoute path="/law-firms-admin/:id" admin>
                                <Page page={LawFirmsAdminEditPage} title={t('Back')} previous="/law-firms-admin" />
                            </PrivateRoute>
                            <PrivateRoute path="/law-firms-admin" admin>
                                <Page page={LawFirmAdminPage} title={t('Back')} />
                            </PrivateRoute>
                            <PrivateRoute path="/job-titles-admin/:id" admin>
                                <Page page={ExpertTitlesAdminEditPage} title={t('Back')} previous="/job-titles-admin" />
                            </PrivateRoute>
                            <PrivateRoute path="/job-titles-admin" admin>
                                <Page page={ExpertTitlesAdminPage} title={t('Back')} />
                            </PrivateRoute>
                            <PrivateRoute path="/experts-admin/:id" admin>
                                <Page page={ExpertsAdminEditPage} title={t('Back')} previous="/experts-admin" />
                            </PrivateRoute>
                            <PrivateRoute path="/experts-admin" admin>
                                <Page page={ExpertsAdminPage} title={t('Back')} />
                            </PrivateRoute>
                            <Route path="/experts/:id/reviews">
                                <Page page={ExpertReviewsPage} title={t('Back to profile')} />
                            </Route>
                            <PrivateRoute path="/experts/:id/write-review">
                                <Page page={WriteReviewPage} title={t('Back to Home')} />
                            </PrivateRoute>
                            <PrivateRoute path="/experts/:id" semipublic>
                                <Page page={ExpertProfilePage} title={t('Back')} semipublic />
                            </PrivateRoute>
                            <PrivateRoute path="/experts" semipublic hideSemipublicLogin>
                                <Page page={ExpertsPage} title={t('Back to Home')} semipublic hideSemipublicLogin />
                            </PrivateRoute>
                            <PrivateRoute path="/assistants-admin/:id" admin>
                                <Page page={AssistantsAdminEditPage} title={t('Back')} previous="/assistants-admin" />
                            </PrivateRoute>
                            <PrivateRoute path="/assistants-admin" admin>
                                <Page page={AssistantsAdminPage} title={t('Back')} />
                            </PrivateRoute>
                            <PrivateRoute path="/developers-admin/:id" admin>
                                <Page page={DevelopersAdminEditPage} title={t('Back')} previous="/developers-admin" />
                            </PrivateRoute>
                            <PrivateRoute path="/developers-admin" admin>
                                <Page page={DevelopersAdminPage} title={t('Back')} />
                            </PrivateRoute>
                            <PrivateRoute path="/languages-expertise-admin/:id" admin>
                                <Page page={LanguagesExpertiseAdminEditPage} title={t('Back')} previous="/languages-expertise-admin" />
                            </PrivateRoute>
                            <PrivateRoute path="/languages-expertise-admin" admin>
                                <Page page={LanguagesExpertiseAdminPage} title={t('Back')} />
                            </PrivateRoute>
                            <PrivateRoute path="/languages-admin/:id" admin>
                                <Page page={LanguagesAdminEditPage} title={t('Back')} previous="/languages-admin" />
                            </PrivateRoute>
                            <PrivateRoute path="/languages-admin" admin>
                                <Page page={LanguagesAdminPage} title={t('Back')} />
                            </PrivateRoute>
                            <PrivateRoute path="/jobs-admin/:jobId/proposals/:id" admin>
                                <Page page={ProposalsAdminEditPage} title={t('Back to Home')} previous="/jobs-admin/:id" />
                            </PrivateRoute>
                            <PrivateRoute path="/jobs-admin/:id" admin>
                                <Page page={ProposalsAdminPage} title={t('Back to Home')} previous="/jobs-admin" />
                            </PrivateRoute>
                            <PrivateRoute path="/proposals-direct/:id" admin>
                                <Page page={ProposalsDirectAdminEditPage} title={t('Back to Home')} previous="/proposals-direct" />
                            </PrivateRoute>
                            <PrivateRoute path="/proposals-direct" admin>
                                <Page page={ProposalsDirectAdminPage} title={t('Back to Home')} />
                            </PrivateRoute>
                            <PrivateRoute path="/jobs-admin" admin>
                                <Page page={JobsAdminPage} title={t('Back to Home')} />
                            </PrivateRoute>
                            <PrivateRoute path="/jobs-create">
                                <Page page={JobsEditPage} title="Back to Dashboard" noFooter={true} />
                            </PrivateRoute>
                            <PrivateRoute path="/job/:id">
                                <Page page={JobsDetailsPage} title={t('Back to Home')} />
                            </PrivateRoute>
                            <PrivateRoute path="/requests-admin/:id" semipublic>
                                <Page page={RequestsAdminEditPage} title="Back" previous="/requests-admin" />
                            </PrivateRoute>
                            <PrivateRoute path="/requests-admin" admin>
                                <Page page={RequestsAdminPage} title={t('Back to Home')} />
                            </PrivateRoute>
                            <PrivateRoute path="/visa-admin/:id" admin>
                                <Page page={VisaAdminEditPage} title="Back" previous="/visa-admin" />
                            </PrivateRoute>
                            <PrivateRoute path="/visa-admin" admin>
                                <Page page={VisaAdminPage} title={t('Back to Home')} />
                            </PrivateRoute>
                            <PrivateRoute path="/blog-admin/:id" admin>
                                <Page page={BlogAdminEditPage} title={t('Back to Home')} previous="/blog-admin" />
                            </PrivateRoute>
                            <PrivateRoute path="/blog-admin" admin>
                                <Page page={BlogAdminPage} title={t('Back to Home')} />
                            </PrivateRoute>
                            <PrivateRoute path="/category-industry-admin/:id" admin>
                                <Page page={CategoryIndustryAdminEditPage} title={t('Back to Home')} previous="/category-industry-admin" />
                            </PrivateRoute>
                            <PrivateRoute path="/category-industry-admin" admin>
                                <Page page={CategoryIndustryAdminPage} title={t('Back to Home')} />
                            </PrivateRoute>
                            <PrivateRoute path="/rc-managers-admin/:id" admin>
                                <Page page={RegionalCenterManagersAdminEditPage} title={t('Back to Home')} previous="/rc-managers-admin" />
                            </PrivateRoute>
                            <PrivateRoute path="/rc-managers-admin" admin>
                                <Page page={RegionalCenterManagersAdminPage} title={t('Back to Home')} />
                            </PrivateRoute>
                            <PrivateRoute path="/required-investment-admin/:id" admin>
                                <Page page={ReqInvestmentAdminEditPage} title={t('Back to Home')} previous="/required-investment-admin" />
                            </PrivateRoute>
                            <PrivateRoute path="/required-investment-admin" admin>
                                <Page page={ReqInvestmentAdminPage} title={t('Back to Home')} />
                            </PrivateRoute>
                            <PrivateRoute path="/practice-areas-admin/:id" admin>
                                <Page page={PracticeAreasAdminEditPage} title={t('Back to Home')} previous="/practice-areas-admin" />
                            </PrivateRoute>
                            <PrivateRoute path="/practice-areas-admin" admin>
                                <Page page={PracticeAreasAdminPage} title={t('Back to Home')} />
                            </PrivateRoute>
                            <Route path="/wiki-articles/:id">
                                <Page page={KnowledgeCenterArticlePage} title={t('Back to Immigration')} previous="/wiki" />
                            </Route>
                            <Route path="/wiki/:section">
                                <Page page={KnowledgeCenterCategoriesPage} title={t('Back to Knowledge Center')} previous="/wiki" />
                            </Route>
                            <Route path="/wiki">
                                <Page page={KnowledgeCenterHomePage} title={t('Back to Home')} />
                            </Route>
                            <PrivateRoute path="/wiki-admin/:id" admin>
                                <Page page={WikiAdminEditPage} title={t('Back to Home')} previous="/wiki-admin" />
                            </PrivateRoute>
                            <PrivateRoute path="/wiki-admin" admin>
                                <Page page={WikiAdminPage} title={t('Back to Home')} />
                            </PrivateRoute>
                            <Route path="/blog/:id">
                                <Page page={BlogArticlePage} title={t('Back to Immigration')} previous="/blog" />
                            </Route>
                            <Route path="/blog">
                                <Page page={BlogPage} title={t('Back to Our Library')} previous="/wiki" />
                            </Route>
                            <PrivateRoute path="/settings">
                                <Page page={SettingsPage} title={t('Back to Home')} />
                            </PrivateRoute>
                            <Route path="/appointment">
                                <Page page={SetAppointmentPage} title={t('Back')} />
                            </Route>
                            <Route path="/expired">
                                <Page page={ExpiredPage} />
                            </Route>
                            <Route path="/login">
                                <Page page={LoginPage} />
                            </Route>
                            <Route path="/success/:type">
                                <SuccessPage />
                            </Route>
                            <PrivateRoute path="/">
                                <Page page={HomePage} />
                            </PrivateRoute>
                        </Switch>
                    </WindowSize>
                </BrowserRouter>
            </div>
        </>
    )
}

export default App
