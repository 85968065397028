import { Developer } from '../types/developer'
import { Filter, Sorter } from '../types/filter'
import { sendDelete, sendGet, sendPost, sendPut } from '../utils/api'

export function developersGetAll(thunkAPI: any, filters?: Filter[], sorters?: Sorter[]): Promise<Developer[]> {
    const params = new URLSearchParams()
    filters?.forEach(filter => {
        params.append(`_${filter.path}.${filter.operation}`, filter.value)
    })
    if (sorters?.length) {
        params.append(`sort`, sorters.map(item => `${item.fieldName}.${item.direction}`).join(','))
    }
    return sendGet(`/developers?${params.toString()}`, thunkAPI)
}

export function developersGet(developerId: string, thunkAPI: any): Promise<Developer> {
    return sendGet(`/developers/${developerId}`, thunkAPI)
}

export function developersCreate(developer: Developer, thunkAPI: any): Promise<Developer> {
    return sendPost(`/developers`, thunkAPI, developer)
}

export function developersUpdate(developer: Developer, thunkAPI: any): Promise<Developer> {
    return sendPut(`/developers/${developer.id}`, thunkAPI, developer)
}

export function developersDelete(ids: string[], thunkAPI: any) {
    return sendDelete(`/developers`, thunkAPI, ids)
}
