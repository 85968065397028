import React, { useMemo } from 'react'
import { PRIVACY_PAGE, TERMS_PAGE } from '../../../api/endpoints'
import { cn } from '../../../utils/cn'
import { useSelector } from '../../../reducers/store'

import styles from './LegalFooter.module.css'

export interface LegalFooterProps {
    className?: string
}

export const LegalFooter = ({ className }: LegalFooterProps) => {
    const lang = useSelector(store => store.session.lang)

    const contactUsLink = useMemo(
        () => (lang === 'ru' || lang === 'es' ? `https://citizon.com/contact_us/${lang}` : `https://citizon.com/contact_us/en`),
        [lang]
    )

    return (
        <div className={cn(styles.container, className)}>
            <p className={styles.p}>
                <div className={styles.copyright}>© 2021 Citizon. All rights reserved.</div>
                <a className={styles.link} href={TERMS_PAGE!}>
                    Terms & Conditions
                </a>
                <a className={styles.link} href={PRIVACY_PAGE!}>
                    Privacy Policy
                </a>
                <a className={styles.link} href={contactUsLink}>
                    Contact Us
                </a>
            </p>
            <p className={styles.p}>
                Citizon does not make investment recommendations and no communication, through this website or in any other medium should be
                construed as a recommendation for any investment offered on or off this investment platform, or as general investment
                advice. Past performance is not a promise of future performance. Please refer to respective fund documentation for full
                details about potential risks, charges and expenses.
            </p>
            <p className={styles.p}>
                Citizon does not make any legal advice or recommendation, all descriptions provided on the platform are purely for
                informational purposes. Citizon strongly recommends that before making any decision or taking any action which might affect
                their legal rights and obligations, you obtain the advice of one or more attorneys qualified in each area of law that might
                apply.
            </p>
        </div>
    )
}
