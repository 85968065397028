import { useEffect } from 'react'
import { useDispatch } from '../reducers/store'
import { fetchReview, fetchReviews } from '../actions/review'
import { clearSelectedReview } from '../reducers/experts'

export function useReviews() {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchReviews())
    }, [dispatch])
}

export function useReview(reviewId: string) {
    const dispatch = useDispatch()
    useEffect(() => {
        if (reviewId !== 'new') {
            dispatch(fetchReview(reviewId))
        }
        return () => {
            dispatch(clearSelectedReview())
        }
    }, [reviewId, dispatch])
}
