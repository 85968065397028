import TextArea from 'antd/lib/input/TextArea'
import React, { useState } from 'react'
import { BASE_FILE_URL } from '../../../api/endpoints'
import { useVisas } from '../../../hooks/useVisa'
import { useSelector } from '../../../reducers/store'
import { Video } from '../../../types/file'
import { Dropdown } from '../Dropdown/Dropdown'
import { FileUpload } from '../FileUpload/FileUpload'
import { Input } from '../Input/Input'
import styles from './VideoUpload.module.css'

export interface VideoUploadProps {
    value?: Video[] | null
    onChange?: (videos: Video[]) => void
}

export const VideoUpload = ({ value = [], onChange }: VideoUploadProps) => {
    const [videos, setVideos] = useState<Video[]>(value ?? [])
    useVisas()
    const visaOptions = useSelector(store => store.experts.visas).map(item => ({ value: item.code, label: item.label }))

    const handleChange = (fileIds: string[] | null) => {
        const newVideos =
            fileIds?.map(id => {
                const video = videos.find(item => item.id === id)
                return video || { id, name: '', description: '' }
            }) ?? []
        setVideos(newVideos)
        onChange?.(newVideos)
    }

    const handleName = (fileId: string, value: string) => {
        const newVideos = videos.map(item => (item.id === fileId ? { ...item, name: value } : item))
        setVideos(newVideos)
        onChange?.(newVideos)
    }

    const handleDescription = (fileId: string, value: string) => {
        const newVideos = videos.map(item => (item.id === fileId ? { ...item, description: value } : item))
        setVideos(newVideos)
        onChange?.(newVideos)
    }

    const handleTag = (fileId: string, value: string[]) => {
        const newVideos = videos.map(item => (item.id === fileId ? { ...item, tag: value } : item))
        setVideos(newVideos)
        onChange?.(newVideos)
    }

    return (
        <div>
            <FileUpload onChange={handleChange as any} value={videos.map(item => item.id)} />
            {videos.map(item => (
                <div key={item.id}>
                    <video src={`${BASE_FILE_URL}/${item.id}`} controls className={styles.video} />
                    <Input value={item.id} disabled className={styles.input} />
                    <Input
                        placeholder="Name"
                        className={styles.input}
                        defaultValue={item.name}
                        onBlur={e => handleName(item.id, e.currentTarget.value)}
                    />
                    <TextArea
                        placeholder="Description"
                        className={styles.input}
                        defaultValue={item.description}
                        onBlur={e => handleDescription(item.id, e.currentTarget.value)}
                    />
                    <Dropdown
                        className={styles.input}
                        placeholder="Select tag"
                        options={visaOptions}
                        mode="tags"
                        defaultValue={item.tag ?? []}
                        onChange={e => handleTag(item.id, e)}
                    />
                </div>
            ))}
        </div>
    )
}
