import React, { ReactNode } from 'react'
import { cn } from '../../../utils/cn'
import styles from './DashboardButton.module.css'

export interface DashboardButtonProps {
    name: string
    description?: string
    icon?: ReactNode
    className?: string
    disabled?: boolean
    ghost?: boolean
    onClick?: () => void
}

export const DashboardButton = ({ name, description, icon, className, disabled, ghost, onClick }: DashboardButtonProps) => {
    return (
        <button className={cn(styles.button, className, { [styles.disabled]: !!disabled, [styles.ghost]: !!ghost })} onClick={onClick}>
            <div className={styles.icon}>{icon}</div>
            <div className={cn(styles.content, { [styles.ghost]: !!ghost })}>
                <span className={styles.name}>{name}</span>
                <span className={styles.description}>{description}</span>
            </div>
        </button>
    )
}
