import React, { memo, useCallback, useEffect } from 'react'
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom'
import { useSelector } from '../../reducers/store'

export interface PrivateRouteProps extends RouteProps {
    semipublic?: boolean
    hideSemipublicLogin?: boolean
    admin?: boolean
}

export const PrivateRoute = (props: PrivateRouteProps) => {
    const user = useSelector(store => store.session.user)
    const location = useLocation()
    useEffect(() => {
        if (!user && !props.hideSemipublicLogin) {
            document.getElementById('root')!.style.overflow = 'hidden'
            document.getElementsByTagName('html').item(0)!.style.overflow = 'hidden'
        } else {
            document.getElementById('root')!.style.overflow = 'initial'
            document.getElementsByTagName('html').item(0)!.style.overflow = 'initial'
        }
    }, [user, props.hideSemipublicLogin])
    const render = useCallback(() => {
        if (!props.admin && !user && !props.semipublic) {
            return <Redirect to={`/login?redirect_uri=${location.pathname}`} />
        } else if (props.admin && !user?.roles.includes('ADMIN')) {
            return <Redirect to={`/home`} />
        } else {
            return props.children
        }
    }, [user, props.children, props.semipublic, location.pathname, props.admin])
    const { children, ...rest } = props
    return <Route {...rest} render={render} />
}

export default memo(PrivateRoute)
