import React, { useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Button } from '../../components/ui/Button/Button'
import { useDispatch, useSelector } from '../../reducers/store'
import styles from './AssistantsAdminEditPage.module.css'
import { getId, useAssistant } from '../../hooks/useAssistants'
import Avatar from 'antd/lib/avatar/avatar'
import { Form } from 'antd'
import FormItem from 'antd/lib/form/FormItem'
import { Input } from '../../components/ui/Input/Input'
import { updateAssistant } from '../../actions/assistants'
import { CalendlyUser } from '../../types/assistants'
import { LanguagesSelect } from '../../components/LanguagesSelect/LanguagesSelect'
import useGtmPageLoad from '../../hooks/useGtmPageLoad'

export const AssistantsAdminEditPage = () => {
    useGtmPageLoad('AssistantsAdminEditPage')
    const [form] = Form.useForm()
    const { id } = useParams<{ id: string }>()
    const dispatch = useDispatch()
    const history = useHistory()
    useAssistant(id)
    const selectedAssistant = useSelector(store => store.assistants.selectedAssistant)
    const handleCancel = useCallback(() => {
        history.push('/assistants-admin')
    }, [history])

    const handleSubmit = useCallback(
        (values: Record<string, string>) => {
            const assistant = {
                ...values,
                id: getId(id)
            } as CalendlyUser
            dispatch(updateAssistant(assistant)).then(handleCancel)
        },
        [id, handleCancel, dispatch]
    )

    if (!selectedAssistant) {
        return null
    }
    return (
        <div className={styles.container}>
            <Form
                className={styles.form}
                form={form}
                layout="vertical"
                initialValues={selectedAssistant}
                onFinish={handleSubmit}
                autoComplete="off"
            >
                <FormItem label="Photo" name="avatarUrl" wrapperCol={{ span: 8 }}>
                    <Avatar size={60} src={selectedAssistant.avatarUrl} />
                </FormItem>
                <FormItem label="Name" name="name" wrapperCol={{ span: 8 }}>
                    <Input readOnly />
                </FormItem>
                <FormItem label="Slug" hidden name="slug" wrapperCol={{ span: 8 }}>
                    <Input readOnly />
                </FormItem>
                <FormItem label="Email" name="email" wrapperCol={{ span: 8 }}>
                    <Input readOnly />
                </FormItem>
                <FormItem label="Timezone" name="timezone" wrapperCol={{ span: 8 }}>
                    <Input readOnly />
                </FormItem>
                <FormItem label="Languages" name="languages" wrapperCol={{ span: 8 }}>
                    <LanguagesSelect />
                </FormItem>
            </Form>
            <div className={styles.footer}>
                <Button type="ghost" onClick={handleCancel} className={styles.button}>
                    Cancel
                </Button>
                <Button onClick={form.submit} style={{ width: 'auto' }} className={styles.button}>
                    Save
                </Button>
            </div>
        </div>
    )
}
