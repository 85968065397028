import { useEffect } from 'react'
import { fetchExpertise, fetchExpertises, fetchLanguage, fetchLanguages } from '../actions/languages'
import { clearSelected, clearSelectedExpertise } from '../reducers/languages'
import { useDispatch } from '../reducers/store'

export function useLanguages() {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchLanguages())
    }, [dispatch])
}

export function useLanguage(langId: string) {
    const dispatch = useDispatch()
    useEffect(() => {
        if (langId !== 'new') {
            dispatch(fetchLanguage(langId))
        }
        return () => {
            dispatch(clearSelected())
        }
    }, [langId, dispatch])
}

export function useExpertises() {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchExpertises())
    }, [dispatch])
}

export function useExpertise(id: string) {
    const dispatch = useDispatch()
    useEffect(() => {
        if (id !== 'new') {
            dispatch(fetchExpertise(id))
        }
        return () => {
            dispatch(clearSelectedExpertise())
        }
    }, [id, dispatch])
}
