import { Checkbox, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import React, { useCallback, useMemo, useState } from 'react'
import { deleteProjects } from '../../actions'
import { Button } from '../../components/ui/Button/Button'
import { useDispatch, useSelector } from '../../reducers/store'
import { Project } from '../../types/project'
import { ReactComponent as PlusIcon } from '../../assets/plus.svg'
import styles from './ProjectsAdminPage.module.css'
import { useProjects } from '../../hooks/useProjects'
import { Link } from 'react-router-dom'
import useGtmPageLoad from '../../hooks/useGtmPageLoad'

export const ProjectsAdminPage = () => {
    useGtmPageLoad('ProjectsAdminPage')
    const dispatch = useDispatch()
    const [selectedItems, setSelectedItems] = useState<string[]>([])
    useProjects()
    const data = useSelector(store => store.projects.projects)

    const renderLink = useCallback((value: any, item: Project) => {
        return <Link to={`/projects-admin/${item.id}`}>{value}</Link>
    }, [])

    const renderArrayCategory = useCallback((value: any, item: Project) => {
        return item.categories?.map(item => item.name).join(', ')
    }, [])

    const renderReqInvestment = useCallback((value: any, item: Project) => {
        return item.requiredInvestment?.name
    }, [])

    const columns: ColumnsType<Project> = useMemo(() => {
        return [
            {
                dataIndex: 'name',
                title: 'Name',
                render: renderLink
            },
            { dataIndex: 'address', title: 'City' },
            { dataIndex: 'state', title: 'State' },
            {
                dataIndex: 'categories',
                title: 'Categories',
                render: renderArrayCategory
            },
            {
                dataIndex: 'requiredInvestment',
                title: 'Required Investment',
                render: renderReqInvestment
            },
            { dataIndex: 'investmentType', title: 'Investment Type' },
            {
                dataIndex: 'hideInUsa',
                title: 'Hide in USA',
                render: (value: boolean) => <Checkbox disabled={true} checked={value} />
            }
        ]
    }, [renderLink, renderArrayCategory, renderReqInvestment])

    const handleDelete = () => {
        dispatch(deleteProjects(selectedItems))
        setSelectedItems([])
    }

    const handleSelect = (selectedKeys: (string | number)[]) => {
        setSelectedItems(selectedKeys.map(key => (typeof key !== 'string' ? key.toString() : key)))
    }

    return (
        <div className={styles.container}>
            <div className={styles.controls}>
                <Link to="/projects-admin/new">
                    <Button icon={<PlusIcon />} style={{ width: 'auto' }} className={styles.button}>
                        Add
                    </Button>
                </Link>
                <Button style={{ width: 'auto' }} type="ghost" className={styles.button} onClick={handleDelete}>
                    Delete
                </Button>
            </div>
            <div style={{ overflow: 'auto' }}>
                <Table columns={columns} dataSource={data} rowSelection={{ onChange: handleSelect }} rowKey="id" />
            </div>
        </div>
    )
}
