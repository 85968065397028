export const EMPTY_ARRAY = []

export enum ProposalStatus {
    OPEN = 'OPEN',
    SELECTED = 'SELECTED',
    APPOINTMENT_CONFIRMED = 'APPOINTMENT_CONFIRMED',
    APPOINTMENT_COMPLETED = 'APPOINTMENT_COMPLETED',
    CALL_CANCELLED = 'CALL_CANCELLED',
    CALL_COMPLETED = 'CALL_COMPLETED',
    NOT_ELIGIBLE = 'NOT_ELIGIBLE',
    ASSISTANT_MESSAGE = 'ASSISTANT_MESSAGE',
    COMPANY_MESSAGE = 'COMPANY_MESSAGE'
}
