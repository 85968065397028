import { useEffect } from 'react'
import { fetchAllJobs, fetchJob, fetchJobs, fetchProposal } from '../actions/jobs'
import { clearProposal, clearSelected, removeAllFilters } from '../reducers/jobs'
import { useDispatch, useSelector } from '../reducers/store'
import { Filter } from '../types/filter'

export function useJobs() {
    const dispatch = useDispatch()
    const user = useSelector(store => store.session.user)
    useEffect(() => {
        if (user) {
            dispatch(fetchJobs())
        }
    }, [dispatch, user])
}

export function useJob(jobId: string) {
    const dispatch = useDispatch()
    useEffect(() => {
        if (jobId !== 'new') {
            dispatch(fetchJob(jobId))
        }
        return () => {
            dispatch(clearSelected())
        }
    }, [jobId, dispatch])
}

export function useAllJobs(filters?: Filter[]) {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchAllJobs())
    }, [dispatch, filters])
    useEffect(() => {
        return () => {
            dispatch(removeAllFilters())
        }
    }, [dispatch])
}

export function useProposal(proposalId: string) {
    const dispatch = useDispatch()
    useEffect(() => {
        if (proposalId !== 'new') {
            dispatch(fetchProposal(proposalId))
        }
        return () => {
            dispatch(clearProposal())
        }
    }, [proposalId, dispatch])
}
