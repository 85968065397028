import React, { useEffect, useState } from 'react'
import RichTextEditor, { EditorValue } from 'react-rte'
import { cn } from '../../../utils/cn'
import styles from './SimpleRichEditor.module.css'

export interface SimpleRichEditorProps {
    value?: string
    readOnly?: boolean
    className?: string
    onChange?: (e: string) => void
}

export const SimpleRichEditor = ({ value, readOnly, className, onChange }: SimpleRichEditorProps) => {
    const [message, setMessage] = useState(RichTextEditor.createValueFromString(value ?? '', 'markdown'))

    useEffect(() => {
        setMessage(RichTextEditor.createValueFromString(value ?? '', 'markdown'))
    }, [value])

    const handleMessage = (e: EditorValue) => {
        setMessage(e)
    }

    const handleBlur = {
        onBlur: () => onChange?.(message.toString('markdown'))
    }

    return (
        <RichTextEditor
            readOnly={readOnly}
            className={cn(styles.container, className)}
            onChange={handleMessage}
            value={message}
            {...handleBlur}
        />
    )
}
