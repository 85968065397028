import React from 'react'
import { useTranslation } from 'react-i18next'
import { SetAppointment } from '../../components/ui/SetAppointment/SetAppointment'
import styles from './SetAppointmentPage.module.css'
import useGtmPageLoad from '../../hooks/useGtmPageLoad'

export const SetAppointmentPage = () => {
    useGtmPageLoad('SetAppointmentPage')
    const { t } = useTranslation()
    return (
        <div className={styles.container}>
            <h1 className={styles.title}>{t('Choose options for Zoom call')}</h1>
            <SetAppointment />
        </div>
    )
}
