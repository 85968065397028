import { Filter, Sorter } from '../types/filter'
import { Expert } from '../types/expert'
import { sendDelete, sendGet, sendPost, sendPut } from '../utils/api'

export function expertsGetAll(thunkAPI: any, filters?: Filter[], sorters?: Sorter[]): Promise<Expert[]> {
    const params = new URLSearchParams()
    filters?.forEach(filter => {
        params.append(`_${filter.path}.${filter.operation}`, filter.value)
    })
    if (sorters?.length) {
        params.append(`sort`, sorters.map(item => `${item.fieldName}.${item.direction}`).join(','))
    }
    return sendGet(`/experts?${params.toString()}`, thunkAPI)
}

export function expertsGet(expertId: string, thunkAPI: any): Promise<Expert> {
    return sendGet(`/experts/${expertId}`, thunkAPI)
}

export function expertsCreate(expert: Expert, thunkAPI: any): Promise<Expert> {
    return sendPost(`/experts`, thunkAPI, expert)
}

export function expertsUpdate(expert: Expert, thunkAPI: any): Promise<Expert> {
    return sendPut(`/experts/${expert.id}`, thunkAPI, expert)
}

export function expertsDelete(ids: string[], thunkAPI: any) {
    return sendDelete(`/experts`, thunkAPI, ids)
}
