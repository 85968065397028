import { ProjectCategory } from '../types/project'
import { sendGet } from '../utils/api'

export const BASE_URL = process.env.REACT_APP_BASE_URL
export const BASE_FILE_URL = process.env.REACT_APP_BASE_FILE_URL
export const HOME_PAGE = process.env.REACT_APP_HOME_PAGE
export const TERMS_PAGE = process.env.REACT_APP_TERMS_URL
export const PRIVACY_PAGE = process.env.REACT_APP_PRIVACY_URL
export const RISK_INVESTING = process.env.REACT_APP_RISK_INVESTING_URL
export const DIRECT_PROJECT_RISK_INVESTING = process.env.REACT_APP_DIRECT_PROJECT_RISK_INVESTING_URL

export function projectCategories(thunkAPI: any): Promise<ProjectCategory[]> {
    return sendGet(`/projectCategories`, thunkAPI)
}
