import { Rate } from 'antd'
import React, { useCallback } from 'react'
import { cn } from '../../../utils/cn'
import styles from './ReviewPreview.module.css'
import { ReactComponent as StarFilled } from '../../../assets/star-filled.svg'
import { ReactComponent as StarEmpty } from '../../../assets/star-hole.svg'
import { useSelector } from '../../../reducers/store'
import { capitalize, getLocaleValue } from '../../../utils/string'
import { ShortText } from '../ShortText/ShortText'
import { Review } from '../../../types/Review'
export interface ReviewPreviewProps {
    review: Review
    full?: boolean
    className?: string
}

export const ReviewPreview = ({ review, full, className }: ReviewPreviewProps) => {
    const locale = useSelector(store => store.session.lang)
    const star = useCallback(({ index, value }: any) => {
        if (index < value) {
            return <StarFilled className={styles.star} />
        }
        return <StarEmpty className={styles.star} />
    }, [])

    const date = new Date(review.created)
    const monthName = capitalize(date.toLocaleDateString(locale, { month: 'long' }))
    const displayedDate = `${date.getDate()} ${monthName}, ${date.getFullYear()}`
    const text = getLocaleValue(review, 'text', locale)

    return (
        <div className={cn(styles.container, className)}>
            <Rate disabled value={review.rating} character={star} className={styles.stars} />
            <span className={styles.date}>{displayedDate}</span>
            <p className={styles.text}>
                {full && <div className={styles.full}>{text}</div>}
                {!full && <ShortText ellipsis={{ rows: 3 }}>{text}</ShortText>}
            </p>
        </div>
    )
}
