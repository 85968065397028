import { Filter, Sorter } from '../types/filter'
import { Project } from '../types/project'
import { sendDelete, sendGet, sendPost, sendPut } from '../utils/api'

export function projectsGetAll(thunkAPI: any, filters?: Filter[], sorters?: Sorter[]): Promise<Project[]> {
    const params = new URLSearchParams()
    filters?.forEach(filter => {
        params.append(`_${filter.path}.${filter.operation}`, filter.value)
    })
    if (sorters?.length) {
        params.append(`sort`, sorters.map(item => `${item.fieldName}.${item.direction}`).join(','))
    }
    return sendGet(`/projects?${params.toString()}`, thunkAPI)
}

export function projectsGet(projectId: string, thunkAPI: any): Promise<Project> {
    return sendGet(`/projects/${projectId}`, thunkAPI)
}

export function projectsCreate(project: Project, thunkAPI: any): Promise<Project> {
    return sendPost(`/projects`, thunkAPI, project)
}

export function projectsUpdate(project: Project, thunkAPI: any): Promise<Project> {
    return sendPut(`/projects/${project.id}`, thunkAPI, project)
}

export function projectsDelete(ids: string[], thunkAPI: any) {
    return sendDelete(`/projects`, thunkAPI, ids)
}
