import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export function useLocalizedStates(): Record<string, string> {
    const { t } = useTranslation()
    const states = useMemo(() => {
        return {
            AL: t('Alabama'),
            AK: t('Alaska'),
            AZ: t('Arizona'),
            AR: t('Arkansas'),
            CA: t('California'),
            CO: t('Colorado'),
            CT: t('Connecticut'),
            DE: t('Delaware'),
            DC: t('District Of Columbia'),
            FL: t('Florida'),
            GA: t('Georgia'),
            HI: t('Hawaii'),
            ID: t('Idaho'),
            IL: t('Illinois'),
            IN: t('Indiana'),
            IA: t('Iowa'),
            KS: t('Kansas'),
            KY: t('Kentucky'),
            LA: t('Louisiana'),
            ME: t('Maine'),
            MD: t('Maryland'),
            MA: t('Massachusetts'),
            MI: t('Michigan'),
            MN: t('Minnesota'),
            MS: t('Mississippi'),
            MO: t('Missouri'),
            MT: t('Montana'),
            NE: t('Nebraska'),
            NV: t('Nevada'),
            NH: t('New Hampshire'),
            NJ: t('New Jersey'),
            NM: t('New Mexico'),
            NY: t('New York'),
            NC: t('North Carolina'),
            ND: t('North Dakota'),
            OH: t('Ohio'),
            OK: t('Oklahoma'),
            OR: t('Oregon'),
            PA: t('Pennsylvania'),
            PR: t('Puerto Rico'),
            RI: t('Rhode Island'),
            SC: t('South Carolina'),
            SD: t('South Dakota'),
            TN: t('Tennessee'),
            TX: t('Texas'),
            UT: t('Utah'),
            USVI: t('United States Virgin Islands'),
            VT: t('Vermont'),
            VA: t('Virginia'),
            WA: t('Washington'),
            WV: t('West Virginia'),
            WI: t('Wisconsin'),
            WY: t('Wyoming')
        }
    }, [t])
    return states
}
