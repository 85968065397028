export interface Filter {
    path: string
    operation: FilterOperation
    value: any
}

export enum FilterOperation {
    EQUALS = 'equals',
    IN = 'in',
    SPECIFIED = 'specified',
    CONTAINS = 'contains',
    EQUALS_ONE_OF = 'equals_on_of',
    GREATER_THAN = 'greaterThan',
    LESS_THAN = 'lessThan',
    GREATER_OR_EQUAL_THAN = 'greaterOrEqualThan',
    LESS_OR_EQUAL_THAN = 'lessOrEqualThan',
    SPECIAL_RC = 'special_rc',
    SPECIAL_FULL_NAME = 'special_full_name'
}

export interface Sorter {
    fieldName: string
    direction: 'asc' | 'desc'
}
