import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { useBlogArticle } from '../../hooks/useBlogArticles'
import { useSelector } from '../../reducers/store'
import styles from './BlogArticlePage.module.css'
import { BASE_FILE_URL } from '../../api/endpoints'
import { Avatar } from 'antd'
import { getLocaleValue } from '../../utils/string'
import { BlogArticleSection } from '../../types/blog'
import { StatsTable } from '../../components/ui/StatsTable/StatsTable'
import { useWikiArticle } from '../../hooks/useWikiArticles'
import RichTextEditor from 'react-rte'
import useGtmPageLoad from '../../hooks/useGtmPageLoad'

export const BlogArticlePage = () => {
    useGtmPageLoad('BlogArticlePage')
    const { id: routeId } = useParams<{ id: string }>()
    useBlogArticle(routeId)
    const locale = useSelector(store => store.session.lang)
    const article = useSelector(store => store.blog.selectedArticle)
    const wikiArticleId = article?.category
    useWikiArticle(wikiArticleId)
    const wikiArticle = useSelector(store => store.wiki.selectedArticle)
    if (!article) {
        return null
    }
    return (
        <div className={styles.container}>
            {article?.photo && (
                <div className={styles.imgContainer}>
                    <img className={styles.photo} src={`${BASE_FILE_URL}/${article.photo}`} alt="Article" />
                </div>
            )}
            {wikiArticle && (
                <Link to={`/wiki-articles/${wikiArticle.id}`} className={styles.category}>
                    {wikiArticle.title}
                </Link>
            )}
            <h1 className={styles.title}>{getLocaleValue(article, 'title', locale)}</h1>
            <footer className={styles.footer}>
                {formatDate(article?.date)} ・ {getLocaleValue(article, 'duration', locale)}
            </footer>
            {article.expert && (
                <div className={styles.expert}>
                    <Avatar size={48} src={`${BASE_FILE_URL}/${article.expert?.photos?.[0]}`} />
                    <div className={styles.expertText}>
                        <h4 className={styles.expertName}>
                            {getLocaleValue(article?.expert, 'firstName', locale)} {getLocaleValue(article?.expert, 'lastName', locale)}
                        </h4>
                        <span className={styles.expertJobTitle}>{getLocaleValue(article.expert.jobTitle, 'name', locale)}</span>
                    </div>
                </div>
            )}
            {getLocaleValue(article, 'content', locale)?.sections?.map((item: BlogArticleSection) => {
                if (item.type === 'text') {
                    return (
                        <RichTextEditor
                            key={item.id}
                            readOnly
                            value={RichTextEditor.createValueFromString(item.content as any, 'markdown')}
                            className={styles.content}
                        />
                    )
                }
                if (item.type === 'table') {
                    const tableData = Object.keys(item.content).map(key => ({
                        name: key,
                        value: item.content[key],
                        type: 'right' as const
                    }))
                    return <StatsTable className={styles.table} items={tableData} />
                }
                return null
            })}
        </div>
    )
}

function formatDate(date: string) {
    if (!date) {
        return null
    }
    const dateObj = new Date(date)
    return `${dateObj.getDate()}.${dateObj.getMonth()}.${dateObj.getFullYear()}`
}
