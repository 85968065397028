import React from 'react'
import { cn } from '../../utils/cn'
import { Button } from '../ui/Button/Button'
import styles from './LearnMore.module.css'
import { RISK_INVESTING } from '../../api/endpoints'

export interface LearnMoreProps {
    title: string
    button: string
    className?: string
    link?: string
}

export const LearnMore = ({ title, button, className, link }: LearnMoreProps) => {
    const onClickRedirect = () => (window.location.href = link || RISK_INVESTING || '')
    return (
        <div className={cn(styles.container, className)}>
            <h2 className={styles.title}>{title}</h2>
            <Button onClick={onClickRedirect} type="secondary" className={styles.button}>
                {button}
            </Button>
        </div>
    )
}
