import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Form, Popover, Select, Switch, Tabs } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import FormItem from 'antd/lib/form/FormItem'

import { HOME_PAGE } from '../../api/endpoints'

import { useDispatch, useSelector } from '../../reducers/store'

import { addExpert, updateExpert } from '../../actions/experts'

import { EJobTitleNames } from '../../constants/experts'

import { isNotEmptyObject } from '../../utils/data'

import { LanguagesSelectOrder } from '../../components/LanguagesSelectOrder/LanguagesSelectOrder'
import { StatesSelect } from '../../components/StatesSelect/StatesSelect'
import { VideoUpload } from '../../components/ui/VideoUpload/VideoUpload'
import { Dropdown } from '../../components/ui/Dropdown/Dropdown'
import { AddJson } from '../../components/ui/AddJson/AddJson'
import { Button } from '../../components/ui/Button/Button'
import { Input } from '../../components/ui/Input/Input'

import { useExpert, useJobTitles } from '../../hooks/useExperts'
import { usePracticeAreas } from '../../hooks/usePracticeAreas'
import { useLanguages } from '../../hooks/useLanguages'
import { useLawFirms } from '../../hooks/useLawFirms'
import { useToggle } from '../../hooks/useToggle'

import { Expert, JobTitle, practiceLevels } from '../../types/expert'

import styles from './ExpertsAdminEditPage.module.css'
import { VisasSelectOrder } from '../../components/VisasSelectOrder/VisasSelectOrder'
import { PhotoUpload } from '../../components/ui/PhotoUpload/PhotoUpload'
import useGtmPageLoad from '../../hooks/useGtmPageLoad'

export const ExpertsAdminEditPage = () => {
    useGtmPageLoad('ExpertsAdminEditPage')
    const dispatch = useDispatch()
    useLanguages()
    const languages = useSelector(store => store.languages.languages)
    const history = useHistory()
    const [form] = Form.useForm()
    const ref = useRef(null)
    const [locales, setLocales] = useState<Record<string, any>>({})
    const { id } = useParams<{ id: string }>()
    const [showPopover, togglePopover] = useToggle()
    const [active, setActive] = useState('en')
    const isNew = id === 'new'
    useExpert(id)
    useLawFirms()
    useJobTitles()
    usePracticeAreas()
    const { t } = useTranslation()
    const practiceAreas = useSelector(store => store.experts.practiceAreas)?.map(item => ({ value: item.id, label: item.code }))
    const jobTitles = useSelector(store => store.experts.jobTitles)?.map(item => ({ value: item.id, label: item.name }))
    const selectedExpert = useSelector(store => store.experts.selectedExpert)
    const firms = useSelector(store => store.lawFirms.firms)
    const firmsOptions = firms?.map(item => ({ value: item.id, label: item.name }))
    const timezoneOptions = new Array(27).fill(0)?.map((_, index) => {
        const value = index - 12
        return { value, label: `UTC ${value > 0 ? '+' : ''}${value}` }
    })
    const handleCancel = () => {
        history.push('/experts-admin')
    }
    useEffect(() => {
        if (selectedExpert) {
            setLocales(selectedExpert.localizations ?? {})
        }
    }, [selectedExpert])

    const durationOption = useMemo(
        () => [
            {
                value: 15,
                label: '15 min'
            },
            {
                value: 30,
                label: '30 min'
            },
            {
                value: 60,
                label: '60 min'
            }
        ],
        []
    )

    const [selectedJobTitle, setSelectedJobTitle] = useState<string>()

    const handleChangeJobTitle = (value: any, item: any) => {
        setSelectedJobTitle(item.label)
    }

    useEffect(() => setSelectedJobTitle(selectedExpert?.jobTitle?.name || ''), [selectedExpert?.jobTitle?.name])

    const isImmigration = selectedJobTitle === EJobTitleNames.ImmigrationAttorney
    const isEducation = selectedJobTitle === EJobTitleNames.EducationExpert

    const experienceTextValue = useMemo(() => (isEducation ? 'Relevant Practice' : 'Attorney Practice'), [isEducation])
    const experienceTextValueLocale = useMemo(() => t(experienceTextValue), [experienceTextValue, t])

    const handleAdd = (values: Record<string, any>) => {
        const experienceFill = isNotEmptyObject(values.experience) && !!Object.values(values.experience)?.[0]
        const expert: Partial<Expert> = {
            ...values,
            experience: experienceFill ? { [experienceTextValue]: Object.values(values.experience)?.[0] } : {},
            visaPracticeSet: isImmigration && isNotEmptyObject(values.visaPracticeSet) ? values.visaPracticeSet : null,
            licensedIn: isImmigration ? values.licensedIn : null,
            lawFirm: values.firmId
                ? {
                      id: values.firmId
                  }
                : (null as any),
            jobTitle: {
                id: values.jobTitleId
            } as any as JobTitle,
            practiceAreasSet: isEducation && isNotEmptyObject(values.practiceAreasSet) ? values.practiceAreasSet : null
        }
        Object.entries(expert.localizations ?? {}).forEach(([key, value]) => {
            value.locale = key
        })

        dispatch(addExpert({ expert, onSuccess: handleCancel }))
    }

    const handleUpdate = (values: Record<string, any>) => {
        if (values.localizations) {
            Object.keys(values.localizations).forEach(key => {
                values.localizations[key].locale = key
            })
        }
        const experienceFill = isNotEmptyObject(values.experience) && !!Object.values(values.experience)?.[0]

        const expert: Expert = {
            ...(selectedExpert as Expert),
            ...values,
            localizations: {
                ...locales,
                ...values.localizations
            },
            experience: experienceFill ? { [experienceTextValue]: Object.values(values.experience)?.[0] } : {},
            visaPracticeSet: isImmigration && isNotEmptyObject(values.visaPracticeSet) ? values.visaPracticeSet : null,
            licensedIn: isImmigration ? values.licensedIn : null,
            lawFirm: values.firmId
                ? {
                      id: values.firmId
                  }
                : (null as any),
            jobTitle: {
                id: values.jobTitleId
            } as any as JobTitle,
            practiceAreasSet: isEducation && isNotEmptyObject(values.practiceAreasSet) ? values.practiceAreasSet : null
        }

        dispatch(updateExpert({ expert, onSuccess: handleCancel }))
    }

    const handleAddLocale = (value: any, item: any) => {
        setLocales({
            ...locales,
            [value]: { locale: value }
        })
        setActive(value)
        togglePopover()
    }

    const opts = languages
        .filter(l => {
            return l.code !== 'en' && !Object.keys(locales).includes(l.code)
        })
        .map((l: any) => ({ value: l.code, label: l.name }))
    const Content = <Dropdown options={opts as any} onSelect={handleAddLocale} />

    const Add = <span ref={ref}>+ Add</span>

    if (!selectedExpert && !isNew) {
        return null
    }

    const tabs = Object.entries(locales).map(([key, item]) => {
        return (
            <Tabs.TabPane key={key} tab={languages.find(l => l.code === key)?.name}>
                <Form
                    className={styles.form}
                    layout="vertical"
                    form={form}
                    onFinish={isNew ? handleAdd : handleUpdate}
                    initialValues={selectedExpert}
                >
                    <FormItem label="First Name" name={['localizations', key, 'firstName']} required wrapperCol={{ span: 8 }}>
                        <Input />
                    </FormItem>
                    <FormItem label="Last Name" name={['localizations', key, 'lastName']} required wrapperCol={{ span: 8 }}>
                        <Input />
                    </FormItem>
                    <FormItem label="City" name={['localizations', key, 'location']} wrapperCol={{ span: 8 }}>
                        <Input />
                    </FormItem>
                    <FormItem label="Biography" name={['localizations', key, 'biography']} wrapperCol={{ span: 8 }}>
                        <TextArea rows={9} />
                    </FormItem>
                    <FormItem
                        label="Experience Description"
                        name={['localizations', key, 'experienceDescription']}
                        wrapperCol={{ span: 8 }}
                    >
                        <TextArea rows={9} />
                    </FormItem>
                    <FormItem label="Videos" name={['localizations', key, 'video']} wrapperCol={{ span: 8 }}>
                        <VideoUpload />
                    </FormItem>
                </Form>
            </Tabs.TabPane>
        )
    })

    const defaultPane = (
        <Tabs.TabPane key="en" tab="English" closable={false}>
            <Form
                className={styles.form}
                form={form}
                layout="vertical"
                onFinish={isNew ? handleAdd : handleUpdate}
                initialValues={selectedExpert}
                autoComplete="off"
            >
                <FormItem label="First Name" name="firstName" required wrapperCol={{ span: 8 }}>
                    <Input />
                </FormItem>
                <FormItem label="Last Name" name="lastName" required wrapperCol={{ span: 8 }}>
                    <Input />
                </FormItem>
                <FormItem
                    label="Title"
                    name="jobTitleId"
                    required
                    wrapperCol={{ span: 8 }}
                    initialValue={selectedExpert?.jobTitle?.id.toString()}
                >
                    <Dropdown options={jobTitles} onChange={handleChangeJobTitle} />
                </FormItem>
                <Form.Item label="Company" name="firmId" wrapperCol={{ span: 8 }} initialValue={selectedExpert?.lawFirm?.id.toString()}>
                    <Dropdown options={firmsOptions} />
                </Form.Item>
                <FormItem label="Languages" name="languages" wrapperCol={{ span: 8 }}>
                    <LanguagesSelectOrder fieldName="lang" />
                </FormItem>
                {isImmigration && (
                    <FormItem label="Visa Practice" name="visaPracticeSet" wrapperCol={{ span: 8 }}>
                        <VisasSelectOrder fieldName="visa" />
                    </FormItem>
                )}
                {isEducation && (
                    <FormItem label="Practice area" name="practiceAreasSet" wrapperCol={{ span: 8 }}>
                        <AddJson keyRenderer={<Dropdown options={practiceAreas} />} valueRenderer={<Dropdown options={practiceLevels} />} />
                    </FormItem>
                )}
                <FormItem label="City" name="location" wrapperCol={{ span: 8 }}>
                    <Input />
                </FormItem>
                <FormItem label="State" name="state" wrapperCol={{ span: 8 }}>
                    <StatesSelect />
                </FormItem>
                <Form.Item label="Time Zone" name="timezone" wrapperCol={{ span: 8 }}>
                    <Dropdown options={timezoneOptions} />
                </Form.Item>
                <FormItem label="First Consultation Fee" name="firstConsultationFee" wrapperCol={{ span: 8 }}>
                    <Input prefix="$" placeholder="0" type="number" />
                </FormItem>
                <FormItem label="First Consultation Duration" name="firstConsultationDuration" wrapperCol={{ span: 8 }}>
                    <Dropdown options={durationOption} />
                </FormItem>
                <FormItem label="Special Offer" name="specialOffer" wrapperCol={{ span: 8 }}>
                    <Input prefix={'−$'} placeholder="0" type="number" />
                </FormItem>
                {isImmigration && (
                    <FormItem label="Authorized In" name="authorizedIn" wrapperCol={{ span: 8 }}>
                        <StatesSelect
                            mode="multiple"
                            allStates={
                                <Select.Option key="all" value="all">
                                    Federal Level (all states)
                                </Select.Option>
                            }
                        />
                    </FormItem>
                )}
                {isImmigration && (
                    <FormItem label="Licensed in" name="licensedIn" wrapperCol={{ span: 8 }}>
                        <StatesSelect
                            mode="multiple"
                            allStates={
                                <Select.Option key="all" value="all">
                                    Federal Level (all states)
                                </Select.Option>
                            }
                        />
                    </FormItem>
                )}
                <FormItem label="Biography" name="biography" wrapperCol={{ span: 8 }}>
                    <TextArea rows={9} />
                </FormItem>
                <FormItem label="Experience" name="experience" wrapperCol={{ span: 8 }}>
                    <AddJson
                        maxFields={1}
                        keyRenderer={<Input value={experienceTextValueLocale} disabled={true} />}
                        valueRenderer={<Input placeholder="10" type="number" min={1} max={99} style={{ width: 100 }} />}
                    />
                </FormItem>
                <FormItem label="Experience Description" name="experienceDescription" wrapperCol={{ span: 8 }}>
                    <TextArea rows={9} />
                </FormItem>
                <FormItem label="Photos" name="photos" wrapperCol={{ span: 8 }}>
                    <PhotoUpload preview type="image" />
                </FormItem>
                <FormItem label="Videos" name="video" wrapperCol={{ span: 8 }}>
                    <VideoUpload />
                </FormItem>
                <FormItem label="Published" name="published" wrapperCol={{ span: 8 }} valuePropName="checked">
                    <Switch className={styles.switch} defaultChecked={selectedExpert?.published} />
                </FormItem>
            </Form>
        </Tabs.TabPane>
    )

    const handleTabEdit = (e: string | React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>, action: 'add' | 'remove') => {
        if (action === 'add') {
            togglePopover()
        } else {
            const newLocales = { ...locales }
            delete newLocales[e as string]
            setLocales(newLocales)
        }
    }

    return (
        <div className={styles.container}>
            <Popover content={Content} visible={showPopover} onVisibleChange={togglePopover} />
            <Tabs type="editable-card" activeKey={active} onEdit={handleTabEdit} addIcon={Add} onChange={key => setActive(key)}>
                {defaultPane}
                {tabs}
            </Tabs>
            {selectedExpert?.id && (
                <a className={styles.link} href={`${HOME_PAGE}/experts/${selectedExpert?.id}`}>
                    {`${HOME_PAGE}/experts/${selectedExpert?.id}`}
                </a>
            )}
            <div className={styles.footer}>
                <Button type="ghost" onClick={handleCancel} className={styles.button}>
                    Cancel
                </Button>
                <Button onClick={form.submit} style={{ width: 'auto' }} className={styles.button}>
                    Save
                </Button>
            </div>
        </div>
    )
}
