import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from '../reducers/store'
import { WikiArticle } from '../types/blog'
import { sendDelete, sendGet, sendPost, sendPut } from '../utils/api'

export const fetchWikiArticles = createAsyncThunk<{ articles: WikiArticle[] }, void, { state: RootState }>(
    'fetchWikiArticles',
    async (_, thunkAPI) => {
        const state = thunkAPI.getState() as RootState
        const params = new URLSearchParams()
        state.wiki.filters?.forEach(filter => {
            params.append(`_${filter.path}.${filter.operation}`, filter.value)
        })
        if (state.wiki.sorters?.length) {
            params.append(`sort`, state.projects.sorters.map(item => `${item.fieldName}.${item.direction}`).join(','))
        }
        const response = await sendGet(`/wiki?${params.toString()}`, thunkAPI)
        return { articles: response }
    }
)

export const fetchWikiArticle = createAsyncThunk('fetchWikiArticle', async (id: string, thunkAPI) => {
    const response = await sendGet(`/wiki/${id}`, thunkAPI)
    return { article: response }
})

export const addWikiArticle = createAsyncThunk<
    { article: WikiArticle },
    { article: Partial<WikiArticle>; onSuccess: () => void },
    { state: RootState }
>('addWikiArticle', async ({ article, onSuccess }, thunkAPI) => {
    const response = await sendPost('/wiki', thunkAPI, article)
    onSuccess()
    thunkAPI.dispatch(fetchWikiArticles())
    return { article: response }
})

export const updateWikiArticle = createAsyncThunk<
    { article: WikiArticle },
    { article: Partial<WikiArticle>; onSuccess: () => void },
    { state: RootState }
>('updateWikiArticle', async ({ article, onSuccess }, thunkAPI) => {
    const response = await sendPut(`/wiki/${article.id}`, thunkAPI, article)
    onSuccess()
    return { article: response }
})

export const deleteWikiArticles = createAsyncThunk<{}, string[], { state: RootState }>('deleteWikiArticles', async (ids, thunkAPI) => {
    await sendDelete('/wiki', thunkAPI, ids)
    thunkAPI.dispatch(fetchWikiArticles())
    return {}
})
