import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { Form as AntdForm, CheckboxOptionType, RadioChangeEvent } from 'antd'
import styles from './ProjectFilters.module.css'
import { PageHeader } from '../PageHeader/PageHeader'
import { Form } from '../ui/Form/Form'
import { Dropdown } from '../ui/Dropdown/Dropdown'
import { StatesSelect } from '../StatesSelect/StatesSelect'
import { FloatFooter } from '../FloatFooter/FloatFooter'
import { Button } from '../ui/Button/Button'
import { useDispatch, useSelector } from '../../reducers/store'
import { addFilter, removeFilter, removeAllFilters } from '../../reducers/projects'
import { FilterOperation } from '../../types/filter'
import { fetchProjects } from '../../actions'
import { RadioGroup } from '../ui/RadioGroup/RadioGroup'
import { useProjectCategories } from '../../hooks/useProjects'
import { useTranslation } from 'react-i18next'
import { cn } from '../../utils/cn'
import { useReqInvestments } from '../../hooks/useReqInvestment'
import { ProjectType } from '../../types/project'

export interface ProjectFiltersProps {
    onClose?: () => void
}

export const ProjectFilters = ({ onClose }: ProjectFiltersProps) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    useProjectCategories()
    useReqInvestments()
    const projects = useSelector(store => store.projects.projects)
    const lang = useSelector(store => store.session.lang) ?? 'en'
    const [allProjects, setAllProjects] = useState(projects)
    useEffect(() => {
        if (projects?.length > 0 && allProjects?.length === 0) {
            setAllProjects(projects)
        }
    }, [projects, allProjects])
    const projectsTotal = useMemo(() => projects?.filter(projectItem => projectItem.published)?.length || 0, [projects])
    const filters = useSelector(store => store.projects?.filters)
    const categories = useSelector(store => store.projects?.categories)
    const categoriesOptions = categories?.map(item => ({ value: item.id, label: item?.localizations[lang]?.name || item.name }))
    const reqInvestments = useSelector(store => store.projects?.requiredInvestment)
    const amountOfInvestment = reqInvestments?.map(item => ({ value: item.id, label: item?.localizations[lang]?.name || item.name }))

    const valueCategories = useMemo(() => filters.find(item => item.path === 'categories.id')?.value, [filters])

    const valueInvestmentType = useMemo(() => filters.find(item => item.path === 'investmentType.id')?.value, [filters])

    const valueProjectType = useMemo(() => filters.find(item => item.path === 'projectType.id')?.value, [filters])

    const valueRequiredInvestment = useMemo(() => filters.find(item => item.path === 'requiredInvestment.id')?.value, [filters])

    const investmentType = useMemo(
        () => [
            { value: 'loan', label: t('Loan') },
            { value: 'preferred_equity', label: t('Preferred Equity') },
            { value: 'equity', label: t('Equity') },
            { value: 'seniorLoan', label: t('Senior Loan') },
            { value: 'mezzanineLoan', label: t('Mezzanine Loan') }
        ],
        [t]
    )

    const approvedPetitions: CheckboxOptionType[] = useMemo(
        () => [
            { value: 'any', label: t('Any type') },
            { value: 'i526', label: 'I-526' },
            { value: 'i829', label: 'I-829' }
        ],
        [t]
    )

    const projectTypes = useMemo(
        () => [
            { value: ProjectType.RegionalCenter, label: t('Regional Center') },
            { value: ProjectType.DirectProject, label: t('Direct Project') }
        ],
        [t]
    )

    const handleState = useCallback(
        (states: string[]) => {
            if (states.includes('all')) {
                dispatch(removeFilter('state'))
            } else {
                dispatch(
                    addFilter({
                        path: 'state',
                        operation: FilterOperation.IN,
                        value: states
                    })
                )
            }
            dispatch(fetchProjects())
        },
        [dispatch]
    )

    const handleAmount = useCallback(
        (reqInvest: string[]) => {
            if (!reqInvest.length) {
                dispatch(removeFilter('requiredInvestment.id'))
            } else {
                dispatch(
                    addFilter({
                        path: 'requiredInvestment.id',
                        operation: FilterOperation.IN,
                        value: reqInvest
                    })
                )
            }
            dispatch(fetchProjects())
        },
        [dispatch]
    )

    const handleInvestmentType = useCallback(
        (investType: string[]) => {
            if (!investType.length) {
                dispatch(removeFilter('investmentType.id'))
            } else {
                dispatch(
                    addFilter({
                        path: 'investmentType.id',
                        operation: FilterOperation.IN,
                        value: investType
                    })
                )
            }
            dispatch(fetchProjects())
        },
        [dispatch]
    )

    const handleProjectType = useCallback(
        (pType: string[]) => {
            if (!pType.length) {
                dispatch(removeFilter('projectType.id'))
            } else {
                dispatch(
                    addFilter({
                        path: 'projectType.id',
                        operation: FilterOperation.IN,
                        value: pType
                    })
                )
            }
            dispatch(fetchProjects())
        },
        [dispatch]
    )

    const handleCategory = useCallback(
        (states: string[]) => {
            if (!states.length) {
                dispatch(removeFilter('categories.id'))
            } else {
                dispatch(
                    addFilter({
                        path: 'categories.id',
                        operation: FilterOperation.IN,
                        value: states
                    })
                )
            }
            dispatch(fetchProjects())
        },
        [dispatch]
    )

    const handleApprovedPetitions = useCallback(
        (e: RadioChangeEvent) => {
            if (e.target.value === 'any') {
                dispatch(removeFilter(`approvedPetitions`))
            } else {
                dispatch(
                    addFilter({
                        path: `approvedPetitions`,
                        operation: FilterOperation.SPECIAL_RC,
                        value: e.target.value
                    })
                )
            }
            dispatch(fetchProjects())
        },
        [dispatch]
    )

    const handleClearAll = useCallback(() => {
        dispatch(removeAllFilters())
        dispatch(fetchProjects())
    }, [dispatch])

    return (
        <>
            <PageHeader className={cn(styles.header, styles.mobile)} title={t('Back')} onBack={onClose} />
            <div className={styles.container}>
                <h2 className={cn(styles.name, styles.mobile)}>{t('Search for EB-5 Projects')}</h2>
                <Form layout="vertical" className={styles.form}>
                    <AntdForm.Item label={t('Location, U.S. states')}>
                        <StatesSelect
                            allStates
                            projects={allProjects}
                            mode="multiple"
                            value={filters.find(item => item.path === 'state')?.value ?? ['all']}
                            onChange={handleState}
                        />
                    </AntdForm.Item>
                    <AntdForm.Item label={t('Amount of investment')}>
                        <Dropdown
                            options={amountOfInvestment}
                            mode="multiple"
                            maxTagCount="responsive"
                            placeholder={t('All Categories')}
                            onChange={handleAmount}
                            value={valueRequiredInvestment}
                        />
                    </AntdForm.Item>
                    <AntdForm.Item label={t('Project Type')}>
                        <Dropdown
                            options={projectTypes}
                            mode="multiple"
                            maxTagCount="responsive"
                            placeholder={t('All Types')}
                            onChange={handleProjectType}
                            value={valueProjectType}
                        />
                    </AntdForm.Item>
                    <AntdForm.Item label={t('Category of Industry')}>
                        <Dropdown
                            options={categoriesOptions}
                            mode="multiple"
                            maxTagCount="responsive"
                            placeholder={t('All Categories')}
                            onChange={handleCategory}
                            value={valueCategories}
                        />
                    </AntdForm.Item>
                    <AntdForm.Item label={t('Investment Type')}>
                        <Dropdown
                            options={investmentType}
                            mode="multiple"
                            maxTagCount="responsive"
                            placeholder={t('All Categories invest')}
                            onChange={handleInvestmentType}
                            value={valueInvestmentType}
                        />
                    </AntdForm.Item>
                    {!(valueProjectType?.length === 1 && valueProjectType?.includes(ProjectType.DirectProject)) && (
                        <AntdForm.Item label={t('RC with approved petitions')}>
                            <RadioGroup
                                onChange={handleApprovedPetitions}
                                value={filters.find(item => item.path === 'approvedPetitions')?.value ?? 'any'}
                                options={approvedPetitions}
                            />
                        </AntdForm.Item>
                    )}
                    <div className={styles.desktop}>
                        <Button type="ghost">
                            {!!projectsTotal && t('Show N projects', { count: projectsTotal })}
                            {!projectsTotal && t('Nothing found')}
                        </Button>
                        {!!filters.length && (
                            <Button type="ghost" onClick={handleClearAll}>
                                {t('Clear filters')}
                            </Button>
                        )}
                    </div>
                </Form>
                <FloatFooter className={styles.mobile}>
                    <Button onClick={onClose} disabled={!projectsTotal}>
                        {!!projectsTotal && t('Show N projects', { count: projectsTotal })}
                        {!projectsTotal && t('Nothing found')}
                    </Button>
                    {!!filters.length && (
                        <Button type="ghost" onClick={handleClearAll}>
                            {t('Clear filters')}
                        </Button>
                    )}
                </FloatFooter>
            </div>
        </>
    )
}
