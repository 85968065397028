import { Form, Popover, Tabs } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { Button } from '../../components/ui/Button/Button'
import { Input } from '../../components/ui/Input/Input'
import { useDispatch, useSelector } from '../../reducers/store'
import styles from './ProposalsDirectAdminEditPage.module.css'
import { useHistory, useParams } from 'react-router-dom'
import FormItem from 'antd/lib/form/FormItem'
import { Dropdown } from '../../components/ui/Dropdown/Dropdown'
import { useLanguages } from '../../hooks/useLanguages'
import { useToggle } from '../../hooks/useToggle'
import { useProposalDirect } from '../../hooks/useProposalsDirect'
import { ProposalDirect } from '../../types/proposalsDitect'
import { addProposalDirect, updateProposalDirect } from '../../actions/jobs'
import useGtmPageLoad from '../../hooks/useGtmPageLoad'

export const ProposalsDirectAdminEditPage = () => {
    useGtmPageLoad('ProposalsDirectAdminEditPage')
    const dispatch = useDispatch()
    useLanguages()
    const languages = useSelector(store => store.languages.languages)
    const history = useHistory()
    const [form] = Form.useForm()
    const ref = useRef(null)
    const [locales, setLocales] = useState<Record<string, any>>({})
    const { id } = useParams<{ id: string }>()
    const [showPopover, togglePopover] = useToggle()
    const [active, setActive] = useState('en')
    const isNew = id === 'new'
    useProposalDirect(id)
    const selectedProposal = useSelector(store => store.jobs.selectedProposalDirect)

    const handleCancel = () => {
        history.push('/proposals-direct')
    }

    const statusOptions = [
        {
            value: 'SELECTED',
            label: 'SELECTED'
        },
        {
            value: 'APPOINTMENT_CONFIRMED',
            label: 'APPOINTMENT_CONFIRMED'
        },
        {
            value: 'APPOINTMENT_COMPLETED',
            label: 'APPOINTMENT_COMPLETED'
        }
    ]

    useEffect(() => {
        if (selectedProposal) {
            setLocales(selectedProposal.localizations ?? {})
        }
    }, [selectedProposal])

    const handleAdd = (values: Record<string, any>) => {
        const proposal: ProposalDirect = {
            ...values,
            id: '',
            status: values.status,
            manuallyStatus: values.manuallyStatus,
            localizations: values.localizations
        }
        Object.entries(proposal.localizations ?? {}).forEach(([key, value]) => {
            value.locale = key
        })
        dispatch(addProposalDirect({ proposal, onSuccess: handleCancel }))
    }

    const handleUpdate = (values: Record<string, any>) => {
        if (values.localizations) {
            Object.keys(values.localizations).forEach(key => {
                values.localizations[key].locale = key
            })
        }
        const proposal: ProposalDirect = {
            ...(selectedProposal as ProposalDirect),
            status: values.status,
            manuallyStatus: values.manuallyStatus,
            localizations: {
                ...locales,
                ...values.localizations
            }
        }
        dispatch(updateProposalDirect({ proposal, onSuccess: handleCancel }))
    }

    const handleAddLocale = (value: any) => {
        setLocales({
            ...locales,
            [value]: { locale: value }
        })
        setActive(value)
        togglePopover()
    }

    const opts = languages
        ?.filter(l => {
            return l.code !== 'en' && !Object.keys(locales).includes(l.code)
        })
        ?.map((l: any) => ({ value: l.code, label: l.name }))
    const Content = <Dropdown options={opts as any} onSelect={handleAddLocale} />

    const Add = <span ref={ref}>+ Add</span>

    if (!selectedProposal && !isNew) {
        return null
    }

    const selectedProposalType = (): string => {
        if (selectedProposal?.regionalCenter?.id) {
            return 'RC'
        }
        if (selectedProposal?.project?.id) {
            return 'EB-project'
        }
        if (selectedProposal?.expert?.id) {
            return 'expert'
        }
        return ''
    }

    const selectedProposalName = (): string => {
        if (selectedProposal?.regionalCenter?.id) {
            return selectedProposal.regionalCenter.name
        }
        if (selectedProposal?.project?.id) {
            return selectedProposal.project.name
        }
        if (selectedProposal?.expert?.id) {
            return selectedProposal.expert.firstName + ' ' + selectedProposal.expert.lastName
        }
        return ''
    }

    const tabs = Object.entries(locales)?.map(([key]) => {
        return (
            <Tabs.TabPane key={key} tab={languages.find(l => l.code === key)?.name}>
                <Form
                    className={styles.form}
                    layout="vertical"
                    form={form}
                    onFinish={isNew ? handleAdd : handleUpdate}
                    initialValues={selectedProposal}
                >
                    <FormItem label="Status text" name={['localizations', key, 'manuallyStatus']} wrapperCol={{ span: 8 }}>
                        <Input value={selectedProposal?.manuallyStatus} />
                    </FormItem>
                </Form>
            </Tabs.TabPane>
        )
    })

    const defaultPane = (
        <Tabs.TabPane key="en" tab="English" closable={false}>
            <Form
                className={styles.form}
                form={form}
                layout="vertical"
                onFinish={isNew ? handleAdd : handleUpdate}
                initialValues={selectedProposal}
                autoComplete="off"
            >
                <FormItem label="User" wrapperCol={{ span: 8 }}>
                    <Input value={selectedProposal?.id} disabled={true} />
                </FormItem>
                <FormItem label="Proposal type" wrapperCol={{ span: 8 }}>
                    <Input value={selectedProposalType()} disabled={true} />
                </FormItem>
                <FormItem label="Proposal name" wrapperCol={{ span: 8 }}>
                    <Input value={selectedProposalName()} disabled={true} />
                </FormItem>
                <FormItem label="Status" name="status" wrapperCol={{ span: 8 }}>
                    <Dropdown options={statusOptions} />
                </FormItem>
                <FormItem label="Status text" name="manuallyStatus" wrapperCol={{ span: 8 }}>
                    <Input />
                </FormItem>
            </Form>
        </Tabs.TabPane>
    )

    const handleTabEdit = (e: string | React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>, action: 'add' | 'remove') => {
        if (action === 'add') {
            togglePopover()
        } else {
            const newLocales = { ...locales }
            delete newLocales[e as string]
            setLocales(newLocales)
        }
    }

    return (
        <div className={styles.container}>
            <Popover content={Content} visible={showPopover} onVisibleChange={togglePopover} />
            <Tabs type="editable-card" activeKey={active} onEdit={handleTabEdit} addIcon={Add} onChange={key => setActive(key)}>
                {defaultPane}
                {tabs}
            </Tabs>
            <div className={styles.footer}>
                <Button type="ghost" onClick={handleCancel} className={styles.button}>
                    Cancel
                </Button>
                <Button onClick={form.submit} style={{ width: 'auto' }} className={styles.button}>
                    Save
                </Button>
            </div>
        </div>
    )
}
