import React, { ReactNode, CSSProperties } from 'react'
import { Spin } from 'antd'
import { cn } from '../../../utils/cn'
import { ReactComponent as Spinner } from '../../../assets/loader.svg'
import styles from './Button.module.css'

export interface ButtonProps {
    icon?: ReactNode
    children?: ReactNode
    disabled?: boolean
    className?: string
    style?: CSSProperties
    loading?: boolean
    type?: 'primary' | 'secondary' | 'ghost'
    submit?: boolean
    shape?: 'circle' | 'rounded'
    onClick?: () => void
}

export const Button = ({
    children = '',
    icon,
    disabled = false,
    type = 'primary',
    className,
    loading,
    submit,
    shape = 'rounded',
    onClick,
    ...rest
}: ButtonProps) => {
    return (
        <button
            className={cn(className, styles.container, {
                [styles.focus]: type === 'primary' || !type,
                [styles.secondary]: type === 'secondary',
                [styles.ghost]: type === 'ghost',
                [styles.disabled]: disabled,
                [styles.loading]: !!loading,
                [styles.circle]: shape === 'circle'
            })}
            type={submit ? 'submit' : 'button'}
            onClick={onClick}
            {...rest}
        >
            {loading && <Spin className={styles.spin} indicator={<Spinner />} />}
            {!loading && icon && <div className={styles.icon}>{icon}</div>}
            {!loading && children}
        </button>
    )
}
