import React, { useCallback, useMemo } from 'react'
import { CheckboxOptionType, Form, Modal } from 'antd'
import styles from './JobsEditPage.module.css'
import TextArea from 'antd/lib/input/TextArea'
import { RadioGroup } from '../../components/ui/RadioGroup/RadioGroup'
import { CountrySelect } from '../../components/CountrySelect/CountrySelect'
import { Input } from '../../components/ui/Input/Input'
import { FloatButton } from '../../components/ui/Button/FloatButton'
import { useForm } from 'antd/lib/form/Form'
import { useDispatch } from '../../reducers/store'
import { addJob } from '../../actions/jobs'
import { Job } from '../../types/jobs'
import { useHistory } from 'react-router-dom'
import { Rule } from 'antd/lib/form'
import { useTranslation } from 'react-i18next'
import { Button } from '../../components/ui/Button/Button'
import { cn } from '../../utils/cn'
import { useToggle } from '../../hooks/useToggle'
import { SuccessPage } from '../SuccessPage/SuccessPage'
import useGtmPageLoad from '../../hooks/useGtmPageLoad'

export const JobsEditPage = () => {
    useGtmPageLoad('JobsEditPage')
    const [form] = useForm()
    const dispatch = useDispatch()
    const history = useHistory()
    const { t } = useTranslation()
    const [modal, toggleModal] = useToggle(false)
    const isDesktop = window.screen.width > 960

    const caregoryOptions: CheckboxOptionType[] = [
        { value: 'immigration', label: t('Immigration') },
        { value: 'education', label: t('Education') },
        { value: 'other', label: t('Other') }
    ]

    const handleSubmit = useCallback(
        (values: Record<string, any>) => {
            ;(window as any).dataLayer.push({ event: 'postRequest' })
            dispatch(
                addJob({
                    job: values as Job,
                    onSuccess: () => {
                        if (!isDesktop) {
                            history.push('/success/create-project')
                        } else {
                            toggleModal()
                        }
                    }
                })
            )
        },
        [history, dispatch, isDesktop, toggleModal]
    )

    const validator = useMemo<Rule[]>(() => {
        return [
            {
                required: true,
                message: t('This field is required')
            }
        ]
    }, [t])

    return (
        <div className={styles.container}>
            <h1 className={styles.title}>{t('Create a Request')}</h1>
            <Form className={styles.form} form={form} onFinish={handleSubmit} layout="vertical" requiredMark={false}>
                <Form.Item label={t('Name')} name="title" rules={validator}>
                    <Input placeholder={t('Enter a name of your request')} className={styles.input} />
                </Form.Item>
                <Form.Item label={t('Description')} name="description" rules={validator}>
                    <TextArea rows={6} placeholder={t('Please give us more details regarding your request')} className={styles.textarea} />
                </Form.Item>
                <Form.Item label={t('Category')} name="category" initialValue="immigration" rules={validator}>
                    <RadioGroup options={caregoryOptions} className={cn(styles.input, styles.radio)} />
                </Form.Item>
                <Form.Item label={t('Your Country of Origin')} name="country" rules={validator}>
                    <CountrySelect className={styles.input} />
                </Form.Item>
            </Form>
            <Button onClick={form.submit} className={cn(styles.desktop, styles.block)}>
                {t('Post Request')}
            </Button>
            <FloatButton className={styles.mobile} onClick={form.submit} title={t('Post Request')} />
            <Modal visible={modal} footer={null} onCancel={toggleModal} className={styles.modal} closable={false} maskClosable={false}>
                <SuccessPage page={false} forceType="create-project" />
            </Modal>
        </div>
    )
}
