import React, { useCallback, useEffect } from 'react'
import { cn } from '../../utils/cn'
import { useTranslation } from 'react-i18next'
import { useDispatch } from '../../reducers/store'
import { clearError } from '../../reducers/session'

import { Or } from '../../components/ui/Or/Or'
import { useMe } from '../../hooks/useMe'

import { HOME_PAGE, PRIVACY_PAGE, TERMS_PAGE } from '../../api/endpoints'

import { ReactComponent as GoogleIcon } from '../../assets/google.svg'
import { ReactComponent as AppleButtonIcon } from '../../assets/apple-button.svg'

import styles from './LoginInit.module.css'

interface AppleSignInResponse
    extends CustomEvent<{
        authorization: {
            code: string
            id_token: string
            state: string
            /**
             * Exists only on first auth, use id_token instead
             */
            user?: any
        }
    }> {}

export interface LoginInitProps {
    /**
     * Showed at the bottom of a page
     */
    embedded?: boolean
    /**
     * Showed in popup
     */
    popup?: boolean
    expired?: boolean
    className?: string
    onStep?: (step: 'email' | 'phone') => void
}

/**
 * Initial state for login
 *
 * Handles Google and Apple sign ins internally or calls onStep for email and phone
 */
export function LoginInit({ embedded, popup, className, onStep, expired }: LoginInitProps) {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const [user, fetchMe] = useMe()
    const handleAppleSignInSuccess: EventListener = useCallback(
        (res: AppleSignInResponse) => {
            dispatch(clearError())
            localStorage.setItem('token', res.detail.authorization.id_token)
            fetchMe()
        },
        [fetchMe, dispatch]
    ) as any

    const handleGoogleSignInSuccess = useCallback(
        (a: any) => {
            dispatch(clearError())
            localStorage.setItem('token', a.getAuthResponse().id_token)
            fetchMe()
        },
        [fetchMe, dispatch]
    )
    const handleGoogleRef = (ref: HTMLDivElement) => {
        ;(window as any).gapi?.load('auth', () => {
            const auth2 = (window as any).gapi?.auth2?.init({
                client_id: '225812984309-vtqc10ceu4m5ovd8lcpm3t0qdjtulvfa.apps.googleusercontent.com',
                cookiepolicy: 'single_host_origin',
                onsuccess: handleGoogleSignInSuccess
            })
            auth2.attachClickHandler(ref, {}, handleGoogleSignInSuccess)
        })
    }

    const handleAppleRef = (ref: HTMLButtonElement) => {
        const appleID = (window as any).AppleID
        if (appleID) {
            appleID.auth.init({
                clientId: process.env.REACT_APP_APPLE_SERVICE_ID,
                scope: 'name email',
                redirectURI: HOME_PAGE,
                state: 'zone',
                usePopup: true
            })
        }
    }

    useEffect(() => {
        document.addEventListener('AppleIDSignInOnSuccess', handleAppleSignInSuccess)
        return () => {
            document.removeEventListener('AppleIDSignInOnSuccess', handleAppleSignInSuccess)
        }
    }, [handleAppleSignInSuccess])

    const handleAppleSignIn = () => {
        ;(window as any).dataLayer.push({ event: 'loginClick', loginMethod: 'apple' })
        const appleID = (window as any).AppleID
        appleID?.auth.signIn()
    }

    return (
        <div>
            <h1 className={cn(styles.title, { [styles.titleEmbedded]: !!embedded || !!expired })}>
                {embedded && t('Log In to See Full Profile')}
                {!embedded && t('Log In With')}
            </h1>
            <div className={styles.block}>
                <div className={styles.magicLink}>
                    <button
                        className={styles.button}
                        onClick={() => {
                            ;(window as any).dataLayer.push({ event: 'loginClick', loginMethod: 'email' })
                            onStep?.('email')
                        }}
                    >
                        {t('Email')}
                    </button>
                    <button
                        className={cn(styles.button, styles.buttonLast)}
                        onClick={() => {
                            ;(window as any).dataLayer.push({ event: 'loginClick', loginMethod: 'phone' })
                            onStep?.('phone')
                        }}
                    >
                        {t('Cellphone')}
                    </button>
                </div>
                <Or className={!!popup ? styles.white : undefined} />
                <div className={styles.buttonApple}>
                    <button ref={handleAppleRef} onClick={handleAppleSignIn} className={styles.apple}>
                        <AppleButtonIcon style={{ minWidth: 140, height: 42 }} />
                    </button>
                </div>
                <div
                    ref={handleGoogleRef}
                    className={styles.buttonGoogle}
                    onClick={() => {
                        ;(window as any).dataLayer.push({ event: 'loginClick', loginMethod: 'google' })
                    }}
                >
                    <GoogleIcon className={styles.icon} />
                    {t('Sign in with Google')}
                </div>
            </div>
            {popup && (
                <p className={styles.p}>
                    <span>By logging In, you agree to our </span>
                    <a className={styles.link} href={TERMS_PAGE!}>
                        Terms & Conditions
                    </a>
                    <span> and </span>
                    <a className={styles.link} href={PRIVACY_PAGE!}>
                        Privacy Policy
                    </a>
                </p>
            )}
        </div>
    )
}
