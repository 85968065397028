import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Form as AntdForm, CheckboxOptionType, RadioChangeEvent } from 'antd'
import styles from './ExpertFilters.module.css'
import { PageHeader } from '../PageHeader/PageHeader'
import { Form } from '../ui/Form/Form'
import { StatesSelect } from '../StatesSelect/StatesSelect'
import { FloatFooter } from '../FloatFooter/FloatFooter'
import { Button } from '../ui/Button/Button'
import { useDispatch, useSelector } from '../../reducers/store'
import { addFilter, removeFilter, removeAllFilters } from '../../reducers/experts'
import { FilterOperation } from '../../types/filter'
import { RadioGroup } from '../ui/RadioGroup/RadioGroup'
import { fetchExperts } from '../../actions/experts'
import { Dropdown } from '../ui/Dropdown/Dropdown'
import { Expert } from '../../types/expert'
import { useTranslation } from 'react-i18next'
import { useVisas } from '../../hooks/useVisa'
import { getLocaleValue } from '../../utils/string'
import { cn } from '../../utils/cn'
import { EJobTitleCodes } from '../../constants/experts'
import { usePracticeAreas } from '../../hooks/usePracticeAreas'
import { EMPTY_ARRAY } from '../../constants/common'

export interface ExpertFiltersProps {
    onClose?: () => void
}

export const ExpertFilters = ({ onClose }: ExpertFiltersProps) => {
    useVisas()
    usePracticeAreas()
    const experts = useSelector(store => store.experts.experts)
    const locale = useSelector(store => store.session.lang)
    const visas = useSelector(store => store.experts.visas).map(item => ({
        value: item.code,
        shortLabel: item.label,
        label: (
            <div>
                <span className={styles.visaType}>{item.label}</span>
                <span>{getLocaleValue(item, 'description', locale)}</span>
            </div>
        )
    }))
    const practiceAreas = useSelector(store => store.experts.practiceAreas).map(item => ({
        value: item.code,
        shortLabel: item.code,
        label: item.code,
        id: item.id
    }))
    const [allExperts, setAllExperts] = useState<Expert[]>([])
    const [searchExpertType, setSearchExpertType] = useState<string>('any')
    const { t } = useTranslation()
    const languages = useSelector(store => store.languages.languages)?.map(item => ({
        value: item.code,
        label: getLocaleValue(item, 'name', locale)
    }))
    useEffect(() => {
        if (experts.length > 0 && allExperts.length === 0) {
            setAllExperts(experts)
            const availableVisas: string[] = []
            experts.forEach(item => {
                const expertVisas = item.visaPracticeSet ? Object.keys(item.visaPracticeSet) : EMPTY_ARRAY
                expertVisas.forEach(expertVisa => {
                    if (!availableVisas.includes(expertVisa)) {
                        availableVisas.push(expertVisa)
                    }
                })
            })
        }
    }, [experts, allExperts.length])
    const expertsTotal = useMemo(() => experts?.filter(expertsItem => expertsItem.published)?.length || 0, [experts])

    const categoryType: CheckboxOptionType[] = useMemo(
        () => [
            { value: 'any', label: t('Any amount') },
            { value: 'immigration', label: t('Immigration') },
            { value: 'education', label: t('Education') }
        ],
        [t]
    )
    const filters = useSelector(store => store.experts.filters)
    const dispatch = useDispatch()

    const handleState = useCallback(
        (states: string[]) => {
            if (states.includes('all')) {
                dispatch(removeFilter('state'))
            } else {
                dispatch(
                    addFilter({
                        path: 'state',
                        operation: FilterOperation.IN,
                        value: states
                    })
                )
            }
            dispatch(fetchExperts())
        },
        [dispatch]
    )

    const handleCategory = useCallback(
        (e: RadioChangeEvent) => {
            dispatch(removeFilter('visaPracticeSet.id'))
            dispatch(removeFilter('practiceAreasSet.id'))
            if (e.target.value === 'any') {
                setSearchExpertType('any')
                dispatch(removeFilter('jobTitle'))
            }
            if (e.target.value === 'immigration') {
                setSearchExpertType('immigration')
                dispatch(removeFilter('jobTitle'))
                dispatch(
                    addFilter({
                        path: 'jobTitle',
                        operation: FilterOperation.IN,
                        value: EJobTitleCodes.ImmigrationAttorney
                    })
                )
            }
            if (e.target.value === 'education') {
                setSearchExpertType('education')
                dispatch(removeFilter('jobTitle'))
                dispatch(
                    addFilter({
                        path: 'jobTitle',
                        operation: FilterOperation.IN,
                        value: EJobTitleCodes.EducationExpert
                    })
                )
            }
            dispatch(fetchExperts())
        },
        [dispatch]
    )

    const handleClearAll = useCallback(() => {
        dispatch(removeAllFilters())
        dispatch(fetchExperts())
    }, [dispatch])

    const handleLanguage = useCallback(
        (languages: string[]) => {
            if (languages.includes('any')) {
                dispatch(removeFilter('languages.id'))
            } else if (!languages.length) {
                dispatch(removeFilter('languages.id'))
            } else {
                dispatch(
                    addFilter({
                        path: 'languages.id',
                        operation: FilterOperation.IN,
                        value: languages
                    })
                )
            }
            dispatch(fetchExperts())
        },
        [dispatch]
    )

    const handleVisa = useCallback(
        (languages: string[]) => {
            if (languages.includes('any')) {
                dispatch(removeFilter('visaPracticeSet.id'))
                dispatch(removeFilter('practiceAreasSet.id'))
            } else if (!languages.length) {
                dispatch(removeFilter('visaPracticeSet.id'))
                dispatch(removeFilter('practiceAreasSet.id'))
            } else {
                dispatch(
                    addFilter({
                        path: 'visaPracticeSet.id',
                        operation: FilterOperation.IN,
                        value: languages
                    })
                )
            }
            dispatch(fetchExperts())
        },
        [dispatch]
    )

    const handlePracticeAreas = useCallback(
        (areas: string[]) => {
            if (areas.includes('any')) {
                dispatch(removeFilter('practiceAreasSet.id'))
                dispatch(removeFilter('visaPracticeSet.id'))
            } else if (!languages.length) {
                dispatch(removeFilter('practiceAreasSet.id'))
                dispatch(removeFilter('visaPracticeSet.id'))
            } else {
                const areasId = practiceAreas.filter(item => areas?.includes(item.value))?.map(item => item?.id)
                dispatch(
                    addFilter({
                        path: 'practiceAreasSet.id',
                        operation: FilterOperation.IN,
                        value: areasId
                    })
                )
            }
            dispatch(fetchExperts())
        },
        [dispatch, practiceAreas, languages]
    )

    const categoryImmigrationFilter = filters.find(item => item.path === 'jobTitle' && item.value === EJobTitleCodes.ImmigrationAttorney)
    const categoryEducationFilter = filters.find(item => item.path === 'jobTitle' && item.value === EJobTitleCodes.EducationExpert)
    const categoryValueAny = categoryImmigrationFilter && categoryEducationFilter
    const categoryValueImmigration = categoryImmigrationFilter && 'immigration'
    const categoryValueEducation = categoryEducationFilter && 'education'

    return (
        <>
            <PageHeader className={styles.header} title={t('Back')} onBack={onClose} />
            <div className={styles.container}>
                <h2 className={cn(styles.name, styles.mobile)}>{t('Search for specialists')}</h2>
                <Form layout="vertical" className={styles.form}>
                    <AntdForm.Item label={t('Category')}>
                        <RadioGroup
                            className={styles.radio}
                            onChange={handleCategory}
                            value={categoryValueAny || categoryValueImmigration || categoryValueEducation || 'any'}
                            options={categoryType}
                        />
                    </AntdForm.Item>
                    <AntdForm.Item label={t('Language')}>
                        <Dropdown
                            placeholder={t('Any Language')}
                            options={languages}
                            mode="multiple"
                            defaultValue={filters.find(item => item.path === 'languages.id')?.value}
                            onChange={handleLanguage}
                        />
                    </AntdForm.Item>
                    {searchExpertType === 'immigration' && (
                        <AntdForm.Item label={t('Visa Practice')}>
                            <Dropdown
                                optionLabelProp="shortLabel"
                                placeholder={t('Any Visas')}
                                options={visas}
                                mode="multiple"
                                defaultValue={filters.find(item => item.path === 'visaPracticeSet.id')?.value}
                                onChange={handleVisa}
                            />
                        </AntdForm.Item>
                    )}
                    {searchExpertType === 'education' && (
                        <AntdForm.Item label={t('Practice areas')}>
                            <Dropdown
                                optionLabelProp="shortLabel"
                                placeholder={t('Any Areas')}
                                options={practiceAreas}
                                mode="multiple"
                                defaultValue={filters.find(item => item.path === 'practiceAreasSet.id')?.value}
                                onChange={handlePracticeAreas}
                            />
                        </AntdForm.Item>
                    )}
                    <AntdForm.Item label={t('Location, U.S. states')}>
                        <StatesSelect
                            allStates
                            mode="multiple"
                            value={filters.find(item => item.path === 'state')?.value ?? ['all']}
                            onChange={handleState}
                        />
                    </AntdForm.Item>
                    <div className={styles.desktop}>
                        <Button disabled={!expertsTotal} type="ghost">
                            {!!expertsTotal && t('Show N specialists', { count: expertsTotal })}
                            {!expertsTotal && t('Nothing found')}
                        </Button>
                        {!!filters.length && (
                            <Button type="ghost" onClick={handleClearAll}>
                                {t('Clear filters')}
                            </Button>
                        )}
                    </div>
                </Form>
                <FloatFooter className={styles.mobile}>
                    <Button onClick={onClose} disabled={!expertsTotal}>
                        {!!expertsTotal && t('Show N specialists', { count: expertsTotal })}
                        {!expertsTotal && t('Nothing found')}
                    </Button>
                    {!!filters.length && (
                        <Button type="ghost" onClick={handleClearAll}>
                            {t('Clear filters')}
                        </Button>
                    )}
                </FloatFooter>
            </div>
        </>
    )
}
