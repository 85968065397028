import { useEffect } from 'react'
import { fetchWikiArticle, fetchWikiArticles } from '../actions/wiki'
import { clearSelected, removeAllFilters, addFilter } from '../reducers/wiki'
import { useDispatch } from '../reducers/store'
import { Filter } from '../types/filter'

export function useWikiArticles(filter?: Filter) {
    const dispatch = useDispatch()
    useEffect(() => {
        if (filter) {
            dispatch(addFilter(filter))
        }
        dispatch(fetchWikiArticles())
        return () => {
            dispatch(removeAllFilters())
        }
    }, [dispatch, filter])
}

export function useWikiArticle(id?: string) {
    const dispatch = useDispatch()
    useEffect(() => {
        if (id && id !== 'new') {
            dispatch(fetchWikiArticle(id))
        }
        return () => {
            dispatch(clearSelected())
        }
    }, [id, dispatch])
}
