import { createSlice } from '@reduxjs/toolkit'
import { pages } from '../mock'

export const pagesReducer = createSlice({
    name: 'pages',
    initialState: {
        pages,
        password: ''
    },
    reducers: {}
})
