import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getMe } from '../actions/auth'
import { fetchNotifications, readAllNotifications, readNotification } from '../actions/notifications'
import { ScheduleCallRequest } from '../types/calendly'
import { Notification } from '../types/notifications'
import { User } from '../types/person'
import { addJob, fetchJob } from '../actions/jobs'
import { EError } from '../constants/error'

export interface ISessionError {
    errorCode: EError
    errorMessage?: string
}

type sessionSlice = {
    token?: string
    user?: User
    requestedEmail?: string
    requestedProject?: string
    showChat?: boolean
    scheduledCallPayload: ScheduleCallRequest
    lang: string
    notifications: Notification[]
    error?: ISessionError
}

export const sessionReducer = createSlice({
    name: 'session',
    initialState: {
        scheduledCallPayload: {},
        lang: localStorage.getItem('lang') ?? 'en',
        notifications: []
    } as sessionSlice,
    reducers: {
        setToken: (state, action: PayloadAction<{ token: string }>) => {
            state.token = action.payload.token
        },
        clearToken: state => {
            state.token = ''
            state.user = undefined
            state.requestedEmail = undefined
        },
        setRequestedEmail: (state, action: PayloadAction<{ requestedEmail: string }>) => {
            state.requestedEmail = action.payload.requestedEmail
        },
        toggleChat: state => {
            state.showChat = !state.showChat
        },
        addCallPayload: (state, action: PayloadAction<Partial<ScheduleCallRequest>>) => {
            const scheduledCallPayload = { ...state.scheduledCallPayload }
            Object.entries(action.payload).forEach(([key, value]) => {
                scheduledCallPayload[key as keyof ScheduleCallRequest] = value
            })
            state.scheduledCallPayload = scheduledCallPayload
        },
        clearCallPayload: state => {
            state.scheduledCallPayload = {}
        },
        changeLanguage: (state, action: PayloadAction<{ code: string }>) => {
            state.lang = action.payload.code
        },
        setError: (state, action: PayloadAction<{ error: ISessionError }>) => {
            state.error = action.payload.error
        },
        clearError: state => {
            state.error = undefined
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getMe.fulfilled, (state, action) => {
                state.user = action.payload?.user
                state.lang = localStorage.getItem('lang') || action.payload?.user.language || 'en'
            })
            .addCase(fetchNotifications.fulfilled, (state, action) => {
                state.notifications = action.payload.notifications
            })
            .addCase(readNotification.fulfilled, (state, action) => {
                state.notifications = state.notifications.map(item =>
                    item.id === action.payload.notification.id ? { ...item, read: true } : item
                )
            })
            .addCase(readAllNotifications.fulfilled, state => {
                state.notifications = state.notifications.map(item => ({ ...item, read: true }))
            })
            .addCase(addJob.fulfilled, (state, action) => {
                state.requestedProject = action.payload.job.title
            })
            .addCase(fetchJob.fulfilled, (state, action) => {
                const scheduledCallPayload = { ...state.scheduledCallPayload }
                scheduledCallPayload.jobId = action.payload.job.id
                state.scheduledCallPayload = scheduledCallPayload
            })
    }
})

export const { clearToken, setRequestedEmail, toggleChat, addCallPayload, clearCallPayload, changeLanguage, setError, clearError } =
    sessionReducer.actions
