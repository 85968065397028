import React, { useCallback } from 'react'
import { Project, ProjectType, ProjectTypeValue } from '../../types/project'
import styles from './ProjectCard.module.css'
import { ReactComponent as LocationMarkerIcon } from '../../assets/location-marker.svg'
import { Tags } from '../ui/Tags/Tags'
import { Link, useHistory } from 'react-router-dom'
import { BASE_FILE_URL } from '../../api/endpoints'
import { useProjectTags } from '../../hooks/useProjectTags'
import { useLocalizedStates } from '../../hooks/useLocalizedStates'
import { getLocaleValue } from '../../utils/string'
import { useDispatch, useSelector } from '../../reducers/store'
import { SetAppointmentButton } from '../ui/SetAppointmentButton/SetAppointmentButton'
import { cn } from '../../utils/cn'
import { addCallPayload, setError } from '../../reducers/session'
import { EError } from '../../constants/error'
import { useTranslation } from 'react-i18next'

interface ProjectCardProps {
    project: Project
    photo?: 'small' | 'large'
    className?: string
}

/**
 * Used to show EB-5 projects in lists
 */
export const ProjectCard = ({ project, className, photo = 'large' }: ProjectCardProps) => {
    const { t } = useTranslation()
    const history = useHistory()
    const mainPreview = `${BASE_FILE_URL}/${project.photos[0]}`
    const tags = useProjectTags(project)
    const states = useLocalizedStates()
    const locale = useSelector(store => store.session.lang)
    const address = getLocaleValue(project, 'address', locale)
    const dispatch = useDispatch()

    const handleAppointment = useCallback(() => {
        ;(window as any).dataLayer.push({ event: 'contactRegionalCenter' })
        dispatch(
            addCallPayload({
                projectId: project.id
            })
        )
    }, [dispatch, project.id])

    const projectLink = !(project.limited && project.hideInUsa) ? `/projects/${project.id}` : `/projects`

    const handleProject = useCallback(
        (link: string) => (e: any) => {
            e.stopPropagation()
            if (project.limited && project.hideInUsa && link === '/projects') {
                dispatch(
                    setError({
                        // Status project content not available
                        error: {
                            errorCode: EError.Error423,
                            errorMessage: 'Content not available for your country'
                        }
                    })
                )
            } else {
                history.push(link)
            }
        },
        [dispatch, project.limited, project.hideInUsa, history]
    )

    return (
        <div className={cn(styles.container, className)}>
            <Link
                onClick={handleProject(projectLink)}
                to={projectLink}
                className={cn(styles.imgContainer, { [styles.small]: photo === 'small' })}
            >
                <img src={mainPreview} className={cn(styles.img)} alt="Project logo" />
            </Link>
            <span
                className={cn(styles.projectTypeBadge, {
                    [styles.backBlue]: project.projectType === ProjectType.RegionalCenter,
                    [styles.backViolet]: project.projectType === ProjectType.DirectProject
                })}
            >
                {t(ProjectTypeValue[project.projectType!])}
            </span>
            <Link onClick={handleProject(projectLink)} to={projectLink}>
                <div className={styles.content}>
                    <h2 className={styles.name}>
                        <Link onClick={handleProject(projectLink)} to={projectLink}>
                            {getLocaleValue(project, 'name', locale)}
                        </Link>
                    </h2>
                    {project.projectType !== ProjectType.DirectProject && (
                        <Link
                            onClick={handleProject(`/projects/${project.id}/${project.regionalCenter?.id}`)}
                            to={`/projects/${project.id}/${project.regionalCenter?.id}`}
                            className={styles.regionalCenter}
                        >
                            {getLocaleValue(project.regionalCenter, 'name', locale)}
                        </Link>
                    )}
                    <span className={styles.address}>
                        <LocationMarkerIcon className={styles.locationMarker} />
                        {`${address}, ${states[project.state]}`}
                    </span>
                    <Tags items={tags} />
                </div>
            </Link>
            <SetAppointmentButton
                onClick={handleAppointment}
                className={cn(styles.appointment, styles.desktop)}
                float={false}
                noSubject={true}
                noAssistant={true}
                size="small"
                textFor="regionalCenter"
            />
        </div>
    )
}
