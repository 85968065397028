import { createAsyncThunk } from '@reduxjs/toolkit'
import i18next from 'i18next'
import { RootState } from '../reducers/store'
import { User } from '../types/person'
import { sendGet, sendPost } from '../utils/api'
import { clearError, setError } from '../reducers/session'
import { EError } from '../constants/error'

export const getMe = createAsyncThunk<{ user: User } | undefined, void, { state: RootState }>('getMe', async (_, thunkAPI) => {
    const token = localStorage.getItem('token')
    let userLocation = 'undefined'

    // key is trial 100 000 request
    await fetch('https://api.ipregistry.co/?key=1bf3jpuf4uwd6ygh')
        .then(response => {
            return response.json()
        })
        .then(json => {
            if (!!json.location?.country?.name?.length) {
                userLocation = json.location?.country?.code || 'unknown'
            }
        })
        .catch(error => console.error(error))

    sendPost(`/auth/checkExpiration`, thunkAPI, { token, userLocation: userLocation }).then((response: string) => {
        if (!response?.length) {
            thunkAPI.dispatch(
                setError({
                    error: {
                        errorCode: EError.TokenExpired
                    }
                })
            )
        } else {
            localStorage.setItem('token', response)
            thunkAPI.dispatch(clearError())
        }
    })
    const user: User = await sendGet('/auth/me', thunkAPI)
    const cachedLang = localStorage.getItem('lang')

    if (cachedLang && thunkAPI.getState().session.lang !== cachedLang) {
        i18next.changeLanguage(cachedLang)
    }
    if (!cachedLang && user.language && thunkAPI.getState().session.lang !== user.language) {
        i18next.changeLanguage(user.language)
        localStorage.setItem('lang', user.language)
    }
    return { user }
})

export const updateMe = createAsyncThunk<{ user: User }, { user: User; onSuccess?: () => void }, { state: RootState }>(
    'updateMe',
    async ({ user, onSuccess }, thunkAPI) => {
        const result: User = await sendPost('/auth/me', thunkAPI, user)
        onSuccess?.()
        thunkAPI.dispatch(getMe())
        return { user: result }
    }
)
