import { Filter, Sorter } from '../types/filter'
import { Language } from '../types/i18n'
import { sendDelete, sendGet, sendPost, sendPut } from '../utils/api'

export function languagesGetAll(thunkAPI: any, filters?: Filter[], sorters?: Sorter[]): Promise<Language[]> {
    const params = new URLSearchParams()
    filters?.forEach(filter => {
        params.append(`_${filter.path}.${filter.operation}`, filter.value)
    })
    if (sorters?.length) {
        params.append(`sort`, sorters.map(item => `${item.fieldName}.${item.direction}`).join(','))
    }
    return sendGet(`/languages?${params.toString()}`, thunkAPI)
}

export function languagesGet(langId: string, thunkAPI: any): Promise<Language> {
    return sendGet(`/languages/${langId}`, thunkAPI)
}

export function languagesCreate(lang: Language, thunkAPI: any): Promise<Language> {
    return sendPost(`/languages`, thunkAPI, lang)
}

export function languagesUpdate(lang: Language, thunkAPI: any): Promise<Language> {
    return sendPut(`/languages/${lang.id}`, thunkAPI, lang)
}

export function languagesDelete(ids: string[], thunkAPI: any) {
    return sendDelete(`/languages`, thunkAPI, ids)
}
