import Table, { ColumnsType } from 'antd/lib/table'
import React, { useCallback, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button } from '../../components/ui/Button/Button'
import { useRegionalCenters } from '../../hooks/useRegionalCenters'
import { useSelector } from '../../reducers/store'
import { RegionalCenter } from '../../types/regionalCenter'
import styles from './RegionalCenterAdminPage.module.css'
import { ReactComponent as PlusIcon } from '../../assets/plus.svg'
import { useDispatch } from 'react-redux'
import { deleteRegionalCenters } from '../../actions'
import { ShortText } from '../../components/ui/ShortText/ShortText'
import useGtmPageLoad from '../../hooks/useGtmPageLoad'

export const RegionalCenterAdminPage = () => {
    useGtmPageLoad('RegionalCenterAdminPage')
    useRegionalCenters()
    const dispatch = useDispatch()
    const [selectedItems, setSelectedItems] = useState<string[]>([])
    const data = useSelector(store => store.regionalCenters.centers)

    const renderLink = useCallback((value, item) => {
        return <Link to={`/regional-centers-admin/${item.id}`}>{value}</Link>
    }, [])

    const renderText = useCallback((value: any) => {
        return (
            <ShortText rows={2} expandable={false}>
                {value}
            </ShortText>
        )
    }, [])

    const renderDate = useCallback((value: string) => {
        return new Date(value).toLocaleDateString('en-Us', { day: 'numeric', month: 'long', year: 'numeric' })
    }, [])

    const renderCount = useCallback((_, item) => {
        return item.managers?.length
    }, [])

    const columns: ColumnsType<RegionalCenter> = useMemo(() => {
        return [
            {
                dataIndex: 'name',
                title: 'Name',
                render: renderLink
            },
            { dataIndex: 'city', title: 'City' },
            { dataIndex: 'state', title: 'State' },
            { dataIndex: 'founded', title: 'Founded' },
            { dataIndex: 'states', title: 'States' },
            {
                dataIndex: 'description',
                title: 'Description',
                render: renderText
            },
            { dataIndex: 'timezone', title: 'Time Zone' },
            { dataIndex: 'eb5CompletedProjects', title: 'EB-5 Completed Projects' },
            { dataIndex: 'eb5ProgressProjects', title: 'EB-5 Progress Projects' },
            { dataIndex: 'eb5TotalProjects', title: 'EB-5 Total Projects' },
            { dataIndex: 'i526', title: 'I-526' },
            { dataIndex: 'i829', title: 'I-829' },
            { dataIndex: 'raised', title: 'Raise Capital' },
            { dataIndex: 'endPlanned', title: 'End Planned' },
            {
                dataIndex: 'updated',
                title: 'Updated',
                render: renderDate
            },
            {
                dataIndex: 'managers',
                title: 'Managers',
                render: renderCount
            }
        ]
    }, [renderText, renderDate, renderCount, renderLink])

    const handleDelete = () => {
        dispatch(deleteRegionalCenters(selectedItems))
        setSelectedItems([])
    }

    const handleSelect = (selectedKeys: (string | number)[]) => {
        setSelectedItems(selectedKeys.map(key => (typeof key !== 'string' ? key.toString() : key)))
    }

    return (
        <div className={styles.container}>
            <div className={styles.controls}>
                <Link to="/regional-centers-admin/new">
                    <Button icon={<PlusIcon />} style={{ width: 'auto' }} className={styles.button}>
                        Add
                    </Button>
                </Link>
                <Button style={{ width: 'auto' }} type="ghost" className={styles.button} onClick={handleDelete}>
                    Delete
                </Button>
            </div>
            <div style={{ overflow: 'auto' }}>
                <Table
                    columns={columns}
                    dataSource={data}
                    rowSelection={{ selectedRowKeys: selectedItems, onChange: handleSelect }}
                    rowKey="id"
                />
            </div>
        </div>
    )
}
