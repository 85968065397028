import Table, { ColumnsType } from 'antd/lib/table'
import React, { ChangeEvent, useCallback, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Button } from '../../components/ui/Button/Button'
import { useDispatch, useSelector } from '../../reducers/store'
import styles from './JobsAdminPage.module.css'
import { useAllJobs } from '../../hooks/useJobs'
import { User } from '../../types/person'
import { Job } from '../../types/jobs'
import { ShortText } from '../../components/ui/ShortText/ShortText'
import { Checkbox } from 'antd'
import { Input } from '../../components/ui/Input/Input'
import { addFilter, removeFilter } from '../../reducers/jobs'
import { FilterOperation } from '../../types/filter'
import useGtmPageLoad from '../../hooks/useGtmPageLoad'

export const JobsAdminPage = () => {
    useGtmPageLoad('JobsAdminPage')
    // const [selectedItems, setSelectedItems] = useState<string[]>([])
    const languages = useSelector(store => store.languages.languages)
    const filters = useSelector(store => store.jobs.filters)
    useAllJobs(filters)
    const dispatch = useDispatch()
    const data = useSelector(store => store.jobs.jobs)

    const renderLink = useCallback((value: string, item: Job) => {
        return <Link to={`/jobs-admin/${item.id}`}>{value}</Link>
    }, [])

    const renderUser = useCallback((value: User, item: Job) => {
        return <span>{value.email ?? value.phone}</span>
    }, [])

    const columns: ColumnsType<Job> = useMemo(() => {
        return [
            {
                dataIndex: 'id',
                title: 'Id'
            },
            {
                dataIndex: 'title',
                title: 'Project',
                render: renderLink
            },
            {
                dataIndex: 'user',
                title: 'User',
                render: renderUser
            },
            {
                dataIndex: 'created',
                title: 'Created',
                render: (value: string) => new Date(value).toLocaleString()
            },
            {
                dataIndex: 'category',
                title: 'Category'
            },
            {
                dataIndex: 'read',
                title: 'Read',
                render: (value: boolean) => <Checkbox checked={value} />
            },
            {
                dataIndex: 'user',
                title: 'Language',
                render: (value: User) =>
                    (languages.find(item => item.code === value.language) ?? languages.find(item => item.code === 'en'))?.name
            },
            {
                dataIndex: 'description',
                title: 'Description',
                render: (value: any) => (
                    <div style={{ maxWidth: '300px', marginBottom: '20px' }}>
                        <ShortText rows={4}>{value}</ShortText>
                    </div>
                )
            }
        ]
    }, [renderLink, renderUser, languages])

    // const handleDelete = () => {
    //     setSelectedItems([])
    // }

    const handleSelect = () => {
        // selectedKeys: (string | number)[]
        // setSelectedItems(selectedKeys.map(key => (typeof key !== 'string' ? key.toString() : key)))
    }

    const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.currentTarget.value) {
            dispatch(removeFilter('id'))
        } else {
            dispatch(
                addFilter({
                    operation: FilterOperation.EQUALS,
                    path: 'id',
                    value: e.currentTarget.value
                })
            )
        }
    }

    return (
        <div className={styles.container}>
            <div className={styles.controls}>
                <Button style={{ width: 'auto' }} type="ghost" className={styles.button} disabled>
                    Delete
                </Button>
                <Input placeholder="Search by Id" style={{ width: '260px' }} onChange={handleSearch} />
            </div>
            <div style={{ overflow: 'auto' }}>
                <Table columns={columns} dataSource={data} rowSelection={{ onChange: handleSelect }} rowKey="id" />
            </div>
        </div>
    )
}
