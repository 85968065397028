import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import { useWikiArticles } from '../../hooks/useWikiArticles'
import { useSelector } from '../../reducers/store'
import { FilterOperation } from '../../types/filter'
import { getLocaleValue } from '../../utils/string'
import styles from './KnowledgeCenterCategoriesPage.module.css'
import useGtmPageLoad from '../../hooks/useGtmPageLoad'

export const KnowledgeCenterCategoriesPage = () => {
    useGtmPageLoad('KnowledgeCenterCategoriesPage')
    const { t } = useTranslation()
    const { section } = useParams<{ section: string }>()
    const locale = useSelector(store => store.session.lang)
    const filter = useMemo(() => {
        return { path: 'section', operation: FilterOperation.EQUALS, value: section }
    }, [section])
    useWikiArticles(filter)
    const articles = useSelector(store => store.wiki.articles)
    return (
        <div className={styles.container}>
            <h1 className={styles.title}>
                {section === 'immigration' && t('Immigration to USA')}
                {section === 'regionalCenter' && t('Regional Center')}
                {section === 'education' && t('Education in USA')}
            </h1>
            <ul>
                {articles
                    .filter(item => item.root)
                    .map(item => (
                        <li key={item.id}>
                            <Link to={`/wiki-articles/${item.id}`} className={styles.link}>
                                {getLocaleValue(item, 'title', locale)}
                            </Link>
                        </li>
                    ))}
            </ul>
        </div>
    )
}
