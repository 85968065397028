import { Rate } from 'antd'
import React, { useCallback, useMemo } from 'react'
import { Expert } from '../../../types/expert'
import { getAverageRating } from '../../../utils/experts'
import styles from './ShortReview.module.css'
import { ReactComponent as StarFilled } from '../../../assets/star-filled.svg'
import { ReactComponent as StarEmpty } from '../../../assets/star-empty.svg'
import { ReactComponent as StarHalf } from '../../../assets/star-half.svg'
import { useTranslation } from 'react-i18next'
import { cn } from '../../../utils/cn'

export interface ShortReviewProps {
    expert: Expert
    supertShort?: boolean
    className?: string
    hint?: (hint: string) => React.ReactNode
}

const point = 1
const halfPoint = 0.49

export const ShortReview = ({ expert, supertShort, hint, className }: ShortReviewProps) => {
    const { t } = useTranslation()
    const star = useCallback(({ index, value }: any) => {
        if (index + point <= value) {
            return <StarFilled className={styles.star} />
        }
        if (index + halfPoint <= value) {
            return <StarHalf className={styles.star} />
        }

        return <StarEmpty className={styles.star} />
    }, [])

    const approvedReviews = useMemo(() => expert?.reviews?.filter(item => item.approved) || [], [expert])

    const hintText = !approvedReviews?.length
        ? t('No reviews')
        : approvedReviews?.length > 1
        ? t('N reviews', { count: approvedReviews?.length ?? 0 })
        : t('N reviews_0', { count: approvedReviews?.length ?? 0 })
    const average = getAverageRating(expert)
    if (supertShort) {
        return (
            <div className={cn(styles.reviews, className)}>
                <StarFilled className={styles.star} />
                <span className={styles.average}>{(average || 0).toPrecision(2)}</span>
                <span className={styles.reviewsTotal}>{hint?.(hintText) ?? hintText}</span>
            </div>
        )
    }

    return (
        <div className={cn(styles.reviews, className)}>
            <Rate disabled value={average} character={star} allowHalf />
            <span className={styles.reviewsTotal}>{hint?.(hintText) ?? hintText}</span>
        </div>
    )
}
