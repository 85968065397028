import React, { useState } from 'react'
import { Button, message, Upload } from 'antd'
import { UploadOutlined } from '@ant-design/icons'

import { UploadChangeParam } from 'antd/lib/upload'
import { RcFile, UploadFile } from 'antd/lib/upload/interface'

import { Input } from '../Input/Input'

import { BASE_URL, BASE_FILE_URL } from '../../../api/endpoints'

import { ReactComponent as RemoveIcon } from '../../../assets/trash.svg'

import { File } from '../../../types/file'
import { IPhoto } from '../../../types/expert'
import styles from './PhotoUpload.module.css'

export interface PhotoUploadProps {
    preview?: boolean
    single?: boolean
    value?: IPhoto[]
    mode?: 'id' | 'object'
    type?: 'image' | 'video' | 'other'
    onChange?: (fileId: IPhoto[]) => void
    onSuccess?: (fileId: IPhoto[]) => void
    onRemove?: (fileId: IPhoto[]) => void
}

export const PhotoUpload = ({
    preview,
    single = false,
    value = [],
    type = 'other',
    mode = 'id',
    onChange,
    onSuccess,
    onRemove,
    ...rest
}: PhotoUploadProps) => {
    const token = localStorage.getItem('token')
    const headers = token ? { Authorization: `Bearer ${token}` } : undefined

    const [files, setFiles] = useState<IPhoto[]>(value)

    const handleChange = (info: UploadChangeParam<UploadFile<File>>) => {
        if (info?.file?.status === 'done') {
            const file: IPhoto = { photo: info.file?.response?.name || '', ord: 0 }
            const newFiles: IPhoto[] = [...files, file]
            setFiles(newFiles)
            onChange?.(newFiles)
            onSuccess?.(newFiles)
        }
    }

    const handleChangeOrder = (file: IPhoto, e: any) => {
        const itemToChange = files?.find(item => item.photo === file.photo)
        const newFiles: IPhoto[] = [...files]
        if (itemToChange) {
            const indexItem = newFiles.indexOf(itemToChange)
            newFiles[indexItem] = { ...file, ord: e.target.value }
        }
        setFiles(newFiles)
        onChange?.(newFiles)
        onSuccess?.(newFiles)
    }

    const handleRemove = (photo: IPhoto) => {
        const newFiles = files.filter(item => item.photo !== photo.photo)
        setFiles(newFiles)
        onChange?.(newFiles)
    }

    const handleValidation = (file: RcFile) => {
        let isValid = true
        if (type === 'image' && !file.type.startsWith('image')) {
            isValid = false
        }
        if (type === 'video' && file.type.startsWith('video')) {
            isValid = false
        }
        if (isValid) {
            return true
        } else {
            message.error('Unsupported format')
            return Upload.LIST_IGNORE
        }
    }

    return (
        <>
            <Upload
                name="file"
                beforeUpload={handleValidation}
                action={`${BASE_URL}/files`}
                headers={headers}
                maxCount={10}
                multiple={!single}
                onChange={handleChange}
                className={styles.container}
                showUploadList={false}
                {...rest}
            >
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
            <div className={styles.photoList}>
                {!!files?.length &&
                    files.map((item: IPhoto, index) => (
                        <div className={styles.photoItem} key={index}>
                            <img key={index} className={styles.photoImg} src={`${BASE_FILE_URL}/${item.photo}`} alt="Expert" />
                            <div className={styles.photoText}>{item.photo}</div>
                            <Input
                                className={styles.photoOrder}
                                placeholder="Order"
                                type="number"
                                value={item.ord}
                                onChange={(e: any) => handleChangeOrder(item, e)}
                            />
                            <Button className={styles.photoButton} shape="circle" type="ghost" onClick={() => handleRemove(item)}>
                                <RemoveIcon />
                            </Button>
                        </div>
                    ))}
            </div>
        </>
    )
}
