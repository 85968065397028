import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { clearToken } from '../../reducers/session'
import { useDispatch } from '../../reducers/store'
import styles from './ProfileMenu.module.css'

export interface ProfileMenuProps {
    onSignOut?: () => void
}

export const ProfileMenu = ({ onSignOut }: ProfileMenuProps) => {
    const history = useHistory()
    const dispatch = useDispatch()

    const handleSignOut = () => {
        const instance = (window as any).gapi?.auth2?.getAuthInstance?.()
        instance?.signOut()
        localStorage.removeItem('token')
        dispatch(clearToken())
        history.push('/login')
        onSignOut?.()
    }

    return (
        <nav className={styles.container}>
            <ul className={styles.items}>
                <li className={styles.item}>Notifications</li>
                <Link to="/settings" onClick={onSignOut}>
                    <li className={styles.item}>Settings</li>
                </Link>
                <li className={styles.item} onClick={handleSignOut}>
                    Sign Out
                </li>
            </ul>
        </nav>
    )
}
