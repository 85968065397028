import { useEffect } from 'react'
import { fetchExpert, fetchExperts, fetchJobTitle, fetchJobTitles, fetchMyExperts } from '../actions/experts'
import { addFilter, clearSelected, clearSelectedTitle, removeAllFilters } from '../reducers/experts'
import { useDispatch } from '../reducers/store'
import { Filter } from '../types/filter'

export function useExperts(filter?: Filter, keepOnUnmount?: boolean) {
    const dispatch = useDispatch()
    useEffect(() => {
        if (filter) {
            dispatch(addFilter(filter))
        }
        dispatch(fetchExperts())
        return () => {
            if (!keepOnUnmount) {
                dispatch(removeAllFilters())
            }
        }
    }, [dispatch, filter, keepOnUnmount])
}

export function useExpert(expertId: string) {
    const dispatch = useDispatch()
    useEffect(() => {
        if (expertId !== 'new') {
            dispatch(fetchExpert(expertId))
        }
        return () => {
            dispatch(clearSelected())
        }
    }, [expertId, dispatch])
}

export function useMyExperts() {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchMyExperts())
    }, [dispatch])
}

export function useJobTitles() {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchJobTitles())
    }, [dispatch])
}

export function useJobTitle(id: string) {
    const dispatch = useDispatch()
    useEffect(() => {
        if (id !== 'new') {
            dispatch(fetchJobTitle(id))
        }
        return () => {
            dispatch(clearSelectedTitle())
        }
    }, [id, dispatch])
}
