import React from 'react'
import { Person } from '../../../types/person'
import { Person as PersonItem } from '../../Person/Person'
import { PersonList } from '../../Person/PersonList'
import { BASE_FILE_URL } from '../../../api/endpoints'

export interface AddPeopleProps {
    value?: Person[]
}

export const AddPeople = ({ value = [] }: AddPeopleProps) => {
    const sortArray = [...value]?.sort((a, b) => (a.ord > b.ord ? 1 : -1))
    return (
        <PersonList>
            {sortArray?.map((item, index) => (
                <PersonItem
                    id={index.toString()}
                    key={index}
                    avatar={item.photo && `${BASE_FILE_URL}/${item.photo}`}
                    name={`${item.firstName ?? ''} ${item.lastName ?? ''}`}
                    description={item.title}
                    details={item.biography}
                />
            ))}
        </PersonList>
    )
}
