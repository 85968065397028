import { useCallback } from 'react'
import { getMe } from '../actions/auth'
import { useDispatch, useSelector } from '../reducers/store'

export function useMe() {
    const dispatch = useDispatch()
    const user = useSelector(store => store.session.user)
    const fetchUser = useCallback(() => {
        if (!user) {
            dispatch(getMe())
        }
    }, [dispatch, user])
    return [user, fetchUser] as const
}
