import { sendDelete, sendGet, sendPost, sendPut } from '../utils/api'
import { ProposalDirect } from '../types/proposalsDitect'

export function proposalsDirectGetAll(thunkAPI: any): Promise<ProposalDirect[]> {
    return sendGet(`/proposals-direct`, thunkAPI)
}

export function proposalsDirectGet(proposalId: string, thunkAPI: any): Promise<ProposalDirect> {
    return sendGet(`/proposals-direct/${proposalId}`, thunkAPI)
}

export function proposalsDirectCreate(proposal: ProposalDirect, thunkAPI: any): Promise<ProposalDirect> {
    return sendPost(`/proposals-direct`, thunkAPI, proposal)
}

export function proposalsDirectDelete(ids: string[], thunkAPI: any) {
    return sendDelete(`/proposals-direct`, thunkAPI, ids)
}

export function proposalsDirectUpdate(proposal: ProposalDirect, thunkAPI: any) {
    return sendPut(`/proposals-direct/${proposal.id}`, thunkAPI, proposal)
}
