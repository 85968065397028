import React, { useEffect, useMemo, useRef } from 'react'
import styles from './Chart.module.css'
import { Chart as ChartJs, DoughnutController, ArcElement, Tooltip, Legend } from 'chart.js'
import { Tag } from 'antd'
import { useTranslation } from 'react-i18next'
import { cn } from '../../utils/cn'

ChartJs.register(DoughnutController, ArcElement, Tooltip, Legend)

export interface ChartProps {
    items: Record<string, number>
}

export const Chart = ({ items }: ChartProps) => {
    /**
     * Charts are not meant to recalculate responsively
     *
     * If this behavior requested check `responsive` flag and `onResize` callback in chart constructor
     */
    const isDesktop = window.screen.width > 960
    const ref = useRef<ChartJs>()
    const { t } = useTranslation()
    const data = useMemo(() => {
        return {
            datasets: [
                {
                    label: '',
                    data: Object.values(items),
                    backgroundColor: Object.values(items).map((_, index) => getColor(index)),
                    borderWidth: 0,
                    cutout: isDesktop ? 65 : 90
                }
            ]
        }
    }, [items, isDesktop])

    const total = items && Object.values(items).reduce((sum, next) => sum + next, 0)

    const handleRef = (e: HTMLCanvasElement) => {
        if (e) {
            if (ref.current) {
                ref.current.destroy()
            }
            ref.current = new ChartJs(e, {
                type: 'doughnut',
                data,
                options: {
                    responsive: false,
                    layout: {
                        padding: {
                            left: isDesktop ? 0 : 20,
                            right: isDesktop ? 0 : 20
                        }
                    }
                }
            })
        }
    }

    useEffect(() => {
        return () => {
            ref.current?.destroy()
        }
    }, [])

    const displayedTotal = total?.toLocaleString('en-US') ?? '0'

    return (
        <div className={styles.container}>
            <div className={styles.canvasContainer}>
                <canvas
                    ref={handleRef}
                    width={isDesktop ? '200px' : undefined}
                    height={isDesktop ? '200px' : undefined}
                    style={isDesktop ? { width: '200px', height: '200px' } : undefined}
                />
                <div className={styles.total}>
                    <div>{t('Total')}</div>
                    <div>${displayedTotal}</div>
                </div>
            </div>
            <ul className={styles.tagList}>
                {Object.entries(items).map(([name, value], index) => {
                    const currency = value.toLocaleString('en-Us', {
                        style: 'currency',
                        currency: 'USD',
                        maximumFractionDigits: 0,
                        minimumFractionDigits: 0
                    })
                    const percentage = Math.round((value / total) * 100)

                    return (
                        <li key={name} className={styles.legendItem}>
                            <Tag className={cn(styles.tag, styles.mobile)} style={{ backgroundColor: getColor(index) }}>
                                {`${t(name)} —`}
                                <span className={styles.currency}>{currency}</span>
                                <span>({percentage}%)</span>
                            </Tag>
                            <div className={cn(styles.desktop, styles.bullet)} style={{ backgroundColor: getColor(index) }} />
                            <div className={cn(styles.desktop, styles.desktopLegend)}>
                                <div>{t(name)}</div>
                                <div>
                                    {currency} <span className={styles.percentage}>{percentage}%</span>
                                </div>
                            </div>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

const colors = [
    '#0EC0F8', // secondary/blue
    '#56D552', // secondary/green
    '#B352D5', // secondary/purple
    '#F87171', // third/red_400
    '#FBBF24', // third/yellow_400
    '#818CF8', // third/indigo_400
    '#A78BFA', // third/purple_400
    '#F472B6', // third/mr.pink_400
    '#60A5FA', // third/blue_400
    '#34D399' // light_green
]

function getColor(index: number) {
    return colors[index % colors.length]
}
