import { Developer } from './developer'
import { File, Video } from './file'
import { RegionalCenter } from './regionalCenter'
import { CategoryIndustry } from './categoryIndustry'
import { ReqInvestment } from './reqInvestment'

/*
 * Project info
 */
export interface Project {
    id: string
    /**
     * Displayed name of the project
     */
    name: string
    /**
     * USA state of the project
     */
    state: string
    /**
     * Address of the projects
     */
    address: string
    /**
     * Regional center curating the project
     */
    regionalCenter: RegionalCenter
    /**
     * Categories for searching project
     */
    categories?: ProjectCategory[]
    /**
     * Required investment
     */
    requiredInvestment: ReqInvestment
    /**
     * Regional Center administrative fee
     */
    administrativeFee: number
    /**
     * Citizon special offer (discount)
     */
    specialOffer: number
    /**
     * Total for Investor
     */
    total: number
    /**
     * Project description
     */
    description: string
    constructionStartPlanned: string
    constructionEndPlanned: string
    constructionStartActual: string
    constructionEndActual: string
    capitalStack: Record<string, number>
    interestRate: string
    investmentTerm: string
    investmentType: string
    investmentTypeDescription: string
    eb5Units: number
    jobsEstimated: number
    jobsRequired: number
    files: File[]
    photos: string[]
    video: Video[]
    /**
     * Project open/closed
     */
    status: 'open' | 'closed'
    proposalManualStatus?: string
    proposalManualStatusLocalizations?: any
    proposalStatus?: string
    /**
     * TEA Project
     */
    tea: boolean
    projectPartner?: Developer[]
    published: boolean
    /**
     * Special offer description
     */
    disclaimer: string
    /**
     * Dont show project for USA users in project card
     */
    hideInUsa?: boolean
    /**
     * Dont show project for USA users in project list
     */
    limited?: boolean
    localizations: Record<
        string,
        Pick<Project, 'name' | 'address' | 'investmentTerm' | 'interestRate' | 'description' | 'files' | 'disclaimer'> & { locale: string }
    >
    /**
     * Type of project
     */
    projectType?: ProjectType
}

/**
 * Possible values od Project Type field
 */
export enum ProjectType {
    RegionalCenter = 'REGIONAL_CENTER',
    DirectProject = 'DIRECT_PROJECT'
    // REGIONAL_CENTER = 'Regional Center',
    // DIRECT_PROJECT = 'Direct Project'
}

export enum ProjectTypeValue {
    REGIONAL_CENTER = 'Regional Center',
    DIRECT_PROJECT = 'Direct Project'
}

/**
 * Tag for searching a project
 */
export interface ProjectCategory {
    id: string
    /**
     * Tag name
     */
    name: string
    /**
     * Tag type
     *
     * TODO: Use colors instead?
     */
    type?: 'open'
    localizations: Record<string, Omit<CategoryIndustry, 'localizations'> & { locale: string }>
}
