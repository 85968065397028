import React from 'react'
import { FloatFooter } from '../../FloatFooter/FloatFooter'
import { Button, ButtonProps } from './Button'

export interface FloatButtonProps extends ButtonProps {
    children?: React.ReactNode
    title?: string
}

export const FloatButton = ({ children, title, className, ...rest }: FloatButtonProps) => {
    const trigger = children ?? <Button {...rest}>{title}</Button>
    return <FloatFooter className={className}>{trigger}</FloatFooter>
}
