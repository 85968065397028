import { Form } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { Rule } from 'rc-field-form/lib/interface'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { Button } from '../../components/ui/Button/Button'
import { FloatButton } from '../../components/ui/Button/FloatButton'
import { Input } from '../../components/ui/Input/Input'
import { useRecaptcha } from '../../hooks/useRecaptcha'
import { setRequestedEmail } from '../../reducers/session'
import { useDispatch, useSelector } from '../../reducers/store'
import { sendPost } from '../../utils/api'
import { cn } from '../../utils/cn'
import { SuccessPage } from '../SuccessPage/SuccessPage'
import styles from './LoginWithEmail.module.css'
import { RECAPTCHA_KEY } from '../../constants/app'

export interface LoginWithEmailProps {
    popup?: boolean
}

export function LoginWithEmail({ popup }: LoginWithEmailProps) {
    const [form] = useForm()
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const history = useHistory()
    const { recaptchaRef, recaptchaV2Token, recaptchaV3Failed, setRecaptchaV3Failed } = useRecaptcha(form.submit)
    const lang = useSelector(store => store.session.lang) ?? 'en'
    const [completed, setCompleted] = useState(false)

    const emailRules: Rule[] = useMemo(
        () => [
            {
                required: true,
                /**
                 * Before changing this to more `correct` regexp please consult with RFC-3696
                 *
                 * @see https://datatracker.ietf.org/doc/html/rfc3696#section-3
                 */
                pattern: /.+@.+/,
                message: t('Please, input correct email'),
                validateTrigger: 'onFinish'
            }
        ],
        [t]
    )

    const handleSubmit = (values: Record<string, string>) => {
        if (recaptchaV2Token) {
            emailSignIn(values.email, recaptchaV2Token, 'v2', lang)
        } else {
            const grecaptcha = (window as any).grecaptcha
            grecaptcha.ready(function () {
                grecaptcha.execute(RECAPTCHA_KEY, { action: 'submit' }).then((token: string) => {
                    emailSignIn(values.email, token, 'v3', lang)
                })
            })
        }
    }

    const emailSignIn = (email: string, token: string, type: 'v2' | 'v3', lang?: string) => {
        dispatch(setRequestedEmail({ requestedEmail: email }))
        sendPost(`/auth/sign-up`, {}, { email, token, type, lang })
            .then(() => {
                if (!popup) {
                    history.push('/success/email')
                } else {
                    setCompleted(true)
                }
            })
            .catch(() => {
                setRecaptchaV3Failed(true)
            })
    }

    if (completed) {
        return (
            <div className={styles.successContainer}>
                <SuccessPage forceType="email" className={styles.success} page={!popup} />
            </div>
        )
    }

    return (
        <div className={styles.container}>
            <Form form={form} onFinish={handleSubmit} requiredMark={false} layout="vertical">
                <h1 className={styles.title}>{t('Log In With Email')}</h1>
                <Form.Item name="email" label={t('We send you private link to EMAIL')} rules={emailRules} className={styles.wesend}>
                    <Input type="email" placeholder="Email" />
                </Form.Item>
                {!popup && <FloatButton className={styles.mobile} onClick={form.submit} title={t('Send Link')} />}
            </Form>
            {popup && (
                <Button onClick={() => form.submit()} className={styles.button}>
                    {t('Send Link')}
                </Button>
            )}
            <span className={styles.captcha}>
                {t('This site is protected by reCAPTCHA and the Google')}
                <a href="https://policies.google.com/privacy">{t('Privacy Policy')}</a>
                <a href="https://policies.google.com/terms">{t('Terms of Service')}</a>
            </span>
            <div className={cn(styles.recaptcha, { [styles.recaptchaHidden]: !recaptchaV3Failed })} ref={recaptchaRef} />
        </div>
    )
}
