import React from 'react'
import { useExpertises, useLanguages } from '../../hooks/useLanguages'
import { useSelector } from '../../reducers/store'
import { Dropdown } from '../ui/Dropdown/Dropdown'
import { AddArray, AddArrayProps } from '../ui/AddArray/AddArray'
import { Input } from '../ui/Input/Input'

export const LanguagesSelectOrder = (props: AddArrayProps) => {
    useLanguages()
    useExpertises()
    const languages = useSelector(store => store.languages.languages)
    const expertises = useSelector(store => store.languages.expertises)
    const keyOptions = languages?.map(item => ({ value: item.code, label: item.name }))
    const valueOptions = expertises?.map(item => ({ value: item.level, label: item.level }))
    return (
        <AddArray
            keyRenderer={<Dropdown options={keyOptions} />}
            valueRenderer={<Dropdown options={valueOptions} />}
            orderRenderer={<Input placeholder="Order" type="number" />}
            {...props}
        />
    )
}
