import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { addDeveloper, fetchDeveloper, fetchDevelopers } from '../actions/developers'
import { Developer } from '../types/developer'
import { Filter, Sorter } from '../types/filter'

type DeveloperSlice = {
    developers: Developer[]
    selectedDeveloper?: Developer
    pending?: boolean
    filters: Filter[]
    sorters: Sorter[]
    lang?: string
}

export const developersReducer = createSlice({
    name: 'developers',
    initialState: {
        developers: [],
        filters: [],
        sorters: [],
        lang: 'en'
    } as DeveloperSlice,
    reducers: {
        changeLanguage: (state, action: PayloadAction<{ code: string }>) => {
            state.lang = action.payload.code
        },
        clearSelected: state => {
            state.selectedDeveloper = undefined
        }
    },
    extraReducers: builder => {
        builder
            .addCase(fetchDevelopers.fulfilled, (state, action) => {
                state.developers = action.payload.developers
            })
            .addCase(fetchDeveloper.fulfilled, (state, action) => {
                state.selectedDeveloper = action.payload.developer
            })
            .addCase(addDeveloper.fulfilled, state => {
                state.selectedDeveloper = undefined
            })
    }
})

export const { clearSelected, changeLanguage } = developersReducer.actions
