import { createAsyncThunk } from '@reduxjs/toolkit'
import { expertsGetAll, expertsCreate, expertsGet, expertsDelete, expertsUpdate } from '../api/experts'
import { RootState } from '../reducers/store'
import { Expert, JobTitle } from '../types/expert'
import { sendDelete, sendGet, sendPost, sendPut } from '../utils/api'
import { Review } from '../types/Review'

export const fetchExperts = createAsyncThunk<{ experts: Expert[] }, void, { state: RootState }>('fetchExperts', async (_, thunkAPI) => {
    const state = thunkAPI.getState() as RootState
    const response = await expertsGetAll(thunkAPI, state.experts.filters, state.experts.sorters)
    return { experts: response }
})

export const fetchExpert = createAsyncThunk('fetchExpert', async (expertId: string, thunkAPI) => {
    const response = await expertsGet(expertId, thunkAPI)
    return { expert: response }
})

export const fetchMyExperts = createAsyncThunk('fetchMyExpertS', async (_, thunkAPI) => {
    const response = await sendGet('/my-experts', thunkAPI)
    return { experts: response }
})

export const addExpert = createAsyncThunk<{ expert: Expert }, { expert: Partial<Expert>; onSuccess: () => void }, { state: RootState }>(
    'addExpert',
    async ({ expert, onSuccess }, thunkAPI) => {
        const response = await expertsCreate(expert as Expert, thunkAPI)
        onSuccess()
        thunkAPI.dispatch(fetchExperts())
        return { expert: response }
    }
)

export const updateExpert = createAsyncThunk<{ expert: Expert }, { expert: Expert; onSuccess: () => void }, { state: RootState }>(
    'updateExpert',
    async ({ expert, onSuccess }, thunkAPI) => {
        const response = await expertsUpdate(expert, thunkAPI)
        onSuccess()
        return { expert: response }
    }
)

export const deleteExperts = createAsyncThunk<{}, string[], { state: RootState }>('deleteFirms', async (ids, thunkAPI) => {
    await expertsDelete(ids, thunkAPI)
    thunkAPI.dispatch(fetchExperts())
    return {}
})

export const addReview = createAsyncThunk<{ review: Review }, { review: Partial<Review>; onSuccess: () => void }, { state: RootState }>(
    'addReview',
    async ({ review, onSuccess }, thunkAPI) => {
        const response = await sendPost(`/experts/${review.expert?.id}/reviews`, thunkAPI, review)
        onSuccess()
        return { review: response }
    }
)

export const fetchJobTitles = createAsyncThunk<{ titles: JobTitle[] }, void, { state: RootState }>(
    'fetchJobTitles',
    async (_, thunkAPI) => {
        const response = await sendGet(`/expert-titles`, thunkAPI)
        return { titles: response }
    }
)

export const fetchJobTitle = createAsyncThunk<{ title: JobTitle }, string, { state: RootState }>('fetchJobTitle', async (id, thunkAPI) => {
    const response = await sendGet(`/expert-titles/${id}`, thunkAPI)
    return { title: response }
})

export const addJobTitle = createAsyncThunk<{ title: JobTitle }, { title: Partial<JobTitle>; onSuccess: () => void }, { state: RootState }>(
    'addJobTitle',
    async ({ title, onSuccess }, thunkAPI) => {
        const response = await sendPost(`/expert-titles`, thunkAPI, title)
        onSuccess?.()
        return { title: response }
    }
)

export const updateJobTitle = createAsyncThunk<
    { title: JobTitle },
    { title: Partial<JobTitle>; onSuccess: () => void },
    { state: RootState }
>('updateJobTitle', async ({ title, onSuccess }, thunkAPI) => {
    const response = await sendPut(`/expert-titles/${title.id}`, thunkAPI, title)
    onSuccess?.()
    return { title: response }
})

export const deleteJobTitle = createAsyncThunk<{}, string[], { state: RootState }>('deleteJobTitles', async (ids, thunkAPI) => {
    await sendDelete(`/expert-titles`, thunkAPI, ids)
    thunkAPI.dispatch(fetchJobTitles())
    return {}
})
