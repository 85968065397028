import React, { useCallback, useEffect, useState } from 'react'
import { cn } from '../../../utils/cn'
import { useTranslation } from 'react-i18next'
import { BottomSheet } from '../BottomSheet/BottomSheet'
import { Button } from '../Button/Button'
import styles from './ReviewBrowser.module.css'
import { ReviewPreview } from './ReviewPreview'
import { Review } from '../../../types/Review'

export interface ReviewBrowserProps {
    reviews: Review[]
    disabled?: boolean
    className?: string
    initialShow?: boolean
    initialIndex?: number
    clickable?: boolean
    hideButton?: boolean
    style?: React.CSSProperties
    onClose?: () => void
}

export const ReviewBrowser = ({
    reviews,
    disabled,
    className,
    initialShow,
    onClose,
    initialIndex,
    style,
    hideButton = false
}: ReviewBrowserProps) => {
    const [show, setShow] = useState(initialShow)
    const [index, setIndex] = useState(initialIndex ?? 0)

    useEffect(() => {
        if (initialShow !== show) {
            setShow(initialShow)
            setIndex(initialIndex ?? 0)
        }
    }, [initialShow, initialIndex, show])

    const { t } = useTranslation()

    const toggleShow = useCallback(() => {
        if (!show) {
            setShow(true)
        } else {
            setShow(false)
            onClose?.()
        }
    }, [show, onClose])

    const handleReview = useCallback(() => {
        if (index + 1 === reviews.length) {
            setIndex(0)
        } else {
            setIndex(index + 1)
        }
    }, [index, reviews.length])

    const content = (
        <div className={styles.container}>{reviews[index] && <ReviewPreview review={reviews[index]} full className={styles.popup} />}</div>
    )

    const footer = (
        <div className={styles.footer}>
            <Button type="ghost" disabled={reviews.length < 2} onClick={handleReview} className={styles.button}>
                {t('Read next review')}
            </Button>
        </div>
    )
    return (
        <BottomSheet show={show} onToggle={toggleShow} content={content} footer={footer} size="dynamic">
            {hideButton ? (
                <></>
            ) : (
                <Button type="ghost" disabled={disabled} className={cn(className, styles.buttonMobile)} style={style}>
                    {t('Read all N reviews', { N: reviews.length, count: reviews.length })}
                </Button>
            )}
        </BottomSheet>
    )
}
