import { Empty } from 'antd'
import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import { ProposalItem } from '../../components/Proposaltem/ProposalItem'
import { ShortText } from '../../components/ui/ShortText/ShortText'
import { StatsTable, StatsTableItem } from '../../components/ui/StatsTable/StatsTable'
import { useJob } from '../../hooks/useJobs'
import { useDispatch, useSelector } from '../../reducers/store'
import { cn } from '../../utils/cn'
import { capitalize, getLocaleValue } from '../../utils/string'
import styles from './JobsDetailsPage.module.css'
import availableOptions from '../../components/CountrySelect/countries.json'
import { AssistantItem } from '../../components/ui/AssistantItem/AssistantItem'
import { useAssistants } from '../../hooks/useAssistants'
import { BottomSheet } from '../../components/ui/BottomSheet/BottomSheet'
import { SetAppointmentButton } from '../../components/ui/SetAppointmentButton/SetAppointmentButton'
import { ReactComponent as Logo } from '../../assets/citizon-logo-secondary.svg'
import { readAllProposals } from '../../actions/jobs'
import { Proposal } from '../../types/jobs'
import useGtmPageLoad from '../../hooks/useGtmPageLoad'

export const JobsDetailsPage = () => {
    useGtmPageLoad('JobsDetailsPage')
    const { id } = useParams<{ id: string }>()
    useJob(id)
    useAssistants()
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const lang = useSelector(store => store.session.lang)
    const languages = useSelector(store => store.languages.languages)
    const job = useSelector(store => store.jobs.selectedJob)
    const assistants = useSelector(store => store.assistants.assistants)
    const proposals = useMemo(() => job?.proposals.filter(proposal => !proposal.initiatedByUser) ?? [], [job?.proposals])
    const proposalExperts = proposals.map(item => item.expert?.id).filter(item => !!item)
    const invited = useMemo(() => {
        return job?.proposals.reduce<Proposal[]>((result, proposal) => {
            const duplicateExpert = result.find(item => item.expert?.id === proposal.expert?.id)
            if (proposal.initiatedByUser && !proposalExperts.includes(proposal.expert?.id) && !duplicateExpert) {
                result.push(proposal)
            }
            return result
        }, [])
    }, [job?.proposals, proposalExperts])
    const table: StatsTableItem[] = useMemo(() => {
        if (!job) {
            return []
        }
        return [
            { name: t('Category'), value: t(capitalize(job.category)) as any },
            { name: t('Country of Origin'), value: t((availableOptions as any)[job.country]) }
        ]
    }, [job, t, languages, lang])

    useEffect(() => {
        if (job?.id) {
            dispatch(readAllProposals({ jobId: job.id }))
        }
    }, [job?.id, dispatch])

    if (!job) {
        return <Empty />
    }

    const assistantMessage = proposals.find(item => item.status === 'ASSISTANT_MESSAGE')
    const assistant = assistants.find(item => item.id === assistantMessage?.assistant?.id)
    const companyMessage = proposals.find(item => item.status === 'COMPANY_MESSAGE')
    const content = assistant && (
        <div>
            <AssistantItem
                title={assistant.name}
                className={styles.assistant}
                message={assistantMessage?.message}
                description="Your personal assistant"
                avatarUrl={assistant.avatarUrl}
            />
        </div>
    )

    const displayedProposals = proposals.filter(proposal => !['ASSISTANT_MESSAGE', 'COMPANY_MESSAGE'].includes(proposal.status))

    return (
        <div className={styles.container}>
            <h1 className={styles.title}>{job.title}</h1>
            <StatsTable items={table} widths={['140px', 'auto']} className={styles.stats} />
            <ShortText className={styles.text} rows={3}>
                {job.description}
            </ShortText>
            {!!invited?.length && (
                <>
                    <h1 className={cn(styles.subtitle, styles.paddingTop)}>
                        {t('Invited By Me')}
                        <span className={styles.total}>{invited?.length ?? 0}</span>
                    </h1>
                    {invited.map(proposal => (
                        <Link to={`/experts/${proposal.expert?.id}`} key={proposal.id}>
                            <ProposalItem proposal={proposal} />
                        </Link>
                    ))}
                </>
            )}
            {displayedProposals.length > 0 && (
                <>
                    <h1 className={cn(styles.subtitle, styles.paddingTop)}>
                        Proposals
                        <span className={styles.total}>{displayedProposals?.length ?? 0}</span>
                    </h1>
                    {displayedProposals.map(proposal => (
                        <ProposalItem proposal={proposal} key={proposal.id} />
                    ))}
                </>
            )}
            {assistant && (
                <div className={styles.assistantContainer}>
                    <BottomSheet
                        destroyOnClose
                        content={content}
                        size="veryLarge"
                        footer={<SetAppointmentButton float={false} noAssistant={true} />}
                    >
                        <AssistantItem
                            avaSize={60}
                            title={assistant.name}
                            hintClassName={styles.hint}
                            ghost
                            short
                            message={assistantMessage?.message}
                            description="Your personal assistant"
                            avatarUrl={assistant.avatarUrl}
                        />
                    </BottomSheet>
                </div>
            )}
            {companyMessage && (
                <div className={styles.assistantContainer}>
                    <AssistantItem
                        avaSize={60}
                        title={t('Citizon Support')}
                        ghost
                        rich
                        hintClassName={styles.hint}
                        message={companyMessage.message}
                        description={t('Your Personal Assistant')}
                        avatarUrl={<Logo />}
                    />
                </div>
            )}
        </div>
    )
}
