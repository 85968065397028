import { Collapse, Skeleton } from 'antd'
import CollapsePanel from 'antd/lib/collapse/CollapsePanel'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactNode } from 'react-markdown'
import { Link, useHistory, useParams } from 'react-router-dom'
import { Button } from '../../components/ui/Button/Button'
import { StatsTable } from '../../components/ui/StatsTable/StatsTable'
import { VideoPlayer } from '../../components/ui/VideoPlayer/VideoPlayer'
import { useExperts } from '../../hooks/useExperts'
import { useVisas } from '../../hooks/useVisa'
import { useWikiArticle } from '../../hooks/useWikiArticles'
import { useSelector } from '../../reducers/store'
import { BlogArticleSection } from '../../types/blog'
import { FilterOperation } from '../../types/filter'
import { cn } from '../../utils/cn'
import { getLocaleValue } from '../../utils/string'
import { ReactComponent as Down } from '../../assets/chevron-down.svg'
import styles from './KnowledgeCenterArticlePage.module.css'
import RichTextEditor from 'react-rte'
import { Video } from '../../types/file'
import { Expert } from '../../types/expert'
import useGtmPageLoad from '../../hooks/useGtmPageLoad'

export const KnowledgeCenterArticlePage = () => {
    useGtmPageLoad('KnowledgeCenterArticlePage')
    const { id } = useParams<{ id: string }>()
    useWikiArticle(id)
    useVisas()
    const history = useHistory()
    const { t } = useTranslation()
    const locale = useSelector(store => store.session.lang)
    const article = useSelector(store => store.wiki.selectedArticle)
    const visas = useSelector(store => store.experts.visas)
    const experts = useSelector(store => store.experts.experts)

    const visa = visas.find(visa => article?.tags?.find(item => visa.code === item))

    const filter = useMemo(() => {
        return visa?.code
            ? {
                  path: 'visaPracticeSet',
                  operation: FilterOperation.CONTAINS,
                  value: visa?.code
              }
            : undefined
    }, [visa?.code])

    useExperts(filter)

    if (!article) {
        return (
            <div className={styles.container}>
                <Skeleton paragraph={{ rows: 12 }} loading />
            </div>
        )
    }

    const handleBrowse = () => {
        if (filter) {
            history.push(`/experts?visa=${filter.value}`)
        } else {
            history.push('/experts')
        }
    }

    const videos = experts?.reduce<Array<Video & { expert: Expert }>>((all, nextExpert) => {
        nextExpert.video?.forEach(item => {
            const matchingVideo = item.tag?.some(videoTag => article.tags?.includes(videoTag))
            if (matchingVideo) {
                all.push({ ...item, expert: nextExpert })
            }
        })
        return all
    }, [])
    const totalVideos = videos.length
    const localeSections: BlogArticleSection[] = getLocaleValue(article, 'content', locale)?.sections
    const displayedSections: BlogArticleSection[] = []
    localeSections?.forEach(item => {
        if (!item.collapsable) {
            displayedSections.push(item)
            return
        }
        const existingSection = displayedSections.find(sec => sec.type === 'collapsable' && sec.collapsable === item.collapsable)
        if (existingSection?.type === 'collapsable') {
            existingSection.content.push(item as any)
        } else {
            displayedSections.push({
                id: (displayedSections.length ?? 0) + 1,
                collapsable: item.collapsable,
                content: [{ ...item, header: undefined }] as any,
                type: 'collapsable',
                header: item.header,
                headerSize: item.headerSize
            })
        }
    })

    return (
        <div className={styles.container}>
            <article className={styles.article}>
                <h1 className={styles.header}>{getLocaleValue(article, 'title', locale)}</h1>
                {localeSections?.find(item => item.header) && (
                    <nav className={cn(styles.navigation, styles.mobile)}>
                        {localeSections
                            .filter(item => item.header)
                            .map(item => (
                                <a
                                    href={`#${item.header}`}
                                    key={item.id}
                                    className={cn(styles.navItem, { [styles.navPadding]: item.headerSize === 'h2' })}
                                >
                                    {item.header}
                                </a>
                            ))}
                    </nav>
                )}
                {displayedSections.map(renderSection)}
                <div className={styles.browseExperts}>
                    <h2 className={styles.browseTitle}>{t('Browse All N Visa Experts', { visaType: visa?.label || 'All' })}</h2>
                    <span className={cn(styles.mobile, styles.browseHint)}>
                        {visa && t('Experts who have at least 10 cases with N Visas', { visaType: visa?.label })}
                        {!visa && t('US visas are divided into 2 main categories Immigrant and non-immigrant.')}
                    </span>
                    <Button type="ghost" onClick={handleBrowse} className={styles.button}>
                        {t('Browse N Experts', { count: experts?.length })}
                    </Button>
                </div>
                {totalVideos > 0 && <h2 className={cn(styles.browseTitle, styles.padding)}>{t('Related Videos')}</h2>}
                {totalVideos > 0 && (
                    <VideoPlayer
                        className={styles.padding}
                        video={videos}
                        dots={false}
                        replaceDescription={index => {
                            const video = videos[index]
                            return (
                                <div className={styles.videoDescription}>
                                    {t('by')}
                                    <Link to={`/experts/${video.expert.id}`}>
                                        {video.expert.firstName} {video.expert.lastName}
                                    </Link>
                                </div>
                            )
                        }}
                    />
                )}
                {totalVideos > 0 && <a className={styles.browseAllVideos}>Browse all {totalVideos} videos →</a>}
            </article>
            {localeSections?.find(item => item.header) && (
                <aside>
                    <nav className={cn(styles.navigation, styles.desktop)}>
                        {localeSections
                            .filter(item => item.header)
                            .map(item => (
                                <a
                                    href={`#${item.header}`}
                                    key={item.id}
                                    className={cn(styles.navItem, { [styles.navPadding]: item.headerSize === 'h2' })}
                                >
                                    {item.header}
                                </a>
                            ))}
                    </nav>
                </aside>
            )}
        </div>
    )
}

function renderSection(item: BlogArticleSection) {
    let content: ReactNode = ''

    if (item.type === 'collapsable') {
        content = (
            <Collapse className={styles.collapse} ghost expandIconPosition="right">
                <CollapsePanel
                    header={
                        <span className={styles.collapseHeader}>
                            {item.collapsable}
                            <Down className={styles.up} />
                        </span>
                    }
                    key={item.id}
                >
                    <>{item.content.map(renderSection)}</>
                </CollapsePanel>
            </Collapse>
        )
    }
    if (item.type === 'text') {
        content = (
            <RichTextEditor
                readOnly
                value={RichTextEditor.createValueFromString(item.content as any, 'markdown')}
                className={styles.content}
            />
        )
    }
    if (item.type === 'table') {
        const tableData = Object.keys(item.content).map(key => ({
            name: key,
            value: item.content[key] as any,
            type: 'right' as const
        }))
        content = <StatsTable className={styles.table} items={tableData} />
    }
    return (
        <div key={item.id} style={{ marginTop: '8px', marginBottom: '8px' }}>
            {item.header && item.headerSize === 'h1' && (
                <h1 className={styles.sectionHeader} id={item.header}>
                    {item.header}
                </h1>
            )}
            {item.header && item.headerSize === 'h2' && (
                <h1 className={styles.subheader} id={item.header}>
                    {item.header}
                </h1>
            )}
            {content}
        </div>
    )
}
