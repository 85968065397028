import { createAsyncThunk } from '@reduxjs/toolkit'
import { firmsGetAll, firmsCreate, firmsGet, firmsDelete, firmsUpdate } from '../api/lawFirms'
import { RootState } from '../reducers/store'
import { LawFirm } from '../types/lawFirm'

export const fetchLawFirms = createAsyncThunk<{ firms: LawFirm[] }, void, { state: RootState }>('fetchLawFirms', async (_, thunkAPI) => {
    const state = thunkAPI.getState() as RootState
    const response = await firmsGetAll(thunkAPI, state.lawFirms.filters, state.lawFirms.sorters)
    return { firms: response }
})

export const fetchLawFirm = createAsyncThunk('fetchLawFirm', async (firmId: string, thunkAPI) => {
    const response = await firmsGet(firmId, thunkAPI)
    return { firm: response }
})

export const addLawFirm = createAsyncThunk<{ firm: LawFirm }, { firm: Partial<LawFirm>; onSuccess: () => void }, { state: RootState }>(
    'addLawFirm',
    async ({ firm, onSuccess }, thunkAPI) => {
        const response = await firmsCreate(firm as LawFirm, thunkAPI)
        onSuccess()
        thunkAPI.dispatch(fetchLawFirms())
        return { firm: response }
    }
)

export const updateLawFirm = createAsyncThunk<{ firm: LawFirm }, { firm: LawFirm; onSuccess: () => void }, { state: RootState }>(
    'updateLawFirm',
    async ({ firm, onSuccess }, thunkAPI) => {
        const response = await firmsUpdate(firm, thunkAPI)
        onSuccess()
        return { firm: response }
    }
)

export const deleteLawFirms = createAsyncThunk<{}, string[], { state: RootState }>('deleteFirms', async (ids, thunkAPI) => {
    await firmsDelete(ids, thunkAPI)
    thunkAPI.dispatch(fetchLawFirms())
    return {}
})
