import { Rule } from 'antd/lib/form'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export function useRequiredValidator() {
    const { t } = useTranslation()
    const validator = useMemo<Rule[]>(() => {
        return [
            {
                required: true,
                message: t('This field is required')
            }
        ]
    }, [t])
    return validator
}
