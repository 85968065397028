import React from 'react'
import { Avatar } from 'antd'
import { Link } from 'react-router-dom'
import { BASE_FILE_URL } from '../../api/endpoints'
import { Proposal } from '../../types/jobs'
import { cn } from '../../utils/cn'
import { ShortText } from '../ui/ShortText/ShortText'
import styles from './ProposalItem.module.css'
import { getLocaleValue } from '../../utils/string'
import { useSelector } from '../../reducers/store'
import { ShortReview } from '../ui/Reviews/ShortReview'
import { BottomSheet } from '../ui/BottomSheet/BottomSheet'
import { AssistantItem } from '../ui/AssistantItem/AssistantItem'
import { SimpleRichEditor } from '../ui/SimpleRichEditor/SimpleRichEditor'
import { SetAppointmentButton } from '../ui/SetAppointmentButton/SetAppointmentButton'
import { useTranslation } from 'react-i18next'

export interface ProposalItemProps {
    proposal: Proposal
}

export const ProposalItem = ({ proposal }: ProposalItemProps) => {
    const expert = proposal.expert
    const { t } = useTranslation()
    const isDesktop = window.screen.width > 960
    const avatarUrl = expert?.photos?.[0] ? `${BASE_FILE_URL}/${expert.photos[0].photo}` : undefined
    const locale = useSelector(store => store.session.lang)
    const content = (
        <div
            className={cn(styles.container, {
                [styles.yellow]: ['SELECTED', 'CALL_CANCELLED', 'NOT_ELIGIBLE'].includes(proposal.status),
                [styles.green]: ['CALL_COMPLETED'].includes(proposal.status)
            })}
        >
            <div className={styles.header}>
                <Avatar size={60} src={avatarUrl} className={styles.avatar} />
                <div className={styles.main}>
                    <h4 className={styles.name}>
                        {proposal.expert?.firstName} {proposal.expert?.lastName}
                    </h4>
                    {proposal.status === 'OPEN' && (
                        <span className={styles.call}>{getLocaleValue(proposal.expert?.jobTitle, 'name', locale)}</span>
                    )}
                    {proposal.expert && proposal.status === 'OPEN' && <ShortReview expert={proposal.expert} />}
                    {proposal.status === 'NOT_ELIGIBLE' && <span className={styles.call}>Not eligible</span>}
                    {proposal.status === 'SELECTED' && <span className={styles.call}>{t('Selected')}</span>}
                    {proposal.status === 'CALL_CANCELLED' && (
                        <>
                            <span className={styles.call}>{t('Zoom Call Canceled')}</span>
                            <Link to="/appointment" className={styles.call}>
                                Set new appointment
                            </Link>
                        </>
                    )}
                    {proposal.status === 'CALL_COMPLETED' && <span className={styles.call}>{t('Zoom Call Completed')}</span>}
                    {!['CALL_CANCELLED', 'CALL_COMPLETED'].includes(proposal.status) && proposal.manuallyScheduledCall && (
                        <p className={styles.call}>{proposal.manuallyScheduledCall}</p>
                    )}
                </div>
            </div>
            <div className={styles.message}>
                <ShortText ellipsis={{ rows: 2, expandable: true, symbol: t('Read More') }}>{proposal.message}</ShortText>
            </div>
        </div>
    )
    const popup = (
        <div className={styles.sre}>
            <Link to={`/experts/${expert?.id}`}>
                <AssistantItem
                    title={`${expert?.firstName} ${expert?.lastName}`}
                    className={styles.assistant}
                    description={getLocaleValue(expert?.jobTitle, 'name', locale)}
                    avatarUrl={avatarUrl}
                    avaSize={isDesktop ? 100 : 'large'}
                />
            </Link>
            <SimpleRichEditor value={proposal.message} readOnly />
        </div>
    )
    if (!proposal.initiatedByUser) {
        return (
            <BottomSheet
                size="veryLarge"
                content={popup}
                footer={<SetAppointmentButton float={false} className={styles.button} noAssistant={true} />}
            >
                {content}
            </BottomSheet>
        )
    }
    return content
}
