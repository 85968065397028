import { createAsyncThunk } from '@reduxjs/toolkit'
import { projectsGetAll, projectsCreate, projectsGet, projectsDelete, projectsUpdate } from '../api/projects'
import { RootState } from '../reducers/store'
import { Project } from '../types/project'
import { sendGet } from '../utils/api'

export const fetchProjects = createAsyncThunk<{ projects: Project[] }, void, { state: RootState }>('fetchProjects', async (_, thunkAPI) => {
    const state = thunkAPI.getState() as RootState
    const response = await projectsGetAll(thunkAPI, state.projects.filters, state.projects.sorters)
    return { projects: response }
})

export const fetchProject = createAsyncThunk('fetchProject', async (projectId: string, thunkAPI) => {
    const response = await projectsGet(projectId, thunkAPI)
    return { project: response }
})

export const addProject = createAsyncThunk<
    { project: Project },
    { project: Partial<Project>; onSuccess: () => void },
    { state: RootState }
>('addProject', async ({ project, onSuccess }, thunkAPI) => {
    const response = await projectsCreate(project as Project, thunkAPI)
    onSuccess()
    thunkAPI.dispatch(fetchProjects())
    return { project: response }
})

export const updateProject = createAsyncThunk<{ project: Project }, { project: Project; onSuccess: () => void }, { state: RootState }>(
    'updateProject',
    async ({ project, onSuccess }, thunkAPI) => {
        const response = await projectsUpdate(project, thunkAPI)
        onSuccess()
        return { project: response }
    }
)

export const deleteProjects = createAsyncThunk<{}, string[], { state: RootState }>('deleteProjects', async (ids, thunkAPI) => {
    await projectsDelete(ids, thunkAPI)
    thunkAPI.dispatch(fetchProjects())
    return {}
})

export const fetchMyProjects = createAsyncThunk('fetchMyProjects', async (_, thunkAPI) => {
    const response = await sendGet('/my-projects', thunkAPI)
    return { projects: response }
})
