import { Checkbox, Form, Popover, Tabs } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { Button } from '../../components/ui/Button/Button'
import { Input } from '../../components/ui/Input/Input'
import { useDispatch, useSelector } from '../../reducers/store'
import styles from './ReviewAdminEditPage.module.css'
import { useHistory, useParams } from 'react-router-dom'
import FormItem from 'antd/lib/form/FormItem'
import { Dropdown } from '../../components/ui/Dropdown/Dropdown'
import TextArea from 'antd/lib/input/TextArea'
import { useLanguages } from '../../hooks/useLanguages'
import { useToggle } from '../../hooks/useToggle'
import { Review } from '../../types/Review'
import { addReview, updateReview } from '../../actions/review'
import { useReview } from '../../hooks/useReview'
import { useExperts } from '../../hooks/useExperts'
import useGtmPageLoad from '../../hooks/useGtmPageLoad'

export const ReviewAdminEditPage = () => {
    useGtmPageLoad('ReviewAdminEditPage')
    const dispatch = useDispatch()
    useLanguages()
    const languages = useSelector(store => store.languages.languages)
    const history = useHistory()
    const [form] = Form.useForm()
    const ref = useRef(null)
    const [locales, setLocales] = useState<Record<string, any>>({})
    const { id } = useParams<{ id: string }>()
    const [showPopover, togglePopover] = useToggle()
    const [active, setActive] = useState('en')
    const [selectedExpert, setSelectedExpert] = useState<string>()
    const isNew = id === 'new'
    useExperts()
    useReview(id)
    const data = useSelector(store => store.experts)
    const currentUser = useSelector(store => store.session.user)
    const { experts, selectedReview: selectedReviewData } = data
    const [selectedReview, setSelectedReview] = useState<Review>()
    const handleCancel = () => {
        history.push('/review-admin')
    }

    useEffect(() => {
        if (selectedReviewData) {
            setSelectedReview(selectedReviewData)
        }
    }, [selectedReviewData])

    const expertsOptions = experts.map(item => ({ value: item.id, label: `${item.firstName || ''} ${item.lastName || ''}` }))

    const emptyReview: Review = {
        id: '',
        user: {},
        text: '',
        rating: 0,
        created: '2020-01-01T00:00:00.000+00:00',
        approved: false
    }

    useEffect(() => {
        if (selectedReview) {
            setLocales(selectedReview.localizations ?? {})
        }
    }, [selectedReview])

    const handleAdd = (values: Record<string, any>) => {
        const expertId = selectedExpert || selectedReview?.expert?.id.toString() || ''
        const review: Review = {
            created: selectedReview?.created || values?.created,
            rating: values.rating,
            text: values.text,
            expert: { id: expertId },
            approved: selectedReview?.approved || values?.approved,
            localizations: values.localizations,
            user: { id: Number(currentUser?.id) }
        }
        Object.entries(review.localizations ?? {}).forEach(([key, value]) => {
            value.locale = key
        })
        dispatch(addReview({ review, onSuccess: handleCancel }))
    }

    const handleUpdate = (values: Record<string, any>) => {
        if (values.localizations) {
            Object.keys(values.localizations).forEach(key => {
                values.localizations[key].locale = key
            })
        }
        const expertId = selectedExpert || selectedReview?.expert?.id.toString() || ''
        const review: Review = {
            ...(selectedReview as Review),
            created: selectedReview?.created || values?.created,
            rating: values.rating,
            text: values.text,
            expert: { id: expertId },
            approved: selectedReview?.approved || !!values?.approve,
            localizations: { ...locales, ...values.localizations },
            user: { id: selectedReview?.user?.id || 0 }
        }
        dispatch(updateReview({ review, onSuccess: handleCancel }))
    }

    const handleAddLocale = (value: any, item: any) => {
        setLocales({
            ...locales,
            [value]: { locale: value }
        })
        setActive(value)
        togglePopover()
    }

    const opts = languages
        .filter(l => {
            return l.code !== 'en' && !Object.keys(locales).includes(l.code)
        })
        .map((l: any) => ({ value: l.code, label: l.name }))
    const Content = <Dropdown options={opts as any} onSelect={handleAddLocale} />

    const Add = <span ref={ref}>+ Add</span>

    if (!selectedReview && !isNew) {
        return null
    }

    const handleChangeReview = (value: any, item: any) => {
        setSelectedExpert(value)
    }

    const handleChangeDate = (event: any) => {
        const newDate = event?.target?.valueAsDate
        const newReview: Review = { ...(selectedReview || emptyReview) }
        newReview.created = `${newDate?.getFullYear()}-${(newDate?.getMonth() + 1).toString().padStart(2, '0')}-${newDate
            ?.getDate()
            .toString()
            .padStart(2, '0')}T${newReview?.created?.substr(11, 19)}`
        setSelectedReview(newReview)
    }

    const handleChangeTime = (event: any) => {
        const newTime = event?.target?.valueAsDate
        const localNow = new Date()
        const negateTimezone = localNow.getTimezoneOffset() / 60
        const newTimeValue =
            (newTime?.getHours() + negateTimezone).toString().padStart(2, '0') +
            ':' +
            newTime?.getMinutes().toString().padStart(2, '0') +
            ':' +
            newTime?.getSeconds().toString().padStart(2, '0')
        const newReview: Review = { ...(selectedReview || emptyReview) }
        newReview.created = selectedReview?.created.substr(0, 11) + newTimeValue + selectedReview?.created.substr(19, 10)
        setSelectedReview(newReview)
    }

    const handleChangeApprove = (event: any) => {
        const newReview: Review = { ...(selectedReview || emptyReview) }
        newReview.approved = event.target.checked
        setSelectedReview(newReview)
    }

    const tabs = Object.entries(locales).map(([key, item]) => {
        return (
            <Tabs.TabPane key={key} tab={languages.find(l => l.code === key)?.name}>
                <Form
                    className={styles.form}
                    layout="vertical"
                    form={form}
                    onFinish={isNew ? handleAdd : handleUpdate}
                    initialValues={selectedReview}
                >
                    <FormItem label="Text review" name={['localizations', key, 'text']} wrapperCol={{ span: 8 }}>
                        <TextArea rows={9} />
                    </FormItem>
                </Form>
            </Tabs.TabPane>
        )
    })

    const userLabel = isNew
        ? `${currentUser?.firstName || ''} ${currentUser?.lastName || ''}`
        : `${selectedReview?.user?.firstName || ''} ${selectedReview?.user?.lastName || ''}`

    const defaultPane = (
        <Tabs.TabPane key="en" tab="English" closable={false}>
            <Form
                className={styles.form}
                form={form}
                layout="vertical"
                onFinish={isNew ? handleAdd : handleUpdate}
                initialValues={selectedReview}
                autoComplete="off"
            >
                <FormItem label="User" wrapperCol={{ span: 8 }}>
                    <Input value={userLabel} disabled={true} />
                </FormItem>
                <FormItem label="Expert" name="expertId" wrapperCol={{ span: 8 }} initialValue={selectedReview?.expert?.id.toString()}>
                    <Dropdown options={expertsOptions} onChange={handleChangeReview} />
                </FormItem>
                <FormItem label="Rating" name="rating" required wrapperCol={{ span: 2 }}>
                    <Input type="number" min={0} max={5} />
                </FormItem>
                <FormItem label="Date created" name="created" required wrapperCol={{ span: 8 }}>
                    <div className={styles.dateArea}>
                        <Input
                            type="date"
                            value={selectedReview?.created?.substr(0, 10) || ''}
                            onChange={handleChangeDate}
                            className={styles.dataInput}
                        />
                        <Input type="time" value={selectedReview?.created?.substr(11, 8) || ''} onChange={handleChangeTime} />
                    </div>
                </FormItem>
                <FormItem label="Text Review" name="text" required wrapperCol={{ span: 8 }}>
                    <TextArea rows={9} />
                </FormItem>
                <FormItem label="Approved" name="approved" required wrapperCol={{ span: 8 }}>
                    <Checkbox checked={selectedReview?.approved} onChange={handleChangeApprove} />
                </FormItem>
            </Form>
        </Tabs.TabPane>
    )

    const handleTabEdit = (e: string | React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>, action: 'add' | 'remove') => {
        if (action === 'add') {
            togglePopover()
        } else {
            const newLocales = { ...locales }
            delete newLocales[e as string]
            setLocales(newLocales)
        }
    }

    return (
        <div className={styles.container}>
            <Popover content={Content} visible={showPopover} onVisibleChange={togglePopover} />
            <Tabs type="editable-card" activeKey={active} onEdit={handleTabEdit} addIcon={Add} onChange={key => setActive(key)}>
                {defaultPane}
                {tabs}
            </Tabs>
            <div className={styles.footer}>
                <Button type="ghost" onClick={handleCancel} className={styles.button}>
                    Cancel
                </Button>
                <Button onClick={form.submit} style={{ width: 'auto' }} className={styles.button}>
                    Save
                </Button>
            </div>
        </div>
    )
}
