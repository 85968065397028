import { Filter, Sorter } from '../types/filter'
import { CategoryIndustry } from '../types/categoryIndustry'
import { sendDelete, sendGet, sendPost, sendPut } from '../utils/api'

export function categoryIndustryGetAll(thunkAPI: any, filters?: Filter[], sorters?: Sorter[]): Promise<CategoryIndustry[]> {
    const params = new URLSearchParams()
    filters?.forEach(filter => {
        params.append(`_${filter.path}.${filter.operation}`, filter.value)
    })
    if (sorters?.length) {
        params.append(`sort`, sorters.map(item => `${item.fieldName}.${item.direction}`).join(','))
    }
    return sendGet(`/projectCategories?${params.toString()}`, thunkAPI)
}

export function categoryIndustryGet(categoryId: string, thunkAPI: any): Promise<CategoryIndustry> {
    return sendGet(`/projectCategories/${categoryId}`, thunkAPI)
}

export function categoryIndustryCreate(category: CategoryIndustry, thunkAPI: any): Promise<CategoryIndustry> {
    return sendPost(`/projectCategories`, thunkAPI, category)
}

export function categoryIndustryUpdate(category: CategoryIndustry, thunkAPI: any): Promise<CategoryIndustry> {
    return sendPut(`/projectCategories/${category.id}`, thunkAPI, category)
}

export function categoryIndustryDelete(ids: string[], thunkAPI: any) {
    return sendDelete(`/projectCategories`, thunkAPI, ids)
}
