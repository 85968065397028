import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { TagItem } from '../components/ui/Tags/Tags'
import { useSelector } from '../reducers/store'
import { Expert } from '../types/expert'
import { capitalize, getLocaleValue } from '../utils/string'
import { EJobTitleNames } from '../constants/experts'

export function useExpertTags(expert: Expert) {
    const languages = useSelector(store => store.languages.languages)
    const lang = useSelector(store => store.session.lang)
    const { t } = useTranslation()
    const isEducation = expert.jobTitle?.name === EJobTitleNames.EducationExpert
    const isImmigration = expert.jobTitle?.name === EJobTitleNames.ImmigrationAttorney
    const tags = useMemo(() => {
        const result: TagItem[] = []
        expert.languages
            ?.map(item => languages?.find(lang => lang.code === item.lang))
            ?.forEach(language => {
                result.push({ value: capitalize(getLocaleValue(language, 'name', lang) ?? '') })
            })
        if (expert.experience && Object.values(expert.experience)[0]) {
            result.push({ value: `${Object.values(expert.experience)[0] as any}${t('yrs experience')}` })
        }
        return result?.filter(item => item.value !== '') || []
    }, [expert, t, lang, languages])

    if (isEducation) {
        return [{ value: t('Education'), status: 'open' }, ...tags]
    }

    if (isImmigration) {
        return [{ value: t('Immigration'), status: 'open' }, ...tags]
    }

    return tags
}
