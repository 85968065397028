import { Checkbox, Empty, Form, Switch } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import React from 'react'
import { updateMe } from '../../actions/auth'
import { Dropdown } from '../../components/ui/Dropdown/Dropdown'
import { Input } from '../../components/ui/Input/Input'
import { useLanguages } from '../../hooks/useLanguages'
import { useDispatch, useSelector } from '../../reducers/store'
import { User } from '../../types/person'
import { cn } from '../../utils/cn'
import styles from './SettingsPage.module.css'
import useGtmPageLoad from '../../hooks/useGtmPageLoad'

export const SettingsPage = () => {
    useGtmPageLoad('SettingsPage')
    const me = useSelector(store => store.session.user)
    useLanguages()
    const languages = useSelector(store => store.languages.languages).map(item => ({ value: item.code, label: item.name }))
    const [form] = useForm()
    const dispatch = useDispatch()

    if (!me) {
        return <Empty />
    }

    const name = !me.name && me.firstName && me.lastName ? `${me.firstName} ${me.lastName}` : me.name

    const handleBlur = (e: any) => {
        const user: User = form.getFieldsValue()
        user.id = me.id
        dispatch(updateMe({ user }))
    }

    return (
        <div className={styles.container}>
            <h2 className={styles.title}>Settings</h2>
            <Form initialValues={me} onBlur={handleBlur} layout="vertical" form={form}>
                <Form.Item label="Name" name="name" initialValue={name}>
                    <Input className={styles.input} />
                </Form.Item>
                <Form.Item label="Email" name="email">
                    <Input readOnly className={styles.input} />
                </Form.Item>
                <Form.Item label="Phone" name="phone">
                    <Input readOnly className={styles.input} />
                </Form.Item>
                <Form.Item label="Language" name="language">
                    <Dropdown options={languages} className={styles.lang} />
                </Form.Item>
                <Form.Item label="Time Zone" name="timezone" className={styles.mobile}>
                    <Input disabled className={styles.input} />
                </Form.Item>
                <h3 className={styles.notificationsTitle}>Notifications</h3>
                <div className={styles.notificationsDesc}>We will send meeting reminders and other service information.</div>
                <Form.Item label="Email" name="notifyEmail" className={styles.horizontal}>
                    <Switch className={cn(styles.mobile, styles.switch)} defaultChecked={me.notifyEmail} />
                    <Checkbox className={cn(styles.desktop, styles.switch)} defaultChecked={me.notifyEmail} />
                </Form.Item>
                <Form.Item label="SMS" name="notifySms" className={styles.horizontal}>
                    <Switch className={cn(styles.mobile, styles.switch)} defaultChecked={me.notifySms} />
                    <Checkbox className={cn(styles.desktop, styles.switch)} defaultChecked={me.notifySms} />
                </Form.Item>
                <Form.Item label="WhatsApp" name="notifyWhatsapp" className={styles.horizontal}>
                    <Switch className={cn(styles.mobile, styles.switch)} defaultChecked={me.notifyWhatsapp} />
                    <Checkbox className={cn(styles.desktop, styles.switch)} defaultChecked={me.notifyWhatsapp} />
                </Form.Item>
            </Form>
        </div>
    )
}
