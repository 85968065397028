import React, { useEffect } from 'react'
import styles from './ProjectProfilePage.module.css'
import { ProjectDetailsCard } from '../../components/ProjectDetailsCard/ProjectDetailsCard'
import { useParams } from 'react-router-dom'
import { useSelector } from '../../reducers/store'
import { useProject } from '../../hooks/useProjects'
import { SetAppointmentButton } from '../../components/ui/SetAppointmentButton/SetAppointmentButton'
import { useDispatch } from 'react-redux'
import { addCallPayload } from '../../reducers/session'
import useGtmPageLoad from '../../hooks/useGtmPageLoad'

interface ProjectProfilePageProps {
    id?: string
}
/**
 * This page contains a profile for selected project
 */
export const ProjectProfilePage = ({ id }: ProjectProfilePageProps) => {
    useGtmPageLoad('ProjectProfilePage')
    const user = useSelector(store => store.session.user)
    const { id: routeId } = useParams<{ id: string }>()
    useProject(id || routeId)
    const dispatch = useDispatch()
    const project = useSelector(store => store.projects.selectedProject)

    useEffect(() => {
        dispatch(
            addCallPayload({
                projectId: routeId
            })
        )
    }, [routeId, dispatch])

    const handleSetAppointmentClick = () => {
        ;(window as any).dataLayer.push({ event: 'contactRegionalCenter' })
    }

    return (
        <div className={styles.container}>
            {project && <ProjectDetailsCard project={project} />}
            {user && (
                <SetAppointmentButton
                    onClick={handleSetAppointmentClick}
                    className={styles.mobile}
                    noSubject={true}
                    noAssistant={true}
                    size="dynamic"
                />
            )}
        </div>
    )
}
