import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export default function useGtmPageLoad(pageName: string, config?: Record<string, any>) {
    const location = useLocation()
    const pathname = location.pathname
    useEffect(() => {
        ;(window as any).dataLayer.push({
            event: 'pageLoad',
            pageName,
            pageAddress: pathname[pathname.length - 1] === '/' ? pathname.slice(0, -1) : pathname,
            ...config
        })
    }, [pageName, config, pathname])
}
