import React from 'react'
import ReactDOM from 'react-dom'
import 'antd/dist/antd.css'
import './i18n'
import './index.css'
import App from './App'
import { store } from './reducers/store'
import { Provider } from 'react-redux'
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: 'GTM-NB3KG48'
}

TagManager.initialize(tagManagerArgs)

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
)
