import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Modal } from 'antd'

import { clearError, clearToken } from '../../reducers/session'
import { useDispatch } from '../../reducers/store'
import { LoginInit } from '../LoginPage/LoginInit'
import { LoginWithEmail } from '../LoginPage/LoginWithEmail'
import { LoginWithSms } from '../LoginPage/LoginWithSms'

import { ReactComponent as CloseIcon } from '../../assets/x.svg'

import styles from './ExpiredPage.module.css'
import useGtmPageLoad from '../../hooks/useGtmPageLoad'

export const ExpiredPage = () => {
    useGtmPageLoad('ExpiredPage')
    const { t } = useTranslation()
    const history = useHistory()
    const dispatch = useDispatch()

    const [step, setStep] = useState('init')

    const onCloseErrorModal = useCallback(() => {
        localStorage.removeItem('token')
        dispatch(clearError())
        dispatch(clearToken())
        const instance = (window as any).gapi?.auth2?.getAuthInstance?.()
        instance?.signOut()
        history.push('/login')
    }, [dispatch, history])

    const modalContent = (
        <div className={styles.content}>
            <div className={styles.title}>{t('Sign In Link is Expired')}</div>
            <div className={styles.text}>{t('Expired text')}</div>
            <div className={styles.border}>
                <div style={{ display: step !== 'init' ? 'none' : 'block' }}>
                    <LoginInit className={styles.loginContent} popup={true} onStep={setStep} expired={true} />
                </div>
                {step === 'email' && <LoginWithEmail popup />}
                {step === 'phone' && <LoginWithSms popup />}
            </div>
        </div>
    )

    return (
        <Modal
            visible={true}
            footer={null}
            onCancel={onCloseErrorModal}
            className={styles.modal}
            destroyOnClose={true}
            closeIcon={<CloseIcon />}
            width={440}
        >
            {modalContent}
        </Modal>
    )
}
