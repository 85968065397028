import React, { memo, useState, useEffect } from 'react'

export interface CurrentTimeProps {
    timezone?: number
    showOffset?: boolean
    className?: string
}

export const CurrentTime = ({ timezone, showOffset = false, className }: CurrentTimeProps) => {
    const [date, setDate] = useState(now(timezone))
    useEffect(() => {
        const interval = setInterval(() => {
            setDate(now(timezone))
        }, 1000)
        return () => {
            clearInterval(interval)
        }
    }, [timezone])
    const result = date.toLocaleTimeString('en-Us', { hour: 'numeric', minute: 'numeric' })
    const visibleOffset = timezone ?? -(date.getTimezoneOffset() / 60)
    return (
        <span className={className}>
            {result} {showOffset && `(GMT ${visibleOffset > 0 ? '+' : ''}${visibleOffset})`}
        </span>
    )
}

/**
 * Returns current time for specified timezone
 *
 * @param timezone UTC timezone, -12..+14
 */
function now(timezone: number = 0) {
    // On your computer
    const localNow = new Date()
    const negateTimezone = localNow.getTimezoneOffset() / 60
    // In UTC
    const utcNow = new Date(localNow.setHours(localNow.getHours() + negateTimezone))
    // On computer in specified timezone
    const remoteNow = new Date(utcNow.setHours(utcNow.getHours() + timezone))
    return remoteNow
}

export default memo(CurrentTime)
