import React, { ReactNode, useMemo } from 'react'
import { cn } from '../../../utils/cn'
import { BottomSheet, TBottomSheetSizes } from '../BottomSheet/BottomSheet'
import styles from './StatsBlock.module.css'
import { useTranslation } from 'react-i18next'
import { ReactComponent as InformationIcon } from '../../../assets/info-icon.svg'

export interface StatsBlockProps {
    title?: string
    titleInfo?: ReactNode
    minorValue?: string
    majorValue?: string
    items: Record<string, string | number | undefined>
    className?: string
    size?: TBottomSheetSizes
}

export const StatsBlock = ({ title = '', titleInfo, majorValue = '', minorValue = '', items = {}, className, size }: StatsBlockProps) => {
    const { t } = useTranslation()
    const [headers, values] = useMemo(() => {
        return [Object.keys(items), Object.values(items)]
    }, [items])
    return (
        <div className={cn(styles.container, className)}>
            {title && (
                <h2 className={cn(styles.title, !minorValue ? 'mb-4' : undefined)}>
                    {title}
                    {titleInfo && (
                        <BottomSheet content={titleInfo} title={title} size={size}>
                            <InformationIcon className={styles.icon} />
                        </BottomSheet>
                    )}
                </h2>
            )}
            {minorValue && <div className={styles.minorValue}>{minorValue}</div>}
            {majorValue && <div className={styles.majorValue}>{majorValue}</div>}
            <table className={styles.table}>
                <thead>
                    <tr>
                        {headers.map(item => (
                            <th key={item} className={styles.hint}>
                                {t(item)}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        {values.map((item, index) => (
                            <td key={index} className={styles.value}>
                                {item}
                            </td>
                        ))}
                    </tr>
                </tbody>
            </table>
        </div>
    )
}
