import Table, { ColumnsType } from 'antd/lib/table'
import React, { useCallback, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { deleteLawFirms } from '../../actions/lawFirms'
import { Button } from '../../components/ui/Button/Button'
import { useLawFirms } from '../../hooks/useLawFirms'
import { useDispatch, useSelector } from '../../reducers/store'
import { LawFirm } from '../../types/lawFirm'
import styles from './LawFirmAdminPage.module.css'
import { ReactComponent as PlusIcon } from '../../assets/plus.svg'
import useGtmPageLoad from '../../hooks/useGtmPageLoad'

export const LawFirmAdminPage = () => {
    useGtmPageLoad('LawFirmAdminPage')
    const dispatch = useDispatch()
    const [selectedItems, setSelectedItems] = useState<string[]>([])
    useLawFirms()
    const data = useSelector(store => store.lawFirms.firms)

    const renderLink = useCallback((value: any, item: LawFirm) => {
        return <Link to={`/law-firms-admin/${item.id}`}>{value}</Link>
    }, [])

    const columns: ColumnsType<LawFirm> = useMemo(() => {
        return [
            {
                dataIndex: 'name',
                title: 'Name',
                render: renderLink
            },
            { dataIndex: 'address', title: 'City' },
            { dataIndex: 'phone', title: 'phone' }
        ]
    }, [renderLink])

    const handleDelete = () => {
        dispatch(deleteLawFirms(selectedItems))
        setSelectedItems([])
    }

    const handleSelect = (selectedKeys: (string | number)[]) => {
        setSelectedItems(selectedKeys.map(key => (typeof key !== 'string' ? key.toString() : key)))
    }

    return (
        <div className={styles.container}>
            <div className={styles.controls}>
                <Link to="/law-firms-admin/new">
                    <Button icon={<PlusIcon />} style={{ width: 'auto' }} className={styles.button}>
                        Add
                    </Button>
                </Link>
                <Button style={{ width: 'auto' }} type="ghost" className={styles.button} onClick={handleDelete}>
                    Delete
                </Button>
            </div>
            <div style={{ overflow: 'auto' }}>
                <Table columns={columns} dataSource={data} rowSelection={{ onChange: handleSelect }} rowKey="id" />
            </div>
        </div>
    )
}
