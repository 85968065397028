import Table, { ColumnsType } from 'antd/lib/table'
import React, { useCallback, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { deleteBlogArticles } from '../../actions/blog'
import { Button } from '../../components/ui/Button/Button'
import { useBlogArticles } from '../../hooks/useBlogArticles'
import { useDispatch, useSelector } from '../../reducers/store'
import { BlogArticle } from '../../types/blog'
import { ReactComponent as PlusIcon } from '../../assets/plus.svg'
import styles from './BlogAdminPage.module.css'
import useGtmPageLoad from '../../hooks/useGtmPageLoad'

export const BlogAdminPage = () => {
    useGtmPageLoad('BlogAdminPage')
    const dispatch = useDispatch()
    const [selectedItems, setSelectedItems] = useState<string[]>([])
    useBlogArticles()
    const data = useSelector(store => store.blog.articles)

    const renderLink = useCallback((value: any, item: BlogArticle) => {
        return <Link to={`/blog-admin/${item.id}`}>{value}</Link>
    }, [])

    const columns: ColumnsType<BlogArticle> = useMemo(() => {
        return [
            {
                dataIndex: 'title',
                title: 'Title',
                render: renderLink
            }
        ]
    }, [renderLink])

    const handleDelete = () => {
        dispatch(deleteBlogArticles(selectedItems))
        setSelectedItems([])
    }

    const handleSelect = (selectedKeys: (string | number)[]) => {
        setSelectedItems(selectedKeys.map(key => (typeof key !== 'string' ? key.toString() : key)))
    }

    return (
        <div className={styles.container}>
            <div className={styles.controls}>
                <Link to="/blog-admin/new">
                    <Button icon={<PlusIcon />} style={{ width: 'auto' }} className={styles.button}>
                        Add
                    </Button>
                </Link>
                <Button style={{ width: 'auto' }} type="ghost" className={styles.button} onClick={handleDelete}>
                    Delete
                </Button>
            </div>
            <div style={{ overflow: 'auto' }}>
                <Table columns={columns} dataSource={data} rowSelection={{ onChange: handleSelect }} rowKey="id" />
            </div>
        </div>
    )
}
