import { TAppointmentText } from './SetAppointmentButton'

export const getAppointmentText = (appointmentType: TAppointmentText): string[] => {
    const textForms: string[] = []
    switch (appointmentType) {
        case 'expert': {
            textForms[0] = 'Choose Subject Text Expert1'
            textForms[1] = 'Choose Subject Text Expert2'
            textForms[2] = 'Choose Subject Text Expert3'
            return textForms
        }
        case 'regionalCenter': {
            textForms[0] = 'Choose Subject Text1'
            textForms[1] = 'Choose Subject Text2'
            textForms[2] = 'Choose Subject Text3'
            return textForms
        }
        case 'consultant': {
            textForms[0] = 'Choose Subject Text Consierge1'
            textForms[1] = 'Choose Subject Text Consierge2'
            textForms[2] = 'Choose Subject Text Consierge3'
            return textForms
        }
        default:
            return ['', '', '']
    }
}
