import React, { useCallback, useEffect } from 'react'
import { cn } from '../../../utils/cn'
import styles from './SetAppointment.module.css'
import { useScript } from '../../../hooks/useScript'
import { CalendlyEvent } from '../../../types/calendly'
import { useDispatch, useSelector } from '../../../reducers/store'
import { scheduleCall } from '../../../actions/scheduledCalls'

export const SetAppointment = () => {
    const user = useSelector(store => store.session.user)
    const dispatch = useDispatch()
    const src = 'https://assets.calendly.com/assets/external/widget.js'
    const assistants = useSelector(store => store.assistants.assistants)
    const assistant = useSelector(store => store.assistants.selectedAssistant) ?? assistants[0]
    let schedulingUrl = `${assistant?.schedulingUrl}?primary_color=ea580c&text_color=1f2937`
    if (user?.email) {
        schedulingUrl += `&email=${user.email}`
    }
    if (user?.name) {
        schedulingUrl += `&name=${user.name}`
    } else if (user?.firstName && user?.lastName) {
        schedulingUrl += `&name=${user.firstName} ${user.language}`
    }
    useScript(src)
    useEffect(() => {
        return () => {
            const previousScript = document.querySelector(`script[src="${src}"]`)
            if (previousScript) {
                previousScript.parentNode?.removeChild(previousScript)
            }
        }
    })
    const scheduledPayload = useSelector(store => store.session.scheduledCallPayload)
    const reportScheduledCall = useCallback(
        (e: MessageEvent<CalendlyEvent>) => {
            if (!isCalendlyEvent(e)) {
                return
            }
            dispatch(
                scheduleCall({
                    eventLink: e.data.payload.event.uri,
                    inviteeLink: e.data.payload.invitee?.uri,
                    jobId: scheduledPayload.jobId,
                    expertId: scheduledPayload.expertId,
                    projectId: scheduledPayload.projectId,
                    regionalCenterId: scheduledPayload.regionalCenterId
                })
            )
        },
        [scheduledPayload.jobId, scheduledPayload.expertId, scheduledPayload.projectId, scheduledPayload.regionalCenterId, dispatch]
    )
    useEffect(() => {
        window.addEventListener('message', reportScheduledCall)
        return () => {
            window.removeEventListener('message', reportScheduledCall)
        }
    })
    if (!user || !assistant) {
        return null
    }
    return (
        <div className={styles.container}>
            <div className={styles.card}>
                <div className={cn('calendly-inline-widget', styles.widget)} data-url={schedulingUrl} />
            </div>
        </div>
    )
}

function isCalendlyEvent(e: MessageEvent<CalendlyEvent>) {
    return e.data.event && e.data.event.indexOf('calendly') === 0 && e.data.event === 'calendly.event_scheduled'
}
