import { createAsyncThunk } from '@reduxjs/toolkit'
import { developersGetAll, developersCreate, developersGet, developersDelete, developersUpdate } from '../api/developers'
import { RootState } from '../reducers/store'
import { Developer } from '../types/developer'

export const fetchDevelopers = createAsyncThunk<{ developers: Developer[] }, void, { state: RootState }>(
    'fetchDevelopers',
    async (_, thunkAPI) => {
        const state = thunkAPI.getState() as RootState
        const response = await developersGetAll(thunkAPI, state.lawFirms.filters, state.lawFirms.sorters)
        return { developers: response }
    }
)

export const fetchDeveloper = createAsyncThunk('fetchDeveloper', async (developerId: string, thunkAPI) => {
    const response = await developersGet(developerId, thunkAPI)
    return { developer: response }
})

export const addDeveloper = createAsyncThunk<
    { developer: Developer },
    { developer: Partial<Developer>; onSuccess: () => void },
    { state: RootState }
>('addDeveloper', async ({ developer, onSuccess }, thunkAPI) => {
    const response = await developersCreate(developer as Developer, thunkAPI)
    onSuccess()
    thunkAPI.dispatch(fetchDevelopers())
    return { developer: response }
})

export const updateDeveloper = createAsyncThunk<
    { developer: Developer },
    { developer: Developer; onSuccess: () => void },
    { state: RootState }
>('updateDeveloper', async ({ developer, onSuccess }, thunkAPI) => {
    const response = await developersUpdate(developer, thunkAPI)
    onSuccess()
    return { developer: response }
})

export const deleteDevelopers = createAsyncThunk<{}, string[], { state: RootState }>('deleteDevelopers', async (ids, thunkAPI) => {
    await developersDelete(ids, thunkAPI)
    thunkAPI.dispatch(fetchDevelopers())
    return {}
})
