import { useEffect } from 'react'
import { fetchAssistant, fetchAssistants } from '../actions/assistants'
import { clearSelected } from '../reducers/assisntants'
import { useDispatch, useSelector } from '../reducers/store'

export function useAssistants() {
    const dispatch = useDispatch()
    const assistants = useSelector(store => store.assistants.assistants)
    useEffect(() => {
        if (assistants?.length > 0) {
            return
        }
        dispatch(fetchAssistants())
    }, [dispatch, assistants])
}

export function useAssistant(assistantId: string) {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchAssistant(assistantId))
        return () => {
            dispatch(clearSelected())
        }
    }, [assistantId, dispatch])
}

export function getId(uri: string) {
    const tokens = uri.split('/')
    return tokens[tokens.length - 1]
}
