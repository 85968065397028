import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { addWikiArticle, fetchWikiArticle, fetchWikiArticles } from '../actions/wiki'
import { WikiArticle } from '../types/blog'
import { Filter, Sorter } from '../types/filter'

type WikiSlice = {
    articles: WikiArticle[]
    selectedArticle?: WikiArticle
    pending?: boolean
    filters: Filter[]
    sorters: Sorter[]
}

export const wikiReducer = createSlice({
    name: 'wiki',
    initialState: {
        articles: [],
        filters: [],
        sorters: []
    } as WikiSlice,
    reducers: {
        clearSelected: state => {
            state.selectedArticle = undefined
        },
        addFilter: (state, action: PayloadAction<Filter>) => {
            const existing = state.filters.find(item => item.path === action.payload.path)
            if (existing) {
                existing.value = action.payload.value
            } else {
                state.filters.push(action.payload)
            }
        },
        removeFilter: (state, action: PayloadAction<string>) => {
            state.filters = state.filters.filter(item => item.path !== action.payload)
        },
        removeAllFilters: state => {
            state.filters = []
        }
    },
    extraReducers: builder => {
        builder
            .addCase(fetchWikiArticles.fulfilled, (state, action) => {
                state.articles = action.payload.articles
            })
            .addCase(fetchWikiArticle.fulfilled, (state, action) => {
                state.selectedArticle = action.payload.article
            })
            .addCase(addWikiArticle.fulfilled, state => {
                state.selectedArticle = undefined
            })
    }
})

export const { clearSelected, removeFilter, removeAllFilters, addFilter } = wikiReducer.actions
