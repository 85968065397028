import { createAsyncThunk } from '@reduxjs/toolkit'
import {
    categoryIndustryGetAll,
    categoryIndustryCreate,
    categoryIndustryGet,
    categoryIndustryDelete,
    categoryIndustryUpdate
} from '../api/categoryIndustry'
import { RootState } from '../reducers/store'
import { CategoryIndustry } from '../types/categoryIndustry'

export const fetchCategoriesIndustry = createAsyncThunk<{ category: CategoryIndustry[] }, void, { state: RootState }>(
    'fetchCategoriesIndustry',
    async (_, thunkAPI) => {
        const response = await categoryIndustryGetAll(thunkAPI)
        return { category: response }
    }
)

export const fetchCategoryIndustry = createAsyncThunk<{ category: CategoryIndustry }, string, { state: RootState }>(
    'fetchCategoryIndustry',
    async (categoryId: string, thunkAPI) => {
        const response = await categoryIndustryGet(categoryId, thunkAPI)
        return { category: response }
    }
)

export const addCategoryIndustry = createAsyncThunk<
    { category: CategoryIndustry },
    { category: Partial<CategoryIndustry>; onSuccess: () => void },
    { state: RootState }
>('addCategoryIndustry', async ({ category, onSuccess }, thunkAPI) => {
    const response = await categoryIndustryCreate(category as CategoryIndustry, thunkAPI)
    onSuccess()
    thunkAPI.dispatch(fetchCategoriesIndustry())
    return { category: response }
})

export const updateCategoryIndustry = createAsyncThunk<
    { category: CategoryIndustry },
    { category: CategoryIndustry; onSuccess: () => void },
    { state: RootState }
>('updateCategoryIndustry', async ({ category, onSuccess }, thunkAPI) => {
    const response = await categoryIndustryUpdate(category, thunkAPI)
    onSuccess()
    return { category: response }
})

export const deleteCategoryIndustry = createAsyncThunk<{}, string[], { state: RootState }>(
    'deleteCategoryIndustry',
    async (ids, thunkAPI) => {
        await categoryIndustryDelete(ids, thunkAPI)
        thunkAPI.dispatch(fetchCategoriesIndustry())
        return {}
    }
)
