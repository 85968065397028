import { createAsyncThunk } from '@reduxjs/toolkit'
import {
    regionalCenters,
    regionalCenterGet,
    regionalCenterCreate,
    regionalCenterDelete,
    regionalCenterUpdate
} from '../api/regionalCenters'
import { RootState } from '../reducers/store'
import { RegionalCenter } from '../types/regionalCenter'

export const fetchRegionalCenters = createAsyncThunk<{ centers: RegionalCenter[] }, void, { state: RootState }>(
    'fetchRegionalCenters',
    async (_, thunkAPI) => {
        const response = await regionalCenters(thunkAPI)
        return { centers: response }
    }
)

export const fetchRegionalCenter = createAsyncThunk<{ center: RegionalCenter }, string, { state: RootState }>(
    'fetchRegionalCenter',
    async (centerId: string, thunkAPI) => {
        const response = await regionalCenterGet(centerId, thunkAPI)
        return { center: response }
    }
)

export const addRegionalCenter = createAsyncThunk<
    { center: RegionalCenter },
    { center: Partial<RegionalCenter>; onSuccess: () => void },
    { state: RootState }
>('addRegionalCenter', async ({ center, onSuccess }, thunkAPI) => {
    const response = await regionalCenterCreate(center as RegionalCenter, thunkAPI)
    onSuccess()
    thunkAPI.dispatch(fetchRegionalCenters())
    return { center: response }
})

export const deleteRegionalCenters = createAsyncThunk<{}, string[], { state: RootState }>(
    'deleteRegionalCenters',
    async (ids, thunkAPI) => {
        await regionalCenterDelete(ids, thunkAPI)
        thunkAPI.dispatch(fetchRegionalCenters())
        return {}
    }
)

export const updateRegionalCenter = createAsyncThunk<
    { center: RegionalCenter },
    { center: RegionalCenter; onSuccess: () => void },
    { state: RootState }
>('updateRegionalCenter', async ({ center, onSuccess }, thunkAPI) => {
    const response = await regionalCenterUpdate(center, thunkAPI)
    onSuccess()
    return { center: response }
})
