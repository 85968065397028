import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ProjectCard } from '../../components/ProjectCard/ProjectCard'
import { ProjectFilters } from '../../components/SearchBar/ProjectFilters'
import { SearchBar } from '../../components/SearchBar/SearchBar'
import { Dropdown } from '../../components/ui/Dropdown/Dropdown'
import { useProjects } from '../../hooks/useProjects'
import { useDispatch, useSelector } from '../../reducers/store'
import { updateSorter } from '../../reducers/projects'
import { cn } from '../../utils/cn'
import styles from './ProjectsPage.module.css'
import useGtmPageLoad from '../../hooks/useGtmPageLoad'

/**
 * This page contains a list and search/filtration controls for projects
 */
export const ProjectsPage: FC = () => {
    useGtmPageLoad('ProjectsPage')
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const projects = useSelector(store => store.projects.projects)
    const sorter = useSelector(store => store.projects.sorters?.find(item => item.fieldName === 'constructionEndPlanned'))
    useProjects(undefined, sorter)

    const projectCountPublished = useMemo(() => projects?.filter(projectItem => projectItem.published)?.length || 0, [projects])

    const sorters = [
        {
            value: 'desc',
            label: t('Date from new to old')
        },
        {
            value: 'asc',
            label: t('Date from old to new')
        }
    ]

    const handleSortChange = (direction: any) => {
        dispatch(
            updateSorter({
                fieldName: 'constructionEndPlanned',
                direction
            })
        )
    }

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <h1 className={styles.title}>{t('EB-5 Projects')}</h1>
                <span className={styles.total}>{projectCountPublished}</span>
            </div>
            <div className={styles.searchContainer}>
                <SearchBar type="projects" placeholder={t('Search projects')} className={styles.search} />
                <Dropdown
                    options={sorters}
                    className={cn(styles.sorters, styles.desktop)}
                    value={sorter?.direction}
                    onChange={handleSortChange}
                />
            </div>
            <div className={styles.content}>
                <div className={cn(styles.desktop, styles.filters)}>
                    <ProjectFilters />
                </div>
                <ul className={styles.list}>
                    {projects
                        ?.filter(i => i.published)
                        ?.map(item => (
                            <ProjectCard key={item.id} project={item} className={styles.item} />
                        ))}
                </ul>
            </div>
        </div>
    )
}
