export interface ILanguageCodeContent {
    [key: string]: {
        language: string
    }
}

export const languageCode: ILanguageCodeContent = {
    en: { language: 'English' },
    es: { language: 'Spanish' },
    ru: { language: 'Russian' }
}
