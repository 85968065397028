import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from '../../reducers/store'
import styles from './ProposalsAdminEditPage.module.css'
import { Form } from 'antd'
import FormItem from 'antd/lib/form/FormItem'
import { Button } from '../../components/ui/Button/Button'
import { addProposal, updateProposal } from '../../actions/jobs'
import { Proposal } from '../../types/jobs'
import { useExperts } from '../../hooks/useExperts'
import { Dropdown } from '../../components/ui/Dropdown/Dropdown'
import { useProposal } from '../../hooks/useJobs'
import { Input } from '../../components/ui/Input/Input'
import { useAssistants } from '../../hooks/useAssistants'
import { SimpleRichEditor } from '../../components/ui/SimpleRichEditor/SimpleRichEditor'
import useGtmPageLoad from '../../hooks/useGtmPageLoad'

const statusOptions = [
    { value: 'OPEN', label: 'Open Proposal' },
    { value: 'SELECTED', label: 'User selected an expert' },
    { value: 'CALL_CANCELLED', label: 'Zoom Call is cancelled' },
    { value: 'CALL_COMPLETED', label: 'Zoom Call is completed' },
    { value: 'NOT_ELIGIBLE', label: 'Project is not eligible' },
    { value: 'ASSISTANT_MESSAGE', label: 'Assistant message' },
    { value: 'COMPANY_MESSAGE', label: 'Company message' }
]

// TODO: Will be delete in favor ProposalsDirectAdminEditPage. CD-121

export const ProposalsAdminEditPage = () => {
    useGtmPageLoad('ProposalsAdminEditPage')
    const dispatch = useDispatch()
    useExperts()
    useAssistants()
    const experts =
        useSelector(store => store.experts.experts)?.map(item => ({ value: item.id, label: `${item.firstName} ${item.lastName}` })) ?? []
    const assistants = useSelector(store => store.assistants.assistants)?.map(item => ({ value: item.id ?? '', label: item.name })) ?? []
    const history = useHistory()
    const [form] = Form.useForm()
    const { jobId, id } = useParams<{ jobId: string; id: string }>()
    const isNew = id === 'new'
    useProposal(id)
    const selectedProposal = useSelector(store => store.jobs.selectedProposal)
    const [assistantDisabled, setAssistantDisabled] = useState(true)
    useEffect(() => {
        if (['ASSISTANT_MESSAGE'].includes(selectedProposal?.status ?? '')) {
            setAssistantDisabled(false)
        }
    }, [selectedProposal?.status])

    const availableStatusOptions = useMemo(() => {
        return !selectedProposal?.initiatedByUser
            ? statusOptions.filter(item => item.value !== 'SELECTED' && item.value !== 'NOT_ELIGIBLE')
            : statusOptions
    }, [selectedProposal?.initiatedByUser])

    const handleCancel = () => {
        history.push(`/jobs-admin/${jobId}`)
    }

    const handleAdd = (values: Record<string, any>) => {
        const proposal: Proposal = {
            job: {
                id: jobId
            },
            expert: values['expert.id']
                ? {
                      id: values['expert.id']
                  }
                : undefined,
            assistant: values['assistant.id']
                ? {
                      id: values['assistant.id']
                  }
                : undefined,
            initiatedByUser: false,
            status: values.status,
            manuallyScheduledCall: values.manuallyScheduledCall,
            message: values.message
        } as Proposal
        dispatch(addProposal({ proposal, onSuccess: handleCancel }))
    }

    const handleUpdate = (values: Record<string, any>) => {
        const proposal: Proposal = {
            id: id,
            expert: values['expert.id']
                ? {
                      id: values['expert.id']
                  }
                : undefined,
            assistant: values['assistant.id']
                ? {
                      id: values['assistant.id']
                  }
                : undefined,
            manuallyScheduledCall: values.manuallyScheduledCall,
            status: values.status,
            message: values.message
        } as Proposal
        dispatch(updateProposal({ proposal, onSuccess: handleCancel }))
    }

    if (!selectedProposal && !isNew) {
        return null
    }

    const handleChange = (values: Record<string, any>) => {
        if ('ASSISTANT_MESSAGE' === values.status && assistantDisabled) {
            setAssistantDisabled(false)
        }
        if (values.status && values.status !== 'ASSISTANT_MESSAGE') {
            setAssistantDisabled(true)
            form.setFields([{ name: 'assistant.id', value: null }])
        }
    }

    return (
        <div className={styles.container}>
            <Form
                className={styles.form}
                form={form}
                layout="vertical"
                initialValues={selectedProposal}
                onValuesChange={handleChange}
                onFinish={isNew ? handleAdd : handleUpdate}
                autoComplete="off"
            >
                <FormItem
                    label="Assign an expert to this job"
                    name="expert.id"
                    wrapperCol={{ span: 8 }}
                    initialValue={selectedProposal?.expert?.id ? String(selectedProposal?.expert?.id) : undefined}
                >
                    <Dropdown options={experts} disabled={selectedProposal?.initiatedByUser} />
                </FormItem>
                <FormItem
                    label="Assign assistant to this job"
                    name="assistant.id"
                    wrapperCol={{ span: 8 }}
                    initialValue={selectedProposal?.assistant?.id ? String(selectedProposal?.assistant.id) : undefined}
                >
                    <Dropdown options={assistants} disabled={assistantDisabled} />
                </FormItem>
                <FormItem label="Proposal message" name="message">
                    <SimpleRichEditor />
                </FormItem>
                <FormItem label="Scheduled Call" name="manuallyScheduledCall">
                    <Input placeholder="Zoom Call at 12 APR 2021, 09:15 AM" />
                </FormItem>
                <FormItem label="Status" name="status">
                    <Dropdown options={availableStatusOptions} />
                </FormItem>
            </Form>
            <div className={styles.footer}>
                <Button type="ghost" onClick={handleCancel} className={styles.button}>
                    Cancel
                </Button>
                <Button onClick={form.submit} style={{ width: 'auto' }} className={styles.button}>
                    Save
                </Button>
            </div>
        </div>
    )
}
