import React from 'react'
import { Modal } from 'antd'
import styles from './SuccessPage.module.css'
import { ReactComponent as LogoGhost } from '../../assets/citizon-logo-ghost.svg'
import { ReactComponent as Success } from '../../assets/success.svg'
import { ReactComponent as Mail } from '../../assets/mail.svg'
import { useSelector } from '../../reducers/store'
import { Link, useParams } from 'react-router-dom'
import { Page } from '../../components/Page/Page'
import { Trans, useTranslation } from 'react-i18next'
import { Button } from '../../components/ui/Button/Button'
import { cn } from '../../utils/cn'
import useGtmPageLoad from '../../hooks/useGtmPageLoad'
import useMobileView from '../../hooks/useMobileView'

type SuccessPageType = 'email' | 'sms' | 'create-project' | 'expert-review'

export interface SuccessPageProps {
    forceType?: SuccessPageType
    page?: boolean
    className?: string
}

export const SuccessPage = ({ forceType, className, page = true }: SuccessPageProps) => {
    useGtmPageLoad('SuccessPage')
    const { t } = useTranslation()
    const { isMobile } = useMobileView()
    const { type = forceType } = useParams<{ type: SuccessPageType }>()
    const email = useSelector(store => store.session.requestedEmail)
    const projectName = useSelector(store => store.session.requestedProject)
    const title = useTitle(type!)
    const isSignUp = type === 'email' || type === 'sms'
    const content = (
        <div className={cn(styles.container, className)}>
            {isSignUp && (
                <Link to="/home">
                    <LogoGhost className={styles.logo} />
                </Link>
            )}
            {!isSignUp && <Success className={styles.success} />}
            {isSignUp && (
                <div className={styles.mail}>
                    <Mail />
                </div>
            )}
            <h1 className={styles.title}>{title}</h1>
            {isSignUp && (
                <>
                    {type === 'email' && (
                        <>
                            <div className={styles.text}>
                                <span>{t('We sent your private link')}</span>
                                <span className={styles.email}>{email}</span>
                            </div>
                            <div className={styles.check}>
                                <Trans i18nKey="If you unable to find the email">
                                    If you unable to find the email, please check your <b>spam folder</b>
                                </Trans>
                            </div>
                        </>
                    )}
                    {type === 'sms' && (
                        <div className={styles.text}>
                            <span>{t('We sent your private link SMS')}</span>
                            <span className={styles.email}>
                                {email?.toString()?.replace(email?.toString()?.substr(-13, 13), ' ' + email?.toString()?.substr(-13, 13))}
                            </span>
                            <div className={styles.check}>{t('Check sms')}</div>
                        </div>
                    )}
                </>
            )}
            {type === 'create-project' && (
                <div className={styles.text}>
                    <Trans i18nKey="Project Created Description">
                        <b>{projectName}</b> was published. You can find it and applications from the top experts on your Citizon home page.
                        {`\nThe experts will send proposals within 1-2 days.`}
                    </Trans>
                </div>
            )}
            {type === 'expert-review' && (
                <div className={styles.text}>{t(`Your review will appear on the Expert's page after moderation.`)}</div>
            )}
            {!isSignUp && (
                <Link to="/" className={styles.button}>
                    <Button>OK</Button>
                </Link>
            )}
        </div>
    )

    if (isSignUp || !page) {
        return content
    }
    return isSignUp || isMobile ? (
        <Page page={() => content} noFooter={true} />
    ) : (
        <Page
            page={() => (
                <Modal className={styles.modal} visible={true} footer={null} closable={false} maskClosable={false}>
                    {content}
                </Modal>
            )}
        />
    )
}

function useTitle(type: SuccessPageType) {
    const { t } = useTranslation()
    if (type === 'email' || type === 'sms') {
        return t('Your sign in link is on its way!')
    }
    if (type === 'create-project') {
        return t('Project Created')
    }
    if (type === 'expert-review') {
        return t('Review Sent')
    }
}
