import React, { useEffect } from 'react'
import { cn } from '../../utils/cn'
import { useHistory, useLocation } from 'react-router-dom'

import { AppBar } from '../AppBar/AppBar'
import { LoginPage } from '../../pages/LoginPage/LoginPage'
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary'
import { ErrorModal } from '../ErrorModal/ErrorModal'
import { CountryErrorModal } from '../CountryErrorModal/CountryErrorModal'
import { LegalFooter } from '../ui/LegalFooter/LegalFooter'

import useScrollToTop from '../../hooks/useScrollToTop'

import { useSelector } from '../../reducers/store'

import { EError } from '../../constants/error'

import styles from './Page.module.css'

export interface PageProps {
    /**
     * Component to render the page
     */
    page: React.ComponentType<any>
    /**
     * Previous route for 'back' button
     */
    previous?: string
    /**
     * Breadcrumbs title for the page. When empty, backward button is not shown
     */
    title?: string
    /**
     * If true header background image and logo will be shown
     */
    showLogo?: boolean
    /**
     * If true page will be shown partially with suggestion to sign up
     */
    semipublic?: boolean
    /**
     * Hide login block for semipublic users
     */
    hideSemipublicLogin?: boolean
    /**
     * Disable footer
     */
    noFooter?: boolean
}

/**
 * Main layout control for selected page
 */
export const Page = ({
    title = '',
    page,
    previous = '',
    showLogo = false,
    noFooter = false,
    semipublic,
    hideSemipublicLogin,
    ...rest
}: PageProps) => {
    const history = useHistory()
    const { pathname } = useLocation()
    const user = useSelector(store => store.session.user)
    const errorData = useSelector(store => store.session.error)
    useScrollToTop()
    const Component = page
    const hasExpiredError = errorData?.errorCode === EError.TokenExpired

    useEffect(() => {
        // TODO fix to access list paths
        if (
            !user &&
            pathname !== '/home' &&
            !pathname.includes('/projects') &&
            !pathname.includes('/experts') &&
            !pathname.includes('/wiki') &&
            !pathname.includes('/blog') &&
            !pathname.includes('/requests-admin')
        ) {
            history.push('/login')
        }
    }, [user, history, pathname])

    useEffect(() => {
        if (hasExpiredError) {
            history.push('/expired')
        }
    }, [errorData, hasExpiredError, history])

    return (
        <ErrorBoundary>
            <div className={cn(styles.container, { [styles.unlogged]: !user && !hideSemipublicLogin })}>
                <AppBar />
                <ErrorModal />
                <CountryErrorModal />
                <div className={styles.content} id="content">
                    <Component {...rest} />
                </div>
                {semipublic && !user && !hideSemipublicLogin && (
                    <div className={styles.semipublic}>
                        <div className={styles.blur} />
                        <LoginPage embedded />
                    </div>
                )}
                {!!user && !hasExpiredError && !noFooter && <LegalFooter />}
            </div>
        </ErrorBoundary>
    )
}
