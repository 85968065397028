import { createAsyncThunk } from '@reduxjs/toolkit'
import { jobsCreate, jobsGet, jobsGetAll } from '../api/jobs'
import { RootState } from '../reducers/store'
import { Job, Proposal } from '../types/jobs'
import { sendDelete, sendGet, sendPost, sendPut } from '../utils/api'
import { ProposalDirect } from '../types/proposalsDitect'
import {
    proposalsDirectCreate,
    proposalsDirectDelete,
    proposalsDirectGet,
    proposalsDirectGetAll,
    proposalsDirectUpdate
} from '../api/proposalsDirect'

export const fetchJobs = createAsyncThunk<{ jobs: Job[] }, void>('fetchJobs', async (_, thunkAPI) => {
    const response = await jobsGetAll(thunkAPI)
    return { jobs: response }
})

export const fetchJob = createAsyncThunk('fetchJob', async (jobId: string, thunkAPI) => {
    const response = await jobsGet(jobId, thunkAPI)
    return { job: response }
})

export const addJob = createAsyncThunk<{ job: Job }, { job: Partial<Job>; onSuccess?: () => void }, { state: RootState }>(
    'addJob',
    async ({ job, onSuccess }, thunkAPI) => {
        const response = await jobsCreate(job as Job, thunkAPI)
        onSuccess?.()
        thunkAPI.dispatch(fetchJobs())
        return { job: response }
    }
)

export const updateJob = createAsyncThunk<{ job: Job }, { job: Partial<Job>; onSuccess?: () => void }, { state: RootState }>(
    'updateJob',
    async ({ job, onSuccess }, thunkAPI) => {
        const response = await sendPut(`/jobs/${job.id}`, thunkAPI, job)
        onSuccess?.()
        thunkAPI.dispatch(fetchJobs())
        return { job: response }
    }
)

export const fetchAllJobs = createAsyncThunk<{ jobs: Job[] }, void, { state: RootState }>('fetchAllJobs', async (_, thunkAPI) => {
    const params = new URLSearchParams()
    const filters = thunkAPI.getState().jobs.filters
    filters?.forEach(filter => {
        params.append(`_${filter.path}.${filter.operation}`, filter.value)
    })
    const response = await sendGet(`/jobs-all?${params.toString()}`, thunkAPI)
    return { jobs: response }
})

export const fetchProposalsDirect = createAsyncThunk<{ proposals: ProposalDirect[] }, void>('fetchProposalsDirect', async (_, thunkAPI) => {
    const response = await proposalsDirectGetAll(thunkAPI)
    return { proposals: response }
})

export const fetchProposalDirect = createAsyncThunk('fetchProposalDirect', async (proposalId: string, thunkAPI) => {
    const response = await proposalsDirectGet(proposalId, thunkAPI)
    return { proposal: response }
})

export const deleteProposalDirect = createAsyncThunk<{}, string[], { state: RootState }>('deleteProposalDirect', async (ids, thunkAPI) => {
    await proposalsDirectDelete(ids, thunkAPI)
    thunkAPI.dispatch(fetchProposalsDirect())
    return {}
})

export const addProposalDirect = createAsyncThunk<
    { proposal: ProposalDirect },
    { proposal: Partial<ProposalDirect>; onSuccess: () => void },
    { state: RootState }
>('addProposalDirect', async ({ proposal, onSuccess }, thunkAPI) => {
    const response = await proposalsDirectCreate(proposal as ProposalDirect, thunkAPI)
    onSuccess()
    thunkAPI.dispatch(fetchProposalsDirect())
    return { proposal: response }
})

export const updateProposalDirect = createAsyncThunk<
    { proposal: ProposalDirect },
    { proposal: ProposalDirect; onSuccess: () => void },
    { state: RootState }
>('updateProposalDirect', async ({ proposal, onSuccess }, thunkAPI) => {
    const response = await proposalsDirectUpdate(proposal, thunkAPI)
    onSuccess()
    return { proposal: response }
})

export const fetchProposal = createAsyncThunk<{ proposal: Proposal }, string, { state: RootState }>(
    'fetchProposal',
    async (proposalId: string, thunkAPI) => {
        const response = await sendGet(`/proposals/${proposalId}`, thunkAPI)
        return { proposal: response }
    }
)

export const addProposal = createAsyncThunk<{ proposal: Proposal }, { proposal: Proposal; onSuccess: () => void }, { state: RootState }>(
    'addProposal',
    async ({ proposal, onSuccess }, thunkAPI) => {
        const response = await sendPost(`/proposals`, thunkAPI, proposal)
        onSuccess?.()
        thunkAPI.dispatch(fetchJobs())
        return { proposal: response }
    }
)

export const updateProposal = createAsyncThunk<{ proposal: Proposal }, { proposal: Proposal; onSuccess: () => void }, { state: RootState }>(
    'updateProposal',
    async ({ proposal, onSuccess }, thunkAPI) => {
        const response = await sendPut(`/proposals`, thunkAPI, proposal)
        onSuccess?.()
        thunkAPI.dispatch(fetchJobs())
        return { proposal: response }
    }
)

export const readAllProposals = createAsyncThunk<void, { jobId: string }>('readAllProposals', async ({ jobId }, thunkAPI) => {
    await sendPost(`/jobs/${jobId}/read-proposals`, thunkAPI)
})

export const deleteProposals = createAsyncThunk<{}, string[], { state: RootState }>('deleteProposals', async (ids, thunkAPI) => {
    await sendDelete(`/proposals`, thunkAPI, ids)
    thunkAPI.dispatch(fetchAllJobs())
    return {}
})
