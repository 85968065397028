import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { DashboardButton } from '../../components/ui/DashboardButton/DashboardButton'
import { ReactComponent as ImmigrationIcon } from '../../assets/immigration-icon.svg'
import { ReactComponent as RegionalCenterIcon } from '../../assets/regional-center-icon.svg'
import { ReactComponent as EducationIcon } from '../../assets/education-icon.svg'
import { ReactComponent as BlogIcon } from '../../assets/blog-icon.svg'
import { ReactComponent as SearchIcon } from '../../assets/search-icon.svg'
import styles from './KnowledgeCenterHomePage.module.css'
import { Or } from '../../components/ui/Or/Or'
import { SearchInput } from '../../components/SearchBar/SearchInput'
import { cn } from '../../utils/cn'
import { useWikiArticles } from '../../hooks/useWikiArticles'
import { useSelector } from '../../reducers/store'
import { getLocaleValue } from '../../utils/string'
import { useBlogArticles } from '../../hooks/useBlogArticles'
import { Button } from '../../components/ui/Button/Button'
import useGtmPageLoad from '../../hooks/useGtmPageLoad'
import { sortArrayByField } from '../../utils/array'

export const KnowledgeCenterHomePage = () => {
    useGtmPageLoad('KnowledgeCenterHomePage')
    const { t } = useTranslation()
    useWikiArticles()
    useBlogArticles()
    const locale = useSelector(store => store.session.lang)
    const blogArticles = useSelector(store => store.blog.articles)
    const articles = useSelector(store => store.wiki.articles)?.filter(item => item.root)
    const immigrationArticles = useMemo(
        () =>
            sortArrayByField(
                articles?.filter(item => item.section === 'immigration'),
                'ord'
            ),
        [articles]
    )
    const rcArticles = useMemo(
        () =>
            sortArrayByField(
                articles?.filter(item => item.section === 'regionalCenter'),
                'ord'
            ),
        [articles]
    )
    const educationArticles = useMemo(
        () =>
            sortArrayByField(
                articles?.filter(item => item.section === 'education'),
                'ord'
            ),
        [articles]
    )
    const blogArticlesSorted = useMemo(() => sortArrayByField(blogArticles, 'ord'), [blogArticles])
    return (
        <div className={styles.container}>
            <h1 className={styles.title}>{t('Knowledge Center')}</h1>
            <SearchInput
                className={styles.search}
                prefix={<SearchIcon className={styles.icon} />}
                placeholder={t('Search Visas and more')}
            />
            <nav className={styles.navigation}>
                <section className={styles.link}>
                    <Link to="/wiki/immigration">
                        <DashboardButton
                            ghost
                            icon={<ImmigrationIcon />}
                            className={styles.button}
                            name={t('Immigration to USA')}
                            description={t('All About Visas')}
                        />
                    </Link>
                    <ul className={cn(styles.desktop, styles.list)}>
                        {immigrationArticles?.map(item => {
                            return (
                                <li key={item.id} className={styles.item}>
                                    <Link to={`/wiki-articles/${item.id}`} className={styles.link}>
                                        {getLocaleValue(item, 'title', locale)}
                                    </Link>
                                </li>
                            )
                        })}
                    </ul>
                </section>
                <section className={styles.link}>
                    <Link to="/wiki/regionalCenter">
                        <DashboardButton
                            ghost
                            icon={<RegionalCenterIcon />}
                            className={styles.button}
                            name={t('Regional Center')}
                            description={t(`What's That`)}
                        />
                    </Link>
                    <ul className={cn(styles.desktop, styles.list)}>
                        {rcArticles?.map(item => {
                            return (
                                <li key={item.id} className={styles.item}>
                                    <Link to={`/wiki-articles/${item.id}`} className={styles.link}>
                                        {getLocaleValue(item, 'title', locale)}
                                    </Link>
                                </li>
                            )
                        })}
                    </ul>
                </section>
                <section className={styles.link}>
                    <Link to="/wiki/education">
                        <DashboardButton
                            ghost
                            icon={<EducationIcon />}
                            className={styles.button}
                            name={t('Education in USA')}
                            description={t('Like Piece of Cake')}
                        />
                    </Link>
                    <ul className={cn(styles.desktop, styles.list)}>
                        {educationArticles?.map(item => {
                            return (
                                <li key={item.id} className={styles.item}>
                                    <Link to={`/wiki-articles/${item.id}`} className={styles.link}>
                                        {getLocaleValue(item, 'title', locale)}
                                    </Link>
                                </li>
                            )
                        })}
                    </ul>
                </section>
                <section className={cn(styles.desktop, styles.link)}>
                    <Link to="/blog">
                        <DashboardButton
                            ghost
                            icon={<BlogIcon />}
                            className={styles.button}
                            name={t('Blog')}
                            description={t('About a regular life and their features')}
                        />
                    </Link>
                    <ul className={cn(styles.desktop, styles.list)}>
                        {blogArticlesSorted?.map(item => {
                            return (
                                <li key={item.id} className={styles.item}>
                                    <Link to={`/blog/${item.id}`} className={styles.link}>
                                        {getLocaleValue(item, 'title', locale)}
                                    </Link>
                                </li>
                            )
                        })}
                        <Link to="/blog">
                            <Button type="ghost" style={{ marginTop: '32px' }}>
                                {t('Read all N posts', { count: blogArticles?.length })}
                            </Button>
                        </Link>
                    </ul>
                </section>
            </nav>
            <Or className={styles.mobile} />
            <Link to="/blog" className={styles.mobile}>
                <DashboardButton
                    icon={<BlogIcon />}
                    className={styles.button}
                    name={t('Blog')}
                    description={t('About a regular life and their features')}
                />
            </Link>
        </div>
    )
}
