import React, { useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { BASE_FILE_URL } from '../../api/endpoints'
import { CurrentTime } from '../../components/ui/CurrentTime/CurrentTime'
import { Person } from '../../components/Person/Person'
import { PersonList } from '../../components/Person/PersonList'
import { Progress } from '../../components/ui/Progress/Progress'
import { ProjectCard } from '../../components/ProjectCard/ProjectCard'
import { ShortText } from '../../components/ui/ShortText/ShortText'
import { StatsBlock } from '../../components/ui/StatsBlock/StatsBlock'
import { StatsTable, StatsTableItem } from '../../components/ui/StatsTable/StatsTable'
import { useProjects } from '../../hooks/useProjects'
import { useRegionalCenter } from '../../hooks/useRegionalCenters'
import { useDispatch, useSelector } from '../../reducers/store'
import { FilterOperation } from '../../types/filter'
import { cn } from '../../utils/cn'
import styles from './RegionalCenterProfilePage.module.css'
import { Empty } from 'antd'
import { useTranslation } from 'react-i18next'
import { SetAppointmentButton } from '../../components/ui/SetAppointmentButton/SetAppointmentButton'
import { useLocalizedStates } from '../../hooks/useLocalizedStates'
import { getLocaleValue } from '../../utils/string'
import { addCallPayload } from '../../reducers/session'
import { AttachedDocuments } from '../../components/ui/AttachedDocuments/AttachedDocuments'
import { File } from '../../types/file'
import { BottomSheet } from '../../components/ui/BottomSheet/BottomSheet'
import useGtmPageLoad from '../../hooks/useGtmPageLoad'

/**
 * This page contains a profile for selecter regional center
 */
export const RegionalCenterProfilePage = () => {
    useGtmPageLoad('RegionalCenterProfilePage')
    const { t } = useTranslation()
    const isDesktop = window.screen.width > 960
    const dispatch = useDispatch()
    const states = useLocalizedStates()
    const { regionalCenterId: routeId } = useParams<{ regionalCenterId: string }>()
    const filter = useMemo(() => {
        return { path: 'regionalCenter.id', operation: FilterOperation.IN, value: routeId }
    }, [routeId])
    useProjects(filter)
    useRegionalCenter(routeId)
    const locale = useSelector(store => store.session.lang)
    const regionalCenter = useSelector(store => store.regionalCenters.selectedCenter)
    const projects = useSelector(store => store.projects.projects)?.filter(projectItem => projectItem.published)
    const user = useSelector(store => store.session.user)

    const renderCurrentTime = useCallback(({ item }) => {
        return <CurrentTime showOffset timezone={item.value as number} />
    }, [])

    const city = getLocaleValue(regionalCenter, 'city', locale)

    const statsItems: StatsTableItem[] = [
        { name: t('HQ'), value: `${city}, ${states[regionalCenter?.state ?? '']}` },
        {
            name: t('Local time'),
            value: regionalCenter?.timezone,
            render: renderCurrentTime
        },
        { name: t('Founded'), value: regionalCenter?.founded },
        { name: t('States'), value: regionalCenter?.states?.map(item => states[item]).join(', ') }
    ]
    const eb5Projects = useMemo(
        () => ({
            [t('Returned Capital')]: regionalCenter?.eb5CompletedProjects,
            [t('In Progress')]: regionalCenter?.eb5ProgressProjects,
            [t('Total')]: regionalCenter?.eb5TotalProjects
        }),
        [regionalCenter?.eb5CompletedProjects, regionalCenter?.eb5ProgressProjects, regionalCenter?.eb5TotalProjects, t]
    )
    const approvedPetitions = useMemo(
        () => [
            { name: 'I-526', value: regionalCenter?.i526 },
            { name: 'I-829', value: regionalCenter?.i829, alternate: true }
        ],
        [regionalCenter?.i526, regionalCenter?.i829]
    )

    const trackRecord = useMemo(
        () => ({
            [t('Raised')]: getLocaleValue(regionalCenter, 'raised', locale),
            [t('Returned')]: getLocaleValue(regionalCenter, 'returned', locale)
        }),
        [regionalCenter, t, locale]
    )

    const handleCenterAppointment = useCallback(() => {
        dispatch(
            addCallPayload({
                regionalCenterId: routeId
            })
        )
    }, [dispatch, routeId])

    const localeFiles: File[] = useMemo(() => getLocaleValue(regionalCenter, 'files', locale), [locale, regionalCenter])

    const otherFiles = useMemo(() => {
        const result = Object.values(regionalCenter?.localizations || {})
            .filter(item => item.locale !== locale && item.files?.length > 0)
            .flatMap(item => item.files)
        if (locale !== 'en') {
            regionalCenter?.files?.forEach(item => result.push(item))
        }
        return result
    }, [regionalCenter?.files, regionalCenter?.localizations, locale])

    const managers = useMemo(() => [...(regionalCenter?.managers || [])]?.sort((a, b) => (a.ord > b.ord ? 1 : -1)), [regionalCenter])

    return (
        <>
            <div className={styles.container}>
                <div className={styles.content}>
                    <h1 className={cn(styles.padding, styles.majorTitle)}>{getLocaleValue(regionalCenter, 'name', locale)}</h1>
                    <StatsTable className={cn(styles.padding, styles.table, styles.mobile)} items={statsItems} />
                    <StatsTable className={cn(styles.padding, styles.table, styles.desktop)} items={statsItems} widths={['10%', '*']} />
                    <ShortText className={cn(styles.padding, styles.description)}>
                        {getLocaleValue(regionalCenter, 'description', locale)}
                    </ShortText>
                    <div className={styles.statsBlocks}>
                        <StatsBlock
                            title={t('EB-5 Projects')}
                            minorValue={regionalCenter?.updated && `${t('Updated')} ${formatDate(regionalCenter.updated, locale)}`}
                            items={eb5Projects}
                        />
                        <StatsBlock
                            className={styles.desktop}
                            title={t('Track Record')}
                            titleInfo={t('(i) in Track Record')}
                            items={trackRecord}
                        />
                    </div>
                    <h3 className={styles.title}>
                        {t('Petitions Approved')}
                        <BottomSheet
                            content={t('(i) in Petitions Approved')}
                            className={styles.icon}
                            size="dynamic"
                            title={t('Petitions Approved')}
                        />
                    </h3>
                    <Progress className={cn(styles.padding, styles.progress)} items={approvedPetitions} />
                    <StatsBlock
                        className={styles.mobile}
                        title={t('Track Record')}
                        titleInfo={t('(i) in Track Record')}
                        items={trackRecord}
                        size="dynamic"
                    />
                    <div className={styles.management}>
                        <h3 className={styles.title} style={{ marginTop: '0px' }}>
                            {t('Management team')}
                        </h3>
                        <PersonList className={styles.padding}>
                            {managers?.map((item, index) => (
                                <Person
                                    id={item.id}
                                    key={item.id}
                                    avatar={`${BASE_FILE_URL}/${item.photo}`}
                                    name={`${getLocaleValue(item, 'firstName', locale)} ${getLocaleValue(item, 'lastName', locale)}`}
                                    description={getLocaleValue(item, 'title', locale)}
                                    details={getLocaleValue(item, 'biography', locale)}
                                    className={cn(styles.person, { [styles.first]: index === 0 })}
                                />
                            ))}
                            {!managers?.length && <Empty />}
                        </PersonList>
                    </div>
                    <AttachedDocuments
                        title={t('Learn More About Regional Center')}
                        className={styles.docs}
                        localeFiles={localeFiles}
                        otherFiles={otherFiles}
                    />
                    <h3 className={styles.title} style={{ marginTop: '36px' }}>
                        {t('Active EB-5 Projects')}
                    </h3>
                    <div className={styles.padding}>
                        {projects?.map(item => (
                            <ProjectCard key={item.id} project={item} photo={isDesktop ? 'small' : 'large'} className={styles.project} />
                        ))}
                    </div>
                </div>
                <div className={cn(styles.desktop, styles.sider)}>
                    <SetAppointmentButton
                        float={false}
                        onClick={handleCenterAppointment}
                        noSubject={true}
                        noAssistant={true}
                        size="small"
                        textFor="regionalCenter"
                    />
                </div>
            </div>
            {user && (
                <SetAppointmentButton
                    className={styles.mobile}
                    onClick={handleCenterAppointment}
                    noSubject={true}
                    noAssistant={true}
                    size="dynamic"
                    textFor="regionalCenter"
                />
            )}
        </>
    )
}

function formatDate(date: string, locale: string) {
    const dateObject = new Date(date)
    return dateObject.toLocaleDateString('ru-ru')
}
