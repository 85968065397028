import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from '../reducers/store'
import { Notification } from '../types/notifications'
import { sendGet, sendPost } from '../utils/api'

export const fetchNotifications = createAsyncThunk<{ notifications: Notification[] }, void, { state: RootState }>(
    'fetchNotifications',
    async (_, thunkAPI) => {
        const response = await sendGet('/notifications', thunkAPI)
        return { notifications: response }
    }
)

export const readNotification = createAsyncThunk<{ notification: Notification }, number, { state: RootState }>(
    'readNotification',
    async (notificationId, thunkAPI) => {
        const response = await sendPost(`/notifications/${notificationId}`, thunkAPI)
        return { notification: response }
    }
)

export const readAllNotifications = createAsyncThunk<{ notification: Notification }, void, { state: RootState }>(
    'readAllNotifications',
    async (_, thunkAPI) => {
        const response = await sendPost(`/notifications`, thunkAPI)
        return { notification: response }
    }
)
