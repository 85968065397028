import React from 'react'
import { Avatar, Button, List } from 'antd'
import styles from './Person.module.css'
import { ReactComponent as RightIcon } from '../../assets/chevron-right.svg'
import { BottomSheet } from '../ui/BottomSheet/BottomSheet'
import { cn } from '../../utils/cn'

export interface PersonProps {
    id: string
    name: string
    avatar?: string
    description?: string
    details?: string
    className?: string
}

export const Person = ({ id, name, description, avatar, details, className }: PersonProps) => {
    if (details) {
        return (
            <BottomSheet
                title={<Person id={id} avatar={avatar} name={name} description={description} />}
                content={details}
                size="medium"
                footer={false}
            >
                <List.Item className={cn(styles.item, className)} style={{ cursor: 'pointer' }}>
                    <List.Item.Meta avatar={avatar && <Avatar size={48} src={avatar} />} title={name} description={description} />
                    <Button type="text" className={styles.details} icon={<RightIcon />} />
                </List.Item>
            </BottomSheet>
        )
    }

    return (
        <List.Item className={cn(styles.item, className)}>
            <List.Item.Meta avatar={avatar && <Avatar size={48} src={avatar} />} title={name} description={description} />
            {details && (
                <BottomSheet
                    title={<Person id={id} avatar={avatar} name={name} description={description} />}
                    content={details}
                    size="medium"
                >
                    <Button type="text" className={styles.details} icon={<RightIcon />} />
                </BottomSheet>
            )}
        </List.Item>
    )
}
