import { Avatar } from 'antd'
import React from 'react'
import RichTextEditor from 'react-rte'
import { cn } from '../../../utils/cn'
import { ShortText } from '../ShortText/ShortText'
import styles from './AssistantItem.module.css'

export interface AssistanceItemProps {
    avatarUrl: React.ReactNode
    title: string
    description: string
    message?: string
    short?: boolean
    rich?: boolean
    ghost?: boolean
    className?: string
    avaSize?: number | 'small' | 'large' | 'default'
    style?: React.CSSProperties
    hintClassName?: string
    onClick?: () => void
}

export const AssistantItem = ({
    className,
    style,
    avatarUrl,
    title,
    description,
    ghost,
    message,
    short,
    rich,
    avaSize = 'large',
    hintClassName,
    onClick
}: AssistanceItemProps) => {
    let displayedMessage = message && <p className={styles.message}>{message}</p>
    if (short) {
        displayedMessage = (
            <ShortText className={styles.message} rows={2} expandable={false}>
                {message}
            </ShortText>
        )
    }
    if (rich) {
        displayedMessage = (
            <RichTextEditor readOnly value={RichTextEditor.createValueFromString(message as any, 'markdown')} className={styles.message} />
        )
    }
    return (
        <div className={cn(className, styles.assistant, { [styles.ghost]: !ghost })} style={style} onClick={onClick}>
            <div className={styles.header}>
                <Avatar size={avaSize} src={avatarUrl} />
                <div className={styles.assistantText}>
                    <span className={styles.assistantTitle}>{title}</span>
                    <span className={cn(styles.assistantHint, hintClassName)}>{description}</span>
                </div>
            </div>
            {displayedMessage}
        </div>
    )
}
