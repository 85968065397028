import { isNotEmptyObject } from './data'

export function capitalize(src: string) {
    if (!src) {
        return ''
    }
    return `${src[0].toLocaleUpperCase()}${src.substr(1)}`
}

export function getLocaleValue(entity: Record<string, any> | undefined, field: string, locale: string = 'en') {
    if (typeof entity?.localizations?.[locale]?.[field] === 'object') {
        return isNotEmptyObject(entity.localizations?.[locale]?.[field]) ? entity?.localizations?.[locale]?.[field] : entity?.[field]
    }
    return entity?.localizations?.[locale]?.[field] ?? entity?.[field]
}

export function getFullName(firstName: string, lastName: string) {
    if (firstName && lastName) {
        return `${firstName} ${lastName}`
    }
    if (firstName) {
        return firstName
    }
    if (lastName) {
        return lastName
    }
}

/**
 * Function text endings set
 *
 * @param numArg - number check
 * @param textForms - array of forms contain max 4 string values
 */

export function textForm(numArg: number, textForms: string[]) {
    if (numArg === 0) {
        return textForms[3] || ''
    }
    if (numArg === 1) {
        return textForms[0] || ''
    }
    if (numArg === 2 || numArg === 3 || numArg === 4) {
        return textForms[1] || ''
    }
    return textForms[2] || ''
}
