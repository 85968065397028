import React from 'react'
import { Select as AntdSelect, SelectProps, Tag } from 'antd'
import { cn } from '../../../utils/cn'
import { ReactComponent as CheckIcon } from '../../../assets/check.svg'
import { ReactComponent as Up } from '../../../assets/chevron-up.svg'
import { ReactComponent as Down } from '../../../assets/chevron-down.svg'
import styles from './Dropdown.module.css'
import { useToggle } from '../../../hooks/useToggle'

export interface Option {
    value: string
    label: string
}

export interface CustomTagProps {
    label: React.ReactNode
    value: { label: string; value: any }
    disabled: boolean
    onClose: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void
    closable: boolean
}

export const Dropdown = ({
    className,
    dropdownClassName,
    placeholder = 'Select',
    inlineTags = false,
    value,
    open,
    onDropdownVisibleChange,
    ...rest
}: SelectProps<any> & { inlineTags?: boolean }) => {
    const [show, toggleShow] = useToggle()

    const renderTags = ({ label }: CustomTagProps) => {
        return <Tag className={styles.tag}>{label}</Tag>
    }

    const renderTagsInline = ({ label }: CustomTagProps) => {
        return <span className={styles.tagInline}>{label}</span>
    }

    return (
        <AntdSelect
            size="large"
            placeholder={placeholder}
            showSearch={!rest.mode}
            showArrow
            open={open}
            optionFilterProp="children"
            suffixIcon={open ?? show ? <Up className={styles.icon} /> : <Down className={styles.icon} />}
            maxTagPlaceholder="..."
            menuItemSelectedIcon={<CheckIcon className={styles.check} />}
            dropdownClassName={styles.dropdown}
            className={cn(styles.select, className)}
            tagRender={inlineTags ? (renderTagsInline as any) : (renderTags as any)}
            onDropdownVisibleChange={onDropdownVisibleChange ?? toggleShow}
            value={value?.[0]?.id ? (value as any).map((item: any) => item.id) : value}
            {...rest}
        />
    )
}
