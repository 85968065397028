import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { fetchExperts } from '../../actions/experts'
import { ExpertCard } from '../../components/ExpertCard/ExpertCard'
import { ExpertFilters } from '../../components/SearchBar/ExpertFilters'
import { SearchBar } from '../../components/SearchBar/SearchBar'
import { Dropdown } from '../../components/ui/Dropdown/Dropdown'
import { addFilter } from '../../reducers/experts'
import { useDispatch, useSelector } from '../../reducers/store'
import { FilterOperation } from '../../types/filter'
import { cn } from '../../utils/cn'
import styles from './ExpertsPage.module.css'
import useGtmPageLoad from '../../hooks/useGtmPageLoad'

/**
 * This page contains a list and search/filtration controls for experts
 */
export const ExpertsPage = () => {
    useGtmPageLoad('ExpertsPage')
    const { t } = useTranslation()
    const experts = useSelector(store => store.experts.experts)
    const history = useHistory()
    const dispatch = useDispatch()
    const preselectedVisa = new URLSearchParams(history.location.search).get('visa')

    const expertsCountPublished = useMemo(() => experts?.filter(expertItem => expertItem.published)?.length || 0, [experts])

    useEffect(() => {
        if (preselectedVisa) {
            dispatch(
                addFilter({
                    path: 'visaPracticeSet',
                    operation: FilterOperation.CONTAINS,
                    value: preselectedVisa
                })
            )
            dispatch(fetchExperts())
            history.replace('/experts')
        } else {
            dispatch(fetchExperts())
        }
    }, [preselectedVisa, dispatch, history])
    const sorters = [
        {
            value: 'desc',
            label: t('Date from new to old')
        },
        {
            value: 'asc',
            label: t('Date from old to new')
        }
    ]
    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <h1 className={styles.title}>{t('Experts')}</h1>
                <span className={styles.total}>{expertsCountPublished}</span>
            </div>
            <div className={styles.searchContainer}>
                <SearchBar placeholder={t('Search specialist')} type="experts" className={styles.search} />
                <Dropdown className={cn(styles.sorters, styles.desktop)} options={sorters} value="desc" />
            </div>
            <div className={styles.content}>
                <div className={cn(styles.desktop, styles.filters)}>
                    <ExpertFilters />
                </div>
                <ul className={styles.list}>
                    {experts
                        ?.filter(i => i.published)
                        ?.map(item => (
                            <ExpertCard key={item.id} expert={item} className={styles.expert} showTitle={false} />
                        ))}
                </ul>
            </div>
        </div>
    )
}
