import { useEffect } from 'react'
import { fetchVisa, fetchVisas } from '../actions/visa'
import { clearSelectedVisa } from '../reducers/experts'
import { useDispatch } from '../reducers/store'

export function useVisas() {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchVisas())
    }, [dispatch])
}

export function useVisa(id: string) {
    const dispatch = useDispatch()
    useEffect(() => {
        if (id !== 'new') {
            dispatch(fetchVisa(id))
        }
        return () => {
            dispatch(clearSelectedVisa())
        }
    }, [id, dispatch])
}
