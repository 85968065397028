import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from '../reducers/store'
import { PracticeAreas } from '../types/practiceAreas'
import { sendDelete, sendGet, sendPost, sendPut } from '../utils/api'

export const fetchPracticeAreas = createAsyncThunk<{ practiceAreas: PracticeAreas[] }, void, { state: RootState }>(
    'fetchPracticeAreas',
    async (_, thunkAPI) => {
        const response = await sendGet(`/practiceAreas`, thunkAPI)
        return { practiceAreas: response }
    }
)

export const fetchPracticeArea = createAsyncThunk<{ practiceArea: PracticeAreas }, string, { state: RootState }>(
    'fetchPracticeArea',
    async (id, thunkAPI) => {
        const response = await sendGet(`/practiceAreas/${id}`, thunkAPI)
        return { practiceArea: response }
    }
)

export const addPracticeArea = createAsyncThunk<
    { practiceArea: PracticeAreas },
    { practiceArea: Partial<PracticeAreas>; onSuccess: () => void },
    { state: RootState }
>('addPracticeArea', async ({ practiceArea, onSuccess }, thunkAPI) => {
    const response = await sendPost(`/practiceAreas`, thunkAPI, practiceArea)
    onSuccess?.()
    return { practiceArea: response }
})

export const updatePracticeArea = createAsyncThunk<
    { practiceArea: PracticeAreas },
    { practiceArea: Partial<PracticeAreas>; onSuccess: () => void },
    { state: RootState }
>('updatePracticeArea', async ({ practiceArea, onSuccess }, thunkAPI) => {
    const response = await sendPut(`/practiceAreas/${practiceArea.id}`, thunkAPI, practiceArea)
    onSuccess?.()
    return { practiceArea: response }
})

export const deletePracticeArea = createAsyncThunk<{}, string[], { state: RootState }>('deletePracticeArea', async (ids, thunkAPI) => {
    await sendDelete(`/practiceAreas`, thunkAPI, ids)
    thunkAPI.dispatch(fetchPracticeAreas())
    return {}
})
