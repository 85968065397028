import React from 'react'
import styles from './LawFirmSummary.module.css'
import { cn } from '../../utils/cn'
import { BASE_FILE_URL } from '../../api/endpoints'
import { LawFirm } from '../../types/lawFirm'
import { useSelector } from '../../reducers/store'
import { useTranslation } from 'react-i18next'
import { getLocaleValue } from '../../utils/string'

export interface LawFirmSummaryProps {
    lawFirm: LawFirm
    className?: string
}

export const LawFirmSummary = ({ lawFirm, className }: LawFirmSummaryProps) => {
    const { t } = useTranslation()
    const lang = useSelector(store => store.session.lang)
    const name = getLocaleValue(lawFirm, 'name', lang)
    const address = getLocaleValue(lawFirm, 'address', lang)
    return (
        <div className={cn(styles.container, className)}>
            <span className={cn(styles.mobile, styles.belong)}>{t('Belong to company')}</span>
            <div className={styles.logo}>
                <img src={`${BASE_FILE_URL}/${lawFirm.logo}`} alt="law firm" />
            </div>
            <div className={styles.content}>
                <h3 className={styles.title}>{name}</h3>
                <span className={styles.address}>{address}</span>
            </div>
        </div>
    )
}
