export enum EError {
    Unknown = 'Unknown',
    TokenExpired = 'TokenExpired',
    Error400 = '400',
    Error401 = '401',
    Error403 = '403',
    Error404 = '404',
    Error423 = '423',
    Error500 = '500'
}
