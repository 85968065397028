import React from 'react'
import { useExpertises, useLanguages } from '../../hooks/useLanguages'
import { useSelector } from '../../reducers/store'
import { AddJson, AddJsonProps } from '../ui/AddJson/AddJson'
import { Dropdown } from '../ui/Dropdown/Dropdown'

export const LanguagesSelect = (props: AddJsonProps) => {
    useLanguages()
    useExpertises()
    const languages = useSelector(store => store.languages.languages)
    const expertises = useSelector(store => store.languages.expertises)
    const keyOptions = languages?.map(item => ({ value: item.code, label: item.name }))
    const valueOptions = expertises?.map(item => ({ value: item.level, label: item.level }))
    return <AddJson keyRenderer={<Dropdown options={keyOptions} />} valueRenderer={<Dropdown options={valueOptions} />} {...props} />
}
