export function toText(date: Date) {
    const now = new Date()
    const diffInSeconds = (now.getTime() - date.getTime()) / 1000
    if (diffInSeconds < 60) {
        return 'now'
    }
    if (diffInSeconds < 60 * 60) {
        const minutes = Math.floor(diffInSeconds / 60)
        return `${minutes} min ago`
    }
    if (diffInSeconds < 60 * 60 * 60) {
        const hours = Math.floor(diffInSeconds / (60 * 60))
        return `${hours} hour${hours === 1 ? '' : 's'} ago`
    }
    if (diffInSeconds < 60 * 60 * 60 * 24) {
        return `${Math.floor(diffInSeconds / (60 * 60 * 24))} days ago ${date.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit'
        })}`
    }
    return date.toLocaleString()
}
