import React, { useState } from 'react'
import { Button, message, Upload } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { BASE_URL, BASE_FILE_URL } from '../../../api/endpoints'
import { UploadChangeParam } from 'antd/lib/upload'
import { RcFile, UploadFile } from 'antd/lib/upload/interface'
import { File } from '../../../types/file'
import styles from './FileUpload.module.css'

export interface FileUploadProps {
    preview?: boolean
    single?: boolean
    value?: string | Array<string | File>
    mode?: 'id' | 'object'
    type?: 'image' | 'video' | 'other'
    onChange?: (fileId: Array<string | File> | string | null) => void
    onSuccess?: (fileId: Array<string | File>) => void
    onRemove?: (fileId: string[]) => void
}

export const FileUpload = ({
    preview,
    single = false,
    value = [],
    type = 'other',
    mode = 'id',
    onChange,
    onSuccess,
    onRemove,
    ...rest
}: FileUploadProps) => {
    const defaultValues = Array.isArray(value) ? value : value ? [value] : []
    const defaultFiles: File[] =
        defaultValues?.map(item => ({
            id: typeof item === 'string' ? item : item.id,
            name: typeof item === 'string' ? item : item.originalName ?? item.name,
            thumbUrl: `${BASE_FILE_URL}/${item}`
        })) ?? []
    const [files, setFiles] = useState<File[]>(defaultFiles)
    const token = localStorage.getItem('token')

    const handleChange = (info: UploadChangeParam<UploadFile<File>>) => {
        if (info.file.status === 'done') {
            const file = info.file.response!
            const newFiles = single ? [file] : [...files, file]
            setFiles(newFiles)
            if (mode === 'id') {
                onChange?.(single ? file.name : newFiles.map(i => i.name))
            } else {
                onChange?.(newFiles)
            }

            onSuccess?.(mode === 'id' ? newFiles.map(i => i.name) : newFiles)
        }
    }

    const handleRemove = (f: UploadFile<File> | any) => {
        const removedId = f.response ? f.response?.name : f.name
        const newFiles = files.filter(item => item.name !== removedId)
        setFiles(newFiles)
        onChange?.(single ? null : newFiles.map(i => i.name))
        onRemove?.([removedId!])
    }

    const handleValidation = (file: RcFile) => {
        let isValid = true
        if (type === 'image' && !file.type.startsWith('image')) {
            isValid = false
        }
        if (type === 'video' && file.type.startsWith('video')) {
            isValid = false
        }
        if (isValid) {
            return true
        } else {
            message.error('Unsupported format')
            return Upload.LIST_IGNORE
        }
    }

    const headers = token
        ? {
              Authorization: `Bearer ${token}`
          }
        : undefined

    return (
        <Upload
            name="file"
            beforeUpload={handleValidation}
            defaultFileList={defaultFiles as any}
            action={`${BASE_URL}/files`}
            headers={headers}
            maxCount={single ? 1 : undefined}
            multiple={!single}
            listType={type === 'image' ? 'picture' : 'text'}
            onChange={handleChange}
            onRemove={handleRemove}
            className={styles.container}
            {...rest}
        >
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload>
    )
}
