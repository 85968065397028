import React, { ReactNode, useCallback, useMemo } from 'react'
import styles from './HomePage.module.css'
import { DashboardButton } from '../../components/ui/DashboardButton/DashboardButton'
import { useMyProjects, useProjects } from '../../hooks/useProjects'
import { useExperts, useMyExperts } from '../../hooks/useExperts'
import { useDispatch, useSelector } from '../../reducers/store'
import { ReactComponent as ExpertsIcon } from '../../assets/experts.svg'
import { ReactComponent as ProjectsIcon } from '../../assets/projects.svg'
import { ReactComponent as BigPlusIcon } from '../../assets/big-plus.svg'
import { Link } from 'react-router-dom'
import { useJobs } from '../../hooks/useJobs'
import { Avatar, Collapse, Form } from 'antd'
import CollapsePanel from 'antd/lib/collapse/CollapsePanel'
import { useTranslation } from 'react-i18next'

import { BASE_FILE_URL } from '../../api/endpoints'
import { ReactComponent as Down } from '../../assets/chevron-down.svg'
import { setSelectedAssistant } from '../../reducers/assisntants'

import { useAssistants } from '../../hooks/useAssistants'

import { getFullName } from '../../utils/string'
import { cn } from '../../utils/cn'

import { JobItem } from '../../components/ui/JobItem/JobItem'
import { Or } from '../../components/ui/Or/Or'
import { AssistantItem } from '../../components/ui/AssistantItem/AssistantItem'
import { ExpertCard } from '../../components/ExpertCard/ExpertCard'
import { Button } from '../../components/ui/Button/Button'
import { ProjectCard } from '../../components/ProjectCard/ProjectCard'
import { SearchInput } from '../../components/SearchBar/SearchInput'
import { BottomSheet } from '../../components/ui/BottomSheet/BottomSheet'
import { RadioGroup } from '../../components/ui/RadioGroup/RadioGroup'

import Photo from '../../assets/avatar-woman.jpg'
import useGtmPageLoad from '../../hooks/useGtmPageLoad'
import { getStatusText } from '../../utils/data'

export const HomePage = () => {
    useGtmPageLoad('HomePage')
    const dispatch = useDispatch()
    const { t } = useTranslation()
    useProjects()
    useExperts()
    useJobs()
    useAssistants()
    useMyExperts()
    useMyProjects()
    const locale = useSelector(store => store.session.lang)
    const { projects, myProjects } = useSelector(store => store.projects)
    const { experts, myExperts } = useSelector(store => store.experts)
    const { assistants, selectedAssistant } = useSelector(store => store.assistants)
    const projectCountPublished = useMemo(() => projects?.filter(projectItem => projectItem.published)?.length || 0, [projects])
    const expertsCountPublished = useMemo(() => experts?.filter(expertItem => expertItem.published)?.length || 0, [experts])
    const jobs = useSelector(store => store.jobs.jobs)
    const assistant = selectedAssistant || assistants?.[0]

    const languageOptions = [
        { value: 'en', label: t('English') },
        { value: 'es', label: t('Spanish') },
        { value: 'ru', label: t('Russian') }
    ]

    const handleChange = useCallback(
        (values: Record<string, any>) => {
            if (values.language && assistants) {
                const matching = assistants.find(item => item.languages?.[values.language]) ?? assistants[0]
                dispatch(setSelectedAssistant({ assistant: matching }))
            }
        },
        [assistants, dispatch]
    )

    const content: ReactNode = (
        <div className={styles.containerModal}>
            <h2 className={styles.titleModal}>{t('Let us lead you')}</h2>
            {!'true' && ( // CD-91 hide assistant block
                <AssistantItem
                    ghost
                    avatarUrl={assistant?.avatarUrl}
                    title={assistant?.name}
                    description="Your personal assistant"
                    className={styles.assistantArea}
                />
            )}
            <span className={styles.chooseModal}>{t('Choose Subject Text Consierge1')}</span>
            <span className={cn(styles.chooseModal, styles.chooseModalBold)}>{t('Choose Subject Text Consierge2')}</span>
            <span className={styles.chooseModal}>{t('Choose Subject Text Consierge3')}</span>
            <Form onValuesChange={handleChange} layout="vertical" className={styles.formModal}>
                <Form.Item label={t('Preferred Language')} className={styles.subjectModal} name="language" initialValue="en">
                    <RadioGroup options={languageOptions} className={styles.languagesModal} />
                </Form.Item>
            </Form>
        </div>
    )

    return (
        <div className={styles.container}>
            <SearchInput className={cn(styles.search)} placeholder={t('Search EB-5 Projects or Experts')} />
            <div className={styles.innerContainer}>
                <div className={styles.content}>
                    {myExperts?.length > 0 && (
                        <Collapse ghost defaultActiveKey="my-experts" className={styles.collapse} expandIconPosition="right">
                            <CollapsePanel
                                header={
                                    <div className={styles.collapseHeaderContainer} style={{ marginBottom: '16px' }}>
                                        <div className={styles.collapseHeader}>
                                            <h2 className={styles.collapseTitle}>
                                                {t('My Experts')}
                                                <span className={styles.count}>{myExperts?.length}</span>
                                            </h2>
                                            <Down className={styles.up} />
                                        </div>
                                        <Link className={styles.link} to="/experts">
                                            <span className={cn(styles.mobile, styles.browseMobile)}>{t('Browse Experts')}</span>
                                            <Button type="ghost" className={cn(styles.desktop, styles.browse)}>
                                                {t('Browse Experts')}
                                            </Button>
                                        </Link>
                                    </div>
                                }
                                key="my-experts"
                            >
                                {myExperts.map((expert, index) => (
                                    <Link key={expert.id + index.toString()} to={`/experts/${expert.id}`} className={cn(styles.expert)}>
                                        <div className={cn(styles.mobile, styles.expert)}>
                                            <Avatar
                                                size={60}
                                                src={`${BASE_FILE_URL}/${expert.photos?.[0]?.photo}`}
                                                className={styles.mobile}
                                            />
                                            <div className={styles.expertInfo}>
                                                <span className={styles.name}>{getFullName(expert.firstName, expert.lastName)}</span>
                                                <span className={styles.expertStatus}>
                                                    {getStatusText(
                                                        expert.proposalStatus,
                                                        t(expert.proposalStatus || ''),
                                                        expert.proposalManualStatus,
                                                        expert.proposalManualStatusLocalizations?.[locale]
                                                    )}
                                                </span>
                                            </div>
                                        </div>

                                        <ExpertCard expert={expert} className={cn(styles.desktop, styles.card)} />
                                    </Link>
                                ))}
                            </CollapsePanel>
                        </Collapse>
                    )}
                    {myExperts?.length === 0 && (
                        <div className={cn(styles.desktop, styles.emptyBlock)}>
                            <h2 className={styles.collapseTitle}>{t('My Experts')}</h2>
                            <div className={styles.emptyPlaceholder}>There are no projects here. But you can easily fix this</div>
                            <Link className={styles.link} to="/experts">
                                <span className={styles.mobile}>{t('Browse Experts')}</span>
                                <Button
                                    type="ghost"
                                    className={cn(styles.desktop, styles.emptyBrowse)}
                                    style={{ paddingLeft: '4px', paddingRight: '4px' }}
                                >
                                    {t('Browse Experts')}
                                </Button>
                            </Link>
                        </div>
                    )}
                    {myProjects && myProjects?.length > 0 && (
                        <Collapse ghost defaultActiveKey="my-projects" className={styles.collapse} expandIconPosition="right">
                            <CollapsePanel
                                header={
                                    <div className={styles.collapseHeaderContainer} style={{ marginBottom: '16px', marginTop: '8px' }}>
                                        <div className={styles.collapseHeader}>
                                            <h2 className={styles.collapseTitle}>
                                                {t('My EB-5 Projects')}
                                                <span className={styles.count}>{myProjects?.length}</span>
                                            </h2>
                                            <Down className={styles.up} />
                                        </div>
                                        <Link className={styles.link} to="/projects">
                                            <span className={cn(styles.mobile, styles.browseMobile)}>{t('Find Project')}</span>
                                            <Button type="ghost" className={cn(styles.desktop, styles.browse)}>
                                                {t('Find Project')}
                                            </Button>
                                        </Link>
                                    </div>
                                }
                                key="my-projects"
                            >
                                {myProjects?.map((project, index) => (
                                    <Link key={project.id + index.toString()} to={`/projects/${project.id}`} className={styles.project}>
                                        <div className={cn(styles.mobile)}>
                                            <img className={styles.project} src={`${BASE_FILE_URL}/${project.photos?.[0]}`} alt="Project" />
                                            <div className={styles.expertInfo}>
                                                <span className={styles.name}>{project.name}</span>
                                                <span className={styles.expertStatus}>
                                                    {getStatusText(
                                                        project.proposalStatus,
                                                        t(project.proposalStatus || ''),
                                                        project.proposalManualStatus,
                                                        project.proposalManualStatusLocalizations?.[locale]
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                        <ProjectCard project={project} photo="small" className={cn(styles.desktop, styles.card)} />
                                    </Link>
                                ))}
                            </CollapsePanel>
                        </Collapse>
                    )}
                    {!myProjects?.length && jobs.length !== 0 && (
                        <div className={cn(styles.desktop, styles.emptyBlock)}>
                            <h2 className={styles.collapseTitle}>{t('My Open Project')}</h2>
                            <div className={styles.emptyPlaceholder}>There are no projects here. But you can easily fix this</div>
                            <Link className={styles.link} to="/jobs-create">
                                <Button type="ghost" className={cn(styles.desktop, styles.emptyBrowse)}>
                                    {t('Create Project')}
                                </Button>
                            </Link>
                        </div>
                    )}
                    {jobs?.length > 0 && (
                        <Collapse ghost defaultActiveKey="open-projects" className={styles.collapse} expandIconPosition="right">
                            <CollapsePanel
                                style={{ paddingTop: '16px' }}
                                header={
                                    <div className={styles.collapseHeaderContainer} style={{ marginBottom: '8px' }}>
                                        <div className={styles.collapseHeader}>
                                            <h2 className={styles.collapseTitle}>
                                                {t('My Open Projects')}
                                                <span className={styles.count}>{jobs?.length}</span>
                                            </h2>

                                            <Down className={styles.up} />
                                        </div>
                                        <Link className={styles.link} to="/jobs-create">
                                            <span className={cn(styles.mobile, styles.browseMobile)}>{t('Create')}</span>
                                            <Button type="ghost" className={cn(styles.desktop, styles.browse)}>
                                                {t('Create Project')}
                                            </Button>
                                        </Link>
                                    </div>
                                }
                                key="open-projects"
                            >
                                {jobs.map((job, index) => (
                                    <Link key={job.id + index.toString()} to={`/job/${job.id}`}>
                                        <JobItem key={job.id} job={job} className={styles.jobItem} />
                                    </Link>
                                ))}
                            </CollapsePanel>
                        </Collapse>
                    )}
                    {jobs?.length === 0 && (
                        <div className={cn(styles.desktop, styles.emptyBlock)} style={{ marginBottom: '36px' }}>
                            <h2 className={styles.collapseTitle}>{t('My Open Project')}</h2>
                            <div className={styles.emptyPlaceholder}>There are no projects here. But you can easily fix this</div>
                            <Link className={styles.link} to="/jobs-create">
                                <Button type="ghost" className={cn(styles.desktop, styles.emptyBrowse)}>
                                    {t('Create Project')}
                                </Button>
                            </Link>
                        </div>
                    )}
                </div>
                <ul className={cn(styles.desktop, styles.nav)}>
                    <li>{t('My Experts')}</li>
                    <li>{t('My EB-5 Projects')}</li>
                    <li>{t('My Open Requests')}</li>
                    <li>{t("Let's Start")}</li>
                </ul>
            </div>
            <div className={styles.start}>
                <h2 className={styles.title}>{t(`Let's Start`)}</h2>
                <nav className={styles.navigation}>
                    <Link to="/experts" className={styles.buttonLink}>
                        <DashboardButton
                            icon={<ExpertsIcon />}
                            className={styles.button}
                            name={t('Browse Experts')}
                            description={`${expertsCountPublished} ${t('People from all US')}`}
                        />
                    </Link>
                    <Link to="/projects" className={styles.buttonLink}>
                        <DashboardButton
                            icon={<ProjectsIcon />}
                            className={styles.button}
                            name={t('Browse EB-5 Projects')}
                            description={`${projectCountPublished} ${t('EB-5 Projects from all US')}`}
                        />
                    </Link>
                    <Link to="/jobs-create" style={{ flexBasis: '25%' }}>
                        <DashboardButton
                            icon={<BigPlusIcon className={styles.bigPlus} />}
                            className={styles.button}
                            name={t('Create Request')}
                            description={t('And our Experts make you proposals')}
                        />
                    </Link>
                    {assistant && (
                        <>
                            <div className={cn(styles.desktop, styles.or)}>{t('OR')}</div>
                            <BottomSheet
                                content={content}
                                size="large"
                                closable
                                className={styles.desktop}
                                footer={
                                    <Link to="/appointment">
                                        <Button className={styles.chooseButton}>{t('Choose Time')}</Button>
                                    </Link>
                                }
                            >
                                <div className={styles.desktop}>
                                    <DashboardButton
                                        icon={<Avatar size="large" src={Photo} />}
                                        className={cn(styles.button, styles.button25, styles.desktop)}
                                        name={t('Request Free Consierge Service')}
                                        description={t('With your personal assistant')}
                                    />
                                </div>
                            </BottomSheet>
                        </>
                    )}
                </nav>
            </div>
            <Or className={styles.mobile} />
            {assistant && (
                <BottomSheet
                    content={content}
                    size="large"
                    closable
                    footer={
                        <Link to="/appointment">
                            <Button className={styles.chooseButton}>{t('Choose Time')}</Button>
                        </Link>
                    }
                >
                    <div className={cn(styles.mobile, styles.dButton)}>
                        <DashboardButton
                            icon={<Avatar size="large" src={Photo} />}
                            className={cn(styles.mobile, styles.button)}
                            name={t('Request Free Consierge Service')}
                            description={t('With your personal assistant')}
                        />
                    </div>
                </BottomSheet>
            )}
        </div>
    )
}
