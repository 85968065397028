import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { addRegionalCenter, fetchRegionalCenter, fetchRegionalCenters } from '../actions'
import { addRCManager, deleteRCManager, fetchRCManager, fetchRCManagers } from '../actions/rcmanagers'
import { RegionalCenter } from '../types/regionalCenter'
import { Filter, Sorter } from '../types/filter'
import { RCManager } from '../types/rcmanager'

type RegionalCenterSlice = {
    centers: RegionalCenter[]
    managers?: RCManager[]
    selectedCenter?: RegionalCenter
    selectedManager?: RCManager
    filters: Filter[]
    sorters: Sorter[]
}

export const regionalCentersReducer = createSlice({
    name: 'regionalCenters',
    initialState: {
        centers: [],
        managers: [],
        filters: [],
        sorters: []
    } as RegionalCenterSlice,
    reducers: {
        clearSelected: state => {
            state.selectedCenter = undefined
        },
        addRCMFilter: (state, action: PayloadAction<Filter>) => {
            const existing = state.filters.find(item => item.path === action.payload.path)
            if (existing) {
                existing.value = action.payload.value
            } else {
                state.filters.push(action.payload)
            }
        },
        clearRCMSelected: state => {
            state.selectedManager = undefined
        },
        removeAllRCMFilters: state => {
            state.filters = []
        }
    },
    extraReducers: builder => {
        builder
            .addCase(fetchRegionalCenters.pending, (state, action) => {})
            .addCase(fetchRegionalCenters.fulfilled, (state, action) => {
                state.centers = action.payload.centers
            })
            .addCase(fetchRegionalCenter.fulfilled, (state, action) => {
                state.selectedCenter = action.payload.center
            })
            .addCase(addRegionalCenter.fulfilled, state => {
                state.selectedCenter = undefined
            })

            .addCase(fetchRCManagers.fulfilled, (state, action) => {
                state.managers = action.payload.managers
            })
            .addCase(fetchRCManager.fulfilled, (state, action) => {
                state.selectedManager = action.payload.manager
            })
            .addCase(addRCManager.fulfilled, state => {
                state.selectedCenter = undefined
            })
            .addCase(deleteRCManager.fulfilled, state => {
                state.selectedCenter = undefined
            })
    }
})

export const { clearSelected, addRCMFilter, clearRCMSelected, removeAllRCMFilters } = regionalCentersReducer.actions
