import SockJS from 'sockjs-client'
import { CompatClient, Stomp } from '@stomp/stompjs'
import { useDispatch } from '../reducers/store'
import { fetchNotifications } from '../actions/notifications'
import { BASE_URL } from '../api/endpoints'
import { useEffect } from 'react'
import AbstractXHRObject from 'sockjs-client/lib/transport/browser/abstract-xhr'

let client: CompatClient

export function useStomp() {
    useEffect(() => {
        const _start = AbstractXHRObject.prototype._start
        AbstractXHRObject.prototype._start = function (method: any, url: string, payload: any, opts: any) {
            if (!opts) {
                opts = { noCredentials: true }
            }
            return _start.call(this, method, url, payload, opts)
        }
    }, [])
    const dispatch = useDispatch()
    if (client) {
        return
    }
    let sock = new SockJS(`${BASE_URL}/gs-guide-websocket`)

    client = Stomp.over(sock)

    client.connect({}, () => {
        client.subscribe('/topic/notifications', e => {
            dispatch(fetchNotifications())
        })
    })
}
