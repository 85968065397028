import { useEffect } from 'react'
import { fetchReqInvestment, fetchfetchReqInvestments } from '../actions/reqInvestment'
import { clearSelectedReqInvestment } from '../reducers/projects'
import { useDispatch } from '../reducers/store'

export function useReqInvestments() {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchfetchReqInvestments())
    }, [dispatch])
}

export function useReqInvestment(id: string) {
    const dispatch = useDispatch()
    useEffect(() => {
        if (id !== 'new') {
            dispatch(fetchReqInvestment(id))
        }
        return () => {
            dispatch(clearSelectedReqInvestment())
        }
    }, [id, dispatch])
}
