import { createSlice } from '@reduxjs/toolkit'
import { addLanguage, fetchLanguages, fetchLanguage, fetchExpertises, fetchExpertise, addExpertise } from '../actions/languages'
import { Filter, Sorter } from '../types/filter'
import { Language, LanguageExpertise } from '../types/i18n'

type LanguageSlice = {
    languages: Language[]
    selectedLanguage?: Language
    pending?: boolean
    filters: Filter[]
    sorters: Sorter[]
    lang?: string
    expertises: LanguageExpertise[]
    selectedExpertise?: LanguageExpertise
}

export const languagesReducer = createSlice({
    name: 'languages',
    initialState: {
        languages: [],
        filters: [],
        sorters: [],
        expertises: []
    } as LanguageSlice,
    reducers: {
        clearSelected: state => {
            state.selectedLanguage = undefined
        },
        clearSelectedExpertise: state => {
            state.selectedExpertise = undefined
        }
    },
    extraReducers: builder => {
        builder
            .addCase(fetchLanguages.fulfilled, (state, action) => {
                state.languages = action.payload.languages
            })
            .addCase(fetchLanguage.fulfilled, (state, action) => {
                state.selectedLanguage = action.payload.language
            })
            .addCase(addLanguage.fulfilled, state => {
                state.selectedLanguage = undefined
            })
            .addCase(fetchExpertises.fulfilled, (state, action) => {
                state.expertises = action.payload.expertises
            })
            .addCase(fetchExpertise.fulfilled, (state, action) => {
                state.selectedExpertise = action.payload.expertise
            })
            .addCase(addExpertise.fulfilled, (state, action) => {
                state.selectedExpertise = undefined
            })
    }
})

export const { clearSelected, clearSelectedExpertise } = languagesReducer.actions
