import { BASE_URL } from '../api/endpoints'

import { EError } from '../constants/error'
import { setError } from '../reducers/session'
import { isNotEmptyObject } from './data'

export function sendRequest(url: string, method: 'GET' | 'POST' | 'PUT' | 'DELETE', thunkAPI: any, data?: any, stringify?: boolean) {
    const token = localStorage.getItem('token')
    const options: any = token
        ? {
              headers: {
                  Authorization: `Bearer ${token}`
              },
              method
          }
        : { headers: {}, method }
    if (method === 'POST' || method === 'PUT' || method === 'DELETE') {
        ;(options.headers as Record<string, string>)['Content-Type'] = 'application/json'
    }
    if (isNotEmptyObject(data)) {
        options.body = stringify === false ? data : JSON.stringify(data)
    }
    return fetch(`${BASE_URL}${url}`, options)
        .then(res => {
            if (res.ok) {
                const contentType = res.headers.get('content-type')
                if (contentType && contentType.indexOf('application/json') !== -1) {
                    return res.json()
                }
                return res.text()
            }
            throw res
        })
        .catch((error: any) => {
            const errorMessage = `API url:  ${url} \n${error.statusText}`
            switch (error?.status) {
                case 400: {
                    thunkAPI?.dispatch(
                        setError({
                            error: {
                                errorCode: EError.Error400,
                                errorMessage: errorMessage
                            }
                        })
                    )
                    break
                }
                case 401: {
                    thunkAPI?.dispatch(
                        setError({
                            error: {
                                errorCode: EError.Error401,
                                errorMessage: errorMessage
                            }
                        })
                    )
                    break
                }
                case 403: {
                    thunkAPI?.dispatch(
                        setError({
                            error: {
                                errorCode: EError.Error403,
                                errorMessage: errorMessage
                            }
                        })
                    )
                    break
                }
                case 404: {
                    thunkAPI?.dispatch(
                        setError({
                            error: {
                                errorCode: EError.Error404,
                                errorMessage: errorMessage
                            }
                        })
                    )
                    break
                }
                case 423: {
                    thunkAPI?.dispatch(
                        setError({
                            // Status project content not available
                            error: {
                                errorCode: EError.Error423,
                                errorMessage: 'Content not available for your country'
                            }
                        })
                    )
                    break
                }
                case 500: {
                    thunkAPI?.dispatch(
                        setError({
                            error: {
                                errorCode: EError.Error500,
                                errorMessage: errorMessage
                            }
                        })
                    )
                    break
                }
                default: {
                    thunkAPI?.dispatch(
                        setError({
                            error: {
                                errorCode: EError.Unknown,
                                errorMessage: error.toString()
                            }
                        })
                    )
                    throw error
                }
            }
        })
}

export function sendGet(url: string, thunkAPI: any, data?: any) {
    return sendRequest(url, 'GET', thunkAPI, data)
}

export function sendPost(url: string, thunkAPI: any, data?: any, stringify?: boolean) {
    return sendRequest(url, 'POST', thunkAPI, data, stringify)
}

export function sendPut(url: string, thunkAPI: any, data?: any) {
    return sendRequest(url, 'PUT', thunkAPI, data)
}

export function sendDelete(url: string, thunkAPI: any, data?: any) {
    return sendRequest(url, 'DELETE', thunkAPI, data)
}
