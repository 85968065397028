import React from 'react'
import { Tag } from 'antd'
import styles from './Tags.module.css'
import { cn } from '../../../utils/cn'

export type TagItem = { value: string; status?: string }

interface TagsProps {
    items?: TagItem[]
    className?: string
}

export const Tags = ({ items, className }: TagsProps) => {
    return (
        <ul className={cn(styles.container, className)}>
            {items?.map(item => {
                return (
                    <li key={item.value}>
                        <Tag
                            className={cn(styles.tag, {
                                [styles.open]: item.status === 'open',
                                [styles.closed]: item.status === 'closed'
                            })}
                        >
                            {item.value}
                        </Tag>
                    </li>
                )
            })}
        </ul>
    )
}
