import { sendDelete, sendGet, sendPost, sendPut } from '../utils/api'
import { Review } from '../types/Review'

export function reviews(thunkAPI: any): Promise<Review[]> {
    return sendGet(`/experts-reviews`, thunkAPI)
}

export function reviewsGet(reviewId: string, thunkAPI: any): Promise<Review> {
    return sendGet(`/experts-reviews/${reviewId}`, thunkAPI)
}

export function reviewsCreate(review: Review, thunkAPI: any): Promise<Review> {
    return sendPost(`/experts-reviews`, thunkAPI, review)
}

export function reviewsDelete(ids: string[], thunkAPI: any) {
    return sendDelete(`/experts-reviews`, thunkAPI, ids)
}

export function reviewsUpdate(review: Review, thunkAPI: any) {
    return sendPut(`/experts-reviews/${review.id}`, thunkAPI, review)
}
