import { Badge } from 'antd'
import { useSelector } from '../../../reducers/store'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Job } from '../../../types/jobs'
import { cn } from '../../../utils/cn'
import styles from './JobItem.module.css'
import { textForm } from '../../../utils/string'

export interface JobItemProps {
    className?: string
    job: Job
}

export const JobItem = ({ job, className }: JobItemProps) => {
    const { t } = useTranslation()
    const lang = useSelector(store => store.session.lang)
    const proposals = useMemo(
        () =>
            job.proposals.filter(
                proposal => !proposal.initiatedByUser && !['ASSISTANT_MESSAGE', 'COMPANY_MESSAGE'].includes(proposal.status)
            ),
        [job.proposals]
    )
    const newProposals = proposals.filter(item => !item.read).length
    const invited = useMemo(() => job.proposals.filter(proposal => proposal.initiatedByUser), [job.proposals])
    const proposalsCount = proposals.length ?? 0
    const invitedCount = invited.length ?? 0

    const dateObject = new Date(job.created)
    const today = new Date()
    const diffTime = Math.abs(today.getTime() - dateObject.getTime())
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
    const now = new Date()
    const diffInSeconds = (now.getTime() - dateObject.getTime()) / 1000
    const diffHours = Math.floor(diffInSeconds / (60 * 60))

    return (
        <div className={cn(styles.container, className)}>
            <div>
                <Badge count={newProposals}>
                    <h4 className={styles.title}>{job.title}</h4>
                </Badge>
            </div>

            <div className={styles.items}>
                {/* temporary hide CD-296 */}
                {false && (
                    <>
                        <span className={styles.value}>
                            {proposalsCount > 0 && proposalsCount}{' '}
                            {textForm(proposalsCount, [t('Proposals'), t('Proposals 234'), t('Proposals 5+'), t('Proposals 0')])} ∙
                        </span>
                        <span className={styles.value}>
                            {invitedCount === 0 && lang === 'en' && '0'}
                            {invitedCount > 0 && invitedCount}{' '}
                            {textForm(invitedCount, [t('Invited'), t('Invited 234'), t('Invited 5+'), t('Invited 0')])}
                        </span>
                    </>
                )}
                {!!job.created && (
                    <>
                        {/* temporary hide CD-296 */}
                        {/* <span className={styles.value}> ∙</span> */}
                        <span className={styles.value}>
                            {diffDays === 1 ? (
                                textForm(diffHours === 0 ? 1 : diffHours, [
                                    t('hour ago', { count: diffHours === 0 ? 1 : diffHours }),
                                    t('hours ago', { count: diffHours }),
                                    t('hours ago 5+', { count: diffHours }),
                                    t('hours ago 5+', { count: diffHours })
                                ])
                            ) : (
                                <span>
                                    {/* {diffDays > 0 && diffDays - 1}{' '} */}
                                    {textForm(diffDays - 1, [
                                        t('day ago', { count: diffDays - 1 }),
                                        t('days ago', { count: diffDays - 1 }),
                                        t('days ago 5+', { count: diffDays - 1 }),
                                        t('days ago 5+', { count: diffDays - 1 })
                                    ])}
                                </span>
                            )}
                        </span>
                    </>
                )}
            </div>
        </div>
    )
}
