import { ProposalStatus } from '../constants/common'

export const isNotEmptyArray = (object: any): boolean => object && Array.isArray(object) && !!object.length

export const isNotEmptyObject = (object: any): boolean => object && !!Object.keys(object).length

export const getVariableName = (obj: Record<string, any>): string => Object.keys(obj)[0]

export const getStatusText = (status?: string, statusTranslate?: string, manualStatus?: string, manualStatusLocale?: string): string => {
    if (status?.length && status === ProposalStatus.APPOINTMENT_COMPLETED) {
        return statusTranslate || status
    }

    if (manualStatusLocale?.length) {
        return manualStatusLocale
    }

    if (manualStatus?.length) {
        return manualStatus
    }

    if (status?.length) {
        return statusTranslate || status
    }

    return ''
}
