import { Expert } from '../types/expert'
import { Review } from '../types/Review'

export function getAverageRating(expert?: Expert) {
    if (!expert) {
        return 0
    }
    const approvedReviews = expert?.reviews?.filter(item => item.approved) || []
    const totalRating = approvedReviews.reduce((result, next: Review) => result + next.rating, 0)
    const reviewsCount = approvedReviews.length
    return totalRating / reviewsCount
}
