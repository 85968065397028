import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { TagItem } from '../components/ui/Tags/Tags'
import { Project } from '../types/project'
import { getLocaleValue } from '../utils/string'
import { useSelector } from '../reducers/store'

export function useProjectTags(project: Project) {
    const { t } = useTranslation('')
    const lang = useSelector(store => store.session.lang)
    const investmentTypes: Record<string, string> = useMemo(
        () => ({
            loan: t('Loan'),
            preferredEquity: t('Preferred Equity'),
            equity: t('Equity'),
            seniorLoan: t('Senior Loan'),
            mezzanineLoan: t('Mezzanine Loan')
        }),
        [t]
    )
    const tags = useMemo(() => {
        const result: TagItem[] = [
            { value: project.status === 'open' ? t('Open for Investment') : t('Closed for Investment'), status: project.status },
            ...(project.categories?.map(cat => ({ value: getLocaleValue(cat, 'name', lang) })) ?? [])
        ]
        if (project.tea) {
            result.push({ value: 'TEA' })
        } else {
            result.push({ value: 'non TEA' })
        }
        if (project.requiredInvestment) {
            result.push({ value: project.requiredInvestment.name })
        }
        if (project.investmentType) {
            result.push({ value: t(investmentTypes[project.investmentType]) })
        }
        return result
    }, [investmentTypes, project.investmentType, project.requiredInvestment, project.categories, project.status, project.tea, t, lang])
    return tags
}
