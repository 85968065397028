import React from 'react'
import { RegionalCenter } from '../../types/regionalCenter'
import styles from './RegionalCenterSummary.module.css'
import { cn } from '../../utils/cn'
import { Link } from 'react-router-dom'
import { BASE_FILE_URL } from '../../api/endpoints'
import { useTranslation } from 'react-i18next'

export interface RegionalCenterSummaryProps {
    title: string
    projectId?: string
    regionalCenter: RegionalCenter
    className?: string
}

export const RegionalCenterSummary = ({ title, projectId, regionalCenter, className }: RegionalCenterSummaryProps) => {
    const { t } = useTranslation()
    const link = projectId ? `/projects/${projectId}/${regionalCenter.id}` : `/regional-centers/${regionalCenter.id}`
    return (
        <Link to={link}>
            <div className={cn(styles.container, className)}>
                <div className={styles.belong}>{title}</div>
                <div className={styles.logo}>
                    <img src={`${BASE_FILE_URL}/${regionalCenter.logo}`} alt="Regional Center" />
                </div>
                <div className={styles.content}>
                    <h3 className={styles.title}>{regionalCenter.name}</h3>
                    <div className={styles.petitions}>
                        <h4 className={styles.subtitle}>{t('Petitions Approved')}</h4>
                        <div className={styles.valuesBlock}>
                            <span className={styles.type}>I-526</span>
                            <span className={styles.value}>{regionalCenter.i526}</span>
                            {` ∙ `}
                            <span className={styles.type}>I-829</span>
                            <span className={styles.value}>{regionalCenter.i829}</span>
                        </div>
                    </div>
                    <div className={styles.projects}>
                        <h4 className={styles.subtitle}>{t('EB-5 Projects')}</h4>
                        <div className={styles.valuesBlock}>
                            <span className={styles.type}>{t('Completed Projects')}</span>
                            <span className={styles.value}>{regionalCenter.eb5CompletedProjects}</span>
                            {` ∙ `}
                            <span className={styles.type}>{t('In Progress')}</span>
                            <span className={styles.value}>{regionalCenter.eb5ProgressProjects}</span>
                            {` ∙ `}
                            <span className={styles.type}>{t('Total')}</span>
                            <span className={styles.value}>{regionalCenter.eb5CompletedProjects + regionalCenter.eb5ProgressProjects}</span>
                        </div>
                    </div>
                    <div className={styles.readMore}>{t('Read More')}</div>
                </div>
            </div>
        </Link>
    )
}
