import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { BASE_FILE_URL } from '../../api/endpoints'
import { useBlogArticles } from '../../hooks/useBlogArticles'
import { useSelector } from '../../reducers/store'
import { getLocaleValue } from '../../utils/string'
import styles from './BlogPage.module.css'
import useGtmPageLoad from '../../hooks/useGtmPageLoad'

export const BlogPage = () => {
    useGtmPageLoad('BlogPage')
    const { t } = useTranslation()
    useBlogArticles()
    const locale = useSelector(store => store.session.lang)
    const articles = useSelector(store => store.blog.articles)
    return (
        <div className={styles.container}>
            <h1 className={styles.title}>{t('Blog')}</h1>
            {articles.map(item => (
                <div key={item.id}>
                    <Link to={`/blog/${item.id}`}>
                        <article className={styles.article}>
                            <header className={styles.header}>
                                <p className={styles.p}>
                                    <h2 className={styles.articleTitle}>{getLocaleValue(item, 'title', locale)}</h2>
                                    <footer className={styles.footer}>
                                        {formatDate(item.date)} ・ {getLocaleValue(item, 'duration', locale)}
                                    </footer>
                                </p>
                                <img className={styles.img} src={`${BASE_FILE_URL}/${item.photo}`} alt="Article" />
                            </header>
                        </article>
                    </Link>
                </div>
            ))}
        </div>
    )
}

function formatDate(date: string) {
    if (!date) {
        return null
    }
    const dateObj = new Date(date)
    return `${dateObj.getDate()}.${dateObj.getMonth()}.${dateObj.getFullYear()}`
}
