import { Form, Popover, Tabs } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { Button } from '../../components/ui/Button/Button'
import { Input } from '../../components/ui/Input/Input'
import { useDispatch, useSelector } from '../../reducers/store'
import styles from './BlogAdminEditPage.module.css'
import { useHistory, useParams } from 'react-router-dom'
import FormItem from 'antd/lib/form/FormItem'
import { Dropdown } from '../../components/ui/Dropdown/Dropdown'
import { useLanguages } from '../../hooks/useLanguages'
import { useToggle } from '../../hooks/useToggle'
import { useBlogArticle } from '../../hooks/useBlogArticles'
import { BlogArticle, BlogArticleValue } from '../../types/blog'
import { addBlogArticle, updateBlogArticle } from '../../actions/blog'
import { RichEditor } from '../../components/ui/RichEditor/RichEditor'
import { FileUpload } from '../../components/ui/FileUpload/FileUpload'
import { useExperts } from '../../hooks/useExperts'
import { useWikiArticles } from '../../hooks/useWikiArticles'
import useGtmPageLoad from '../../hooks/useGtmPageLoad'

export const BlogAdminEditPage = () => {
    useGtmPageLoad('BlogAdminEditPage')
    const dispatch = useDispatch()
    useLanguages()
    useExperts()
    useWikiArticles()
    const wikiArticles = useSelector(store => store.wiki.articles)
    const categories = wikiArticles?.filter(item => item.root).map(item => ({ value: item.id, label: item.title }))
    const tags = wikiArticles?.map(item => ({ value: item.id, label: item.title }))
    const languages = useSelector(store => store.languages.languages)
    const experts = useSelector(store => store.experts.experts)?.map(item => ({
        label: `${item.firstName} ${item.lastName}`,
        value: Number.parseInt(item.id, 10)
    }))
    const history = useHistory()
    const [form] = Form.useForm()
    const { id } = useParams<{ id: string }>()
    const isNew = id === 'new'
    const ref = useRef(null)
    useBlogArticle(id)
    const selectedArticle = useSelector(store => store.blog.selectedArticle)
    const [locales, setLocales] = useState<Record<string, any>>({})

    const [showPopover, togglePopover] = useToggle()

    const [active, setActive] = useState('en')
    useEffect(() => {
        if (selectedArticle) {
            setLocales(selectedArticle.localizations ?? {})
        }
    }, [selectedArticle])

    const handleCancel = () => {
        history.push('/blog-admin')
    }

    const handleAdd = (values: Record<string, any>) => {
        const article: Partial<BlogArticle> = {
            ...values,
            content: {
                ...values.content,
                sections: (values.content as BlogArticleValue)?.sections?.map(item => {
                    return item.type === 'text' ? { ...item, content: item.content.toString('markdown') } : item
                })
            }
        }
        Object.entries(article.localizations ?? {}).forEach(([key, value]) => {
            value.locale = key
            ;(values.localizations[key].content as BlogArticleValue)?.sections?.forEach(item => {
                if (item.type === 'text') {
                    item.content = item.content.toString('markdown') as any
                }
            })
        })
        dispatch(addBlogArticle({ article, onSuccess: handleCancel }))
    }

    const handleUpdate = (values: Record<string, any>) => {
        if (values.localizations) {
            Object.keys(values.localizations).forEach(key => {
                values.localizations[key].locale = key
                ;(values.localizations[key].content as BlogArticleValue).sections = values.localizations[key].content?.sections?.map(
                    (item: any) => {
                        let newContent = item.content
                        if (item.type === 'text') {
                            newContent = item.content.toString('markdown') as any
                        }
                        return { ...item, content: newContent }
                    }
                )
            })
        }
        const article: Partial<BlogArticle> = {
            ...values,
            id: selectedArticle?.id,
            content: {
                ...values.content,
                sections: (values.content as BlogArticleValue)?.sections?.map(item => {
                    return item.type === 'text' ? { ...item, content: item.content.toString('markdown') } : item
                })
            }
        }
        Object.keys(locales)
            .filter(key => locales[key] === null)
            .forEach(key => {
                if (!article.localizations) {
                    article.localizations = {}
                }
                article.localizations[key] = null as any
            })
        dispatch(updateBlogArticle({ article, onSuccess: handleCancel }))
    }

    const handleTabEdit = (e: string | React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>, action: 'add' | 'remove') => {
        if (action === 'add') {
            togglePopover()
        } else {
            setLocales({
                ...locales,
                [e as string]: null
            })
        }
    }

    if (!selectedArticle && !isNew) {
        return null
    }

    const defaultPane = (
        <Tabs.TabPane key="en" tab="English" closable={false}>
            <Form
                className={styles.form}
                form={form}
                layout="vertical"
                onFinish={isNew ? handleAdd : handleUpdate}
                initialValues={selectedArticle}
                autoComplete="off"
            >
                <FormItem label="Author" name={['expert', 'id']} required wrapperCol={{ span: 8 }}>
                    <Dropdown options={experts} />
                </FormItem>
                <FormItem label="Title" name="title" required wrapperCol={{ span: 8 }}>
                    <Input />
                </FormItem>
                <FormItem label="Category" name="category" required wrapperCol={{ span: 8 }}>
                    <Dropdown options={categories} />
                </FormItem>
                <FormItem label="Duration" name="duration" required wrapperCol={{ span: 8 }}>
                    <Input placeholder="9 min read" />
                </FormItem>
                <FormItem label="Photo" name="photo" wrapperCol={{ span: 8 }}>
                    <FileUpload single preview type="image" />
                </FormItem>
                <FormItem label="Content" name="content" required wrapperCol={{ span: 24 }}>
                    <RichEditor style={{ marginTop: '16px' }} />
                </FormItem>
                <FormItem label="Tags" name="tags" required wrapperCol={{ span: 8 }}>
                    <Dropdown options={tags} mode="tags" />
                </FormItem>
            </Form>
        </Tabs.TabPane>
    )

    const handleAddLocale = (value: any, item: any) => {
        setLocales({
            ...locales,
            [value]: {
                locale: value
            }
        })
        setActive(value)
        togglePopover()
    }

    const opts = languages
        .filter(l => {
            return l.code !== 'en' && !Object.keys(locales).includes(l.code)
        })
        .map((l: any) => ({ value: l.code, label: l.name }))
    const Content = <Dropdown options={opts as any} onSelect={handleAddLocale} />

    const Add = <span ref={ref}>+ Add</span>

    const tabs = Object.entries(locales).map(([key, item]) => {
        return (
            <Tabs.TabPane key={key} tab={languages.find(l => l.code === key)?.name}>
                <Form
                    className={styles.form}
                    layout="vertical"
                    form={form}
                    onFinish={isNew ? handleAdd : handleUpdate}
                    initialValues={selectedArticle}
                >
                    <FormItem label="Title" name={['localizations', key, 'title']} required wrapperCol={{ span: 8 }}>
                        <Input />
                    </FormItem>
                    <FormItem label="Duration" name={['localizations', key, 'duration']} required wrapperCol={{ span: 8 }}>
                        <Input placeholder="9 min read" />
                    </FormItem>
                    <FormItem
                        label="Content"
                        name={['localizations', key, 'content']}
                        required
                        initialValue={form.getFieldValue('content')}
                        wrapperCol={{ span: 8 }}
                    >
                        <RichEditor />
                    </FormItem>
                </Form>
            </Tabs.TabPane>
        )
    })

    return (
        <div className={styles.container}>
            <Popover content={Content} visible={showPopover} onVisibleChange={togglePopover} />
            <Tabs type="editable-card" activeKey={active} onEdit={handleTabEdit} addIcon={Add} onChange={key => setActive(key)}>
                {defaultPane}
                {tabs}
            </Tabs>
            <div className={styles.footer}>
                <Button type="ghost" onClick={handleCancel} className={styles.button}>
                    Cancel
                </Button>
                <Button onClick={form.submit} style={{ width: 'auto' }} className={styles.button}>
                    Save
                </Button>
            </div>
        </div>
    )
}
