import React, { ChangeEvent, useEffect, useState } from 'react'
import { SearchInput } from './SearchInput'
import { FiltersButton } from './FiltersButton'
import styles from './SearchBar.module.css'
import { useDebounce } from '../../hooks/useDebounce'
import { useDispatch } from 'react-redux'
import { addFilter as addProjectsFilter, removeAllFilters as removeProjectsFilters } from '../../reducers/projects'
import { addFilter as addExpertFilter, removeAllFilters as removeExpertsFilters } from '../../reducers/experts'
import { FilterOperation } from '../../types/filter'
import { fetchProjects } from '../../actions'
import { fetchExperts } from '../../actions/experts'
import { cn } from '../../utils/cn'

export interface SearchBarProps {
    placeholder?: string
    type: 'projects' | 'experts'
    className?: string
}

export const SearchBar = ({ placeholder, type, className }: SearchBarProps) => {
    const [searchTerm, setSearchTerm] = useState<string>('')
    const debouncedSearchTerm = useDebounce(searchTerm, 300)
    const dispatch = useDispatch()

    const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.currentTarget.value)
    }

    useEffect(() => {
        if (debouncedSearchTerm) {
            if (type === 'projects') {
                dispatch(
                    addProjectsFilter({
                        path: 'name',
                        operation: FilterOperation.CONTAINS,
                        value: debouncedSearchTerm
                    })
                )
                dispatch(fetchProjects())
            }
            if (type === 'experts') {
                dispatch(
                    addExpertFilter({
                        path: 'fullName',
                        operation: FilterOperation.SPECIAL_FULL_NAME,
                        value: debouncedSearchTerm
                    })
                )
                dispatch(fetchExperts())
            }
        } else {
            if (type === 'projects') {
                dispatch(removeProjectsFilters())
                dispatch(fetchProjects())
            }
            if (type === 'experts') {
                dispatch(removeExpertsFilters())
                dispatch(fetchExperts())
            }
        }
    }, [debouncedSearchTerm, type, dispatch])

    return (
        <div className={cn(styles.container, className)}>
            <SearchInput className={styles.input} placeholder={placeholder} onChange={handleSearch} />
            <FiltersButton className={styles.mobile} type={type} />
        </div>
    )
}
