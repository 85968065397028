import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { store } from '../reducers/store'

interface StorybookContextProps {
    children: React.ReactNode
    padded?: boolean
}

export const StorybookContext = ({ children, padded }: StorybookContextProps) => {
    const content = (
        <Provider store={store}>
            <BrowserRouter>{children}</BrowserRouter>
        </Provider>
    )
    if (padded) {
        return <div style={{ padding: '16px' }}>{content}</div>
    }
    return content
}
