import { Form, Popover, Tabs } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { Button } from '../../components/ui/Button/Button'
import { Input } from '../../components/ui/Input/Input'
import { useDispatch, useSelector } from '../../reducers/store'
import styles from './LawFirmsAdminEditPage.module.css'
import { useHistory, useParams } from 'react-router-dom'
import FormItem from 'antd/lib/form/FormItem'
import { FileUpload } from '../../components/ui/FileUpload/FileUpload'
import { useLawFirm } from '../../hooks/useLawFirms'
import { addLawFirm, updateLawFirm } from '../../actions/lawFirms'
import { LawFirm } from '../../types/lawFirm'
import { Dropdown } from '../../components/ui/Dropdown/Dropdown'
import { useLanguages } from '../../hooks/useLanguages'
import { useToggle } from '../../hooks/useToggle'
import { copyWithStructuralSharing } from '@reduxjs/toolkit/dist/query'
import useGtmPageLoad from '../../hooks/useGtmPageLoad'

export const LawFirmsAdminEditPage = () => {
    useGtmPageLoad('LawFirmsAdminEditPage')
    const dispatch = useDispatch()
    useLanguages()
    const languages = useSelector(store => store.languages.languages)
    const history = useHistory()
    const [form] = Form.useForm()
    const { id } = useParams<{ id: string }>()
    const isNew = id === 'new'
    const ref = useRef(null)
    useLawFirm(id)
    const selectedFirm = useSelector(store => store.lawFirms.selectedFirm)
    const [locales, setLocales] = useState<Record<string, any>>({})

    const [showPopover, togglePopover] = useToggle()

    const [active, setActive] = useState('en')
    useEffect(() => {
        if (selectedFirm) {
            setLocales(selectedFirm.localizations)
        }
    }, [selectedFirm])

    const handleCancel = () => {
        history.push('/law-firms-admin')
    }

    const handleAdd = (values: Record<string, any>) => {
        const firm: Partial<LawFirm> = {
            ...values
        }
        Object.entries(firm.localizations ?? {}).forEach(([key, value]) => {
            value.locale = key
        })
        dispatch(addLawFirm({ firm, onSuccess: handleCancel }))
    }

    const handleUpdate = (values: Record<string, any>) => {
        if (values.localizations) {
            Object.keys(values.localizations).forEach(key => {
                values.localizations[key].locale = key
            })
        }
        const firm = {
            ...values,
            localizations: copyWithStructuralSharing(selectedFirm?.localizations, values.localizations),
            id: Number.parseInt(selectedFirm!.id)
        } as any
        dispatch(updateLawFirm({ firm, onSuccess: handleCancel }))
    }

    if (!selectedFirm && !isNew) {
        return null
    }

    const defaultPane = (
        <Tabs.TabPane key="en" tab="English" closable={false}>
            <Form
                className={styles.form}
                form={form}
                layout="vertical"
                onFinish={isNew ? handleAdd : handleUpdate}
                initialValues={selectedFirm}
                autoComplete="off"
            >
                <FormItem label="Name" name="name" wrapperCol={{ span: 8 }}>
                    <Input />
                </FormItem>
                <FormItem label="Address" name="address" wrapperCol={{ span: 8 }}>
                    <Input />
                </FormItem>
                <FormItem label="Phone" name="phone" wrapperCol={{ span: 8 }}>
                    <Input placeholder="(650) 253-0000" type="tel" />
                </FormItem>
                <FormItem label="Company Logo" name="logo" wrapperCol={{ span: 8 }}>
                    <FileUpload preview single type="image" />
                </FormItem>
            </Form>
        </Tabs.TabPane>
    )

    const handleAddLocale = (value: any, item: any) => {
        setLocales({
            ...locales,
            [value]: { locale: value }
        })
        setActive(value)
        togglePopover()
    }

    const opts = languages
        .filter(l => {
            return l.code !== 'en' && !Object.keys(locales).includes(l.code)
        })
        .map((l: any) => ({ value: l.code, label: l.name }))
    const Content = <Dropdown options={opts as any} onSelect={handleAddLocale} />

    const Add = <span ref={ref}>+ Add</span>

    const tabs = Object.entries(locales).map(([key, item]) => {
        return (
            <Tabs.TabPane key={key} tab={languages.find(l => l.code === key)?.name}>
                <Form
                    className={styles.form}
                    layout="vertical"
                    form={form}
                    onFinish={isNew ? handleAdd : handleUpdate}
                    initialValues={selectedFirm}
                >
                    <FormItem label="Name" name={['localizations', key, 'name']} wrapperCol={{ span: 8 }}>
                        <Input />
                    </FormItem>
                    <FormItem label="Address" name={['localizations', key, 'address']} wrapperCol={{ span: 8 }}>
                        <Input />
                    </FormItem>
                    <FormItem label="Phone" name={['localizations', key, 'phone']} wrapperCol={{ span: 8 }}>
                        <Input placeholder="(650) 253-0000" type="tel" />
                    </FormItem>
                </Form>
            </Tabs.TabPane>
        )
    })

    const handleTabEdit = (e: string | React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>, action: 'add' | 'remove') => {
        if (action === 'add') {
            togglePopover()
        } else {
            const newLocales = { ...locales }
            delete newLocales[e as string]
            setLocales(newLocales)
        }
    }

    return (
        <div className={styles.container}>
            <Popover content={Content} visible={showPopover} onVisibleChange={togglePopover} />
            <Tabs type="editable-card" activeKey={active} onEdit={handleTabEdit} addIcon={Add} onChange={key => setActive(key)}>
                {defaultPane}
                {tabs}
            </Tabs>
            <div className={styles.footer}>
                <Button type="ghost" onClick={handleCancel} className={styles.button}>
                    Cancel
                </Button>
                <Button onClick={form.submit} style={{ width: 'auto' }} className={styles.button}>
                    Save
                </Button>
            </div>
        </div>
    )
}
