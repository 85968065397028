import React from 'react'
import { useTranslation } from 'react-i18next'

import { cn } from '../../../utils/cn'

import styles from './Or.module.css'

export interface OrProps {
    className?: string
}

export const Or = ({ className }: OrProps) => {
    const { t } = useTranslation()
    return (
        <div className={cn(styles.orBlock, className)}>
            <hr className={styles.separator} />
            <span className={styles.or}>{t('OR')}</span>
        </div>
    )
}
