import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from '../reducers/store'
import { ReqInvestment } from '../types/reqInvestment'
import { sendDelete, sendGet, sendPost, sendPut } from '../utils/api'

export const fetchfetchReqInvestments = createAsyncThunk<{ invests: ReqInvestment[] }, void, { state: RootState }>(
    'fetchfetchReqInvestments',
    async (_, thunkAPI) => {
        const response = await sendGet(`/requiredInvestments`, thunkAPI)
        return { invests: response }
    }
)

export const fetchReqInvestment = createAsyncThunk<{ invest: ReqInvestment }, string, { state: RootState }>(
    'fetchReqInvestment',
    async (id, thunkAPI) => {
        const response = await sendGet(`/requiredInvestments/${id}`, thunkAPI)
        return { invest: response }
    }
)

export const addReqInvestment = createAsyncThunk<
    { invest: ReqInvestment },
    { invest: Partial<ReqInvestment>; onSuccess: () => void },
    { state: RootState }
>('addReqInvestment', async ({ invest, onSuccess }, thunkAPI) => {
    const response = await sendPost(`/requiredInvestments`, thunkAPI, invest)
    onSuccess?.()
    return { invest: response }
})

export const updateReqInvestment = createAsyncThunk<
    { invest: ReqInvestment },
    { invest: Partial<ReqInvestment>; onSuccess: () => void },
    { state: RootState }
>('updateReqInvestment', async ({ invest, onSuccess }, thunkAPI) => {
    const response = await sendPut(`/requiredInvestments/${invest.id}`, thunkAPI, invest)
    onSuccess?.()
    return { invest: response }
})

export const deleteReqInvestment = createAsyncThunk<{}, string[], { state: RootState }>('deleteReqInvestment', async (ids, thunkAPI) => {
    await sendDelete(`/requiredInvestments`, thunkAPI, ids)
    thunkAPI.dispatch(fetchfetchReqInvestments())
    return {}
})
