import React, { ReactNode } from 'react'
import styles from './PersonList.module.css'
import { cn } from '../../utils/cn'

export interface PersonListProps {
    children?: ReactNode
    className?: string
}

export const PersonList = ({ children, className }: PersonListProps) => {
    return (
        <>
            <div className={cn(styles.list, styles.mobile, className)}>{children}</div>
            <div className={cn(styles.desktop, styles.list, className)}>{children}</div>
        </>
    )
}
