import React, { useCallback } from 'react'
import { Expert } from '../../types/expert'
import styles from './ExpertCard.module.css'
import { ReactComponent as LocationMarkerIcon } from '../../assets/location-marker.svg'
import { ReactComponent as DollarIcon } from '../../assets/currency-dollar.svg'
import { Link } from 'react-router-dom'
import { BASE_FILE_URL } from '../../api/endpoints'
import { Avatar } from 'antd'
import { Tags } from '../ui/Tags/Tags'
import { useExpertTags } from '../../hooks/useExpertTags'
import { ShortReview } from '../ui/Reviews/ShortReview'
import { useTranslation } from 'react-i18next'
import { getLocaleValue } from '../../utils/string'
import { useDispatch, useSelector } from '../../reducers/store'
import { useLocalizedStates } from '../../hooks/useLocalizedStates'
import { SetAppointmentButton } from '../ui/SetAppointmentButton/SetAppointmentButton'
import { addCallPayload } from '../../reducers/session'
import { cn } from '../../utils/cn'
import { sortArrayByField } from '../../utils/array'

interface ExpertCardProps {
    expert: Expert
    showTitle?: boolean
    className?: string
}

/**
 * Used to show experts in lists
 */
export const ExpertCard = ({ expert, className, showTitle = true }: ExpertCardProps) => {
    const photos = sortArrayByField(expert?.photos, 'ord')
    const mainPreview = `${BASE_FILE_URL}/${photos[0]?.photo}`
    const tags = useExpertTags(expert)
    const locale = useSelector(store => store.session.lang)
    const { t } = useTranslation()
    const states = useLocalizedStates()
    const firstName = getLocaleValue(expert, 'firstName', locale)
    const lastName = getLocaleValue(expert, 'lastName', locale)
    const location = getLocaleValue(expert, 'location', locale)
    const dispatch = useDispatch()

    const handleAppointment = useCallback(() => {
        ;(window as any).dataLayer.push({ event: 'setAppointment', expertId: expert.id })
        dispatch(
            addCallPayload({
                expertId: expert.id
            })
        )
    }, [dispatch, expert.id])

    return (
        <div className={cn(className, styles.container)}>
            <Link to={`/experts/${expert.id}`}>
                <div className={styles.content}>
                    <Avatar src={mainPreview} size={60} className={cn(styles.mobile, styles.avatar)} />
                    <Avatar src={mainPreview} size={173} shape="square" className={cn(styles.desktop, styles.avatar)} />
                    <div className={styles.profile}>
                        <h2 className={styles.name}>
                            {firstName} {lastName}
                        </h2>
                        <span className={cn(styles.jobTitle, { [styles.showTitle]: !!showTitle })}>{t(expert.jobTitle?.name || '')}</span>
                        {expert.reviews?.length > 0 && <ShortReview expert={expert} hint={content => `(${content})`} />}
                        {location && (
                            <span className={styles.address}>
                                <LocationMarkerIcon className={styles.locationMarker} />
                                {location && expert.state && `${location}, ${states[expert.state]}`}
                                {!expert.state && location}
                            </span>
                        )}
                        {expert.firstConsultationFee !== null && expert.firstConsultationFee !== undefined && (
                            <span className={styles.address}>
                                <DollarIcon className={styles.dollarMarker} />
                                {`${expert.firstConsultationFee * (60 / (expert.firstConsultationDuration || 60))}$/${t('hr.')}`}
                            </span>
                        )}
                        <Tags items={tags} />
                    </div>
                </div>
            </Link>
            <SetAppointmentButton
                onClick={handleAppointment}
                className={cn(styles.appointment, styles.desktop)}
                float={false}
                noAssistant={true}
            />
        </div>
    )
}
