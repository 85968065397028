import { useEffect } from 'react'
import { fetchPracticeArea, fetchPracticeAreas } from '../actions/practiceAreas'
import { clearSelectedPracticeArea } from '../reducers/experts'
import { useDispatch } from '../reducers/store'

export function usePracticeAreas() {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchPracticeAreas())
    }, [dispatch])
}

export function usePracticeArea(id: string) {
    const dispatch = useDispatch()
    useEffect(() => {
        if (id !== 'new') {
            dispatch(fetchPracticeArea(id))
        }
        return () => {
            dispatch(clearSelectedPracticeArea())
        }
    }, [id, dispatch])
}
