import { Checkbox, Form, Popover, Tabs } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { Button } from '../../components/ui/Button/Button'
import { Input } from '../../components/ui/Input/Input'
import { useDispatch, useSelector } from '../../reducers/store'
import styles from './WikiAdminEditPage.module.css'
import { useHistory, useParams } from 'react-router-dom'
import FormItem from 'antd/lib/form/FormItem'
import { Dropdown } from '../../components/ui/Dropdown/Dropdown'
import { useLanguages } from '../../hooks/useLanguages'
import { useToggle } from '../../hooks/useToggle'
import { useWikiArticle } from '../../hooks/useWikiArticles'
import { WikiArticle, BlogArticleValue } from '../../types/blog'
import { addWikiArticle, updateWikiArticle } from '../../actions/wiki'
import { RichEditor } from '../../components/ui/RichEditor/RichEditor'
import { useVisas } from '../../hooks/useVisa'
import useGtmPageLoad from '../../hooks/useGtmPageLoad'

export const WikiAdminEditPage = () => {
    useGtmPageLoad('WikiAdminEditPage')
    const dispatch = useDispatch()
    useLanguages()

    const sections = [
        { value: 'immigration', label: 'Immigration to USA' },
        { value: 'regionalCenter', label: 'Regional Center' },
        { value: 'education', label: 'Education in USA' }
    ]

    const languages = useSelector(store => store.languages.languages)
    const history = useHistory()
    const [form] = Form.useForm()
    const { id } = useParams<{ id: string }>()
    const isNew = id === 'new'
    const ref = useRef(null)
    useWikiArticle(id)
    useVisas()
    const visas = useSelector(store => store.experts.visas).map(item => ({ value: item.code, label: item.label }))
    const selectedArticle = useSelector(store => store.wiki.selectedArticle)
    const [locales, setLocales] = useState<Record<string, any>>({})

    const [showPopover, togglePopover] = useToggle()

    const [active, setActive] = useState('en')
    useEffect(() => {
        if (selectedArticle) {
            setLocales(selectedArticle.localizations ?? {})
        }
    }, [selectedArticle])

    const handleCancel = () => {
        history.push('/wiki-admin')
    }

    const handleAdd = (values: Record<string, any>) => {
        const article: Partial<WikiArticle> = {
            ...values,
            content: {
                ...values.content,
                sections: (values.content as BlogArticleValue)?.sections?.map(item => {
                    return item.type === 'text' ? { ...item, content: item.content.toString('markdown') } : item
                })
            }
        }
        Object.entries(article.localizations ?? {}).forEach(([key, value]) => {
            value.locale = key
            ;(values.localizations[key].content as BlogArticleValue)?.sections?.forEach(item => {
                item.content = item.content.toString('markdown') as any
            })
        })
        dispatch(addWikiArticle({ article, onSuccess: handleCancel }))
    }

    const handleUpdate = (values: Record<string, any>) => {
        if (values.localizations) {
            Object.keys(values.localizations).forEach(key => {
                values.localizations[key].locale = key
                ;(values.localizations[key].content as BlogArticleValue).sections = values.localizations[key].content?.sections?.map(
                    (item: any) => {
                        let newContent = item.content
                        if (item.type === 'text') {
                            newContent = item.content.toString('markdown') as any
                        }
                        return { ...item, content: newContent }
                    }
                )
            })
        }
        const article: Partial<WikiArticle> = {
            ...values,
            id: selectedArticle?.id,
            content: {
                ...values.content,
                sections: (values.content as BlogArticleValue)?.sections?.map(item => {
                    return item.type === 'text' ? { ...item, content: item.content.toString('markdown') } : item
                })
            }
        }
        Object.keys(locales)
            .filter(key => locales[key] === null)
            .forEach(key => {
                if (!article.localizations) {
                    article.localizations = {}
                }
                article.localizations[key] = null as any
            })
        dispatch(updateWikiArticle({ article, onSuccess: handleCancel }))
    }

    if (!selectedArticle && !isNew) {
        return null
    }

    const defaultPane = (
        <Tabs.TabPane key="en" tab="English" closable={false}>
            <Form
                className={styles.form}
                form={form}
                layout="vertical"
                onFinish={isNew ? handleAdd : handleUpdate}
                initialValues={selectedArticle}
                autoComplete="off"
            >
                <FormItem label="Section" name="section" required wrapperCol={{ span: 8 }}>
                    <Dropdown options={sections} />
                </FormItem>
                <FormItem label="Title" name="title" required wrapperCol={{ span: 8 }}>
                    <Input />
                </FormItem>
                <FormItem label="Order" name="ord" wrapperCol={{ span: 8 }}>
                    <Input type="number" min={0} max={1000} />
                </FormItem>
                <FormItem label="Is Root Category" name="root" wrapperCol={{ span: 8 }} valuePropName="checked">
                    <Checkbox />
                </FormItem>
                <FormItem label="Content" name="content" required wrapperCol={{ span: 24 }}>
                    <RichEditor />
                </FormItem>
                <FormItem label="Tags" name="tags" required wrapperCol={{ span: 24 }}>
                    <Dropdown mode="tags" options={visas} />
                </FormItem>
            </Form>
        </Tabs.TabPane>
    )

    const handleAddLocale = (value: any, item: any) => {
        setLocales({
            ...locales,
            [value]: {
                locale: value
            }
        })
        setActive(value)
        togglePopover()
    }

    const opts = languages
        .filter(l => {
            return l.code !== 'en' && !Object.keys(locales).includes(l.code)
        })
        .map((l: any) => ({ value: l.code, label: l.name }))
    const Content = <Dropdown options={opts as any} onSelect={handleAddLocale} />

    const Add = <span ref={ref}>+ Add</span>

    const tabs = Object.entries(locales)
        .filter(([key, item]) => item !== null)
        .map(([key, item]) => {
            return (
                <Tabs.TabPane key={key} tab={languages.find(l => l.code === key)?.name}>
                    <Form
                        className={styles.form}
                        layout="vertical"
                        form={form}
                        onFinish={isNew ? handleAdd : handleUpdate}
                        initialValues={selectedArticle}
                    >
                        <FormItem label="Title" name={['localizations', key, 'title']} required wrapperCol={{ span: 8 }}>
                            <Input />
                        </FormItem>
                        <FormItem
                            label="Content"
                            name={['localizations', key, 'content']}
                            required
                            wrapperCol={{ span: 24 }}
                            initialValue={form.getFieldValue('content')}
                        >
                            <RichEditor />
                        </FormItem>
                    </Form>
                </Tabs.TabPane>
            )
        })

    const handleTabEdit = (e: string | React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>, action: 'add' | 'remove') => {
        if (action === 'add') {
            togglePopover()
        } else {
            setLocales({
                ...locales,
                [e as string]: null
            })
        }
    }

    return (
        <div className={styles.container}>
            <Popover content={Content} visible={showPopover} onVisibleChange={togglePopover} />
            <Tabs type="editable-card" activeKey={active} onEdit={handleTabEdit} addIcon={Add} onChange={key => setActive(key)}>
                {defaultPane}
                {tabs}
            </Tabs>
            <div className={styles.footer}>
                <Button type="ghost" onClick={handleCancel} className={styles.button}>
                    Cancel
                </Button>
                <Button onClick={form.submit} style={{ width: 'auto' }} className={styles.button}>
                    Save
                </Button>
            </div>
        </div>
    )
}
