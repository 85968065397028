import { useEffect } from 'react'
import { useDispatch } from '../reducers/store'
import { fetchProposalDirect, fetchProposalsDirect } from '../actions/jobs'
import { clearProposalDirect } from '../reducers/jobs'

export function useProposalsDirect() {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchProposalsDirect())
    }, [dispatch])
}

export function useProposalDirect(proposalId: string) {
    const dispatch = useDispatch()
    useEffect(() => {
        if (proposalId !== 'new') {
            dispatch(fetchProposalDirect(proposalId))
        }
        return () => {
            dispatch(clearProposalDirect())
        }
    }, [proposalId, dispatch])
}
