import { createAsyncThunk } from '@reduxjs/toolkit'
import { rcmanagersGetAll, rcmanagersCreate, rcmanagersGet, rcmanagersDelete, rcmanagerUpdate } from '../api/rcmanagers'
import { RootState } from '../reducers/store'
import { RCManager } from '../types/rcmanager'

export const fetchRCManagers = createAsyncThunk<{ managers: RCManager[] }, void, { state: RootState }>(
    'fetchRCManagers',
    async (_, thunkAPI) => {
        const state = thunkAPI.getState() as RootState
        const response = await rcmanagersGetAll(thunkAPI, state.regionalCenters.filters, state.regionalCenters.sorters)
        return { managers: response }
    }
)

export const fetchRCManager = createAsyncThunk('fetchRCManager', async (managertId: string, thunkAPI) => {
    const response = await rcmanagersGet(managertId, thunkAPI)
    return { manager: response }
})

export const addRCManager = createAsyncThunk<
    { manager: RCManager },
    { manager: Partial<RCManager>; onSuccess: () => void },
    { state: RootState }
>('addRCManager', async ({ manager, onSuccess }, thunkAPI) => {
    const response = await rcmanagersCreate(manager as RCManager, thunkAPI)
    onSuccess()
    thunkAPI.dispatch(fetchRCManagers())
    return { manager: response }
})

export const updateRCManager = createAsyncThunk<
    { manager: RCManager },
    { manager: RCManager; onSuccess: () => void },
    { state: RootState }
>('updateRCManager', async ({ manager, onSuccess }, thunkAPI) => {
    const response = await rcmanagerUpdate(manager, thunkAPI)
    onSuccess()
    return { manager: response }
})

export const deleteRCManager = createAsyncThunk<{}, string[], { state: RootState }>('deleteRCManager', async (ids, thunkAPI) => {
    await rcmanagersDelete(ids, thunkAPI)
    thunkAPI.dispatch(fetchRCManagers())
    return {}
})
