import React, { useEffect, useState } from 'react'
import { ArticleSectionTable, ArticleSectionText, BlogArticleSection, BlogArticleValue } from '../../../types/blog'
import { Button } from '../Button/Button'
import RichTextEditor from 'react-rte'
import styles from './RichEditor.module.css'
import { AddJson } from '../AddJson/AddJson'
import { Dropdown, Menu } from 'antd'
import { Input } from '../Input/Input'

interface RichEditorProps {
    value?: BlogArticleValue
    className?: string
    style?: React.CSSProperties
    onChange?: (value: BlogArticleValue) => void
}

export const RichEditor = ({ value, className, style, onChange }: RichEditorProps) => {
    const [sections, setSections] = useState<BlogArticleSection[]>(getInitialSections(value))

    useEffect(() => {
        setSections(getInitialSections(value))
    }, [value])

    const addSection = (type: string) => {
        const id = (sections?.[sections?.length - 1]?.id ?? 0) + 1
        if (type === 'text') {
            setSections([
                ...sections,
                {
                    id,
                    type: 'text',
                    headerSize: 'h1',
                    content: RichTextEditor.createEmptyValue()
                }
            ])
        }
        if (type === 'table') {
            setSections([
                ...sections,
                {
                    id,
                    type: 'table',
                    content: {},
                    headerSize: 'h1'
                }
            ])
        }
    }

    const updateTextSection = (id: number, e: any) => {
        const newSections = sections.map(item => {
            if (item.id === id) {
                return { ...item, content: e }
            }
            return item
        })
        setSections(newSections)
        onChange?.({ ...value, sections: newSections })
    }

    const removeSection = (id: number) => {
        const newSections = sections.filter(item => item.id !== id)
        setSections(newSections)
        onChange?.({ ...value, sections: newSections })
    }

    const handleTextSection = (section: ArticleSectionText) => {
        return <RichTextEditor value={section.content} onChange={e => updateTextSection(section.id, e)} />
    }

    const handleTableSection = (section: ArticleSectionTable) => {
        return <AddJson className={styles.table} value={section.content} onChange={e => updateTextSection(section.id, e)} />
    }

    const handleCollapsable = (id: number, collapsableTitle: string) => {
        const newSections = sections.map(item => {
            if (item.id === id) {
                item.collapsable = collapsableTitle as any
            }
            return item
        })
        setSections(newSections)
        onChange?.({ ...value, sections: newSections })
    }

    const handleHeader = (id: number, sectionTitle: string) => {
        const newSections = sections.map(item => {
            if (item.id === id) {
                item.header = sectionTitle
            }
            return item
        })
        setSections(newSections)
        onChange?.({ ...value, sections: newSections })
    }

    const handleHeaderSize = (id: number, headerSize: string) => {
        const newSections = sections.map(item => {
            if (item.id === id) {
                item.headerSize = headerSize as 'h1' | 'h2' | 'h3'
            }
            return item
        })
        setSections(newSections)
        onChange?.({ ...value, sections: newSections })
    }

    const menu = (
        <Menu onClick={e => addSection(e.key as any)}>
            <Menu.Item key="text">Text</Menu.Item>
            <Menu.Item key="table">Table</Menu.Item>
        </Menu>
    )

    return (
        <div className={className} style={style}>
            <Dropdown.Button overlay={menu} style={{ marginBottom: '16px' }}>
                Add Section
            </Dropdown.Button>
            {sections?.map(section => {
                return (
                    <div className={styles.section} key={section.id}>
                        <Input
                            className={styles.collapsable}
                            placeholder="Header for this section"
                            defaultValue={section.header}
                            onChange={e => handleHeader(section.id, e.target.value)}
                        />
                        <Input
                            className={styles.collapsable}
                            placeholder="Header size for this section, e.g. 'h1', 'h2' or 'h3'"
                            defaultValue={section.headerSize}
                            onChange={e => handleHeaderSize(section.id, e.target.value)}
                        />
                        <Input
                            className={styles.collapsable}
                            placeholder="Name of collapsable section"
                            defaultValue={section.collapsable}
                            onChange={e => handleCollapsable(section.id, e.target.value)}
                        />
                        {section.type === 'text' && handleTextSection(section)}
                        {section.type === 'table' && handleTableSection(section)}
                        <Button onClick={() => removeSection(section.id)} className={styles.remove}>
                            Remove Section
                        </Button>
                    </div>
                )
            })}
        </div>
    )
}

function getInitialSections(value?: BlogArticleValue): BlogArticleSection[] {
    return (value?.sections?.map(item => {
        if (item.type === 'text') {
            return {
                ...item,
                content:
                    typeof item.content === 'string' ? RichTextEditor.createValueFromString(item.content as any, 'markdown') : item.content
            }
        }
        return item
    }) ?? []) as any
}
