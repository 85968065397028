import React from 'react'

import { useSelector } from '../../reducers/store'

import { Dropdown } from '../ui/Dropdown/Dropdown'
import { AddArray, AddArrayProps } from '../ui/AddArray/AddArray'
import { Input } from '../ui/Input/Input'

import { useVisas } from '../../hooks/useVisa'

import { practiceLevels } from '../../types/expert'

export const VisasSelectOrder = (props: AddArrayProps) => {
    useVisas()
    const visasOption = useSelector(store => store.experts.visas)?.map(item => ({ value: item.code, label: item.label }))
    return (
        <AddArray
            keyRenderer={<Dropdown options={visasOption} />}
            valueRenderer={<Dropdown options={practiceLevels} />}
            orderRenderer={<Input placeholder="Order" type="number" />}
            {...props}
        />
    )
}
