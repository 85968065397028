import { useContext, useMemo } from 'react'
import { WindowSizeContext } from '../components/WindowSize'

const useMobileView = (): {
    isMobile: boolean
} => {
    const { width: windowWidth } = useContext(WindowSizeContext)
    const isMobile = useMemo(() => windowWidth < 960, [windowWidth])

    return {
        isMobile
    }
}

export default useMobileView
