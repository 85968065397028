import { useState, useCallback } from 'react'

export function useToggle(initialState?: boolean) {
    const [show, setShow] = useState(initialState ?? false)

    const handleToggle = useCallback(() => {
        setShow(!show)
    }, [show])

    return [show, handleToggle, setShow] as const
}
