import { useEffect } from 'react'
import { fetchRCManager, fetchRCManagers } from '../actions/rcmanagers'
import { addRCMFilter, clearRCMSelected, removeAllRCMFilters } from '../reducers/regionalCenters'
import { useDispatch } from '../reducers/store'
import { Filter } from '../types/filter'

export function useRCManagers(filter?: Filter, keepOnUnmount?: boolean) {
    const dispatch = useDispatch()
    useEffect(() => {
        if (filter) {
            dispatch(addRCMFilter(filter))
        }
        dispatch(fetchRCManagers())
        return () => {
            if (!keepOnUnmount) {
                dispatch(removeAllRCMFilters())
            }
        }
    }, [dispatch, filter, keepOnUnmount])
}

export function useRCManager(managerId: string) {
    const dispatch = useDispatch()
    useEffect(() => {
        if (managerId !== 'new') {
            dispatch(fetchRCManager(managerId))
        }
        return () => {
            dispatch(clearRCMSelected())
        }
    }, [managerId, dispatch])
}
